// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import { Promise } from "es6-promise";
import httpRequest from "../../../Utils/httpRequest";
import { getCookie } from "../../../Utils/cookies";

export const getFacilityManagerList = async (page) => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse.data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    if (getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER") {
      httpRequest.get(
        "facilityManagerListView",
        {},
        successCallback,
        errorCallback,
        {
          page: page,
          size: 10,
        }
      );
    } else {
      httpRequest.get(
        "resFacilityManagerListView",
        {},
        successCallback,
        errorCallback,
        {
          page: page,
          size: 10,
        }
      );
    }
  });
};

export const deleteFacilityManager = async (deleteId) => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse);
    };
    const errorCallback = (error) => {
      reject(error);
    };

    httpRequest.deleteData(
      "deleteSecurity",
      {
        userIds: deleteId,
        adminRemarks: "delete this user",
      },
      {
        "Content-Type": "application/json",
      },
      successCallback,
      errorCallback,
      {}
    );
  });
};
export const createFacilityManager = async (name, email) => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse.data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    if (getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER") {
      httpRequest.post(
        "createSecurity",
        {
          userName: name,
          corpEmailId: email,
          role: {
            name: "FACILITY_ADMIN",
          },
          // "parking":[{
          //   "parkingId": parkingSpace
          // }]
        },
        {
          "Content-Type": "application/json",
        },
        successCallback,
        errorCallback,
        {}
      );
    } else {
      httpRequest.post(
        "createSecurity",
        {
          userName: name,
          corpEmailId: email,
          role: {
            name: "RESIDENTIAL_FACILITY_ADMIN",
          },
          // "parking":[{
          //   "parkingId": parkingSpace
          // }]
        },
        {
          "Content-Type": "application/json",
        },
        successCallback,
        errorCallback,
        {}
      );
    }
  });
};
export const editFacilityManagerList = async (name, email, adminId) => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse.data);
    };
    const errorCallback = (error) => {
      reject(error);
    };
    if (getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER") {
      httpRequest.patch(
        "editFcm",
        {
          userName: name,
          corpEmailId: email,
          role: {
            name: "FACILITY_ADMIN",
          },
        },
        {
          "Content-Type": "application/json",
        },
        successCallback,
        errorCallback,
        {
          userId: adminId,
        }
      );
    } else {
      httpRequest.patch(
        "editFcm",
        {
          userName: name,
          corpEmailId: email,
          role: {
            name: "RESIDENTIAL_FACILITY_ADMIN",
          },
        },
        {
          "Content-Type": "application/json",
        },
        successCallback,
        errorCallback,
        {
          userId: adminId,
        }
      );
    }
  });
};
