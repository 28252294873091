// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { updateToasterConfig } from "./../../../src/redux/modules/app/actions";
import httpRequest from "../../Utils/httpRequest";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../redux/modules/app/actions";

import {
  CardContent,
  CardHeader,
  Card,
  Button,
  Grid,
  Container,
} from "@material-ui/core";
import { getCookie } from "../../Utils/cookies";
import Modal from "./Modal";
import Avatar from "@material-ui/core/Avatar";
import "./Profile.scss";
import TokenStorage from "../../Utils/tokenstorage";

//@component
//Profile
class Profile extends Component {
  //static subscription;
  constructor(props) {
    super(props);
    this.state = {
      userImg: "",
      userName: "",
      userOrg: "",
      userEmail: "",
      profileImage: "",
      imgLink: "",
      userId: "",
      imgData: "",
      adminUser: "",
      mobile: "",
      orgId: "",
      role: "",
      status: "",

      subscription: {},
    };
  }
  getSubSuccess = (json) => {
    console.log(json);
    const subs = json.data[0] ? json.data[0] : [];
    console.log(subs);
    this.setState({ subscription: subs });
    this.setState({ status: subs.status.name });
  };

  getSubError(json) {
    console.log(json);
  }

  getSubscriptionDetails() {
    httpRequest.get(
      "subscriptionDetails",
      {},
      this.getSubSuccess,
      this.getSubError,
      {
        orgId: this.state.orgId,
        role: this.state.role,
      }
    );
  }

  //function logout
  logout = () => {
    let cliSec = getCookie("cliSec");
    let cliId = getCookie("cliId");
    httpRequest.patch(
      "logout",
      {
        refreshToken: getCookie("refresh"),
        clientId: cliId,
        clientSecret: cliSec,
      },
      this.logoutSuccess,
      this.errorLogout
    );
  };
  //function error callback
  errorLogout = () => {
    this.props.updateToasterConfig({
      show: true,
      message: "Successfully logged out",
      color: "#02C194",
    });
    TokenStorage.clear();
    window.location.href = "/login";
  };
  //function logout success
  logoutSuccess = () => {};
  //function for modal
  modalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };
  //function for login click
  onLoginClick = () => {
    this.showModal();
  };
  //function for user data success
  UserDataSuccess = (jsonResult) => {
    console.log(jsonResult);
    this.setState({
      imgLink: jsonResult?.data?._links?.profileImage.href,
      userId: jsonResult?.data?.userId,
    });
    httpRequest.get(
      "userProfile",
      {},
      this.imageSuccess,
      this.errorCallbackImage,
      {
        userId: this.state.userId,
      }
    );
    this.setState({
      firstName: jsonResult?.data
        ? jsonResult.data.firstName
          ? jsonResult.data.firstName[0].toUpperCase() +
            jsonResult.data.firstName.slice(1)
          : ""
        : "",
      lastName: jsonResult?.data
        ? jsonResult.data.lastName
          ? jsonResult.data.lastName[0].toUpperCase() +
            jsonResult.data.lastName.slice(1)
          : ""
        : "",
      userName: jsonResult.data.org.name,
      adminUser: jsonResult.data
        ? jsonResult.data.userName
          ? jsonResult.data.userName
          : "--"
        : "--",
      userOrg: jsonResult.data.org.orgAcronym,
      userEmail: jsonResult.data.corpEmailId,
      userImg: jsonResult.data._links.profileImage.href,
      mobile: jsonResult.data?.mobileNumber,
      orgId: jsonResult.data?.org?.orgId,
      role: jsonResult.data?.role?.name,
    });

    this.getSubscriptionDetails();
  };
  errorCallbackData = (e) => {
    if (e) {
      // console.log("dataobject")
    }
  };
  //function for image success - not implemented
  imageSuccess = () => {
    // httpRequest.get(
    //   'detailsImgTwo',
    //   {
    //     Accept: 'image/png',
    //   },
    //   this.imageSuccessTwo,
    //   this.errorCallback,
    //   {
    //   },
    // );
    //  console.log(jsonResult.data._links.profileImage.href)
  };
  //function for image success two
  imageSuccessTwo = (jsonResult) => {
    this.setState({
      imgData: jsonResult.data,
    });
  };
  errorCallbackTwo = (e) => {
    if (e) {
      // console.log("image 2 error block")
    }
  };
  //function for error callback
  errorCallbackImage = () => {
    console.log("image error block");
  };
  //function for component mount for user profile
  componentDidMount() {
    httpRequest.get(
      "userProfile",
      {},
      this.UserDataSuccess,
      this.errorCallbackData,
      {
        userId: getCookie("userId"),
      }
    );
    this.props.updateBreadcrumbs("profile");
  }

  editRouteHandler = () => {
    this.props.history.push("/portal/profileedit");
  };

  render() {
    return (
      <>
        {this.state.userName !== " " && (
          <Container style={{ width: "96%" }}>
            <Card raised={true}>
              <CardHeader
                id="headerColor"
                title="User Profile"
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} style={{ alignSelf: "center" }}>
                    <Avatar
                      variant="square"
                      src={`data:image/png;base64,${this.state.imgData}`}
                      className="picture"
                    ></Avatar>
                    <div className="btnContainer" style={{ display: "none" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.logout}
                      >
                        Logout
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={9} sm={12}>
                    <p className="profileName">
                      {this.state.firstName + " " + this.state.lastName}
                    </p>
                    <div className="info">
                      <div className="left">
                        <p>Role</p>
                        <p>Company</p>
                        <p>Username</p>
                        <p>Contact Number</p>
                        <p>Contact Email</p>
                      </div>
                      <div className="right">
                        <p>
                          :{" "}
                          {getCookie("role") === "SECURITY_PERSONNEL"
                            ? "Security Personnel"
                            : "Parking Solution Admin"}
                        </p>
                        <p>
                          : {this.state.userName ? this.state.userName : "--"}
                        </p>
                        <p>
                          : {this.state.adminUser ? this.state.adminUser : "--"}
                        </p>
                        <p>
                          : +91 {this.state.mobile ? this.state.mobile : "--"}
                        </p>
                        <p>
                          : {this.state.userEmail ? this.state.userEmail : "--"}
                        </p>
                      </div>
                    </div>
                    <Modal />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* {subscription details} */}

            {/* <Card style={{ marginTop: "2vh" }} raised={true}>
              <CardHeader
                id="headerColor"
                title="Subscription Details"
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={9} sm={12}>
                    <div className="info">
                      <div className="left">
                        <p>Subscription ID</p>
                        <p>Duration</p>
                        <p>Start Date</p>
                        <p>End Date</p>
                      </div>
                      <div className="right">
                        <p>
                          :{" "}
                          {this.state.subscription?.subscriptionId
                            ? this.state.subscription?.subscriptionId
                            : "--"}
                        </p>
                        <p>
                          :{" "}
                          {this.state.subscription?.subscriptionTypeDto
                            ? this.state.subscription?.subscriptionTypeDto
                                .duration
                            : "--"}
                          {" " + "Months"}
                        </p>
                        <p>
                          :{" "}
                          {

                          `${
                            this.state.subscription?.subscriptionStartDate
                              ? this.state.subscription?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[2]
                              : "--"
                          }/${
                            this.state.subscription?.subscriptionStartDate
                              ? this.state.subscription?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[1]
                              : "--"
                          }/${
                            this.state.subscription?.subscriptionStartDate
                              ? this.state.subscription?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[0]
                              : "--"
                          }`}
                        </p>
                        <p>
                          :{" "}
                          {

                          `${
                            this.state.subscription?.subscriptionEndDate
                              ? this.state.subscription?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[2]
                              : "--"
                          }/${
                            this.state.subscription?.subscriptionEndDate
                              ? this.state.subscription?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[1]
                              : "--"
                          }/${
                            this.state.subscription?.subscriptionEndDate
                              ? this.state.subscription?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[0]
                              : "--"
                          }`}
                        </p>
                      </div>

                      <div style={{ marginLeft: "10vw", display: "flex" }}>
                        <p>status</p>:{" "}
                        <p
                          style={{
                            color:
                              this.state.status === "ACTIVE" ? "green" : "red",
                          }}
                        >
                          {this.state.subscription.status
                            ? this.state.subscription.status?.name
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card> */}
          </Container>
        )}
      </>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(Profile)
);
