import { Promise } from 'es6-promise';
import httpRequest from '../../../Utils/httpRequest';


export const getOrgList = async (page) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.get(
          'orgList',
          {
            'Content-Type': 'application/json',
          },
          successCallback,
          errorCallback,
          {
            page: page,
            size:10
          }
        )
    })
}

export const deleteOrgApi = async (deleteId) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.delete(
          'orgDelete',
          {
            'Content-Type': 'application/json'
          },
          successCallback,
          errorCallback,
          {
            orgId: deleteId
          },
        )
    })
}
export const createOrg = async (name,acr) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.post(
            'addOrg',
            {
              "name":name,
              "orgAcronym":acr
            },
            {
              'Content-Type': 'application/json',
            },
            successCallback,
            errorCallback,
            {},
          )
    })
}
export const editOrgApi = async (name,acr,orgId) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.patch(
          'orgUpdate',
          {
            "name":name,
            "orgAcronym":acr
            
          },
          {
            'Content-Type': 'application/json' ,     
          },
          successCallback,
          errorCallback,
          {
            orgId: orgId,
          },
        )
    })
}
export const getOrgById = async (orgId) => {
  return new Promise((resolve, reject) => {
      const successCallback = (jsonResponse) => {
          resolve(jsonResponse.data)
      }
      const errorCallback = (error) => {
          reject(error)
      }

      httpRequest.get(
        "orgDetailsbyId",
        {
          "Content-Type": "application/json",
        },
        successCallback,
        errorCallback,
        {
          orgId: orgId,
        }
      );
  })
}








