// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./forgot.scss";
import Loader from "../../../Components/Preloader";
//@component
//SendOtp
function SendOTP(props) {
  const [username, setUsername] = useState("");
  const [isLoading] = useState(false);
  const [error, setError] = useState(false);

  // function to remove read only property
  const removeReadOnly = () => {
    document.getElementById("username").removeAttribute("readOnly");
  };
  // function to set username to react parent component state as callback
  const handleSetUsername = (event) => {
    setUsername(event.target.value);
    props.setUsername(event.target.value);
  };
  // function to handle Send OTP button callback
  const handleSubmit = () => {
    if (!username) {
      return setError("Username is required");
    }
    props.handleOTPToggle();
  };
  // function to remove error message
  const dismissError = () => {
    setError("");
  };

  // useEffect(()=>{
  //   console.log("sub-component: ",props)
  // },[])

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__wrapper">
        {isLoading && <Loader />}
        <Card>
          <div className="ForgotPassword__wrapper_box">
            <div className="ForgotPassword__wrapper_box_header_text">
              <span>Forgot Password ?</span>
            </div>

            <div style={{ padding: "10px 80px 10px 16px" }}>
              <span className="label_text">
                Please enter your Email ID. We will send OTP to your registered
                email ID.
              </span>
              {error && (
                <h3 data-test="error" className="error" onClick={dismissError}>
                  <button onClick={dismissError}>✖</button>
                  {error}
                </h3>
              )}
              <label>Enter your Email ID*</label>
              <input
                placeholder="Enter the input detail"
                type="text"
                value={username}
                onChange={handleSetUsername}
                id="username"
                autoComplete="off"
                readOnly
                onFocus={removeReadOnly}
              />

              <Grid container justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    type="button"
                    onClick={props.history.goBack}
                    style={{
                      borderRadius: "25px",
                      fontSize: "0.65rem",
                      color: "#fff",
                      background: "grey",
                      margin: "1em",
                      width: "80px",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderRadius: "25px",
                      fontSize: "0.65rem",
                      margin: "1em",
                    }}
                    variant="contained"
                    color="primary"
                    type="button"
                    // disabled={!(this.state.username)}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Send OTP
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default withRouter(connect(null, null)(SendOTP));
