// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Booking.scss";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import httpRequest from "../../Utils/httpRequest";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../Components/Preloader/index";
import { CardContent, CardHeader, Card, Button, Grid } from "@material-ui/core";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../redux/modules/app/actions";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";

import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

import "./Booking.scss";
import styles from "./styles";
import MenuItem from "@mui/material/MenuItem";
import { getCookie } from "../../Utils/cookies";
const Booking = (props) => {
  const [parkingList, setParkinglist] = useState([]);
  const [parkingspace, setparkingspace] = useState("");
  const [slotCount, setslotCount] = useState(null);
  const [invalidNo, setInvalidNo] = useState("");
  const [emailError, setEmailError] = useState("");
  const [parkingId, setparkingId] = useState("");
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [email, setemail] = useState("");
  const [vehiclenum, setvehiclenum] = useState("");
  const [bookflag, setbookflag] = useState(false);
  const [bookingId, setbookingId] = useState("");
  ///----------------------------------------/////
  const [from, setfrom] = useState(new Date());
  const [to, setto] = useState(new Date());

  const emailRef = useRef(null);
  const vehiclenumRef = useRef(null);

  var coeff = 1000 * 60 * 15;

  const [starttime, setstarttime] = useState(
    new Date(
      new Date(Math.ceil(new Date().getTime() / coeff) * coeff)
    ).getTime()
  );

  var coeffe = 1000 * 60 * 15;

  const [endtime, setendtime] = useState(
    Math.ceil((new Date().getTime() + 30 * 60000) / coeffe) * coeffe
  );

  

 

  const [fromformat, setfromformat] = useState(
    `${new Date().getMonth() +
      1}/${new Date().getDate()}/${new Date().getFullYear()}`
  );
  const [toformat, settoformat] = useState(
    `${new Date().getMonth() +
      1}/${new Date().getDate()}/${new Date().getFullYear()}`
  );
  const [startformat, setstartformat] = useState(
   
    `${new Date(
      new Date(Math.ceil(new Date().getTime() / coeff) * coeff)
    ).getHours()}:${new Date(
      new Date(Math.ceil(new Date().getTime() / coeff) * coeff)
    ).getMinutes()}`
  );
  const [endformat, setendformat] = useState(
    
    `${new Date(
      Math.ceil((new Date().getTime() + 30 * 60000) / coeffe) * coeffe
    ).getHours()}:${new Date(
      Math.ceil((new Date().getTime() + 30 * 60000) / coeffe) * coeffe
    ).getMinutes()}`
  );
  //-----------------------------------------////
  const [loading, setloading] = useState(false);

  useEffect(() => {
    props.dispatch(updateBreadcrumbs("createBooking"));
    populateParkingDetails();
   
  }, []);

  const validateNumber = (vno) => {
    const re = /^([A-Z,a-z]{2}[0-9]{1,2}[A-Z,a-z]{1,2}[0-9]{1,6}|[A-Z,a-z]{2}\d{6}|([1-9][0-9]|10)[Bb][Hh][0-9]{4}[A-HJ-NP-Za-hj-np-z]{1,2})$/i;

    return re.test(String(vno).toLowerCase());
  };

  const vehicleNumberHandle = (event) => {
    if (event.target.value?.length < 5) {
      setInvalidNo("Please Enter Valid Vehicle Number, e.g: KA01XX1234");
      setvehiclenum(event.target.value);
    } else if (event.target.value?.length > 13) {
      setInvalidNo("Exceeded Maximum Limit Allowed");
    } else {
      let valid = validateNumber(event.target.value);
      if (valid === true) {
        let number = event.target.value.toUpperCase();
        setvehiclenum(number);
        setInvalidNo("");
      } else {
        setvehiclenum(event.target.value);
        setInvalidNo("Please Enter Valid Vehicle Number, e.g: KA01XX1234");
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const emailHandler = (event) => {
    if (event.target.value.length > 100) {
      setEmailError("Max.100 characters");
    }
    if (event.target.value.length <= 100) {
      if (validateEmail(event.target.value)) {
        setEmailError("");
        setemail(event.target.value);
      }
      if (!validateEmail(event.target.value)) {
        setemail(event.target.value);
        setEmailError("Enter valid email");
      }
      if (event.target.value.length < 5) {
        setEmailError("Minimum 5 characters allowed");
        setemail(event.target.value);
      }
      if (!event.target.value) {
        setEmailError("");
      }
    }
  };
  const parkingListSuccess = (json) => {
   
    setParkinglist(json.data._embedded.results);

  };

  const BookingSuccess = (json) => {
    setbookingId(json?.data?.bookingId);
    setbookflag(true);

    setloading(false);
    handleClose();
    setemail("");
    setvehiclenum("");
    setInvalidNo("");
    setEmailError("");
    refresh();
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "Booking Successfull",
        color: "#02C194",
      })
    );
  };

  const BookingError = (err) => {
    console.log(err);
    setloading(false);
    handleClose();
    setInvalidNo("");
    setEmailError("");
     props.dispatch(
      updateToasterConfig({
        show: true,
        message: err.message,
        color: "#FF0000",
      })
    );
  };

  
  const handleSubmit = () => {
    setIsdialogOpen(true);
  };

  const confirmBooking = () => {
    setloading(true);
    httpRequest.post(
      "Booking",
      {
        vehicle: {
          vehicleNumber: vehiclenum,
          vehicleType: {
            uid: "1",
            name: "CAR",
          },
        },
        startTime: parseInt(Date.parse(`${fromformat} ${startformat}`), 10),
        endTime: parseInt(Date.parse(`${toformat} ${endformat}`), 10),
        estimatedBill: 0,
        parking: {
          parkingId: parkingId,
        },
        emailId: email,
      },
      {
        "Content-Type": "application/json",
      },
      BookingSuccess,
      BookingError,
      {}
    );
  };

  

  const vehicleType = ["Car", "Bike"];
  useEffect(() => {
  

    if (
      parkingspace?.length < 1 ||
      parkingspace === "" ||
      parkingspace === undefined
    ) {
      setparkingspace(parkingList[0]?.name);
    }

    setparkingId(parkingList[0]?.parkingId);

  }, [parkingList]);

 

  const AvailabilitySuccess = (json) => {
    setslotCount(json?.data?.bookingAvailabilityCount4w);
    setloading(false);
  };

  const AvailabilityErr = (err) => {
    console.log(err);
    setslotCount(null);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: err.message,
        color: "#FF0000",
      })
    );
    setloading(false);
  };

  const getBookingAvailability = () => {
    setloading(true);
    if (parkingId !== undefined) {
      if (from > to) {
        setslotCount(null);
        props.dispatch(
          updateToasterConfig({
            show: true,
            message: "please select the correct date and time",
            color: "#FF0000",
          })
        );
      } else {
        parkingId.length > 0 &&
          httpRequest.get(
            "BookingAvailabilityCount",
            {},
            AvailabilitySuccess,
            AvailabilityErr,
            {
              parkingId: parkingId,
              from: parseInt(Date.parse(`${fromformat} ${startformat}`), 10),
              to: parseInt(Date.parse(`${toformat} ${endformat}`), 10),
            }
          );
      }
    }
  };

  

  /////////////---------availability---------------------////////////////////
  useEffect(() => {
    getBookingAvailability();
  }, [parkingId]);

  useEffect(() => {
    getBookingAvailability();
  }, [fromformat, toformat, startformat, endformat]);

  const populateParkingDetails = () => {
    httpRequest.get(
      "bookingparkingList",
      {},
      parkingListSuccess,
      (error) => {
        console.log(error);
      },
      {
        userId:getCookie('userId')
      }
    );
  };
  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        verticalAlign: "middle",
        fontSize: "12px",
        fontWeight: "600",
        width: (params) => (params.width ? params.width : "auto"),

        "& fieldset": {
          border: "solid 2px #0070D8",
        },
        "& .MuiInputBase-root": {
          height: (params) => (params.height ? params.height : "auto"),
          color: (params) => (params.color ? params.color : "inherit"),
        },
      },
    })
  );

  const refresh = () => {
    // populateParkingDetails();
    // setparkingspace(parkingspace);
    getBookingAvailability();
  };
  const handleParkingchange = (e) => {
    setparkingspace(e.target.value);

    
    parkingList.forEach((ele) => {
      if (ele.name === e.target.value) {
        setparkingId(ele.parkingId);
      }
    });
  };
  const classes = styles();
  const classesDatePicker = useStyles({
    color: "red",
    width: "100%",
    height: "100%",
  });

  const startHandler = (e) => {

    setstarttime(e._d.getTime());
    setstartformat(
      `${new Date(e._d).getHours()}:${new Date(e._d).getMinutes()}`
    );
  };

  const endHandler = (e) => {
    //setendtime(`${new Date(e._d).getHours()}:${new Date(e._d).getMinutes()}`);
    setendtime(e._d.getTime());

    setendformat(`${new Date(e._d).getHours()}:${new Date(e._d).getMinutes()}`);
  };

  const fromHandler = (e) => {
    setfrom(e._d.getTime());

 

    setfromformat(
      `${e._d.getMonth() + 1}/${e._d.getDate()}/${e._d.getFullYear()}`
    );
  };

  const toHandler = (e) => {
    setto(e._d.getTime());
    settoformat(
      `${e._d.getMonth() + 1}/${e._d.getDate()}/${e._d.getFullYear()}`
    );
  };

  

  const handleClose = () => {
    setIsdialogOpen(false);
   
  };

  const handleCancel = () => {
    
    setIsdialogOpen(false);
  };

  const handleBookpopup = () => {
    setbookflag(false);
  };

  const handleView = () => {
    props.history.push("/portal/viewbookings");
  };
  return (
    <>
      {loading && <Loader />}
      <Card style={{ margin: "15px" }}>
        <CardHeader
          title="Create Booking"
          titleTypographyProps={{ variant: "h6" }}
        />

        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <CardContent style={{ paddingLeft: "2vw" }}>
            <p
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Parking Space Name*
            </p>

            {parkingList && (
              <Select
                onChange={handleParkingchange}
                value={parkingspace}
                style={{ width: "100%" }}
              >
                {parkingList.length > 0 &&
                  parkingList?.map((ele) => (
                    <MenuItem value={ele.name}>{ele.name}</MenuItem>
                  ))}
              </Select>
            )}
            <p
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Date and Time*
            </p>
            {/* <Select style={{ width: "100%", marginBottom: "10px" }} />
          <Select style={{ width: "100%" }} /> */}
            <div
              style={{
                // display: "flex",
                // width: "100%",
                // justifyContent: "space-between",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                gap: "10px",
              }}
            >
              <Grid item md={3} className={classes.datePicker}>
                <label className={classes.dateLabel}>From </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    InputProps={{
                      disableUnderline: true,
                      className: classesDatePicker.componentStyle,
                    }}
                    autoOk={true}
                    disablePast={true}
                    disableFuture={false}
                    onKeyDown={(e) => e.preventDefault()}
                    variant="inline"
                    ampm={false}
                    className={classes.fromDatePicker}
                    label=""
                    value={from}
                    onChange={fromHandler}
                    format="DD/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={3} className={classes.datePicker}>
                <label className={classes.dateLabel}>Start </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    InputProps={{
                      disableUnderline: true,
                      className: classesDatePicker.componentStyle,
                    }}
                    minutesStep={15}
                    keyboardIcon={<AccessTimeOutlinedIcon />}
                    autoOk={true}
                    disableFuture={false}
                    disablePast={true}
                    onKeyDown={(e) => e.preventDefault()}
                    //minDate={fromTime}
                    variant="inline"
                    ampm={false}
                    className={classes.toDatePicker}
                    label=""
                    value={starttime}
                    onChange={startHandler}
                    //format="DD/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              {/* 
            time----------------------------------------------------------------> */}
              <Grid item md={3} className={classes.datePicker}>
                <label
                  className={classes.dateLabel}
                  style={{ marginRight: "22px" }}
                >
                  To{" "}
                </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    InputProps={{
                      disableUnderline: true,
                      className: classesDatePicker.componentStyle,
                    }}
                    autoOk={true}
                    disableFuture={false}
                    disablePast={true}
                    onKeyDown={(e) => e.preventDefault()}
                    //minDate={fromTime}
                    variant="inline"
                    ampm={false}
                    className={classes.toDatePicker}
                    label=""
                    value={to}
                    onChange={toHandler}
                    format="DD/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item md={3} className={classes.datePicker}>
                <label
                  className={classes.dateLabel}
                  style={{ marginRight: "10px" }}
                >
                  End{" "}
                </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    InputProps={{
                      disableUnderline: true,
                      className: classesDatePicker.componentStyle,
                    }}
                    minutesStep={15}
                    keyboardIcon={<AccessTimeOutlinedIcon />}
                    autoOk={true}
                    disableFuture={false}
                    disablePast={true}
                    onKeyDown={(e) => e.preventDefault()}
                    //minDate={fromTime}
                    variant="inline"
                    ampm={false}
                    className={classes.toDatePicker}
                    label=""
                    value={endtime}
                    onChange={endHandler}
                    //format="DD/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </div>
            <p
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Email ID*
            </p>
            <OutlinedInput
              ref={emailRef}
              sx={{ width: "100%" }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              onChange={emailHandler}
              value={email}
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Enter Email ID"
            />
            {
              <label style={{ color: "red", marginTop: "5px" }}>
                {emailError}
              </label>
            }
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Vehicle Type*
                </p>
                <Select
                  disabled={true}
                  value={vehicleType[0]}
                  style={{ width: "100%" }}
                >
                  {vehicleType?.map((ele) => (
                    <MenuItem value={ele}>{ele}</MenuItem>
                  ))}
                </Select>
              </div>

              <div>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Vehicle Number*
                </p>
                <OutlinedInput
                  ref={vehiclenumRef}
                  sx={{ width: "100%" }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter Vehicle Number"
                  onChange={vehicleNumberHandle}
                  value={vehiclenum}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
                {invalidNo !== "" && (
                  <label style={{ color: "red", marginTop: "5px" }}>
                    {invalidNo}
                  </label>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "10vh",
                display: "flex",
                justifyContent: "space-between",
                width: "15vw",
                marginBottom: "3vh",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="button"
                disabled={
                  email == "" ||
                  vehiclenum == "" ||
                  emailError != "" ||
                  invalidNo != "" ||
                  slotCount == 0
                }
                className={classes.bookNow}
              >
                Book now
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.history.push("/portal/viewbookings");
                }}
                type="button"
               
              >
                Cancel
              </Button>
            </div>
          </CardContent>

          <div className="available-slots">
            <p style={{ fontSize: "1vw", fontWeight: "bold" }}>
              Available Booking Slots
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",

                alignItems: "center",
              }}
            >
              {/* <----------solts-----------> */}
              <p
                style={{
                  fontSize: "2.5vw",
                  color:
                    slotCount > 0
                      ? "green"
                      : slotCount == null
                      ? "gray"
                      : "red",
                }}
              >
                {slotCount > 0 ? slotCount : slotCount === 0 ? "0" : "--"}
              </p>
              <div
                onClick={refresh}
                style={{
                  height: "1.5vw",
                  width: "1.5vw",
                  backgroundColor: "white",
                  borderRadius: "0.25vw",
                  border: "1px solid #03A9F3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px",
                  cursor: "pointer",
                }}
              >
                <AutorenewOutlinedIcon
                  style={{ color: "#03A9F3", fontSize: "16px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Dialog
        open={IsdialogOpen}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={handleClose}
        aria-labelledby="create-security-admin-dialog"
      >
        {loading && <Loader />}
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">Create Booking</span>
            </Grid>
            {/* <Grid item>
                  <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                </Grid> */}
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
          className="dialog-content"
        >
          <p className="dialog-content-text">
            Do you want to create this Booking ?
          </p>

          <p className="dialog-content-text">
            Email Address:
            <span style={{ fontWeight: "bolder" }}>{email}</span>{" "}
          </p>
          <p className="dialog-content-text" style={{ marginTop: "10px" }}>
            Vehicle no:{" "}
            <span style={{ fontWeight: "bolder" }}>{vehiclenum}</span>
          </p>
          <p className="dialog-content-text" style={{ marginTop: "10px" }}>
            From: <span style={{ fontWeight: "bolder" }}>{fromformat}</span>
          </p>
          <p className="dialog-content-text" style={{ marginTop: "10px" }}>
            Start: <span style={{ fontWeight: "bolder" }}>{startformat}</span>
          </p>
          <p className="dialog-content-text" style={{ marginTop: "10px" }}>
            To: <span style={{ fontWeight: "bolder" }}>{toformat}</span>
          </p>
          <p className="dialog-content-text" style={{ marginTop: "10px" }}>
            End: <span style={{ fontWeight: "bolder" }}>{endformat}</span>
          </p>
          {/* <p className="dialog-content-text">
            Role:
            <span data-testid="role" style={{ fontWeight: "bolder" }}>
              {getCookie("role") === "RESIDENTIAL_GROUP_PARKING_PROVIDER"
                ? "RESIDENTIAL FACILITY ADMIN"
                : "FACILITY ADMIN"}
            </span>
          </p> */}
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button
            type="button"
            id="confirmSubmit"
            onClick={confirmBooking}
            data-testid="confirm-button"
            className="confirm-button"
          >
            Confirm
          </button>
          <button
            type="button"
            id="cancelPopup"
            onClick={handleCancel}
            data-testid="cancel-popup"
            className="cancel-button"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      {/* /////////////////////////////// */}

      <Dialog
        //fullScreen={fullScreen}
        open={bookflag}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={handleClose}
        aria-labelledby="create-security-admin-dialog"
      >
        {loading && <Loader />}
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">Booking Success</span>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
          className="dialog-content"
        >
          <p className="dialog-content-text">
            Booking Id :
            <span style={{ fontWeight: "bolder" }}>{bookingId}</span>{" "}
          </p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button
            type="button"
            id="confirmSubmit"
            onClick={handleBookpopup}
            data-testid="confirm-button"
            className="confirm-button"
          >
            stay on Page
          </button>
          <button
            type="button"
            id="cancelPopup"
            onClick={handleView}
            data-testid="cancel-popup"
            className="cancel-button"
          >
            View Booking
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(Booking));
