// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-env node */
export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
export const UPDATE_TOASTER_CONFIG = "UPDATE_TOASTER_CONFIG";
export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";
export const UPDATE_APPLIED_FILTER_DATA = "UPDATE_APPLIED_FILTER_DATA";

export const UPDATE_SIDEBAR = "UPDATE_SIDEBAR";
export const UPDATE_BUBBLE = "UPDATE_BUBBLE";
