// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import { makeStyles } from '@material-ui/core/styles'

const detailsUseStyles = makeStyles(theme => ({
    selectNavCard: {
        height: "4em", width: "5em", border: 0, borderRadius: "22px",
    },
    tableGrid: {
        marginTop: "2em", marginBottom: "2em", width: "96%", height: "50%", marginLeft: "1.5em"
    },
    table: {
        minWidth: 650,
    },
    editButton: {
        ...theme.typography.view,
        borderRadius: 90,
        height: "2vw",
        width: '7vw',
        fontSize: "0.80rem",
        border: "none",
        fontWeight: "200",
        textTransform: "none",
        backgroundColor: "#e46a76",
        color: "#fff"
    },
    edit_icon: {
        height: '1vw',
        width: '1vw'

    },
    noData: {
        fontSize: '13px',
        textAlign: 'center',
        height: '40vh',
        display: 'grid',
        alignContent: 'center'

    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "12px",
        fontWeight: "bolder",
        // width: 17%;
    },
    header: {
        backgroundColor: "#E5E2EF",
        height: "4vw",
        alignContent: 'center',
        alignItems: 'center'
    },
    headerName: {
        fontWeight: '600',
        marginLeft: '4vw',
        fontSize: '15px !important'
    },
    keyStyle: {
        fontWeight: "600", fontSize: "12px"
    },
    detailsCard: {
        zIndex: 0,
        boxShadow: '0px 3px 6px #00000029 !important',
        marginTop: "16px",
        width: "96%",
        height: "100%",
        marginLeft: "2%"
    },
    statusChangeButton: {
        ...theme.typography.view,
        borderRadius: 50,
        height: "2.5em",
        width: "10em",
        fontSize: "0.71rem",
        border: "none",
        fontWeight: "600",
    },
    backGrid: {
        marginLeft: '2%',
        marginBottom: '6.5%'
    },
    backButton: {
        color: '#fff',
        width: '7vw',
        border: 'none',
        cursor: 'pointer',
        height: '2vw',
        fontSize: '0.80rem',
        boxShadow: '0px 3px 6px #00000029 !important',
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px',
        backgroundColor: '#03A9F3',


    },
    dialogButton: {
        ...theme.typography.view,
        borderRadius: 50,
        color: "#fff",
        height: "2.5em",
        width: "7em",
        fontSize: "0.71rem",
        border: "none",
        fontWeight: "600",
        marginBottom: "2em"
    },
    uploadDialogTitle: {
        fontWeight: "700", borderBottom: "1px solid #808080", textAlign: "left",
    },
    superAdminComment: {
        fontWeight: "600", fontSize: "0.60rem"
    },
    superAdminCommentMargin: {
        marginTop: "3.5em", marginBottom: "2em"
    },
    labelMargin: {
        marginTop: "1.5em"
    },
    createdDateGrid: {
        marginLeft: "2em", marginTop: "1.5em"
    },
    createdDatep: {
        fontSize: "0.65rem"
    },
    textArea: {
        width: "100%", height: "10em", borderRadius: "5px"
    },
    statusText: {
        fontWeight: "600", fontSize: "0.95rem", marginLeft: "2em", color: "#F49D3E", marginTop: "1em"
    },
    statusLabel: {
        fontWeight: "600", fontSize: "0.60rem", marginLeft: "2em"
    },
    complaintDescription: {
        fontSize: "0.65rem",
        maxWidth: "12em",
        height: "4em",
        overflowX: "auto",
        overflowY: "auto"
    },
    gridComplaintDescription: {
        marginLeft: "2em", width: "50%", marginTop: "1.5em"
    },
    dialog: {
        zIndex: 0
    },
    dialogActionGrid: {
        marginTop: "0.5em",
        paddingBottom: '0',
    },
    dialogSubTitle: {
        fontFamily: '\'Poppins\' !important',
        fontSize: '12px',
        letterSpacing: '1px',
        alignSelf: 'center',
        fontWeight:'600'
      },
      dialogAddress: {
        fontSize: '10px',
        fontFamily: '\'Poppins\' !important',
        marginBottom: '0.5vw',
        marginLeft: '2.5vw',
        marginTop: '-0.3vw'
      },
    dialogCancel: {
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px',
        cursor: 'pointer',
        border: "none",
        fontSize: "10px",
        fontWeight: "900",
        color: "#fff",
        backgroundColor: "#707070",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
        minWidth: "64px",
        boxSizing: "border-box",
        lineHeight: "1.75",
        letterSpacing: "1px",
        padding: "9px 27px",
        textDecoration: "none",
        verticalAlign: "middle",
        marginRight: "20px",
    },
    dialogCancelGrid: {
        textAlignLast: "right"
    },
    dialogHeader: {
        fontFamily: '\'Poppins\' !important',
        fontSize: "14px",
        letterSpacing: "0.5px",
        fontWeight: 900,

    },
    dialogConfirm: {
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px',
        cursor: 'pointer',
        border: "none",
        fontSize: "10px",
        fontWeight: "900",
        color: "#fff",
        backgroundColor: "#03A9F3",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
        minWidth: "64px",
        boxSizing: "border-box",
        lineHeight: "1.75",
        letterSpacing: "1px",
        padding: "9px 27px",
        textDecoration: "none",
        verticalAlign: "middle"
    },
}));

export default detailsUseStyles;