// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateShowModal } from '../../redux/modules/app/actions';
import Button from "@material-ui/core/Button";

//@component
//Toaster
class Popup extends Component {
  constructor() {
    super();
    this.state = {
    pop:false
    };

  }
  //function for component mount
  componentDidMount = () => {
    this.setState({ pop: true });
    // setTimeout(() => {
    //   this.hideToaster();
    // }, 5000);
  };
  //function for hideToaster
  popUpClose = () => {
    this.setState({ pop: false });
   
  };
  render() {
    //;
    return (
      this.state.pop && (
      <div className="deletepup-wrapper">
      <div className="password-popup">
        <div className="heading">
          <p className="popup-heading">Account Deactivated</p>
        </div>
        <p className="popup-text">
        Your account has been deactivated. Kindly contact the administrator
        </p>
        <div className="popup-button">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={this.popUpClose}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
      )
    );
  }
}
//function for redux connect
export default connect(
  (state) => ({
    showModal: state.app.showModal,
  }),
  {
    updateShowModal,
  },
)(Popup);
