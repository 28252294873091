// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import "./dateSlider.scss";
import {
  addWeeks,
  format,
  getDate,
  isBefore,
  isSameDay,
  isAfter,
  subDays,
  subWeeks,
} from "date-fns";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";

//@component
//DatePicker
export default function DatePicker(props) {
  let todayDate = new Date();
  let yestdate = todayDate - 24 * 60 * 60 * 1000;
  const [softSelect, setSoftSelect] = useState(new Date(yestdate));
  const [currentWeek, setCurrentWeek] = useState(new Date(yestdate));
  const [currentDate] = useState(new Date(yestdate));
  const { endDate, shouldScroll } = props;
  let { selectDate } = props;
  var dtselect = new Date(yestdate);
  const [selectedDate, setSelectedDate] = useState(
    dtselect.setDate(dtselect.getDate())
  );
  let scroll = false;
  shouldScroll === true ? (scroll = true) : (scroll = false);
  let maxValue;
  if (scroll === false) {
    maxValue = 2;
  } else {
    maxValue = endDate || 8;
  }
  //function for getStyles
  const getStyles = (day) => {
    const classes = [];
    if (isSameDay(day, selectedDate)) {
      classes.push("DateDayItem--selected");
    }
    if (isBefore(day, currentDate)) {
      classes.push("DateDayItem--disabled");
    }
    if (isAfter(day, currentDate)) {
      classes.push("DateDayItem--disabled");
    }
    return classes.join(" ");
  };
  //function for getting id
  const getId = (day) => {
    if (isSameDay(day, selectedDate)) {
      return "selected";
    } else {
      return "";
    }
  };
  //function for get scroll
  const getScroll = () => {
    if (scroll === true) {
      return "Datepicker--DateList--scrollable";
    } else {
      maxValue = 8;
      return "Datepicker--DateList";
    }
  };
  //function for days
  function renderDays() {
    const dayFormat = "EEEE";
    const dateFormat = "d";
    const dateFormats = "MMM yyyy";
    const days = [];
    let startDay = new Date(currentWeek);
    for (let i = 0; i < maxValue; i++) {
      days.push(
        <div
          id={`${getId(subDays(startDay, i))}`}
          className={`Datepicker--DateDayItem ${getStyles(
            subDays(startDay, i)
          )}`}
          key={i * i + 2}
          onClick={() => onDateClick(subDays(startDay, i))}
        >
          {getDate(subDays(startDay, i)) === 1 ? (
            <Waypoint
              horizontal={true}
              onEnter={() => setSoftSelect(subDays(startDay, i))}
            />
          ) : null}
          {getDate(subDays(startDay, i)) === 20 ? (
            <Waypoint
              horizontal={true}
              onEnter={() => setSoftSelect(subDays(startDay, i))}
            />
          ) : null}
          {isSameDay(subDays(startDay, i), currentDate) ? (
            <Waypoint
              horizontal={true}
              onEnter={() => setSoftSelect(subDays(startDay, i))}
            />
          ) : null}
          <div className={"Datepicker--DateLabel"} key={i * i + 1}>
            {format(subDays(startDay, i), dateFormat)}
          </div>
          <div className={"Datepicker--MonthYearLabel"}>
            {scroll
              ? format(subDays(startDay, i), dateFormats)
              : format(subDays(startDay, i), dateFormats)}
            {/*{!scroll? isSameMonth(softSelect,currentWeek)? null: " / " + format(softSelect, dateFormat) : null}*/}
          </div>
          <div className={"Datepicker--DayLabel"} key={i}>
            ({format(subDays(startDay, i), dayFormat)})
          </div>
        </div>
      );
    }
    return (
      <div id={"container"} className={`${getScroll()}`}>
        {days}
      </div>
    );
  }
  // const date = () => {
  //   const curr = new Date(); // get current date
  //   const first = curr.getDate();
  //   const days = [];
  //   for (let i = 1; i < 7; i++) {
  //     const next = new Date(curr.getTime());
  //     next.setDate(first - i);
  //     days.push(next.toString());
  //   }
  // };

  //function for dateclick
  const onDateClick = async (day) => {
    console.log("clicked");
    if ((day, currentDate)) {
      selectDate = null;
      setSelectedDate(day);
      if (props.getSelectedDay) {
        await props.getSelectedDay(day);
      }
    }
  };

  //function for component mount
  useEffect(() => {
    let componentMountDate = new Date(yestdate);
    componentMountDate.setDate(componentMountDate.getDate());
    if (props.getSelectedDay) {
      if (selectDate) {
        props.getSelectedDay(selectDate);
      } else {
        props.getSelectedDay(componentMountDate);
      }
    }
  }, []);

  //function for component update
  useEffect(() => {
    if (selectDate) {
      if (!isSameDay(selectedDate, selectDate)) {
        setSelectedDate(selectDate);
        setTimeout(() => {
          let view = document.getElementById("selected");
          if (view) {
            view.scrollIntoView({
              behavior: "smooth",
              inline: "center",
              block: "nearest",
            });
          }
        }, 20);
      }
    }
  }, [selectDate]);

  //global variable declaration
  let e = document.getElementById("container");
  let width = e ? e.getBoundingClientRect().width - "50" : null;

  //function for nextweek
  const nextWeek = () => {
    scroll
      ? (document.getElementById("container").scrollLeft += width)
      : setCurrentWeek(addWeeks(currentWeek, 1));
  };
  //function for previous week
  const prevWeek = () => {
    scroll
      ? (document.getElementById("container").scrollLeft -= width)
      : setCurrentWeek(subWeeks(currentWeek, 1));
  };

  // noinspection SpellCheckingInspection
  return (
    <div className={"Datepicker--Container"}>
      <div className={"Datepicker--Strip"}>
        <div className={"Datepicker"}>
          <IconButton className={"Datepicker--button-prev"} onClick={prevWeek}>
            <ArrowBackIosIcon />
          </IconButton>
          {renderDays()}
          <IconButton className={"Datepicker--button-next"} onClick={nextWeek}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
