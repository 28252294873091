// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import { Promise } from "es6-promise";
import httpRequest from "../../../Utils/httpRequest";

export const getMtFacilityManagerList = async (page) => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse.data);
    };

    const errorCallback = (error) => {
      reject(error);
    };

    httpRequest.get(
      "mtfacilitymanagerlist",
      {
        "Content-Type": "application/json",
      },
      successCallback,
      errorCallback,
      {
        page: page,
        size: 10,
      }
    );
  });
};

/*const orgListHandler = async () => {
  return new Promise((resolve, reject) => {
    const successCallback = (jsonResponse) => {
      resolve(jsonResponse.data);
    };

    const errorCallback = (error) => {
      reject(error);
    };

    httpRequest.get(
      "orgListdropdown",
      {
        "Content-Type": "application/json",
      },
      successCallBack,
      errorCallBack,
      {}
    );
  });
};*/
