// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import editUseStyles from "./style.js";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/core/styles";

//@component
//Create Account
const TempAccessEditDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [parkingId, setParkingId] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setemailError] = useState("");
  const [emailDisable, setEmailDisable] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberError] = useState("");
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState(1);
  const [vehicleMakeAndModel, setVehicleMakeAndModel] = useState("");
  const [vehicleMakeAndModelError] = useState("");
  const [accessType, setAccessType] = useState([]);
  const [parkList, setParkList] = useState([]);
  const [parkingName, setParkingName] = useState("");
  const [rfId, setRfId] = useState("");
  const [rfIdError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startDateError] = useState("");
  const [endDateError] = useState("");
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [toTime, setToTime] = useState("");
  const [filterList] = useState([]);
  const [selected, setselected] = useState(false);

  const classes = editUseStyles();

  // style to support datepicker
  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        verticalAlign: "middle",
        fontSize: "15px",
        fontFamily: "'Poppins' !important",
        letterSpacing: "3px",
        width: "80%",
        height: "55px !important",
        paddingLeft: "10px",
        border: "1px solid #102E35",
        borderRadius: "4px",
        marginTop: "1%",
        "& fieldset": {
          border: "solid 2px #0070D8",
        },
        "& .MuiInputBase-root": {
          height: (params) => (params.height ? params.height : "auto"),
          color: (params) => (params.color ? params.color : "inherit"),
        },
      },
    })
  );

  const classesDatePicker = useStyles({
    color: "red",
    width: "76%%",
    height: "100%",
  });

  //variable to handle mediaquery and theme

  //function for dialog confirm button
  const submitDetailsConfirm = () => {
    setIsdialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setIsdialogOpen(false);
  };

  const toDateFormatter = (date) => {
    let end_date_split = date.slice(3);
    let datenum = date.substr(0, 3);
    let monthnum = end_date_split.substr(0, 3);
    let yearnum = end_date_split.slice(3);
    let formatted_date = monthnum + datenum + yearnum;
    let formatted_datef = new Date(formatted_date).getTime();

    return formatted_datef;
  };

  const toHandler = (event) => {
    var day = new Date(event._d);
    var dayWrapper = moment(day);
    var dayString = dayWrapper.format("DD-MM-yyyy");

    // setToTime(dayString)
    //setEndDate(dayString)

    let todate = toDateFormatter(dayString);
    setToTime(todate);

    //console.log(filterList.length)
    // createRequestBody(dayString)
    if (filterList.length < 1) {
      console.log("uyiyiuyi", parkingId);
      filterList.push({
        parkingId: parkingId,
        endDate: dayString,
        parkingName: parkingName,
      });
    } else {
      //console.log("item",parkingId)
      let vehicleFound = filterList.find((u) => u.parkingId === parkingId);
      // console.log(vehicleFound)
      if (vehicleFound === undefined) {
        filterList.push({
          parkingId: parkingId,
          endDate: dayString,
          parkingName: parkingName,
        });
      } else {
        filterList.map((item) => {
          if (item.parkingId === parkingId) {
            item.endDate = dayString;
            return item;
          }
        });
      }
    }
  };
  //get data
  const generateDatatHandlerOrg = () => {
    setIsLoading(true);
    //console.log(accessControlId)
    // if (props.location.state) {
    httpRequest.get(
      "viewtempaccountdetails",

      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallback,
      {
        vehicle: props.location.state?.accessControlId,
      }
    );
  };
  //data success
  const successCallbackList = async (jsonResponse) => {
    setIsLoading(false);
    if (jsonResponse) {
      setOrgName(jsonResponse.data?.organizaionName);
      setEmail(jsonResponse.data?.emailId);
      setVehicleMakeAndModel(jsonResponse.data?.vehicleMakeModel);
      setVehicleNumber(jsonResponse.data?.vehicleNumber);
      setRfId(jsonResponse.data?.rfId);
      // setFilterList(jsonResponse.data?.parkingName)
      setAccessType(jsonResponse.data?.accessType?.accessTypes);
      setVehicleType(jsonResponse.data?.vehicleType?.name);
      setParkList(jsonResponse.data.accessedParkingList);
      setParkingName(
        await jsonResponse.data.accessedParkingList[0].parkingName
      );
      setParkingId(await jsonResponse.data.accessedParkingList[0].parkingId);
      // console.log("first", jsonResponse.data?.accessedParkingList[0].parkingName)

      if (jsonResponse.data?.accessType?.accessTypes === "PERMANENT") {
        //setdateSel(true)
        //setFromTime('')
        setToTime("");
      }
      if (jsonResponse.data?.accessType?.accessTypes === "TEMPORARY") {
        //setdateSel(false)
        setStartDate(jsonResponse.data?.accessedParkingList[0]?.startDate);
        //setEndDate(jsonResponse.data?.accessedParkingList[0]?.endDate)
        let todate = toDateFormatter(
          jsonResponse.data?.accessedParkingList[0]?.endDate
        );
        setToTime(todate);
      }
    }
  };

  //error callback
  const errorCallback = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };

  //vehicle list
  const vehicleTypeList = () => {
    httpRequest.get(
      "vehicleList",

      {
        "Content-Type": "application/json",
      },
      successCallbackVehicle,
      errorCallback,
      {}
    );
  };
  const successCallbackVehicle = (jsonResponse) => {
    let vehicle_filter = jsonResponse.data._embedded.vehicleTypeDtoList.slice(
      0,
      2
    );
    setVehicleTypes(vehicle_filter);
  };

  const submitDetails = () => {
    setIsLoading(true);

    if (accessType === "PERMANENT") {
      let request = [];
      parkList.map((item) => {
        request.push({ parkingId: item?.parkingId });
      });
      httpRequest.patch(
        "editVehicleDetails",
        {
          emailId: email,
          vehicleNumber: vehicleNumber,
          vehicleMakeModel: vehicleMakeAndModel,
          rfId: rfId,
          vehicleType: {
            uid: `${vehicleTypeId}`,
            name: vehicleType,
          },
          accessedParkingList: request,
        },
        {
          "Content-Type": "application/json",
        },
        successCallbackUpdate,
        errorCallbackUpdate,
        {
          // accessControlId: props.location.state?.accessControlId,
        }
      );
    }

    if (accessType === "TEMPORARY") {
      if (filterList?.length > 0) {
        parkList.forEach(function(parking) {
          let vehicleFound = filterList.find(
            (u) => u.parkingId === parking.parkingId
          );
          if (vehicleFound === undefined) {
            filterList.push(parking);
          }
        });
      }

      console.log("gfhgfhgfhgfhgfhg", filterList);

      httpRequest.patch(
        "editVehicleDetails",
        {
          emailId: email,
          vehicleNumber: vehicleNumber,
          vehicleMakeModel: vehicleMakeAndModel,
          rfId: rfId,
          vehicleType: {
            uid: `${vehicleTypeId}`,
            name: vehicleType,
          },
          accessedParkingList: filterList?.length < 1 ? parkList : filterList,
        },
        {
          "Content-Type": "application/json",
        },
        successCallbackUpdate,
        errorCallbackUpdate,
        {
          // accessControlId: props.location.state?.accessControlId,
        }
      );
    }
  };

  const successCallbackUpdate = () => {
    setIsLoading(false);
    setIsdialogOpen(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "Vehicle Details Updated Succesfully",
        color: "green",
      })
    );
    setTimeout(() => {
      props.history.push({
        pathname: "/portal/tempaccesslistview",
        state: {
          back: "true",
          loadpage: props.location.state?.page,
          searchKey:props.location.state?.searchKey,
        },
      });
    }, 2000);
  };

  const errorCallbackUpdate = (error) => {
    setIsdialogOpen(false);
    setIsLoading(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: error.message || "Error updating vehicle details",
        color: "red",
      })
    );
  };
  //function to handle back or cancel button with pagination
  const backCancel = () => {
    props.history.push({
      pathname: "/portal/tempaccesslistview",
      state: {
        back: "true",
        loadpage: props.location.state?.page,
        searchKey:props.location.state?.searchKey
      },
    });
  };

  //function call on component render/re-render
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("tempaccessedit"));
    vehicleTypeList();
    generateDatatHandlerOrg();
    // accessListHandler()
  }, []);

  //function for generic email regex validation
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
  };
  //functions to set state individually
  const emailHandle = (event) => {
    if (event.target.value.length > 100) {
      setemailError("Enter less than 100 charecters");
      setEmailDisable("disable");
    }
    if (event.target.value.length <= 100) {
      if (validateEmail(event.target.value)) {
        setEmailDisable("");
        setemailError("");
        setEmail(event.target.value);
      }
      if (!validateEmail(event.target.value)) {
        setemailError("Enter valid Email Id");
        setEmail(event.target.value);
        setEmailDisable("disable");
      }
      if (event.target.value.length < 1) {
        setemailError("");
        setEmail(event.target.value);
        setEmailDisable("disable");
      }
    }
  };

  //functions to set state individually
  const vehicleTypeHandle = (event) => {
    setVehicleType(event.target.value);
    if (event.target.value === "CAR") {
      setVehicleTypeId(1);
    } else {
      setVehicleTypeId(2);
    }
  };

  const parkingHandle = (event) => {
    if (event.target.value !== "0") {
      let parking = parkList.filter(
        (item) => item.parkingId === event.target.value
      );
      console.log(event.target.value);
      setselected(true);
      setParkingId(parking[0].parkingId);
      setParkingName(parking[0].parkingName);
      setStartDate(parking[0].startDate);
      //setEndDate(parking[0]?.endDate)
      let todate = toDateFormatter(parking[0]?.endDate);
      setToTime(todate);
    } else {
      setselected(false);
    }
  };

  //functions to set state individually
  const vehicleMakeAndModelHandle = (event) => {
    var inputBox = document.getElementById(event.target.id);
    var invalidChars = [
      ".",
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());

    if (event.target.value.length <= 50) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setVehicleMakeAndModel("");
      } else {
        setVehicleMakeAndModel(event.target.value);
      }
    }
  };

  //functions to set state individually
  const rfidTagHandle = (event) => {
    var inputBox = document.getElementById(event.target.id);
    var invalidChars = [
      ".",
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];

    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
      inputBox.addEventListener("paste", (e) => e.preventDefault());
    });

    if (event.target.value.length <= 50) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setRfId("");
      } else {
        setRfId(event.target.value);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        // style={{
        //   width: '96%',
        //   margin: '2%',
        //   marginBottom: '10%',
        //   padding: '2%'
        // }}
      >
        <Paper
          elevation={5}
          style={{
            width: "96%",
            margin: "2%",
            marginBottom: "10%",
          }}
        >
          <Grid item md={12} className={classes.headerText}>
            <label>Edit Vehicle Details</label>
          </Grid>

          <Grid container direction="row">
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Organization Name</label>

                <input
                  disabled="true"
                  className={classes.textFieldDisabled}
                  id="orgId"
                  type="text"
                  name="orgName"
                  placeholder="Organization Name"
                  value={orgName}
                />
              </Grid>

              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Vehicle Type</label>

                <TextField
                  type="vehicleType"
                  name="vehicleType"
                  InputProps={{
                    className: classes.textFieldSelect,
                    disableUnderline: true,
                  }}
                  value={vehicleType}
                  select
                  onChange={vehicleTypeHandle}
                >
                  {vehicleTypes.map((option) => (
                    <MenuItem key={option.uid} value={option.name}>
                      <span
                        style={{
                          fontSize: "14px",
                          fontFamily: "'Poppins' !important",
                        }}
                      >
                        {option.name}
                      </span>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Vehicle Make and Model</label>

                <input
                  className={classes.textField}
                  id="vehiclMakeModel"
                  type="text"
                  name="vehiclMakeModel"
                  value={vehicleMakeAndModel}
                  onChange={vehicleMakeAndModelHandle}
                />

                <p>
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {vehicleMakeAndModelError}
                  </span>
                </p>
              </Grid>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Access Type</label>

                <input
                  disabled="true"
                  className={classes.textFieldDisabled}
                  id="AccessType"
                  type="text"
                  name="orgName"
                  placeholder="Access Type"
                  value={accessType}
                />
              </Grid>
              {accessType === "TEMPORARY" && (
                <Grid
                  container
                  direction="column"
                  className={classes.inputArea}
                >
                  <label className={classes.label}>Parking Space Name</label>

                  {/* <TextField

                  type="parkingName"
                  name="parkingName"
                  InputProps={{
                    className: classes.textFieldSelect,
                    disableUnderline: true
                  }}
                  value={vehicleMakeAndModel}
                  select
                  onChange={parkingHandle}
                >
                  {parkList.map((option) => (
                    <MenuItem key={option.parkingName} value={option.parkingId}>
                      <span style={{ fontSize: '14px', fontFamily: '\'Poppins\' !important' }}>{option.parkingName}</span>
                    </MenuItem>
                  ))}
                </TextField> */}
                  <select
                    onChange={parkingHandle}
                    className={classes.textFieldSelect}
                  >
                    <option value="0">Select Parking</option>

                    {parkList.map((option) => {
                      // if (index !== 0) {
                      return (
                        <option
                          key={option.parkingName}
                          value={option.parkingId}
                        >
                          {option.parkingName}
                        </option>
                      );
                      // }
                    })}
                  </select>
                </Grid>
              )}
              {accessType === "TEMPORARY" && selected && (
                <Grid
                  container
                  direction="column"
                  className={classes.inputArea}
                >
                  <label className={classes.label}>Start Date</label>
                  <input
                    disabled="true"
                    className={classes.textFieldDisabled}
                    id="startDate"
                    type="text"
                    name="startDate"
                    value={startDate}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Email Address</label>

                <input
                  className={classes.textField}
                  id="email"
                  type="email"
                  name="contactEmail"
                  value={email}
                  onChange={emailHandle}
                />

                <p>
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {emailError}
                  </span>
                </p>
              </Grid>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>Vehicle Number</label>

                <input
                  disabled="true"
                  className={classes.textFieldDisabled}
                  id="vehicleNumber"
                  type="text"
                  name="vehicleNumber"
                  value={vehicleNumber}
                />
              </Grid>
              <Grid container direction="column" className={classes.inputArea}>
                <label className={classes.label}>RFID Tag</label>

                <input
                  className={classes.textField}
                  id="rfidTag"
                  type="text"
                  name="rfidTag"
                  value={rfId}
                  onChange={rfidTagHandle}
                />

                <p>
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {rfIdError}
                  </span>
                </p>
              </Grid>
              {accessType === "PERMANENT" && (
                <Grid
                  container
                  direction="column"
                  className={classes.inputArea}
                >
                  <label className={classes.label}>Access Granted To</label>

                  {parkList.map((item) => {
                    return (
                      <p>
                        <span
                          style={{
                            fontSize: "14px",
                            color: "rgb(103, 181, 97)",
                            fontWeight: "bold",
                          }}
                        >
                          {item.parkingName}
                        </span>
                      </p>
                    );
                  })}
                </Grid>
              )}
              <Grid
                container
                direction="column"
                className={classes.inputArea}
                style={{ visibility: "hidden" }}
              >
                <label className={classes.label}>Parking Space Name</label>
                <input
                  disabled="true"
                  className={classes.textFieldDisabled}
                  id="parkingSpace"
                  type="text"
                  name="parkingSpace"
                />
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.inputArea}
                style={{ visibility: "hidden" }}
              >
                <label className={classes.label}>Parking Space</label>
                <input
                  disabled="true"
                  className={classes.textFieldDisabled}
                  id="parkingSpace"
                  type="text"
                  name="parkingSpace"
                />
              </Grid>
              {accessType === "TEMPORARY" && selected && (
                <Grid
                  container
                  direction="column"
                  className={classes.inputArea}
                >
                  <label className={classes.label}>End Date: </label>

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk={true}
                      // disabled={dateSel}
                      InputProps={{
                        disableUnderline: true,
                        className: classesDatePicker.componentStyle,
                      }}
                      disablePast
                      onKeyDown={(e) => e.preventDefault()}
                      variant="inline"
                      value={toTime}
                      // onKeyDown={(e) => e.preventDefault()}
                      onChange={toHandler}
                      format="DD-MM-yyyy"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container direction="row" className={classes.inputActionArea}>
              <Button
                variant="contained"
                className={classes.updateButton}
                onClick={submitDetailsConfirm}
                disabled={
                  orgName?.length === 0 ||
                  emailError?.length !== 0 ||
                  emailDisable?.length !== 0 ||
                  vehicleNumber?.length === 0 ||
                  vehicleNumberError?.length !== 0 ||
                  vehicleMakeAndModel?.length === 0 ||
                  //vehicleMakeAndModelError.length !== 0 ||
                  rfId?.length === 0 ||
                  rfIdError?.length !== 0 ||
                  startDateError?.length !== 0 ||
                  endDateError?.length !== 0
                }
              >
                Update
              </Button>
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={backCancel}
              >
                Cancel
              </Button>
            </Grid>

            <Dialog
              // fullScreen={fullScreen}
              open={IsdialogOpen}
              onClose={handleClose}
              PaperProps={{
                style: {
                  padding: "1.5% 3%",
                },
              }}
              className={classes.dialog}
            >
              <DialogTitle
                style={{
                  paddingBottom: "8px",
                  paddingTop: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <span className={classes.dialogHeader}>
                      Update Vehicle Details
                    </span>
                  </Grid>
                </Grid>
              </DialogTitle>
              <Divider />
              <DialogContent
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Grid item>
                  <Grid container direction="column" justify="center">
                    <Grid item>
                      <Typography className={classes.dialogSubHeader}>
                        Are you sure You want to update{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {vehicleNumber}{" "}
                        </span>
                        Vehicle Details?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <p>
                        <span className={classes.dialogSubHeader}>
                          Email-Id:
                        </span>{" "}
                        <span className={classes.dialogSubTitle}>{email}</span>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        <span className={classes.dialogSubHeader}>
                          Vehicle Type:
                        </span>{" "}
                        <span className={classes.dialogSubTitle}>
                          {vehicleType}
                        </span>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        <span className={classes.dialogSubHeader}>
                          Vehicle Make and Model:
                        </span>{" "}
                        <span className={classes.dialogSubTitle}>
                          {vehicleMakeAndModel}
                        </span>
                      </p>
                    </Grid>
                    <Grid item>
                      <p>
                        <span className={classes.dialogSubHeader}>RFID:</span>{" "}
                        <span className={classes.dialogSubTitle}>{rfId}</span>
                      </p>
                    </Grid>
                    {accessType === "TEMPORARY" &&
                      filterList.map((item) => {
                        return (
                          <>
                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  Parking Space Name:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {item.parkingName}
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  End Date:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {item.endDate}
                                </span>
                              </p>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.dialogActionGrid}
                  >
                    <Grid item className={classes.dialogCancelGrid}>
                      <button
                        type="button"
                        onClick={handleClose}
                        className={classes.dialogCancel}
                      >
                        Cancel
                      </button>
                    </Grid>
                    <Grid item className={classes.dialogConfirmGrid}>
                      <button
                        type="button"
                        className={classes.dialogConfirm}
                        onClick={submitDetails}
                      >
                        Confirm
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
//redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   dispatch,
// });
//method to connect react with redux
export default withRouter(
  connect(() => {
    return {};
  })(TempAccessEditDetails)
);
