// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import ChartContainer from "../../Components/Charts/ChartContainer/ChartContainer.js";
import Rating from "../../Components/Ratings/Rating";
import StoreReducer from "../../context/DashboardReducer";
import ViewContainer from "../../Components/ParkingSpaceOverview/ParkingOverview/ViewContainer";
import "./style.scss";
import "../../Assets/Scss/style.scss";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../redux/modules/app/actions";
import { getCookie } from "../../Utils/cookies.js";


//@component
//Dashboard
const Dashboard = (props) => {
  //called when component is mounted
  useEffect(() => {
    props.updateBreadcrumbs("home");
  }, []);

  return (
    <StoreReducer>
      <div className="dashboard">
        <Container
          maxWidth="xl"
          style={{ marginTop: "1%", marginBottom: "3.5%" }}
        >
          <Grid container>
            <Grid item md={12} xs={12}>
              <ViewContainer />
            </Grid>
            {getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER" &&
              getCookie("role") !== "RESIDENTIAL_FACILITY_ADMIN" &&
              getCookie("isHardwareAvailable") !==
                "false" && (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className="padding-btm"
                  style={{ marginBottom: "1.5%" }}
                >
                  <Grid item md={8} xs={12}>
                    {/* <NotificationWidget /> */}
                    <ChartContainer />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Rating />
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Container>
      </div>
    </StoreReducer>
  );
};
//function for redux connect
export default connect(null, { updateBreadcrumbs })(Dashboard);
