// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import EditParking from './EditParking';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addParkinSteps } from '../../../../Constants/appConstants';
import { updateBreadcrumbs } from '../../../../redux/modules/app/actions';
import httpRequest from '../../../../Utils/httpRequest';
import { getCookie } from '../../../../Utils/cookies';
import Loader from '../../../../Components/Preloader';

//@component
//EditParkingModule
class EditParkingModule extends Component {
  //React state
  state = {
    addParkinSteps: JSON.parse(JSON.stringify(addParkinSteps)),
    currentStep: 1,
    data: {},
    imgLink: '',
    pageImg: [],
    imgData: [],
    passPop: false,
    imageIdArray: [],
    isLoading: true,
  };
  //function for component mount
  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const parkingId = urlParams.get('parkingId');
    if (parkingId) {
      httpRequest.get(
        'parkingdetail',
        {},
        this.parkingDetailSuccess,
        this.errorCallback,
        {
          parkingId: parkingId,
          userId: getCookie('userId'),
        },
      );
      httpRequest.get(
        'userProfile',
        {},
        this.userDataSuccess,
        this.errorCallback,
        {
          userId: getCookie('userId'),
        },
      );
    }
  };
  //function for imageSuccessTwo
  imageSuccessTwo = jsonResult => {
    let imageIdArray = this.state.imageIdArray;
    imageIdArray.push(jsonResult);
    this.setState({
      imageIdArray,
    });
    let imgData = this.state.imgData;
    imgData.push(jsonResult.data);
    this.setState({
      imgData,
    });
    var addParkinSteps = { ...this.state.addParkinSteps };
    
    addParkinSteps.step1.images = imgData;
  
    this.setState({ addParkinSteps });
  };
  //function for imageSuccess
  imageSuccess = jsonResult => {
    this.setState({
      pageImg: jsonResult.data.imageLinks,
    });
    this.state.pageImg.forEach(function(each) {
      httpRequest.getImage(
        each.link + '?responseType=BASE64_ENCODE',
        {
          Accept: 'image/png',
        },
        this.imageSuccessTwo,
        this.errorCallback,
        {
          detailsImgTwo: each.link + '?responseType=BASE64_ENCODE',
        },
      );
    }, this);
  };
  //function for parkingDetailSuccess
  parkingDetailSuccess = jsonResult => {
    const jsonData = jsonResult.data
    this.setState({ isLoading: false });
    this.setState({
      imgLink: jsonResult.data._links.parkingImages.href,
    });
    this.updateStateData(jsonData);
    httpRequest.getImage(
      this.state.imgLink,
      {},
      this.imageSuccess,
      this.errorCallback,
      {
        detailsImg: this.state.imgLink,
      },
    );
    this.setState({
      data: jsonResult.data,
    });
  };

 
  //function for updateStateData
  updateStateData = data => {
    const step1Data = {
      parkingId: data.parkingId,
      parkingName: data.name,
      contactName:data.contactName,
      contactNumber: data.contactNumber,
      contactEmail: data.contactEmail,
      parkingBusinessType: {
        uid: data.parkingBusinessType.uid,
      },
      description: data.description,
      images: this.state.imgData,
      deleteImagesFromSpace: [],
      imageId: this.state.imageIdArray,
      addressDetails: data.addressDetails,
      location: data.location,
    };
    const step2Data = JSON.parse(
      JSON.stringify(addParkinSteps.step2),
    );
   
    const vehicleType = data.vehicleTypeInfo;
   
    for (let i = 0; i < vehicleType.length; i++) {
      const vehicle =
        vehicleType[i].vehicleType.uid == 2
          ? 'twoWheeler'
          : vehicleType[i].vehicleType.uid == 1
          ? 'fourWheeler'
          : 'fleet';
     
      step2Data[vehicle].availableSlots =
        vehicleType[i].availableSlots;
      step2Data[vehicle].totalSlots = vehicleType[i].totalSlots;
      step2Data[vehicle].paid = vehicleType[i].paid;
      if (vehicleType[i].priceInfo) {
        step2Data[vehicle].additionalHour =
          vehicleType[i].priceInfo.additionalHour;
        step2Data[vehicle].additionalPrice =
          vehicleType[i].priceInfo.additionalPrice;
        step2Data[vehicle].baseHour =
          vehicleType[i].priceInfo.baseHour;
        step2Data[vehicle].basePrice =
          vehicleType[i].priceInfo.basePrice;
        step2Data[vehicle].pricePerDay =
          vehicleType[i].priceInfo.pricePerDay;
        step2Data[vehicle].pricePerMonth =
          vehicleType[i].priceInfo.pricePerMonth;
        step2Data[vehicle].pricePerWeek =
          vehicleType[i].priceInfo.pricePerWeek;
        step2Data[vehicle].selectedLongterm =
          vehicleType[i].priceInfo.selectedLongterm;
      }
    }
    // console.log("after set vehicletypedata", step2Data)
    for (let day in data.operatingHoursInfo) {
      step2Data.timings[day].time = data.operatingHoursInfo[day][0];
      step2Data.timings[day].isChecked = true;
    }

    step2Data.customData = data.customOperatingHoursInfo.map(
      (item) => {
        return {
          closingTime: item.closingTime,
          endDate: item.endDate,
          openingTime: item.openingTime,
          startDate: item.startDate,
        };
      },
    );

    const step3Data = {
      services: data.services.map(item => {
        return {
          uid: item.uid,
          distanceInMeters: item.distanceInMeters,
        };
      }),
      facilities: data.facilities.map(item => {
        return { uid: item.uid };
      }),
      selectedCheckbox: data.services.map(item => {
        return item.uid;
      }),
      eula: {
        eulaAccepted: false,
        eulaVersion: '1',
      },
      isHardwareInterested: false,
    };


    this.setState(
      {
        addParkinSteps: {
          ...this.state.addParkinSteps,
          step1: { ...step1Data },
          step2: { ...step2Data },
          step3: { ...step3Data },
        },
      },
      () => {
        // console.log(this.state.addParkinSteps);
      },
    );
   
  };
  //function for error call
  errorCallback = () => {};
  //function for updateRootState
  updateRootState = (key, value) => {
    const urlParams = window.location.search;
    if (urlParams !== '') {
      if (key === this.state.currentStep) {
        this.props.updateBreadcrumbs(
          'EditParkingSpaceStep' + this.state.currentStep,
        );
      }
      this.setState(
        {
          currentStep: value,
        },
        () => {
          this.props.updateBreadcrumbs(
            'EditParkingSpaceStep' + this.state.currentStep,
          );
        },
      );
    } else {
      if (key === this.state.currentStep) {
        this.props.updateBreadcrumbs(
          'listParkingSpaceStep' + this.state.currentStep,
        );
      }
      this.setState(
        {
          currentStep: value,
        },
        () => {
          this.props.updateBreadcrumbs(
            'listParkingSpaceStep' + this.state.currentStep,
          );
        },
      );
    }
  };
  //function for updateChildrenState
  updateChildrenState = (parentName, key, value) => {
    // console.log("updatechildrenstate: ",value);
    this.setState(
      prevState => {
        const newObj = { ...prevState.addParkinSteps };
        newObj[parentName][key] = value;
        
        return {
          addParkinSteps: newObj,
        };
      },
      () => {
        // console.log(this.state.addParkinSteps);
      },
    );
  };

  render() {
    return (
      <div className="edit__parking_module">
        {this.state.isLoading && <Loader />}
        <EditParking
          addParkinSteps={this.state.addParkinSteps}
          updateRootState={this.updateRootState}
          updateChildrenState={this.updateChildrenState}
        />
      </div>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(null, { updateBreadcrumbs })(EditParkingModule),
);
