// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateBreadcrumbs, updateToasterConfig } from '../../../redux/modules/app/actions';
import FileViewer from "../../../Components/pdf/FileViewer";
import DataProtection from "../../../Assets/pdf/PZ_Data_Protection_Notice_Common_V2.3_15122023.pdf";





//@component
//View
function DataProtectionNotice(props) {
    useEffect(() => {
        
        props.dispatch(updateBreadcrumbs("Data Protection Notice"))
    
        
    
    
      }, []);
    
 
  return (
    <>
      
            <FileViewer pdfFile={DataProtection} />
          

    </>
  )


}

const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)((DataProtectionNotice)));