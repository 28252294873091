// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import "./style.scss";
import httpRequest from '../../../Utils/httpRequest'
import Loader from '../../../Components/Preloader/index'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//@component
//Create Account
const CreateAccount = props => {
  const [mapsEnable] = useState('true');
  const [isLoading, setIsLoading] = useState(false)
  // const [realms, setRealms]= useState([]);
  const [tenantId] = useState('')
  const [orgs, setOrgs] = useState([]);
  const [orgName, setOrgName] = useState('')
  const [orgId, setOrgId] = useState('')
  const [orgAcr, setOrgAcr] = useState('')
  const [orgAdr, setOrgAdr] = useState('')
  const [adrError, setadrError] = useState("")
  const [solUserName, setSolUserName] = useState('')
  const [fnError, setfnError] = useState("")
  // const [solLName, setSolLName]=useState('')
  // const [ lnError,setlnError ] = useState("")
  const [altName, setAltName] = useState(null)
  const [altNameError, setAltNameError] = useState("")
  const [email, setEmail] = useState('')
  const [emailError, setemailError] = useState("")
  const [emailDisable, setEmailDisable] = useState("")
  const [contact, setContact] = useState(null)
  const [contactError, setcontactError] = useState("")
  const [cityError, setCityError] = useState("")
  const [stateError, setStateError] = useState("")
  const [countryError, setCountryError] = useState("")

  const [contactDisable, setContactDisable] = useState("")
  const [altContact, setAltContact] = useState(null)
  const [altcontactError, setaltcontactError] = useState("")
  const [whitelist, setWhitelist] = useState('')
  const [domainError, setDomainError] = useState("")
  const [domainDisable, setDomainDisable] = useState("")
  const [country, setCountry] = useState('India')
  const [city, setCity] = useState('')
  const [stateName, setStateName] = useState('')
  const [pincode, setPinCode] = useState('')
  const [pinError, setpinError] = useState("")
  const [pinDisable, setPinDisable] = useState("")
  const [totalBikeSlots, setTotalBikeSlots] = useState(0)
  const [totalBikeSlotsError, setTotalBikeSlotsError] = useState('')
  const [totalCarSlots, setTotalCarSlots] = useState(0)
  const [totalCarSlotsError, setTotalCarSlotsError] = useState("")
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  //function to clear fields after creating account
  const clearHandler = () => {
    setTotalBikeSlots(0);
    setTotalCarSlots(0);
    setPinCode("");
    setStateName("");
    setCity("");
    setCountry("");
    setWhitelist("");
    setAltContact("");
    setContact("");
    setEmail("");
    setAltName("");
    setSolUserName("");
    setOrgAdr("");
    setOrgAcr("");
    setOrgName("");
    setOrgId("");
    // setSolUserName("");
  };
  // //Global Variable declaration
  // const updateParentState = (parentKey, childKey, value, props) => {
  //   let obj = { ...props.data[parentKey] };
  //   obj[childKey] = value;
  //   props.updateChildrenState('step1', parentKey, obj);
  // };
  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  //variable to handle mediaquery and theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //function for dialog confirm button  
  const submitDetailsConfirm = () => {
    setIsdialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setIsdialogOpen(false);
  };

  //function for org api call
  const orgListHandler = useCallback(() => {
    httpRequest.get(
      "orgListdropdown",
      {
        "Content-Type": "application/json",
      },
      successCallBackOrg,
      errorCallBackOrg,
      {

      }
    );
  }, []);
  //success callback
  const successCallBackOrg = (jsonResponse) => {
    if (jsonResponse) {
      setOrgs(jsonResponse.data?._embedded.results);
    }
  };
  //error callback
  const errorCallBackOrg = () => {
    //error message here if any
  };
  //function for pre-details submit api call
  const submitDetails = () => {
    setIsLoading(true);
    httpRequest.patch(
      "orgUpdate",
      {
        'orgId': orgId,
        'name': orgName,
        'isGMapOpted': mapsEnable,
        'orgAcronym': orgAcr,
        'emailDomains': whitelist,
        'tenant': {
          'tenantId': tenantId,
        },
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackCreate1,
      errorCallBackCreate1,
      {
        'orgId': orgId,
      }
    );
  };
  //success callback
  const successCallBackCreate1 = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setIsdialogOpen(false);
      createPost();
    }
  };
  //error callback
  const errorCallBackCreate1 = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setIsdialogOpen(false);
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
  };
  //api call to Create Solution Admin.
  const createPost = () => {
    setIsLoading(true);

    httpRequest.post(
      "createMtFacilityManager",
      {
        'mobileNumber': contact,
        'userName': solUserName,
        'corpEmailId': email,
        'altContactPerson': altName,
        'altContactNumber': altContact,
        'totalBikeSlots': totalBikeSlots,
        'totalCarSlots': totalCarSlots,
        'addressDetails': {
          'addressLine1': orgAdr,
          'addressLine2': ".",
          'city': city,
          'state': stateName,
          'pincode': pincode,
          'country': country,
        },
        org: {
          'orgId': orgId,
        },
        role: {
          'name': 'FACILITY_ADMIN',
        }
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackCreate,
      errorCallBackCreate,
      {}
    );

  };
  //success callback
  const successCallBackCreate = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setOpenAlert(true);
      setAlertMessage("Facility Manager Created Successfully");
      setSeverity("Success");
      setOrgs(jsonResponse.data?._embedded.results);
      clearHandler();
      setTimeout(function () {
        props.history.push({
          pathname: "/portal/viewaccount",
          state: {
            source: "create"
          }
        })
      }, 2000);


    }
  };
  //error callback
  const errorCallBackCreate = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
    if (error.message === "Internal Server Error, Please try again later") {
      clearHandler();
      props.history.push({
        pathname: "/portal/viewaccount",
        state: {
          source: "create"
        }
      })
      setOpenAlert(true);
      setAlertMessage("Facility Manager Created Successfully");
      setSeverity("Success");
    }
  };

  //function call on component render/re-render
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("createaccount"))
    // realmListHandler()
    orgListHandler()
  }, [])

  // //functions to set state individually
  // const mapEnableChange = (event) => {
  //   setMapsEnable(event.target.value);
  // };
  //functions to set state individually
  const accountFNamehandle = (event) => {
    var inputBox = document.getElementById(event.target.id); 
    var invalidChars = [
      "0","1","2","3","4","5","6","7","8","9","-","_","+","~","`","!","@","#","$","%","^","&","*","(",")",
      "<",">","?","/","{","}","[","]","|",",",":",";","'","",",","\\","\"","="
    ];  
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }    
    });
    inputBox.addEventListener('paste', e => e.preventDefault());
    if (event.target.value.length > 50) {
      setfnError("enter less than 50 characters");
    }
    if (event.target.value.length <= 50) {
      setfnError("");
      setSolUserName(event.target.value);
    }
  };
  // //functions to set state individually
  // const accountLNamehandle = (event) => {
  //   if (event.target.value.length > 50) {
  //     setlnError("Max.Char length is 50");
  //   }
  //   if (event.target.value.length <= 50) {
  //     setlnError("");
  //     setSolLName(event.target.value);
  //   }
  // };
  //function for generic email regex validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase());
  }
  //function for generic domain regex validation
  const validateDomain = (domain) => {
    const re = /@(.*)\.com|.co.in|.in$/i
    return re.test(String(domain).toLowerCase());
  }
  //functions to set state individually
  const emailHandle = (event) => {
    if (event.target.value.length > 100) {
      setemailError("Enter less than 100 characters");
      setEmailDisable("")
    }
    if (event.target.value.length <= 100) {
      if (validateEmail(event.target.value)) {
        setEmailDisable("")
        setemailError("");
        setEmail(event.target.value);
      }
      if (!validateEmail(event.target.value)) {
        setemailError("Enter valid Email Id")
        setEmailDisable("")
        setEmail(event.target.value);
      }
      if (event.target.value.length < 1) {
        setemailError("")
        setEmailDisable("disable")
        setEmail(event.target.value);
      }
    }
  };
  //functions to set state individually
  const contactHandle = (event) => {
    if (event.target.value.length < 10) {
      setcontactError("Enter 10 numbers only")
       setContactDisable("")
       setContact(event.target.value)
    }
    // if (event.target.value.length > 10) {
    //   setcontactError("Enter 10 numbers only")
    //   // setContactDisable("")
    // }
    if (event.target.value.length === 10) {
      setcontactError("")
      setContact(event.target.value)
     setContactDisable("")
    }
    if (event.target.value.length < 1) {
      setcontactError("")
       setContactDisable("disable")
      setContact(null)
    }
  }
  //functions to set state individually
  const altContactHandle = event => {

    if (event.target.value.length < 10) {
      setaltcontactError("Enter 10 numbers only")
       setAltContact(event.target.value)
    }
    // if (event.target.value.length > 10) {
    //   setaltcontactError("Enter 10 numbers only")
    // }
    if (event.target.value.length === 10) {
      setaltcontactError("")
      setAltContact(event.target.value)
    }
    if (event.target.value.length < 1) {
      setaltcontactError("")
      setAltContact(null)
    }
  }
  //functions to set state individually
  const altNameHandle = event => {
    var inputBox = document.getElementById(event.target.id); 
    var invalidChars = [
      "0","1","2","3","4","5","6","7","8","9","-","_","+","~","`","!","@","#","$","%","^","&","*","(",")",
      "<",">","?","/","{","}","[","]","|",",",":",";","'","",",","\\","\"","="
    ];  
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }    
    });
    inputBox.addEventListener('paste', e => e.preventDefault());

    if (event.target.value.length > 50) {
      setAltNameError("Enter less than 50 characters");
    }
    if (event.target.value.length <= 50) {
      setAltNameError("");
      setAltName(event.target.value)
    }
    if (event.target.value.length < 1) {
      setAltNameError("");
      setAltName(null)
    }
  }
  //functions to set state individually
  const postalHandle = event => {
    if (event.target.value.length < 6) {
      setpinError("Enter Min 6 or Max 8 characters")
      setPinDisable("")
       setPinCode(event.target.value)
    }
    // if (event.target.value.length > 8) {
    //   setpinError("Enter Min 6 and Max 8 characters")
    //   setPinDisable("")
    // }
    if (event.target.value.length < 1) {
      setpinError("")
      setPinDisable("disable")
      setPinCode('')
    }
    if (event.target.value.length >= 6 && event.target.value.length <= 8) {
      setpinError("")
      setPinDisable("")
      setPinCode(event.target.value)
    }
  }
  //functions to set state individually
  const orgAdrHandle = (event) => {
    if (event.target.value.length > 100) {
      setadrError("Enter less than 100 characters");
    }
    if (event.target.value.length <= 100) {
      setadrError("");
      setOrgAdr(event.target.value);
    }
  };
  //functions to set bike slots individually
  const totalBikeSlotsHandle = (event) => {
    // if (event.target.value.length > 4) {
    //   setTotalBikeSlotsError("Enter upto 9999 slots");
    // }

    if (event.target.value.length <= 4) {
      setTotalBikeSlotsError("");
      setTotalBikeSlots(parseInt(event.target.value));
    }
  };
  //functions to set car slots individually
  const totalCarSlotsHandle = (event) => {
    // console.log ("Sadasdasd",event.target.value.length)
    // if (event.target.value.length > 4) {
    //   setTotalCarSlotsError("Enter upto 9999 slots");
    // }

    if (event.target.value.length <= 4) {
      setTotalCarSlotsError("");
      setTotalCarSlots(parseInt(event.target.value));
    }
  };
  //functions to set state individually
  const countryHandle = (event) => {
    if (event.target.value.length > 50) {
      setCountryError("Enter less than 50 characters");
    }
    if (event.target.value.length <= 50) {
      setCountryError("");
      setCountry(event.target.value);
    }
  };
  //functions to set state individually
  const stateHandle = (event) => {
    if (event.target.value.length > 50) {
      setStateError("Enter less than 50 characters");
    }
    if (event.target.value.length <= 50) {
      setStateError("");
      setStateName(event.target.value);
    }
  };
  // const stateHandle = (event) => {
  //   if (event.target.value.length > 50) {
  //     setStateError("Enter less than 50 characters");
  //   }
  //   if (event.target.value.length <= 50) {
  //     setStateError("");
  //     setStateName(event.target.value)

  //   }
  // };
  //functions to set state individually
  const cityHandle = (event) => {
    if (event.target.value.length > 50) {
      setCityError("Enter less than 50 characters");
    }
    if (event.target.value.length <= 50) {
      setCity(event.target.value);
      setCityError("");
    }
  };
 
  //functions to set state individually
  const whitelistHandler = event => {
    let splitArr = [];
    let holder = [];
    holder = splitArr.concat(event.target.value.split(","))
    let whiteArr = [];
    if (event.target.value.length === 0) {
      setDomainDisable("disable")
      setWhitelist(whiteArr.concat(event.target.value.split(",")))
      setDomainError("")
    }
    if (event.target.value.length > 0) {
      holder.forEach(value => {
        if (validateDomain(value)) {
          setWhitelist(whiteArr.concat(event.target.value.split(",")))
          setDomainDisable("")
          setDomainError("")
        }
        if (!validateDomain(value)) {
          setDomainError("Enter valid whitelist domain Id")
        }
      })
    }
  }
  //functions to set state individually
  const orgHandle = event => {
    setOrgName(event.target.value)
    orgs.map((option) => {
      if (option.name === event.target.value) {
        setOrgAcr(option.orgAcronym)
        setOrgId(option.orgId)
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Create Facility Manager</h2>
              </div>
              <form className="form-field" style={{ padding: 0 }}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">

                    <label>Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      id='nameId'
                      name="accountFName"
                      placeholder="First-Name Last-Name"
                      value={solUserName}
                      onChange={accountFNamehandle}
                    />
                    <p

                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {fnError}
                      </span>
                    </p>

                    <label>Email Address*</label>
                    <TextField
                      fullWidth
                      type="email"
                      value={email}
                      autoComplete="off"
                      name="contactEmail"
                      placeholder="Email Address"
                      onChange={emailHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {emailError}
                      </span>
                    </p>

                    <label>Contact Number*</label>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onWheel={evt =>evt.target.blur()}
                      value={contact}
                      className="phone_field"
                      name="contactNumber"
                      placeholder="Enter 10 numbers only"
                      onChange={contactHandle}
                     
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {contactError}
                      </span>
                    </p>

                    <label>Alternate Contact Person</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="altName"
                      value={altName}
                      name="altName"
                      placeholder="Alternate Contact Name"
                      onChange={altNameHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altNameError}
                      </span>
                    </p>


                    <label>Alternate Contact Number</label>
                    <TextField
                      fullWidth
                      type="number"
                      name="altNumber"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onWheel={evt =>evt.target.blur()}
                      value={altContact}
                      placeholder="Enter 10 numbers only"
                      onChange={altContactHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altcontactError}
                      </span>
                    </p>
                    <label>Organization Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      name="orgName"
                      select
                      onChange={orgHandle}
                    >
                      {orgs.map((option) => (
                        <MenuItem key={option.orgId} value={option.name}>
                          <span style={{ fontSize: '16px' }}>{option.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>

                    <label>Organization Acronym</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="orgAcr"
                      value={orgAcr}
                      disabled
                    />
                    { }

                  </div>
                  <div className="contactdetails__wrapper__container__leftside">

                    <label>Organization Address*</label>
                    <TextField
                      fullWidth
                      type="text"
                      value={orgAdr}
                      placeholder="Enter Org Address"
                      onChange={orgAdrHandle}
                    />
                    <p
                    // style={{
                    //   borderTop: adrError ? "1px solid red" : undefined,
                    // }}
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {adrError}
                      </span>
                    </p>
                    <label>Country*</label>
                    <TextField
                      fullWidth
                      disabled
                      type="text"
                      value="India"
                      onChange={countryHandle}
                    />
                    <p
                    // style={{
                    //   borderTop: adrError ? "1px solid red" : undefined,
                    // }}
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {countryError}
                      </span>
                    </p>


                    <label>State*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="stateName"

                      value={stateName}
                      onChange={stateHandle}
                    />
                    <p

                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {stateError}
                      </span>
                    </p>
                    <label>City*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="city"
                      value={city}
                      onChange={cityHandle}
                    />
                    <p
                    // style={{
                    //   borderTop: adrError ? "1px solid red" : undefined,
                    // }}
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {cityError}
                      </span>
                    </p>
                    <label>Postal Code*</label>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onWheel={evt =>evt.target.blur()}
                      value={pincode}
                      name="postalCode"
                      placeholder="Enter 6 to 8 characters only"
                      onChange={postalHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {pinError}
                      </span>
                    </p>
                    
                    <label>Whitelist Domains*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="whiteList"
                      placeholder="@bosch.com, @bosch.in, …."
                      onChange={whitelistHandler}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {domainError}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="header">
                  <h2 className="heading-text">Parking Slot Allocation</h2>
                </div>
                <div className="contactdetails__wrapper__container">

                  <div className="contactdetails__wrapper__container__leftside">
                    <p style={{ marginTop: '-15px', marginBottom: '15px' }}>Please Fill Atleast One Field</p>
                    <label>Total Bike Slots</label>
                    <TextField
                      fullWidth
                      type="number"
                      onWheel={evt =>evt.target.blur()}
                      value={totalBikeSlots}
                      name="bikeSlots"
                      placeholder="Maximum of 9999 slots are allowed"
                      onChange={totalBikeSlotsHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {totalBikeSlotsError}
                      </span>
                    </p>
                  </div>
                  <div className="contactdetails__wrapper__container__leftside">
                    <p style={{ marginTop: '-15px', marginBottom: '15px', visibility: 'hidden' }}>Filler</p>
                    <label>Total Car Slots</label>
                    <TextField
                      fullWidth
                      type="number"
                      onWheel={evt =>evt.target.blur()}
                      value={totalCarSlots}
                      name="carSlots"
                      placeholder="Maximum of 9999 slots are allowed"
                      onChange={totalCarSlotsHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {totalCarSlotsError}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="button-section">
                  <Button
                    variant="contained"
                    className="form-button submit"
                    onClick={submitDetailsConfirm}
                    disabled={
                      pinDisable.length !== 0 ||
                      stateName.length === 0 ||
                      city.length === 0 ||
                      country.length === 0 ||
                      pincode.length === 0 ||
                      whitelist.length === 0 ||
                      domainDisable.length !== 0 ||
                       contactDisable.length !== 0 ||
                      emailDisable.length !== 0 ||
                      solUserName.length === 0 ||
                      orgAdr.length === 0 ||
                      orgAcr.length === 0 ||
                      orgName.length === 0 ||
                      orgId.length === 0 ||
                      adrError.length !== 0 ||
                      // pinError.length !== 0 ||
                      fnError.length !== 0 ||
                      // lnError.length !== 0 ||
                      emailError.length !== 0 ||
                       contactError.length !== 0 ||
                       altcontactError.length !== 0 ||
                      domainError.length !== 0 ||
                      altNameError.length !== 0 ||
                      (totalCarSlots == 0 && totalBikeSlots == 0) ||
                      (!totalCarSlots && !totalBikeSlots) ||
                     // totalCarSlotsError.length !== 0 || totalBikeSlotsError.length !== 0 ||
                      isLoading
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    className="form-button cancel"
                    onClick={() => props.history.push("/portal/viewaccount")}
                  >
                    Cancel
                  </Button>

                  <Dialog
                    fullScreen={fullScreen}
                    open={IsdialogOpen}
                    onClose={handleClose}
                    aria-labelledby="create facility manager"
                  >
                    <DialogTitle
                      id="create facility manager dialog-header"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Create Facility Manager
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Do you want to create this Facility Manager?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={submitDetails}
                        variant="contained"
                        className="confirm-button"
                        autoFocus
                      >
                        Confirm
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        onClick={handleClose}
                        className="cancel-button"
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar
                    open={openAlert}
                    autoHideDuration={10000}
                    onClose={handleCloseAlert}
                  >
                    <Alert onClose={handleCloseAlert} severity={severity}>
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   dispatch,
// });
//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(CreateAccount)
);
