// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles } from '@material-ui/core/styles'
//@component
//detailsUseStyles
const detailsUseStyles = makeStyles(theme=>({
// 
    viewMore: {
        cursor:"pointer",
        fontStyle:"italic",
        fontSize:"0.65rem",
        marginLeft:"0.5em"
    },
    parkingSpaceGrid:{
        marginTop:"0.25em",
    },
    resolvedButtonGrid:{
        width: "10%"
    },
    resolveButtonGrid:{
        marginTop: "1rem"  
    },
    cancelButtonGrid:{
        marginTop: "1rem"
    },
    textareaError:{
        fontSize: "12px", 
        color: "red"
    },
    textareaGrid:{
        width: "100%"
    },
    backButton: {
        fontFamily: '\'Poppins\' !important',
        cursor: 'pointer',
        borderRadius: '90px',
        border:'none',
        padding: '9px 27px !important',
        width:'13%',
        fontSize: '12px',
        fontWeight: 900,
        backgroundColor: '#A2A2A2',
        color: '#fff',
        boxShadow: '0px 3px 6px #00000029 !important'
    },
    backButtonGrid: {
        marginTop: "1.3%", 
        marginLeft: "1.8%",
        marginBottom: "9.5%",
    },
    subHeaderColumn:{
        fontFamily:'\'Poppins\' !important',
        fontWeight:'600',
        fontSize:"12px",
        lineHeight:"1.6",
        color:"#21034F"
    },
    headerGrid: {
        marginTop: "0.8%", 
        marginLeft: "2.5%",
    },
    headerText: {
        fontFamily:'\'Poppins\' !important',
        fontWeight:'700',
        fontSize:"18px",
        color:'#21034F',
        lineHeight:"1.6",
    },
    commentParagraph: {
        marginTop:"0.3em",
        wordWrap:"break-word",
        fontSize:'0.85rem'
    },
    descriptionParagraph: {
        marginTop:"0.15em",
        wordWrap:"break-word",
        fontSize:'0.85rem'
    },
    invalidButtonGrid: {
        width: "10%", 
        marginLeft: "2em"
    },
    complaintParagraph: {
        fontWeight: "600", 
        fontSize: "1rem"
    },
    complaintGrid: {
        width: "70%", 
        marginLeft: "2em"
    },
    rowValueSpace:{
        marginLeft:"148px"
    },
    rowSpace:{
        marginTop:"1em"
    },
    statusColumnGrid:{
        right:"50px",
        position:"absolute"
    },
    selectNavCard:{
        height:"4em",width:"5em",border:0,borderRadius:"22px",
    },
    tableGrid:{
        marginTop:"2em",marginBottom:"2em",width:"96%",height:"50%",marginLeft:"1.5em"
    },
    table: {
        minWidth: 650,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "12px",
        fontWeight: "bolder",
        // width: 17%;
    },
   detailsGrid:{
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr 2fr)',
        alignItems: 'start'
    },
    OvernightDetailCard: {
        zIndex: 0,
        boxShadow:'0px 3px 6px #00000029 !important',
        marginTop:"12px",
        width: "96%",
        height: "100%",
        marginLeft:"19px",
        paddingLeft: "2.0em",
        paddingTop: "2.5em",
        paddingBottom: "2.5em",
        paddingRight: "2.5em",
    },
    dialogHeading: {
        fontFamily: '\'Poppins\' !important',
        fontWeight:900,
        fontSize:"12px",
        color: "#102E35",
        lineHeight:"1.6",  
        marginBottom: '5px',
        marginTop: '3%',
    },
    statusColors: {
        fontFamily: '\'Poppins\' !important',
        fontWeight:'600',
        fontSize:"12px",
        padding:"5px",
        lineHeight:"1.6",  
        marginBottom: '0.5em',
        color:'green',
    },
    headingRight: {
        fontFamily: '\'Poppins\' !important',
        fontWeight:'600',
        color:'#21034F !important',
        fontSize:"14px",
        padding:"5px",
        lineHeight:"1.6", 
        textAlign:'start',
    },
    value: {
        fontFamily:'\'Poppins\' !important',
        fontWeight:'400',
        fontSize:"12px",
        paddingLeft:"5px",
        paddingTop:"2px",
        lineHeight:"1.6",
        marginRight: '15px',
        wordBreak:'break-word',
        textAlign: 'justify',
        marginBottom: '15px',
        color:"#102E35"
    },
    valueRemarks: {
        fontFamily:'\'Poppins\' !important',
        fontWeight:'400',
        fontSize:"12px",
        color:'#102E35 !important',
        lineHeight:"1.6",
        paddingLeft:"5px",
        marginRight: '3em',
        wordBreak:'break-word',
        textAlign: 'justify',
        marginBottom: '15px',
    },
    statusLog : {
        fontFamily:'\'Poppins\' !important',
        fontWeight:'400',
        fontSize:"12px",
        padding:"5px",
        lineHeight:"1.6",
        wordBreak:'break-word',
        justifyContent: 'space-between',
    },
    valueRight: {
        fontFamily:'\'Poppins\' !important',
        fontWeight:'bolder',
        fontSize:"16px",
        lineHeight:"1.6",
        marginBottom: '5%',
        wordBreak:'break-word',
        textAlign:'start'

    },
    statusChangeButton:{
        ...theme.typography.view,
        borderRadius: 50,
        height: "2.5em",
        width: "10em",
        fontSize:"0.71rem",
        border:"none",
        fontWeight:"600",
    }, 
    dialogButton:{
        ...theme.typography.view,
        borderRadius: 50,
        color: "#fff",
        height: "2.5em",
        width: "7em",
        fontSize:"0.71rem",
        border:"none",
        fontWeight:"600",
        marginBottom:"2em"
    },
  
    superAdminComment: {
        fontWeight:"600",fontSize:"0.60rem"
    },
    superAdminCommentMargin:{
        marginTop:"3.5em",marginBottom:"2em"
    },
    labelMargin:{
        marginTop:"1.5em"
    },
    createdDateGrid: {
        marginLeft:"2em",marginTop:"1.5em"
    },
    dialogTextArea:{
        width:"100%",
        height:"7em",
        borderRadius:"5px",
        marginTop:'0.2%',
        color:'#102E35',
        fontFamily: '\'Poppins\' !important',
        padding:'2%',
        fontSize: '12px',
    },
    dialogTitle:{
        fontFamily: '\'Poppins\' !important',
        fontSize:'14px',
        fontWeight:900,
        color:'#21034F',
    },
    dialogCloseIcon:{
        cursor: "pointer",fontSize:"14px",color:'#21034F'
    },
    statusText:{
        fontWeight:"600",fontSize:"0.95rem",marginLeft:"2em",color:"#F49D3E",
    },
    statusLabel:{
        fontWeight:"600",fontSize:"0.60rem",marginLeft:"2em"
    },
    complaintDescription: {
        fontSize:"0.65rem",
        maxWidth:"12em",
        height:"4em",
        overflowX: "auto",
        overflowY: "auto"
    },
    gridComplaintDescription:{
        marginLeft:"2em",width:"50%",marginTop:"2em"
    },
    statebtnapprove :{
        cursor:'pointer',
        border:"none !important",
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px !important',
        margin: 'auto !important',
        padding: '5px 15px 5px 15px !important',
        backgroundColor: "#67B561",
        color: '#ffffff !important',
        width: '175px',
        height: '35px',
        boxShadow: '4px 4px 6px #00000024',
        marginBottom: '30px',
        disabled :{
            backgroundColor: '#ccc',
          }
      },
      
      statebtnreject :{
        cursor:'pointer',
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px !important',
        border:"none !important",
        margin: 'auto !important',
        padding: '5px 15px 5px 15px !important',
        backgroundColor: "#A0152B",
        color: '#ffffff !important',
        width: '175px',
        height: '35px',
        boxShadow: '4px 4px 6px #00000024',
        marginBottom: '30px',
      },
      gridValues :{
          marginBottom:'1em',
      },
      dialogActionGrid: {
        marginTop:'0.5em',
      },
      dialogSubmit: {
        cursor:'pointer',
        fontSize: '12px',
        fontWeight:900,
        fontFamily: '\'Poppins\' !important',
        backgroundColor:'#03A9F3',
        marginLeft:'20px',
        color:'#fff !important',
        borderRadius:'90px !important',
        border:"none !important",
        width: '90px',
        height: '32px',
        marginBottom:"3%"
      },
      dialogCancel: {
        cursor:'pointer',
        fontSize: '12px',
        fontWeight:900,
        fontFamily: '\'Poppins\' !important',
        backgroundColor:'#707070 !important',
        color:'#fff !important',
        borderRadius:'90px !important',
        border:"none !important",
        width: '90px',
        height: '32px',
        marginBottom:"3%"
      },
     
}));

export default detailsUseStyles;