// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditParkingStepOne from './../EditParkingStepOne';
import httpRequest from '../../../../../Utils/httpRequest';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import { updateBreadcrumbs } from '../../../../../redux/modules/app/actions';
import { getCookie } from '../../../../../Utils/cookies';
import Terms from '../../../../SolutionAdmin/ParkingManagement/EditParkingModule/Termsconditions';
import './style.scss';

//function for styles
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  // backButton: {
  //   marginRight: theme.spacing(1),
  // },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

//@component
//Edit Parking
function EditParking(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [allStepsCompleted] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  //function to cancel pop
  const cancalPopup = () => {
    setShowResults(false);
  };
  //function for userdatasuccess
  const UserDataSuccess = jsonResult => {
    let key = 'parkingId';
    var imageIdKey = [];
    let value = jsonResult.data.parkingId;
    props.updateChildrenState('step1', key, value);
    try {
      if (props.addParkinSteps.step1.deleteImagesFromSpace.length > 0) {
        props.addParkinSteps.step1.imageId.map((item, index) => {
          props.addParkinSteps.step1.deleteImagesFromSpace.map(
            (image, index) => {
              if (image === item.data) {
                imageIdKey.push(item.config.url);
              }
              return <div key={index}></div>;
            },
          );
          return <div key={index}></div>;
        });
        const unique = [...new Set(imageIdKey.map(item => item))];



        const deleteImagesArray = [];
        unique.map((item) => {
          let res = item.split('/');
          let imageId = res[9].split('?');
          deleteImagesArray.push(imageId[0])
        })
        activeStep == 0 &&
          deleteImage(deleteImagesArray, value);
        // return <div key={index}></div>;

        
        setTimeout(() => {
          activeStep == 0 && uploadImage(value)
        }, 2000);


        const newActiveStep = props.history.push('/portal/success');
        setActiveStep(newActiveStep);





      }
      else {
        activeStep == 0 &&
          uploadImage(value);
        const newActiveStep = props.history.push('/portal/success');
        setActiveStep(newActiveStep);

      }
    }
    catch (err) {
      // catch error here
    }


  };
  //function to getcustomOperatingHoursInfo
  const getcustomOperatingHoursInfo = customParam => {
    let result = [];

    customParam.customData.map(item => {
      result.push({
        startDate: item.startDate,
        endDate: item.endDate,
        openingTime: item.openingTime,
        closingTime: item.closingTime,
      });
    });
    return result;
  };
  //function to getvehicleTypeInfo
  const getvehicleTypeInfo = step2Data => {
    let result = [];
    for (let vehicleType in step2Data) {
      let data = {};
      if (step2Data[vehicleType].totalSlots) {
        const currentData = step2Data[vehicleType];

        data = {
          totalSlots: currentData.totalSlots,
          vehicleType: {
            uid: currentData.uid,
          },
          paid: currentData.paid,
        };
        if (currentData.paid) {
          data.priceInfo = {
            baseHour: Number(currentData.baseHour),
            basePrice: currentData.basePrice,
            additionalHour: Number(currentData.additionalHour),
            additionalPrice: currentData.additionalPrice,
            pricePerDay: currentData.pricePerDay,
            pricePerWeek: currentData.pricePerWeek,
            pricePerMonth: currentData.pricePerMonth,
            selectedLongterm: currentData.selectedLongterm,
          };
        }
      }
      if (Object.keys(data).length) {
        result.push(data);
      }
    }
    return result;
  };
  //function to getOperatingHoursInfo
  const getOperatingHoursInfo = params => {
    let result = {};
    for (let day in params) {
      if (params[day].isChecked && day !== 'applyForAll') {
        result[day] = [
          {
            openingTime: params[day].time.openingTime,
            closingTime: params[day].time.closingTime,
          },
        ];
      }
    }
    return result;
  };
  //function to getFacilities
  const getFacilities = params => {
    const unique = params
      .map(e => e.uid)
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => params[e])
      .map(e => params[e]);
    return unique;
  };
  //function to createRequestBody
  const createRequestBody = params => {
    return {
      parkingId: params.step1.parkingId || '',
      parkingBusinessType: params.step1.parkingBusinessType,
      addressDetails: {
        addressLine1: params.step1.addressDetails.addressLine1,
        addressLine2:
          params.step1.addressDetails.addressLine2 !== ''
            ? params.step1.addressDetails.addressLine2
            : '',
        city: params.step1.addressDetails.city,
        state: params.step1.addressDetails.state,
        pincode: params.step1.addressDetails.pincode,
      },

      location: params.step1.location,
      name: params.step1.parkingName,
      description: params.step1.description,
      contactName: params.step1.contactName,
      contactNumber: params.step1.contactNumber,
      contactEmail: params.step1.contactEmail,
      vehicleTypeInfo: getvehicleTypeInfo(params.step2),
      operatingHoursInfo: getOperatingHoursInfo(params.step2.timings),
      customOperatingHoursInfo: getcustomOperatingHoursInfo(
        params.step2,
      ),
      // facilities: params.step3.facilities,
      facilities: getFacilities(params.step3.facilities),
      services: params.step3.services,
      eula: {eulaAccepted:true,
        eulaVersion:"1"},
      isHardwareInterested: params.step3.isHardwareInterested,
    };
  };
  //function for error call
  const errorCallback = error => {
    if (error.message) {
      props.updateToasterConfig({
        show: true,
        message: error.message,
        color: '#FF0000',
      });
    }
  };
  //function to uploadImage
  const uploadImage = value => {


    let imges = props.addParkinSteps.step1.images.filter(
      item => item.name,
    );
    let formData = new FormData();
    for (let i = 0; i < imges.length; i++) {
      formData.append('file', imges[i].file);
    }
    imges.length &&
      httpRequest
        .post(
          'uploadsImages',
          formData,
          {
            'content-type': 'multipart/form-data',
          },
          UserImageSuccess,
          errorCallback,
          { parkingId: value },
        )
        .then(() => { })
        .catch(() => { });
  };
  //function to deleteImage
  const deleteImage = (imagesIds, value) => {
    let imagesdelete = imagesIds;

    let params = '';
    imagesdelete.map((item, index) => {

      if (index === (imagesIds.length - 1)) {

        let param = 'imageIdList='
        param = param.concat(item)

        params = params.concat(param)
      }
      else {
        let parami = 'imageIdList='
        parami = parami.concat(item)
        let paramend = '&';
        parami = parami.concat(paramend)

        params = params.concat(parami)

      }
    });

  
    httpRequest
      .delete(
        'deleteImages',
        {
          'content-type': 'multipart/form-data',
          Authorization: 'bearer ' + getCookie('auth'),
        },
        deleteImageSuccess,
        (e) => {
          console.log("error deleting images", e)
        },
        {
          parkingId: value,
          imageId: params,
        },
      )

      .then(() => { })
      .catch(() => { });
  };
  //function for userimagesuccess
  const deleteImageSuccess = () => {

    // const newActiveStep = props.history.push('/portal/success');
    //     setActiveStep(newActiveStep);
  };
  //function for userimagesuccess
  const UserImageSuccess = () => {

    // const newActiveStep = props.history.push('/portal/success');
    // setActiveStep(newActiveStep);
  };
  //function to handleNext
  const handleNext = () => {
    var anyOneCustomDate = props.addParkinSteps.step2.customData.some(
      function (e) {
        return (
          e.startDate === '0000-00-00' && e.endDate === '0000-00-00'
        );
      },
    );
    if (
      props.addParkinSteps.step2.twoWheeler.totalSlots >= 1 ||
      props.addParkinSteps.step2.fleet.totalSlots >= 1 ||
      props.addParkinSteps.step2.fourWheeler.totalSlots >= 1
    ) {
      var lessThanOne = props.addParkinSteps.step3.services.some(
        function (e) {
          return e.distanceInMeters < 1;
        },
      );
      if (!props.addParkinSteps.step3.eula.eulaAccepted) {
        if (lessThanOne) {
          setTimeout(() => {
            props.updateToasterConfig({
              show: true,
              message: 'Service distance should be greater then 0',
              color: '#FF0000',
            });
          }, 6000);
        } else if (anyOneCustomDate) {
          setTimeout(() => {
            props.updateToasterConfig({
              show: true,
              message: 'Custom dates should not be 0000-00-00',
              color: '#FF0000',
            });
          }, 6000);
        } else {
          setShowResults(true);
          httpRequest.patch(
            'editParking',
            createRequestBody({ ...props.addParkinSteps }),
            {},
            UserDataSuccess,
            errorCallback,
            {
              userId: getCookie('userId'),
              parkingId: props.addParkinSteps.step1.parkingId,
            },
          );
          return false;
        }
      }
      if (
        !props.addParkinSteps.step1.contactName ||
        !props.addParkinSteps.step1.contactEmail ||
        !props.addParkinSteps.step1.parkingName ||
        !props.addParkinSteps.step1.parkingBusinessType ||
        !props.addParkinSteps.step1.location ||
        !props.addParkinSteps.step1.contactNumber ||
        !props.addParkinSteps.step1.description
        // !props.addParkinSteps.step1.images.length
      ) {
        props.updateToasterConfig({
          show: true,
          message:
            'Please fill all the input fields from the Step 1 to submit your parking',
          color: '#FF0000',
        });
      } else {
        if (props.location.search !== '') {
          httpRequest.patch(
            'editParking',
            createRequestBody({ ...props.addParkinSteps }),
            {},
            UserDataSuccess,
            errorCallback,
            {
              userId: getCookie('userId'),
              parkingId: props.addParkinSteps.step1.parkingId,
            },
          );
        } else {
          httpRequest.post(
            'EditParking',
            createRequestBody({ ...props.addParkinSteps }),
            {},
            UserDataSuccess,
            errorCallback,
            { userId: getCookie('userId') },
          );
        }
      }
    } else {
      props.updateToasterConfig({
        show: true,
        message: 'Parking slot of any vehicle should be atleast 1',
        color: '#FF0000',
      });
    }
  };
  //function to handle reset
  const handleReset = () => { };
  return (
    <div className="Editparking">
      <div className="Editparking__wrapper">
        <div className={classes.root}>
          <div className="stepper">
            {allStepsCompleted ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  <EditParkingStepOne
                    search={props.location.search}
                    data={props.addParkinSteps}
                    updateRootState={props.updateRootState}
                    updateChildrenState={props.updateChildrenState}
                  />
                </Typography>
                <div className="Editparking__wrapper_bottombuttons" style={{ marginBottom: "10em" }}>
                  <div className="next">
                    <Button
                      onClick={() => {
                        props.history.push(
                          '/portal/parking-details/' +
                          props.addParkinSteps.step1.parkingId,
                        );
                      }}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="submitBtn">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={false}
                      className={classes.button}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {showResults ? (
        <Terms
          data={props.addParkinSteps.step3}
          updateRootState={props.updateRootState}
          updateChildrenState={props.updateChildrenState}
          next={handleNext}
          cancal={cancalPopup}
        />
      ) : null} */}
    </div>
  )
}
//function for redux connect
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig, updateBreadcrumbs },
  )(EditParking),
);
