// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";
import { Line, Bar } from "react-chartjs-2";
// import ChartUtilization from 'chart.js/auto';
import "./Chart.scss";
//@component
//ChartContainer
class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {},
      parkingData: {},
      options: {},
      showLine: false,
      showBar: true,
      showPie: false,
      fillFlag: false,
      fmtDate: props.selectedDate,
      ymaxVal: props.yAxesMaxVal,
      yStepSize: props.yAxesStepSize,
    };
  }

  //function when component mounts
  componentDidMount() {
    this.graphData();
    this.graphType();
   
  }

  //function when component receives props
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedDate !== this.props.selectedDate) {
      this.setState({
        fmtDate: nextProps.selectedDate,
      });
    }
    if (nextProps.yAxesMaxVal !== this.props.yAxesMaxVal) {
      this.setState({
        ymaxVal: nextProps.yAxesMaxVal,
      });
    }
    if (nextProps.yAxesStepSize !== this.props.yAxesStepSize) {
      this.setState({
        yStepSize: nextProps.yAxesStepSize,
      });
    }
  }

  //function for success call
  UserDataSuccess = (jsonResult) => {
    const hours = jsonResult.data.utilization["21-05-2020"];
    const newHours = hours.sort((a, b) => a.hour - b.hour);
    this.setState({
      parkingData: newHours,
    });
  };

  //funciton for graph data
  graphData = () => {
    const chartData = (canvas) => {
      const ctx = canvas.getContext("2d");
      const solidRed = "#E46A76";
      const gradient = ctx.createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
      gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

      return {
        labels: this.props.parkingData.length
          ? this.props.parkingData.map(function(k) {
              let number = k.hour;
              let time = number + "am";
              if (number == 0) {
                time = "12am";
              }
              if (number > 12) {
                time = number - 12 + "pm";
              }
              if (number == 12) {
                time = "12pm";
              }
              return time;
            })
          : 0,
        datasets: [
          {
            label: "Occupied Slots",
            fill: this.state.fillFlag ? true : false,
            // fill: true,
            data: this.props.parkingData.length
              ? this.props.parkingData.map((d) => d.occupiedSlots)
              : 0,
            backgroundColor: this.state.fillFlag ? gradient : solidRed,
            borderColor: this.state.fillFlag ? "transparent" : "#E46A76",
            customData: this.state.fmtDate,
            labelData: this.props.parkingData.length
              ? this.props.parkingData.map((d) => d.occupancyPercentage)
              : 0,
          },
          // {
          //   label: 'Occupancy %',
          //   fill: false,
          //   // fill: true,
          //   data: this.props.parkingData.length
          //     ? this.props.parkingData.map(d => {
          //       if(d.occupancyPercentage){
          //         return d.occupancyPercentage;
          //       }else{
          //         return 0;
          //       }
          //     })
          //     : 0,

          //   customData: this.state.fmtDate,
          // }
        ],
      };
    };

    this.setState({ chartData });
  };

  //function for type of graph

  graphType = async () => {
    const Chart_type = this.props.chartType;
    let { selectedDate } = this.props;

    if (Chart_type === "Line") {
      await this.setState({
        showLine: true,
        showBar: false,
        showPie: false,
        fillFlag: false,
        fmtDate: selectedDate,
      });
    } else if (Chart_type === "Bar") {
      await this.setState({
        showLine: false,
        showBar: true,
        showPie: false,
        fillFlag: false,
        fmtDate: selectedDate,
      });
    } else if (Chart_type === "Pie") {
      await this.setState({
        showLine: false,
        showBar: false,
        showPie: true,
        fillFlag: true,
        fmtDate: selectedDate,
      });
    }
  };

  render() {
    const options = {
      responsive: true,
      showTooltips: true,
      height: "900px",
      width: "900px",
      maintainAspectRatio: true,
      hover: {
        animationDuration: 0,
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: "index",
        intersect: true,
        bodyFontColor: "#21034F",
        bodyFont: {
          weight: "bold",
        },
        fontFamily: "'Poppins' !important",
        titleFontColor: "#102E35",
        footerFontColor: "#102E35",
        backgroundColor: "rgba(256, 256, 256)",
        borderColor: "#7070701F",
        borderWidth: 2,
        xPadding: 6,
        yPadding: 6,
        cornerRadius: 6,

        callbacks: {
          title: function(tooltipItem, data) {
            let number = data.labels[tooltipItem[0].index];
            let secondNumber = data.labels[tooltipItem[0].index + 1];
            return (
              data.datasets[0].customData +
              "," +
              " " +
              number +
              " - " +
              (tooltipItem[0].index == "23" ? "12AM" : secondNumber)
            );
          },
          footer: function(context, data) {
            return (
              "Occupancy : " +
              "  " +
              data.datasets[0].labelData[context[0].index] +
              "%"
            );
          },
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Number of Occupied Slots",
              fontStyle: "bold",
            },
            ticks: {
              beginAtZero: true,
              stepSize: this.state.yStepSize,
              max: this.state.ymaxVal,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Time (Hours)",
              fontStyle: "bold",
            },
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 6,
            },
          },
        ],
      },
    };
    const { showLine, chartData, showBar, showPie } = this.state;

    return (
      <div style={{ marginTop: "20px" }}>
        {showLine && <Line data={chartData} options={options} />}
        {showBar && <Bar data={chartData} options={options} />}
        {showPie && <Line data={chartData} options={options} />}
      </div>
    );
  }
}
export default Chart;
