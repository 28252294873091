// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';

//@component
//PasswordStrengthMeter
class PasswordStrengthMeter extends Component {
  //switch case function
  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  };

  render() {
    // function scope variable declaration
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        <progress value={testedResult.score} max="4">
          <span>{testedResult.score}</span>
        </progress>
        <br />
        <label className="password-strength-meter-label">
          {password && (
            <>
              <strong>Password strength:</strong>
              {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;
