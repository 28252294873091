// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    setCookie(c, "", -1);
  });
  window.location.reload();
};
