// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
const parkingTabData = {
  baseHour: 1,
  basePrice: "",
  additionalHour: 1,
  additionalPrice: "",
  pricePerDay: 0,
  pricePerWeek: 0,
  pricePerMonth: 0,
  paid: false,
  totalSlots: 0,
  selectedLongterm: "pricePerDay",
};

export const addParkinSteps = {
  step1: {
    parkingId: "",
    parkingName: "",
    contactNumber: "",
    contactName: "",
    contactEmail: "",
    parkingBusinessType: {
      uid: "",
    },
    description: "",
    images: [],
    addressDetails: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
    },
    location: {
      lat: "",
      lng: "",
    },
  },

  step2: {
    slotFlag: false,
    twoWheeler: {
      ...parkingTabData,
      uid: 2,
    },
    fourWheeler: {
      ...parkingTabData,
      uid: 1,
    },
    fleet: {
      ...parkingTabData,
      uid: 3,
    },

    timings: {
      applyForAll: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },

      MONDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      TUESDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      WEDNESDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      THURSDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      FRIDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      SATURDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
      SUNDAY: {
        isChecked: false,
        time: {
          openingTime: "09:00:00",
          closingTime: "21:00:00",
        },
      },
    },
    customData: [],
  },
  step3: {
    services: [],
    facilities: [],
    selectedCheckbox: [],
    servicesNearBy: {
      busStand: {
        isChecked: false,
        distance: "100:00",
      },
      metroStation: {
        isChecked: false,
        distance: "0",
      },
      autoStand: {
        isChecked: false,
        distance: "0",
      },
      cabPick: {
        isChecked: false,
        distance: "0",
      },
      sharedMobility: {
        isChecked: false,
        distance: "0",
      },
    },
    eula: {
      eulaAccepted: false,
      eulaVersion: "1",
    },
    isHardwareInterested: false,
  },
};
export const breadcrumbs = {
  parkingManagement: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management" },
  ],
  profile: [{ name: "Home", link: "/portal/dashboard" }, { name: "Profile" }],
  profileEdit: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Profile", link: "/portal/profile" },
    { name: "Profile Edit" },
  ],
  listParkingSpace: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "List My Parking Space" },
  ],
  listParkingSpaceStep1: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "List My Parking Space - Step 1" },
  ],
  listParkingSpaceStep2: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "List My Parking Space - Step 2" },
  ],
  listParkingSpaceStep3: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "List My Parking Space - Step 3" },
  ],
  EditParkingSpaceStep1: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "Edit My Parking Space " },
  ],
  EditParkingSpaceStep2: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "Edit My Parking Space " },
  ],
  EditParkingSpaceStep3: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "Edit My Parking Space " },
  ],
  parkingDetails: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Parking Management", link: "/portal/parkingmanagment" },
    { name: "Parking Details" },
  ],
  addorganization: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Organization Management", link: "/portal/vieworganization" },
    { name: "Add Organization" },
  ],
  vieworganization: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Organization Management", link: "/portal/vieworganization" },
    { name: "View Organization" },
  ],
  editorganization: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Organization Management", link: "/portal/editorganization" },
    { name: "Edit Organization" },
  ],
  roleManagement: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Role Management" },
  ],
  landing: [{ name: "Home", link: "/portal/dashboard" }, { name: "Reports" }],

  reportsParkedUsers: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Reports-Parked Users" },
  ],
  reportsRegisterUsers: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Registered End Users Report" },
  ],
  reportsDeregisterUsers: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "De-Registered End Users Report" },
  ],
  reportsParkingSpace: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Parking Spaces Report" },
  ],
  reportsOnp: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Overnight Parking Violations Report" },
  ],
  reportsOnpSecurity: [
    { name: "Home", link: "/portal/onplistview" },
    { name: "Reports", link: "/portal/overnight_violation_report" },
    { name: "Overnight Parking Violations Report" },
  ],
  reportsUnregister: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Unregistered/Non Std Vehicles Report" },
  ],
  reportsOverspeed: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Overspeed Violation Report" },
  ],
  tempAccessReports: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/reports" },
    { name: "Vehicle Access Report" },
  ],
  deviceReports: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "residential_reports" },
    { name: "Device Report" },
  ],
  bookingReport: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Bookings Report" },
  ],
  vehicleAccessReports: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "residential_reports" },
    { name: "Vehicle Access Report" },
  ],
  // reportsTempAccessSecurity: [
  //   { name: 'Home', link: '/portal/onplistview' },
  //   { name: 'Reports', link: '/portal/temporary_access_report' },
  //   { name: 'Temporary Access Report' }
  // ],
  complaint: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Complaint Management" },
  ],
  complaintdetails: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Complaint Management", link: "/portal/complaint" },
    { name: "Complaint Details" },
  ],
  soslistview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Emergency Support Contacts" },
  ],
  sosEditview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Emergency Support Contacts", link: "/portal/soslistview" },
    { name: "Emergency Support Contact Details" },
  ],
  onplistview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Services", link: "/portal/onplistview" },
    { name: "Overnight Parking Requests" },
  ],
  onpviewSecurity: [
    { name: "Home", link: "/portal/onplistview" },
    { name: "Services", link: "/portal/onplistview" },
    { name: "Overnight Parking Requests" },
  ],
  onpdetails: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Services", link: "/portal/onplistview" },
    { name: "Overnight Parking Request Details" },
  ],
  securitylistview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/securitylistview" },
    { name: "Security Admins-List View" },
  ],
  securitycreate: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/securitylistview" },
    { name: "Security Admins-Create User" },
  ],
  facilitymanagerlistview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/facilitymanagerlistview" },
    { name: "Facility Manager - List View" },
  ],
  facilitymanagercreate: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/facilitymanagerlistview" },
    { name: "Facility Manager - Create User" },
  ],
  facilitymanageredit: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/facilitymanagerlistview" },
    { name: "Facility Manager - Edit User" },
  ],
  createaccount: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/viewAccount" },
    { name: "Create FCM Account" },
  ],
  editmtfcmaccount: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/viewAccount" },
    { name: "Edit FCM Account" },
  ],

  viewaccount: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "View FCM Account List" },
  ],
  viewaccdetails: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "User Management", link: "/portal/viewAccount" },
    { name: "View FCM Account Details" },
  ],
  tempaccesslistview: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Access Control Management" },
  ],
  viewtempaccdetails: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Access Control Management", link: "/portal/tempaccesslistview" },
    { name: "View Details" },
  ],
  tempaccessedit: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Access Control Management", link: "/portal/tempaccesslistview" },
    { name: "Edit Details" },
  ],
  tempaccessupload: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Access Control Management", link: "/portal/tempaccesslistview" },
    { name: "Bulk Upload" },
  ],
  vehicleExitEvents: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Services", link: "/portal/vehicleExitEvents" },
    { name: "Trigger Vehicle Events" },
  ],
  alertNotifications: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Alert Notifications Contacts" },
  ],
  addAlertNotifications: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Notification contacts", link: "/portal/alertNotifications" },
    { name: "Add Notification Contacts" },
  ],
  editAlertNotifications: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Notification contacts", link: "/portal/alertNotifications" },
    { name: "Update Notification Contacts" },
  ],
  viewBookings: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "View Bookings" },
  ],
  createBooking: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Create Booking" },
  ],
  checkin: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "View Bookings", link: "/portal/viewbookings" },
    { name: "Booking Details" },
  ],
  NoshowReport: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/Eylanding" },
    { name: "No Show Reports" },
  ],
  ManualCancelReport: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/Eylanding" },
    { name: "Manual Cancellations Report" },
  ],
  ReleaseFailReport: [
    { name: "Home", link: "/portal/dashboard" },
    { name: "Reports", link: "/portal/Eylanding" },
    { name: "Release Failure Reports" },
  ],
};
