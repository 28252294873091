// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Card,Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import './style.scss';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import httpRequest from '../../../../../Utils/httpRequest'
import { getCookie } from '../../../../../Utils/cookies' 
import verified from '../../../../../Assets/Images/verified tag.png';
import live from '../../../../../Assets/Images/live.png';
//import outside from '../../../../../Assets/Images/outside.png';
import offline from '../../../../../Assets/Images/offline.png';
import deleteIcon from '../../../../../Assets/icons/deleteIcon.png';
import editIcon from '../../../../../Assets/icons/editIcon.png';
import Loader from '../../../../../Components/Preloader/index'

// Global variable declarations
var TempArray = [];
let weekdays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
var today = new Date().getDay();
var curTime =
  new Date().getHours() +
  ':' +
  new Date().getMinutes() +
  ':' +
  new Date().getSeconds();
var list = [];
var iconChange = [];

//@component
//ParkingSpaceListView 
class ParkingSpaceListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkings: [],
      setopen: false,
      currentPage: this.props.page.number+1,
      postsPerPage: this.props.postsPerPage,
      passPop: false,
      deleteParkingId: null,
      isLoading:false
    };

    this.displayTariff = this.displayTariff.bind(this);
  }

  //function for component unmount
  componentDidMount = () => {
    for (var i in this.props.cardData) {
      if (this.props.cardData[i].status.name === 'APPROVED') {
        TempArray.push({
          Parkingname: this.props.cardData[i].name,
          daysOfApproved: this.props.cardData[i].operatingHoursInfo,
        });
      }
    }
    TempArray.map((item, index) => {
      for (var j in item.daysOfApproved) {
        j === weekdays[today] &&
        curTime > item.daysOfApproved[j][0].openingTime &&
        curTime < item.daysOfApproved[j][0].closingTime
          ? list.push({
              Parkingname: item.Parkingname,
              icon: 'live',
            })
          : list.push({
              Parkingname: item.Parkingname,
              icon: 'live',
            });
      }
      return <div key={index}></div>;
    });
  };
  //function for displayOperatingHours
  displayOperatingHours = (data) => {
    let days = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ];
    let operationTime = '';
    // looping array days
    days.some(function(eachDay) {

      if (Object.keys(data).length === 0 ) {
        return false;
      } else if (data.hasOwnProperty(eachDay)) {
        if (eachDay === weekdays[today]) {
          let info = data[eachDay];
          let openingTimeSplit = info[0].openingTime.split(':');
          let openTime, closeTime, hrFmt;
          if (parseInt(openingTimeSplit[0]) >= 12) {
            if (parseInt(openingTimeSplit[0]) === 12) {
              openTime = '12:' + openingTimeSplit[1] + ' PM';
            } else {
              hrFmt = parseInt(openingTimeSplit[0]) - 12;
              openTime = hrFmt + ':' + openingTimeSplit[1] + ' PM';
            }
          } else {
            openTime =
              openingTimeSplit[0] + ':' + openingTimeSplit[1] + ' AM';
          }
          let closingTimeSplit = info[0].closingTime.split(':');
          if (parseInt(closingTimeSplit[0]) >= 12) {
            if (parseInt(closingTimeSplit[0]) === 12) {
              closeTime = '12:' + closingTimeSplit[1] + ' PM';
            } else {
              hrFmt = parseInt(closingTimeSplit[0]) - 12;
              closeTime = hrFmt + ':' + closingTimeSplit[1] + ' PM';
            }
          } else {
            closeTime =
              closingTimeSplit[0] + ':' + closingTimeSplit[1] + ' AM';
          }
          operationTime =
            eachDay + '\n' + openTime + ' - ' + closeTime;
          return true;
        }
      }
    });
    if (operationTime !== '') {
      return operationTime;
    } else {
      return (operationTime =
        weekdays[today] + '\n' + '00:00 AM' + '-' + '00:00 PM');
    }
  };
  //function for displayTariff
  displayTariff(data) {
    
    let tariff = [];
    let tariffInfo = '';
    data.forEach(function(eachData) {
     
      let basePrice = 0;
      if (
        eachData.priceInfo != null ||
        eachData.priceInfo != undefined 
      ) {
        basePrice = eachData.priceInfo?.basePrice?eachData.priceInfo?.basePrice:"0"
      }
      else {
        basePrice = 0;
      }
      tariff.push(
        eachData.vehicleType.name + ' - Rs: ' + basePrice + '/-',
      );
    });
    tariff.sort();
    tariff.map((each, index) => {
      tariffInfo += each + '\n';
      return <div key={index}></div>;
    });
    return tariffInfo;
  }

  deleteParking = (e, id) => {
    e.stopPropagation();

    this.setState({
      deleteParkingId: id,
      passPop: !this.state.passPop,
    });
  };
  errorCallback = (error) => {
    this.setState({
      passPop: !this.state.passPop,
     
    });
    setTimeout(() => {
      this.setState({
     
        isLoading:false
      });
      this.props.updateToasterConfig({
        show: true,
        message: error.message,
        color: 'red',
      });

    }, 1000);
    

  };



  deleteClickCancel = e => {
    e.stopPropagation();
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  deleteClick = e => {
    e.stopPropagation();
    this.setState({
      isLoading: true,
    });
    httpRequest.delete(
      'deleteParking',
      {},
      this.deleteSuccess,
      this.errorCallback,
      {
        parkingId: this.state.deleteParkingId,
        userId: getCookie('userId'),
      },
    );
  };
  deleteSuccess = () => {
    this.setState({
      passPop: !this.state.passPop,
    });
    setTimeout(() => {
      this.setState({
        isLoading:false
      });
      this.props.updateToasterConfig({
        show: true,
        message: 'The Parking has been deleted',
        color: 'red',
      });
    }, 1000);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };
  showEditPage = (e, id) => {
    e.stopPropagation();
    this.props.history.push(
      '/portal/editparkingspace?parkingId=' + id,
    );
  };

  render() {
    //function scope data assignments
    var mainObject = '';
    iconChange = list.filter(function(i) {
      return i.icon === 'live';
    });
    
  return (
      <Fragment>
        <div className="listview">
       
          <div className="listview__wrapper">
          {this.state.isLoading && <Loader />}
            <Card style={{ borderRadius: '8px' }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead className="listview__wrapper_header">
                    <TableRow>
                      <TableCell style={{ width: '20%' }}>
                        Parking Space Name
                      </TableCell>
                      <TableCell
                        style={{ width: '7.5%' }}
                        align="left"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ width: '7.5%' }}
                        align="left"
                      >
                        Type
                      </TableCell>
                      <TableCell
                        style={{ width: '10%' }}
                        align="left"
                      >
                        Operation Time
                      </TableCell>
                      <TableCell style={{ width: '8%' }} align="left">
                        Tariff
                      </TableCell>
                      <TableCell
                        style={{ width: '13%' }}
                        align="left"
                      >
                        Address
                      </TableCell>
                      <TableCell
                        style={{ width: '5%' }}
                        align="center"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.props.cardData.map((parking, index) => {
                        return parking.status.name === 'DRAFT' ? (
                        <TableRow
                          id={parking.id}
                          key={index}
                          className="draft"
                        >
                          <TableCell
                            className="parking_name"
                            scope="row"
                          >
                            {parking.name.length > 20
                              ? `${parking.name.slice(0, 20)}...`
                              : parking.name}
                            <>
                              {parking.status.name === 'VERIFIED' ? (
                                <>
                                  {iconChange.map((item, index) => {
                                    item.Parkingname ===
                                      parking.name &&
                                      (mainObject = item.Parkingname);
                                    return <div key={index}></div>;
                                  })}

                                  {mainObject === parking.name ? (
                                    <img src={live} alt="image1" />
                                  ) : (
                                    <img src={live} alt="image3" />
                                  )}
                                </>
                              ) : parking.status.name ===
                                'REQUEST_SUBMITTED' ? (
                                <img src={live} alt="image2" />
                              ) : parking.status.name ===
                                'REQUEST_REJECTED' ? (
                                <img src={offline} alt="image2" />
                              ) : parking.status.name ===
                                'APPROVED' ? (
                                <>
                                  {iconChange.map((item, index) => {
                                    item.Parkingname ===
                                      parking.name &&
                                      (mainObject = item.Parkingname);
                                    return <div key={index}></div>;
                                  })}

                                  {mainObject === parking.name ? (
                                    <img src={live} alt="image1" />
                                  ) : (
                                    <img src={live} alt="image3" />
                                  )}
                                </>
                              ) : (
                                <img src={offline} alt="image2" />
                              )}
                            </>
                            <>
                              {parking.isVerified ? (
                                <img
                                  src={verified}
                                  alt="image5"
                                  style={{ width: '70px' }}
                                />
                              ) : null}
                            </>
                          </TableCell>
                          <TableCell
                            className="parking_status"
                            align="left"
                           
                          >
                            <span>
                              
                              {
                             
                                <span style={{ color:`${parking.currentStatus.color}` }}>
                                  {parking.currentStatus.displayName}
                                </span>
                              }
                            </span>
                          </TableCell>
                          <TableCell align="left">No data</TableCell>
                          <TableCell align="left">
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                              {this.displayOperatingHours(
                                parking.operatingHoursInfo?parking.operatingHoursInfo:{},
                              )}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                              {()=>this.displayTariff(
                                parking.vehicleTypeInfo?.priceInfo
                              )}
                            </span>
                          </TableCell>
                          <TableCell align="left">No data</TableCell>
                          <TableCell
                            align="right"
                            style={{
                              color: 'blue',
                              textAlign: 'bottom',
                              right:'12px',
                              paddingRight:0
                            }}
                          >
                            <ButtonGroup size="small" aria-label="small outlined button group" style={{
                               alignItems: 'center',
                               display: 'flex',
                               justifyContent: 'flex-end'
                             }} >
                                  <IconButton
                                    className="noPadBtn"
                                    onClick={e => {
                                      this.deleteParking(
                                        e,
                                        parking.parkingId,
                                      );
                                    }}
                                    style={{marginRight:'18%'}}
                                  >
                                    <img
                                      src={deleteIcon}
                                      style={{ width: '15px' }}
                                      alt="delete-icon"
                                    />
                                  </IconButton>
                                  
                                   
                                  <Link
                                    className="View_button"
                                    to={
                                      '/portal/addparkingspace?parkingId=' +
                                      parking.parkingId
                                    }
                                  >
                                    <span style={{marginRight:'-0.7rem'}}>Continue</span>
                                    
                                  </Link>
                           
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ) :  (
                        <TableRow id={parking.id}>
                          <TableCell
                            className="parking_name"
                            scope="row"
                          >
                            {parking.name.length > 20
                              ? `${parking.name.slice(0, 20)}...`
                              : parking.name}

                            <span>
                              {parking.status.name === 'VERIFIED' ? (
                                <img src={live} alt="image1" />
                              ) : parking.status.name ===
                                'REQUEST_SUBMITTED' ? (
                                <img src={offline} alt="image2" />
                              ) : parking.status.name ===
                                'REQUEST_REJECTED' ? (
                                <img src={offline} alt="image2" />
                              ) : parking.status.name ===
                                'APPROVED' ? (
                                <>
                                  {iconChange.map((item, index) => {
                                    item.Parkingname ===
                                      parking.name &&
                                      (mainObject = item.Parkingname);
                                    return <span key={index}></span>;
                                  })}

                                  {mainObject === parking.name ? (
                                    <img src={live} alt="image1" />
                                  ) : (
                                    <img src={live} alt="image3" />
                                  )}
                                </>
                              ) : (
                                <img src={offline} alt="image2" />
                              )}
                            </span>

                            <span>
                              {parking.isVerified ? (
                                <img
                                  src={verified}
                                  alt="image5"
                                  style={{ width: '70px' }}
                                />
                              ) : null}
                            </span>
                          </TableCell>
                          <TableCell
                            className="parking_status"
                            align="left"
                            style={{ color: '#F49D3E' }}
                          >
                            <span>
                              {parking.status.name === 'VERIFIED' ? (
                                <span style={{ color: `${parking.currentStatus.color}` }}>
                                  Verified
                                </span>
                              ) :
                                parking.status.name ===
                                'ADDITIONAL_INFO_REQUIRED' ? (
                                  <span style={{ color: `${parking.currentStatus.color}` }}>
                                    Additional info required
                                  </span>
                              ) :
                               parking.status.name ===
                                'REQUEST_SUBMITTED' ? (
                                <span style={{ color: '#F49D3E' }}>
                                  Request Submitted
                                </span>
                              ) : parking.status.name ===
                                'REQUEST_REJECTED' ? (
                                <span
                                  style={{
                                    color: `${parking.currentStatus.color}`,
                                  }}
                                >
                                  Request Rejected
                                </span>
                              ) : parking.status.name ===
                              'APPROVED' && parking.currentStatus.name ===
                              'APPROVED' ? (
                              <span style={{ color: '#67B561' }}>
                                Approved
                              </span>
                               ) : parking.status.name ===
                              'APPROVED' && parking.currentStatus.name ===
                              'REQUEST_SUBMITTED' ? (
                              <span style={{ color: '#F49D3E' }}>
                                Approved
                              </span>
                              ) : parking.status.name ===
                                'DEACTIVATED' ? (
                                <span style={{ color: 'brown' }}>
                                  Deactivated
                                </span>
                              ) : (
                                <span style={{ color: `${parking.cuurentStatus.color}` }}>
                                  Draft
                                </span>
                              )}
                             
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {parking.parkingBusinessType.name}
                          </TableCell>
                          <TableCell align="left">
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                              {this.displayOperatingHours(
                                parking.operatingHoursInfo,
                              )}
                            </span>
                           
                          </TableCell>
                          <TableCell align="left">
                            <span
                              style={{ whiteSpace: 'pre-wrap' }}
                              className="vertical-col-2 tarrifView"
                            >
                              {this.displayTariff(
                                parking.vehicleTypeInfo,
                              )}
                            </span>
                           
                          </TableCell>
                          <TableCell align="left">
                            <span className="address">
                              {parking.addressDetails.addressLine1}
                            </span>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              color: 'blue',
                              textAlign: 'right',
                              right:'4px',
                              paddingRight:0
                            }}
                          >
                            <ButtonGroup size="small" aria-label="small outlined button group" style={{
                               alignItems: 'center',
                               display: 'flex',
                               justifyContent: 'flex-end'
                             }}>
                              {parking.createdBy === getCookie('userId') && (
                              <IconButton
                                className="noPadBtn"
                                onClick={e => {
                                  this.deleteParking(
                                    e,
                                    parking.parkingId,
                                  );
                                }}
                              >
                              <img
                                src={deleteIcon}
                                style={{ width: '15px', marginRight:'0.6rem'}}
                                alt="delete-icon"
                              />
                              </IconButton>
                              )}
                              {parking.createdBy === getCookie('userId') && (
                              <IconButton 
                                className="noPadBtn"
                                style={{
                                  cursor:((parking.status.name=== 'REQUEST_SUBMITTED' || parking.currentStatus.name=== 'REQUEST_SUBMITTED')  )?
                                  "context-menu":"pointer"
                                }}
                                onClick={
                                  ((parking.status.name=== 'REQUEST_SUBMITTED' || parking.currentStatus.name=== 'REQUEST_SUBMITTED') )?
                                  undefined
                                  :
                                  e => {this.showEditPage(e,parking.parkingId);}
                                }
                              > 
                                <img
                                  src={editIcon}
                                  style={{ 
                                    width: '15px',
                                    marginRight:'1rem',
                                    visibility: !(parking.status.name=== 'REQUEST_SUBMITTED' || parking.currentStatus.name=== 'REQUEST_SUBMITTED' )?undefined:"hidden"
                                  }}
                                  alt="delete-icon"
                                />
                              </IconButton>
                              )}   
                             <Link
                              className="View_button"
                              to={
                                '/portal/parking-details/' +
                                parking.parkingId
                              }
                            >
                              View 
                            </Link>  
                            
                            </ButtonGroup>   
                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>
        {this.state.passPop && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Delete Parking</p>
                {/* <span
                  className="cross"
                  onClick={this.deleteClickCancel}
                >
                  X
                </span> */}
              </div>
              <p className="popup-text">
                If you want to delete this Parking Space then please
                press Delete button
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={this.deleteClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={this.deleteClick}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="navConfig">
          {/* <div className="dropdownNumber" style={{marginRight:"69px",marginTop:"10px"}}>
            <select
              name="selectNavcards"
              id="selectNavcards"
              className="selectNavcards"
              onChange={this.showSelectedNavCards}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select>
          </div> */}
      
              
        </div>
      </Fragment>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig },
  )(ParkingSpaceListView),
);

