// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.

import deviceReport from "../Assets/icons/deviceReport.png";
import vehicleAccessReport from "../Assets/icons/vehicleAccessReport.png";
import ManualCancel from "../Assets/icons/Manual Cancellations report.png";
import Noshow from "../Assets/icons/No-show report.png";
import ReleaseFailure from "../Assets/icons/Release Failure report.png";

export const individualReportData = [
  {
    image: `${Noshow}`,
    text: "No-Shows",
    to: "/portal/Noshows",
    marginTop: "10px",
  },
  {
    image: `${ReleaseFailure}`,
    text: "Release Failure",
    to: "/portal/Releasefailure",
    marginTop: "10px",
  },
  {
    image: `${ManualCancel}`,
    text: "Manual Cancellations",
    to: "/portal/Manualcancellation",
    marginTop: "12px",
  },
  //   {
  //     image: `${parkingSpace}`,
  //     text: "Parking Spaces",
  //     to: "/portal/reportsParkingSpace",
  //     marginTop: "10px",
  //   },
  //   {
  //     image: `${overspeedViolation}`,
  //     text: "Overspeed Violation",
  //     to: "/portal/reportsOverspeed",
  //     marginTop: "10px",
  //   },
  //   {
  //     image: `${tempAccessReport}`,
  //     text: "Access Control",
  //     to: "/portal/temporary_access_report",
  //     marginTop: "10px",
  //   },
];

export const ResidentialReportData = [
  {
    image: `${deviceReport}`,
    text: "Device Report",
    to: "/portal/device_report",
    marginTop: "10px",
  },

  {
    image: `${vehicleAccessReport}`,
    text: "Vehicle Access Report",
    to: "/portal/vehicle_access_report",
    marginTop: "10px",
  },
];

export const unregisterHeaderCell = [
  { id: "sno", label: "S.no", width: "10%" },
  { id: "category", label: "Category", width: "20%" },
  { id: "vehicletype", label: "Vehicle Type", width: "15%" },
  { id: "vehiclenumber", label: "Vehicle Number", width: "20%" },
  { id: "entry-time", label: "Entry Time", width: "25%" },
];

export const overnightHeaderCell = [
  { id: "sno", label: "S.no", width: "10%" },
  { id: "email id", label: "Email Id", width: "20%" },
  { id: "vehiclenumber", label: "Vehicle Number", width: "12%" },
  { id: "vehicletype", label: "Vehicle Type", width: "10%" },
  { id: "osdate", label: "Start Date", width: "12%" },
  { id: "oedate", label: "End Date", width: "12%" },
  { id: "authorization", label: "Authorization", width: "12%" },
  { id: "totaldays", label: "Total Days", width: "12%" },
];

export const overspeedHeaderCell = [
  { id: "sno", label: "S.no", width: "10%" },
  { id: "email id", label: "Email Id", width: "30%" },
  { id: "vehiclenumber", label: "Vehicle Number", width: "20%" },
  { id: "vehicletype", label: "Vehicle Type", width: "10%" },
  { id: "overspeed", label: "Overspeed", width: "10%" },
  { id: "date", label: "Date and Time", width: "20%" },
];

export const parkingspaceHeaderCell = [
  { id: "sno", label: "S.no", width: "5%" },
  { id: "parkingspacename", label: "Parking Space Name", width: "15%" },
  { id: "address", label: "Address", width: "20%" },
  { id: "parkingslots", label: "Total Parking Slots", width: "15%" },
  { id: "type", label: "Type", width: "10%" },
  { id: "approved/rejected on", label: "Approved/Rejected On", width: "20%" },
  { id: "status", label: "Current Status", width: "15%" },
];

export const tempAccessHeaderCell = [
  { id: "sno", label: "S.no", width: "10%" },
  { id: "contact", label: "Email Id", width: "22%" },
  { id: "vehicle", label: "Vehicle Number Vehicle Type", width: "12%" },
  { id: "accesstype", label: "Access Type", width: "20%" },
  { id: "osdatetime", label: "Entry Date and Time", width: "18%" },
  { id: "oedatetime", label: "Exit Date and Time", width: "18%" },
];

export const orgListHeadCells = [
  { id: "sno", label: "S.No", width: "20%" },
  { id: "organization_ame", label: "Organization Name", width: "30%" },
  { id: "organization_acronym", label: "Organization Acronym", width: "30%" },
  { id: "actions", label: "Actions", width: "20%" },
];

export const securityListHeaderCell = [
  { id: "sno", label: "S.no", width: "5%" },
  { id: "designation", label: "Designation", width: "12%" },
  { id: "parkingspace", label: "Parking Space Name", width: "14%" },
  { id: "emailid", label: "Email Id", width: "17%" },
  { id: "role", label: "Role", width: "15%" },
  { id: "createdby", label: "Created By", width: "15%" },
  { id: "cdate", label: "Date of Creation", width: "15%" },
  { id: "actions", label: "Actions", width: "10%" },
];

export const facilityManagerListHeaderCell = [
  { id: "sno", label: "S.no", width: "8%" },
  { id: "name", label: "Name", width: "15%" },
  { id: "emailid", label: "Email Id", width: "20%" },
  { id: "role", label: "Role", width: "17%" },
  { id: "cdate", label: "Date of Creation", width: "15%" },
  { id: "actions", label: "Actions", width: "8%" },
];

export const facilityManagerMtListHeaderCell = [
  { id: "sno", label: "S.no", width: "5%" },
  { id: "name", label: "Name", width: "12%" },
  { id: "emailid", label: "Email Id", width: "20%" },
  { id: "organization", label: "Organization", width: "14%" },
  { id: "address", label: "Address", width: "20%" },
  { id: "cdate", label: "Date of Creation", width: "14%" },
  { id: "actions", label: "Actions", width: "15%" },
];

export const tempAccessListHeaderCell = [
  { id: "sno", label: "S.no", width: "5%" },
  { id: "orgname", label: "Organization", width: "13%" },
  { id: "emailid", label: "Email Id", width: "20%" },
  {
    id: "vehiclenumber&vehicletype",
    label: "Vehicle Number Vehicle Type",
    width: "12%",
  },
  { id: "rfidtag", label: "RFID", width: "15%" },
  { id: "accesstype", label: "Access Type", width: "12%" },
  { id: "syncstatus", label: "Sync Status", width: "6%" },
  { id: "actions", label: "Actions", width: "16%" },
];

export const emergencyListHeaderCell = [
  { id: "sno", label: "S.no", width: "5%" },
  { id: "pname", label: "Parking Space Name", width: "20%" },
  { id: "cname", label: "Contact Name", width: "18%" },
  { id: "emailid", label: "Email ID", width: "23%" },
  { id: "cno", label: "Contact Number", width: "12%" },
  { id: "status", label: "Disable/Enable", width: "12%" },
  { id: "actions", label: "Actions", width: "10%" },
];

export const registerHeaderCell = [
  { id: "sno", label: "S.no", width: "1%" },
  { id: "username", label: "User Name", width: "2%" },
  { id: "emailid", label: "Email/Employee ID", width: "3%" },
  { id: "rdate", label: "Registered Date", width: "2%" },
];

export const deviceReportHeaderCell = [
  { id: "sno", label: "S.no", width: "8%" },
  { id: "parkingName", label: "Parking Space Name", width: "20%" },
  { id: "parkingAddress", label: "Address", width: "24%" },
  { id: "deviceName", label: "Device Name", width: "18%" },
  { id: "offlineFrom", label: "Offline From", width: "15%" },
  { id: "offlineTill", label: "Offline Till", width: "15%" },
];

export const vehicleAccessReportHeaderCell = [
  { id: "sno", label: "S.no", width: "6%" },
  { id: "parkingSpaceName", label: "Parking Space Name", width: "18%" },
  { id: "parkingAddress", label: "Address", width: "18%" },
  { id: "vehicleNumber", label: "Vehicle Number", width: "16%" },
  { id: "vehicleType", label: "Vehicle Type", width: "13%" },
  { id: "actualEntryTime", label: "Entry Date & Time", width: "15%" },
  { id: "actualExitTime", label: "Exit Date & Time", width: "15%" },
];
