// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles} from '@material-ui/core/styles'

//@component
//reportUseStyles
const listviewUseStyles = makeStyles(theme=>({
  dialog:{
    zIndex: 0
  },

  topRowGrid:{
    marginTop: "1%",
    marginLeft:"1.8%"
  },
 
  boldText:{
    textWeight:"bold"
  },
  notFoundGrid:{
    marginTop: "5em"
  },
  actionRow:{
     maxWidth:"10px"
  },
  tableRowNoImage:{
    height:"28em"
  },
  gridContainerNoImage:{
    position:"absolute",paddingTop:"5px",paddingBottom:"5px"
  },
  typographyGridNoImage:{
    marginRight:"20em"
  },
  paginationGrid:{
    marginBottom:"5em",
    marginRight:"1.5em",
    marginTop:"1.5em"
  },
  pageTextGrid:{
    marginTop:"1em",
    marginRight:"1em"
  },
  editIconList:{
    width:"15px",
    height:"15px"
  },
  searchDiv: {
    padding: '1px 1px',
    width: "200%",
    backgroundColor:"lightgrey",
    borderRadius: "90px",
    variant:'elevation',
    // boxShadow:'0px 3px 6px #00000029 !important'
  },
  dialogSubTitle: {
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '1px'
  },
  dialogSubText: {
    fontWeight:'bold',
    fontFamily: '\'Poppins\' !important',
    fontSize: '11px',
    letterSpacing: '1px'
  },
  dialogSubHeader:{
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '0.5px'
  },
  dialogHeaderTitle:{
    fontFamily: '\'Poppins\' !important',
    fontSize: "14px",
    letterSpacing: "0.5px",
    paddingBottom:'8px',
    fontWeight:900
  },
  dialogCloseIcon:{
    cursor:"pointer",
    fontSize: "12px"
  },
  dialogActionGrid:{
    marginTop:"0.5em",
    paddingBottom:'0'
  },
    dialogSubmitGrid:{
      marginLeft:"2em"
    },
    dialogConfirm:{
      fontFamily: '\'Poppins\' !important',
      borderRadius:'90px',
      cursor: 'pointer',
      border:"none",
      fontSize:"12px",
      fontWeight: 900,
      color:"#fff",
      backgroundColor:"#03A9F3",
      boxShadow:"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
      minWidth: "64px",
      boxSizing: "border-box",
      lineHeight: "1.75",
      letterSpacing: "1px",
      padding: "9px 27px",
      textDecoration: "none",
      verticalAlign: "middle",
      marginBottom: "5px"
    },
    dialogCancel:{
      fontFamily: '\'Poppins\' !important',
      borderRadius: '90px',
      cursor: 'pointer',
      border:"none",
      fontSize:"12px",
      fontWeight: 900,
      color:"#fff",
      backgroundColor:"#707070",
      boxShadow:"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
      minWidth: "64px",
      boxSizing: "border-box",
      lineHeight: "1.75",
      letterSpacing: "1px",
      padding: "9px 27px",
      textDecoration: "none",
      verticalAlign: "middle",
      marginBottom: "5px",
    },
    dialogConfirmGrid: {
      textAlignLast:"right"
    },
    dialogCancelGrid: {
      marginRight:"20px"
    },
    tableCell:{
      fontFamily: '\'Poppins\' !important',
      fontSize:"12px",    
      wordBreak:'break-word',
      fontWeight:'500',
      padding:'1%',
      lineHeight:1.6
    },
    editCross:{
      visibility:"hidden"
    },
    input_cross:{
      // marginLeft:"1em"
    },
    selectNavcards:{
        width: "50px",
        height: "34px",
        textAlign: "center",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
        marginRight: "10px",
        border: "none",
        borderRadius: "6px",
        background: "#ffffff",
        padding: "0 1px",
        paddingRight: "2px",
        marginTop: "2px",
    },
    rowsLabel:{
        fontSize:"0.7rem",marginRight:"0.5em",fontWeight: "bold",
    },
    dropdownNumber: {
        border: "none",
        color: "#a2a2a2",
        outline: "none",
        marginLeft: "1em"
    },
    root: {
      '& .Mui-selected': {
        backgroundColor: '#e46a76 !important',
        color: '#ffffff',
        fontWeight:'900',
       },
       '& .MuiPaginationItem-root' : {
       fontFamily: '\'Poppins\' !important',
       fontSize: '14px',
       },
    
       
        // padding: '2px 4px',
        // display: 'flex',
        // alignItems: 'center',
        // width: 400,
        // marginLeft:"1.7em",
        // backgroundColor:"lightgrey",
        // borderRaduis: "10px",
        // variant:'elevation',
        
      },
      paper:{
        boxShadow:'0px 3px 6px #00000029 !important',
      },
      searchInput: {
        fontFamily: '\'Poppins\' !important',
        fontSize:'12px',
        letterSpacing: '0.5px',
        borderRadius: '90px',
        width: '100%',        
        border: 'none',
        outline: 'none',
        padding: '10px',
        backgroundColor: 'lightgrey',
      },
      searchIconButton: {
        width: '20px',
        fontSize: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '0px',
        color: "#a1a1a1",
        marginLeft:"5px"
      },
      divider: {
        height: 28,
        margin: 4,
      },
    selectNavCard:{
        height:"4em",width:"5em",border:0,borderRadius:"22px",
    },
    tableGrid:{
        marginTop:"2em",
        // marginBottom:"2em",
        width:"96%",
        maxHeight:"30%",
        marginLeft:"1.5em",
       // marginRight:"1.5em"
    },
    table: {
        minWidth: 650,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "10px",
        fontWeight: "bold",
        // width: 17%;
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      divCurrentPage:{
        marginRight:"6em",marginTop:"1.4em"
      },
      dialogButton:{
        ...theme.typography.view,
        borderRadius: 50,
        color: "#fff",
        height: "2.5em",
        width: "7em",
        fontSize:"0.71rem",
        border:"none",
        fontWeight:"600",
        marginBottom:"2em"
    },
    createButton:{
      ...theme.typography.view,
      borderRadius: 50,
      height: "3em",
      width: "8em",
      fontSize:"0.61rem",
      border:"none",
      fontWeight:"300",
      textTransform:"none",
      backgroundColor:"#e46a76",
      color:"#fff"
    },
    viewButton:{
      color:'#03a9f3',fontSize:"0.60rem",textDecoration:"underline",textTransform:"none"
    },
    activateButton:{
      color:'#78BE20',
      fontSize:"0.60rem",
      textTransform: "none",
    },
    statusChangeButton:{
      ...theme.typography.view,
      borderRadius: 50,
      height: "2.5em",
      width: "8em",
      fontSize:"0.61rem",
      border:"none",
      fontWeight:"300",
      textTransform:"none",
      backgroundColor:"#FA5858",
      color:"#FFFFFF"
    },
      styleCurrentPage:{
        backgroundColor: "#e46a76",
        color: "white",
        fontSize: "18px",
        borderRadius: "50%",
        width: "45px",
        height: "35px",
        padding: "5px 10px 5px 10px",
        alignItems: "center",
        textAlign: "center",
        marginTop:"0.5em"
      },
      thData:{
        fontFamily: '\'Poppins\' !important',
        fontWeight: 'bolder',
        fontSize: '12px',
        padding: '1%',
        backgroundColor: 'rgba(226, 220, 242, 0.38)',
        height:'70px',
        wordWrap: 'break-word',
        lineHeight: '1.6', 
      },
      paginationStyle:{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '90px',
        paddingLeft: '15px',
        boxShadow: '0px 3px 6px #00000029 !important',
      },
     
}));

export default listviewUseStyles;