// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import "./style.scss";
import FileViewer from "../pdf/FileViewer";
import TermsConditions from "../../Assets/pdf/ParkZeus_WebApp_(Corporate)_T&C_June 26-FV-20-5-2022-PZ-Bosch_pop.pdf";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { deleteAllCookies } from "../../Utils/cookies";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setCookie } from "../../Utils/cookies";
import TokenStorage from "../../Utils/tokenstorage";
import httpRequest from "../../Utils/httpRequest";
import { withRouter } from "react-router";
import {
  updateAuth,
  updateToasterConfig,
} from "../../../src/redux/modules/app/actions";
import { connect } from "react-redux";

class TermsCondition extends Component {
  componentDidMount() {
    // Changing the state after 2 sec
    // from the time when the component
    // is rendered
    // document.cookie.split(";").forEach((c) => {
    //   setCookie(c, "", -1);
    // });
  }
  //console.log(props?.json);

  styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  DialogTitle = withStyles(this.styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle
        style={{ marginLeft: "30px" }}
        disableTypography
        className={classes.root}
        {...other}
      >
        <Typography variant="h5">
          <strong>{children}</strong>
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  DialogActions = withStyles(() => ({
    root: {
      margin: 0,
      // padding: theme.spacing(1),
      padding: "8px 40px !important",
    },
  }))(MuiDialogActions);

  //const [open, setOpen] = React.useState(props?.flag);

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleDisagree = () => {
    deleteAllCookies();
    this.handleClose();
  };

  successCallback = () => {
    console.log("success");
  };
  errorCallback = () => {
    console.log("error");
  };

  handleAgree = async () => {
    //const f = await apireq();

    // this.setState({ token: jsonResult.data.access_token });
    //const role = this.props.role;

    console.log(this.props);

    //console.log(this.props.userId);

    try {
      //<-----------------api req to set the flag------------------>

      httpRequest.post(
        "eulaAcceptFlag",
        {
          eulaAccepted: true,
          eulaVersion: "1",
          eulaText: "test",
        },
        {
          "Content-Type": "application/json",
        },
        this.successCallback,
        this.errorCallback,
        {
          role: this.props.role,
          userId: this.props.userId,
        }
      );
      setCookie("auth", this.props?.json?.data?.access_token, 1);
      setCookie("refresh", this.props?.json?.data?.refresh_token, 1);
      setCookie("eula", "true");
      TokenStorage.storeRefreshToken(this.props?.json?.data?.refresh_token);
      TokenStorage.storeToken(this.props?.json?.data?.access_token);

      let dt = new Date();

      dt.setSeconds(
        dt.getSeconds() + (this.props?.json?.data?.expires_in - 60)
      );
      TokenStorage.setTokenExpirationTime(dt);

      //console.log(this?.props);
      console.log(this);
      await this.props.updateAuth({
        token: this.props?.json?.data?.access_token,
      });

      const data = await httpRequest.get(
        "createUser",
        {},
        this.createUserSuccess,
        this.errorCallback
      );

      console.log(data);

      this.handleClose();
    } catch (e) {
      console.log(e.message);
    }

    window.location.reload();
  };

  createUserSuccess = (jsonResult) => {
    console.log(this.props.auth);
    let newAuth = { ...this.props.auth };
    newAuth["userId"] = jsonResult.data.userId;

    setCookie("userId", jsonResult.data.userId, 1);
    setCookie("role", jsonResult.data.roles[0], 1);

    this.props.updateAuth(newAuth);
    sessionStorage.setItem("auth", JSON.stringify(newAuth));

    if (
      jsonResult.data.roles[0] === "CLOSED_GROUP_PARKING_PROVIDER" ||
      jsonResult.data.roles[0] === "FACILITY_ADMIN" ||
      jsonResult.data.roles[0] === "MULTITENANT_ADMIN" ||
      jsonResult.data.roles[0] === "RESIDENTIAL_GROUP_PARKING_PROVIDER" ||
      jsonResult.data.roles[0] === "RESIDENTIAL_FACILITY_ADMIN"
    ) {
      this.props.updateToasterConfig({
        show: true,
        message: "Successfully logged In",
        color: "#02C194",
      });

      //<----------pop-up logic to be implemented------------------------------>

      this.props.history.push("/portal/dashboard");
    } else if (jsonResult.data.roles[0] === "SECURITY_PERSONNEL") {
      this.props.updateToasterConfig({
        show: true,
        message: "Successfully logged In",
        color: "#02C194",
      });
      this.props.history.push("/portal/onplistview");
    } else {
      this.props.updateToasterConfig({
        show: true,
        message: "Super Admin cannot use this portal",
        color: "#FF0000",
      });
      TokenStorage.clear();

      this.props.history.push("/login");
    }
  };

  errorCallback = () => {
    TokenStorage.clear();
    this.props.history.push("/login");
    this.props.updateToasterConfig({
      show: true,
      message: "User Unauthenticated",
      color: "#FF0000",
    });
  };

  state = {
    open: this.props.flag,
  };

  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        open={this.state.open}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="Cookie Agreement"
        aria-describedby="Cookie Agreement-description"
        PaperProps={{ md: { width: "80%", height: "100%" } }}
        className="mui-paper"
        maxWidth="900px"
      >
        <this.DialogTitle id="Cookie Agreement">
          Terms & Conditions
        </this.DialogTitle>

        <Typography gutterBottom style={{ overflowX: "hidden" }}>
          <pre style={{ overflow: "hidden" }}>
            <FileViewer pdfFile={TermsConditions} />
          </pre>
        </Typography>

        {/* <iframe
            
            src={`${TermsConditions}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
            width="800"
            height="500"
          ></iframe> */}

        {/* {
            <embed
              src={`${TermsConditions}#toolbar=0&navpanes=0&scrollbar=0`}
              type="application/pdf"
              width="800"
              height="500"
            />
          } */}

        {/* <Typography gutterBottom style={{ overflowX: "hidden" }}>
            <pre style={{ overflowX: "hidden" }}>
              <FileViewer pdfFile={TermsConditions} />
            </pre>
          </Typography> */}

        <this.DialogActions>
          <div>
            <p style={{ fontSize: "12px", fontWeight: "600" }}>
              I confirm that -
            </p>

            <ul style={{ fontSize: "12px", fontWeight: "600",margin:'2px' }}>
              I have read and understood the Terms of Use and shall abide by my
              confidentiality obligations
            </ul>
            <ul style={{ fontSize: "12px", fontWeight: "600",margin:'2px' }}>
              I have read and understood the Data Protection Notice and consent
              to the processing of my personal information by the application
              owner/Bosch to provide requisite access and services to me.
            </ul>
            {/* <ul style={{ fontSize: "12px", fontWeight: "600" }}>
              I consent to the transfer of my personal information by Bosch to
              its service providers for rendering requisite support services to
              me.
            </ul> */}

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                width: "100%",
                padding: "10px",
                paddingLeft: 0,
              }}
            >
              <Button
                autoFocus
                variant="contained"
                onClick={this.handleAgree}
                color="primary"
                // style={{ width: 100, height: 50 }}
              >
                I AGREE
              </Button>
              <Button
                onClick={this.handleDisagree}
                style={{
                  fontFamily: '"Poppins" !important',
                  margin: "12px",
                  borderRadius: "90px",
                  cursor: "pointer",
                  border: "none",
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#fff",
                  backgroundColor: 'gray',
                  boxSizing: "border-box",
                  lineHeight: "1.75",
                  letterSpacing: "1px",
                  padding: "9px 27px",
                  textDecoration: "none",
                  verticalAlign: "middle",
                  width:"78px",
                  height:"31.25px"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </this.DialogActions>
      </Dialog>
    );
  }
}

//export default TermsCondition;

export default withRouter(
  connect(/* (state) => ({
      auth: state.app.auth,
    })*/ null, {
    updateAuth,
    updateToasterConfig,
  })(TermsCondition)
);
