// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import Pagination from "@material-ui/lab/Pagination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import WarningSharpIcon from "@material-ui/icons/WarningSharp";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "../../../Utils/cookies";
import excelImg from "../../../Assets/icons/excel.png";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import Filtericon from "./../../../Assets/Images/funnel-hz.png";
import Clickedfilter from "../../../Assets/Images/Path 1482.png";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader";
import UrlConfig from "../../../Constants/apiUrls";
import OvernightReportUseStyles from "./styles";
import { overspeedHeaderCell } from "../../../Data/report-page";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../../SolutionAdmin/ParkingManagement/AddParkingModule/OperationalTimings/style.scss";
import Reducer from "../../../context/DeviceCoupledReducer";
import CoupledContext from "../../../context/DeviceCoupledContext";

import "../../Reports/Reportstyle.scss";
//@component
//Overnight violation report
function ReportsOverspeed(props) {
  const classes = OvernightReportUseStyles();

  const [onpList, setOnpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [parkingSpaceList, setParkingSpaceList] = useState([]);
  const [authorized, setAuthorized] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [fromTime, setFromTime] = useState(new Date().getTime());
  //const [fromFill, setFromFill] = useState("");
  const [toTime, setToTime] = useState(new Date().getTime());
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [open, setOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [parkingId, setParkingId] = useState(null);
  const [parkingName, setParkingName] = useState(null);
  const [listSelectedFlag, setListSelectedFlag] = useState(false);
  const [noData, setnoData] = useState("");

  const [initLoader, setinitLoader] = useState(true);
  // style to support datepicker
  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        verticalAlign: "middle",
        fontSize: "12px",
        fontWeight: "600",
        fontFamily: "'Poppins' !important",
        width: (params) => (params.width ? params.width : "auto"),
        "& fieldset": {
          border: "solid 2px #0070D8",
        },
        "& .MuiInputBase-root": {
          height: (params) => (params.height ? params.height : "auto"),
          color: (params) => (params.color ? params.color : "inherit"),
        },
      },
    })
  );
  // for datepicker styling
  const classesDatePicker = useStyles({
    color: "red",
    width: "100%",
    height: "100%",
  });
  //style method for dialog
  const styles = (theme) => ({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  //method for dialog
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  // when selecting authotize filter this api call is used
  const generateReportHandler = (pageNumber, authorized, vehicleType) => {
    setPageNumber(pageNumber);
    setIsLoading(true);
    setListSelectedFlag(true);

    httpRequest.get(
      "overSpeedDetectionReport",
      {
        "Content-Type": "application/json",
      },
      successCallbackReport,
      errorCallback,
      {
        fromTime: fromTime,
        toTime: toTime || new Date(),
        page: pageNumber,
        size: 10,
        parkingId: parkingId,
        authorized: authorized,
        vehicleType: vehicleType,
      }
    );
  };
  // success callback for authorize filter api
  const successCallbackReport = (jsonResponse) => {
    setIsLoading(false);
    setResponseDataNotFound("");
    if (jsonResponse.data?.page?.totalPages < 1) {
      setListSelectedFlag(false);
      setOnpList([]);
      setResponseDataNotFound("");
      setnoData("data not found");
    }
    if (
      jsonResponse.data?.page?.totalPages > 0 &&
      jsonResponse.data?._embedded?.results
    ) {
      setnoData("data found");
      setOnpList(jsonResponse.data._embedded.results);
      setPageNumber(jsonResponse.data.page.number);
      setTotalPages(jsonResponse.data.page.totalPages);
      setResponseDataNotFound("");
      setShowDiv(false);
    }
  };
  // error callback for authorize filter api
  const errorCallback = (error) => {
    setShowDiv(false);
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      //any error message
    }
    if (error) {
      setResponseDataNotFound(
        "Data Not Found, Please Refine selection above !"
      );
    }
  };
  //api call when component loads first time on page
  const getListHandler = () => {
    httpRequest.get(
      "reportParkingList",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {}
    );
  };
  // success callback for component page load api
  const successCallbackList = (jsonResponse) => {
    setIsLoading(false);
    setParkingSpaceList(jsonResponse.data._embedded.results);
    if (getCookie("role") === "SECURITY_PERSONNEL") {
      let parkingId = jsonResponse.data._embedded.results.map((data) => {
        return data.parkingId;
      });
      setParkingId(parkingId);
    }
  };
  // error callback for component page load api
  const errorCallbackList = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      // any error message
    }
    if (error) {
      setResponseDataNotFound("");
    }
  };
  //api call for excel/pdf download
  const reportDownload = () => {
    setIsLoading(true);
    axios({
      url: UrlConfig("overSpeedDetectionReportDownload", {
        fromTime: new Date(fromTime).getTime(),
        toTime: new Date(toTime).getTime(),
        parkingId: parkingId,
      }),
      method: "GET",
      responseType: "blob", // important
      headers: {
        realmname: getCookie("realmName"),
        Authorization: "bearer " + getCookie("auth"),
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data?.page?.totalPages < 1) {
          setListSelectedFlag(false);
          setOnpList([]);
          setTableDataNotFound("");
          props.dispatch(
            updateToasterConfig({
              show: true,
              message:
                "Error! No data available for download for selected date range",
              color: "red",
            })
          );
        }
        let fileName = response.headers["content-disposition"].split(
          "filename="
        )[1];
        let fileType = response.headers["content-type"];
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: fileType,
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((error) => {
        // Display the Appropriate Error Message
        setIsLoading(false);
        if (error) {
          setResponseDataNotFound("Data not found, Unable to Download !");
        }
      });
  };
  //function to clear existing setter state values
  const clearHandler = () => {
    //setFromFill("");
  };
  //validation and setting startdatetime
  const fromHandler = (event) => {
    if (new Date(event._d) <= new Date()) {
      let datems = event._d.getTime();
      if (datems <= toTime) {
        setFromTime(datems);
        //setFromFill("fill");
      }
      if (datems > toTime) {
        props.dispatch(
          updateToasterConfig({
            show: true,
            message: "Error! From date selected is greater than To date.",
            color: "red",
          })
        );
      }
    }
  };
  //validation and setting enddatetime
  const toHandler = (event) => {
    if (new Date(event._d) <= new Date()) {
      setToTime(event._d.getTime());
    }
  };
  //function to open excel popup
  const excelPopup = () => {
    if (parkingId === "" || parkingId === null) {
      setOpen(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "Error! No parking space selected from dropdown.",
          color: "red",
        })
      );
    } else {
      setOpen(true);
    }
  };
  // It will set the authorized status selected in filter dropdown
  const statusFilterHandler = (status) => {
    setAuthorized(status);
  };
  // It will set the vehicle type selected in filter dropdown
  const vehicleTypeFilterHandler = (vehicleType) => {
    setVehicleType(vehicleType);
  };
  //function for filter
  const filterOptionSetter = () => {
    if (parkingId === "" || parkingId === null) {
      setShowDiv(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "Error! No parking space selected from dropdown.",
          color: "red",
        })
      );
    } else {
      let toggleShowDiv = !showDiv;
      setShowDiv(toggleShowDiv);
    }
  };
  //updating breadcrumb and list function call
  useEffect(() => {
    const timer = setTimeout(() => {
      setinitLoader(false);
    }, 2000);

    if (getCookie("role") === "SECURITY_PERSONNEL") {
      props.dispatch(updateBreadcrumbs("reportsOverspeed"));
    }
    if (
      getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
      getCookie("role") === "FACILITY_ADMIN" ||
      getCookie("role") === "MULTITENANT_ADMIN"
    ) {
      props.dispatch(updateBreadcrumbs("reportsOverspeed"));
      if (props.location?.state?.onpReportClick) {
        setListSelectedFlag(true);
        setParkingName(props.location?.state?.parkingName);
        setFromTime(props.location?.state?.prevDate);
        setToTime(props.location?.state?.prevDate);
        setParkingId(props.location?.state?.parkingId);
        setIsLoading(true);
        setAuthorized(true);
        httpRequest.get(
          "overSpeedDetectionReport",
          {
            "Content-Type": "application/json",
          },
          successCallbackReport,
          errorCallback,
          {
            fromTime: props.location?.state?.prevDate,
            toTime: props.location?.state?.prevDate || new Date(),
            page: 0,
            size: 10,
            parkingId: props.location?.state?.parkingId,
            authorized: true,
            vehicleType: "",
          }
        );
      } else {
        getListHandler();
        clearHandler();
      }
    }
    getListHandler();
    clearHandler();

    return () => {
      clearInterval(timer);
    };
  }, []);
  // }
  //Pagination change handle for next/previous/click on Page Number
  const handleChange = (event, value) => {
    setPageNumber(value);
    let currpage;
    currpage = value - 1;
    generateReportHandler(currpage, authorized, vehicleType);
  };
  //fn to set parking id upon selection
  const handleChangeId = (event) => {
    let valueSelected = event.target.value;
    console.log(valueSelected);
    if (valueSelected === "" || valueSelected === null) {
      setListSelectedFlag(false);
      setOnpList([]);
      setTableDataNotFound("");
      setParkingId(null);
      setShowDiv(false);
    }
    if (valueSelected) {
      setParkingId(valueSelected);
    }
  };
  // function for click away
  const handleClickAway = () => {
    setShowDiv(false);
  };
  // funtion to capitalize first letter of word
  const capitalize = (vehicleType) => {
    return (
      vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1).toLowerCase()
    );
  };
  // filter apply button callback
  const filterapplyHandler = () => {
    generateReportHandler(0, authorized, vehicleType);
  };
  return (
    <>
      {(isLoading || initLoader) && <Loader />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          direction="column"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Reducer>
            <CoupledContext.Consumer>
              {(context) => (
                <Grid
                  item
                  container
                  className={classes.outerRowGrid}
                  justify="space-around"
                >
                  <Grid item md={3} lg={3}>
                    {props.location?.state?.onpReportClick ? (
                      <div className={classes.selectListCard}>
                        {parkingName}
                      </div>
                    ) : (
                      <select
                        onChange={handleChangeId}
                        className={classes.selectListCard}
                        disabled={
                          context?.deviceCoupleList &&
                          context.deviceCoupleList.length === 0
                        }
                      >
                        <option value="">
                          {context.deviceCoupleList.length === 0
                            ? "No Parking spaces connected"
                            : "Select Parking Space"}
                        </option>
                        {context.deviceCoupleList.map((list, index) => {
                          return (
                            <option key={index} value={list.parkingId}>
                              {list.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </Grid>
                  <Grid item md={2} lg={2} className={classes.datePicker}>
                    <label className={classes.dateLabel}>From: </label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        autoOk={true}
                        InputProps={{
                          disableUnderline: true,
                          className: classesDatePicker.componentStyle,
                        }}
                        disableFuture
                        onKeyDown={(e) => e.preventDefault()}
                        variant="inline"
                        label=""
                        value={fromTime}
                        onChange={fromHandler}
                        format="DD/MM/yyyy"
                        disabled={context.deviceCoupleList.length === 0}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={2} lg={2} className={classes.datePickerto}>
                    <label className={classes.dateLabel}>To: </label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        autoOk={true}
                        InputProps={{
                          disableUnderline: true,
                          className: classesDatePicker.componentStyle,
                        }}
                        disableFuture
                        onKeyDown={(e) => e.preventDefault()}
                        minDate={fromTime}
                        variant="inline"
                        value={toTime}
                        onChange={toHandler}
                        format="DD/MM/yyyy"
                        disabled={context.deviceCoupleList.length === 0}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={2}>
                    <button
                      disabled={parkingId === null || parkingId === ""}
                      style={{
                        backgroundColor:
                          parkingId === null || parkingId === ""
                            ? "#A2A2A2"
                            : undefined,
                      }}
                      type="button"
                      className={classes.generateReportButton}
                      onClick={() =>
                        generateReportHandler(
                          pageNumber,
                          authorized,
                          vehicleType
                        )
                      }
                    >
                      Generate Report
                    </button>
                  </Grid>
                  <Grid item md={1}></Grid>
                  <Grid item md={1}>
                    <IconButton
                      onClick={() => excelPopup("EXCEL")}
                      className={classes.downloadButton}
                      // style={{ marginLeft: '95%' }}
                    >
                      <img
                        alt="excel"
                        src={excelImg}
                        className={classes.reportExportImage}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item md={1} style={{ display: "none" }}>
                    <ButtonGroup
                      style={{ float: "right" }}
                      color="secondary"
                      aria-label="outlined secondary button group"
                    >
                      <Button className={classes.filter_button}>
                        <img
                          src={
                            // Filtericon
                            authorized !== "" || vehicleType !== ""
                              ? Clickedfilter
                              : Filtericon
                          }
                          alt="Filtericon"
                          onClick={() => filterOptionSetter()}
                          className={classes.filter_image}
                        />
                        <Grid
                          item
                          container
                          direction="column"
                          className={classes.filterBox}
                          style={{
                            display: showDiv ? "block" : "none",
                          }}
                        >
                          <Grid
                            item
                            className={classes.filter_group_header_grid}
                          >
                            <p className={classes.filter_group_header}>
                              Select Vehicle Type
                            </p>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => vehicleTypeFilterHandler("")}
                                  checked={vehicleType === ""}
                                />
                                <label className={classes.filter_option_label}>
                                  All
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => vehicleTypeFilterHandler("2")}
                                  checked={vehicleType === "2"}
                                />
                                <label className={classes.filter_option_label}>
                                  Bike
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => vehicleTypeFilterHandler("1")}
                                  checked={vehicleType === "1"}
                                />
                                <label className={classes.filter_option_label}>
                                  Car
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <Grid
                            item
                            className={classes.filter_group_header_grid}
                          >
                            <p className={classes.filter_group_header}>
                              Select Authorization
                            </p>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => statusFilterHandler("")}
                                  checked={authorized === ""}
                                />
                                <label className={classes.filter_option_label}>
                                  All
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => statusFilterHandler(false)}
                                  checked={authorized === false}
                                />
                                <label className={classes.filter_option_label}>
                                  Yes
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <Grid item className={classes.liGrid}>
                            <li>
                              <div className="OperationalTimings__wrapper_form_checkside">
                                <Checkbox
                                  color="default"
                                  size="small"
                                  type="checkbox"
                                  data-target="filter"
                                  onChange={() => statusFilterHandler(true)}
                                  checked={authorized === true}
                                />
                                <label className={classes.filter_option_label}>
                                  No
                                </label>
                              </div>
                            </li>
                          </Grid>
                          <hr className={classes.hrStyling} />
                          <Button
                            onClick={() => filterapplyHandler()}
                            className={classes.applyButtonFilter}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            </CoupledContext.Consumer>
          </Reducer>
          <>
            <Grid
              container
              direction="row"
              justify="center"
              className={classes.tableGrid}
            >
              <TableContainer className={classes.paper} component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="overnight report table"
                >
                  <TableHead>
                    <TableRow>
                      {overspeedHeaderCell.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.id === "sno" ? "center" : "left"}
                          width={headCell.width}
                          className={classes.thData}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {listSelectedFlag && (
                    <TableBody>
                      {onpList.length !== 0 ? (
                        onpList.map((row, index) => {
                          let no = index;
                          function formatNo(no) {
                            return no < 10
                              ? pageNumber + "" + no
                              : (pageNumber + 1) * no;
                          }
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                {formatNo(no + 1)}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row?.userEmailId ? row.userEmailId : "--"}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.vehicle?.vehicleNumber
                                  ? row.vehicle.vehicleNumber
                                  : "--"}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.vehicle?.vehicleType?.name
                                  ? capitalize(row.vehicle.vehicleType.name)
                                  : "--"}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                {row.overSpeed ? row.overSpeed + " kmph" : "--"}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row?.violationDate ? row.violationDate : "--"}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <Grid item>
                          <Grid container direction="row" justify="center">
                            <Typography variant="h5" color="error">
                              {tableDataNotFound}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </TableBody>
                  )}
                  {!listSelectedFlag && (
                    <TableBody className={classes.noDataTableBody}>
                      <Grid
                        container
                        justify="center"
                        className={classes.noList}
                      >
                        <Grid item style={{ marginBottom: "70%" }}>
                          {/* You don't have any list here.
                          {parkingId === null
                            ? "select the available Parking space"
                            : onpList.length === 0
                            ? "No data found."
                            : ""} */}

                          {parkingId === null && noData !== "data not found"
                            ? "Select the available Parking space"
                            : parkingId != null && noData === ""
                            ? "Click on generate report"
                            : ""}

                          {isLoading ? (
                            <Loader />
                          ) : noData.length > 0 &&
                            noData === "data not found" ? (
                            "No data found"
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </TableBody>
                  )}
                  {responseDataNotFound && (
                    <TableBody className={classes.noDataTableBody}>
                      <Grid
                        container
                        justify="center"
                        className={classes.noResponseData}
                      >
                        <Grid item>{responseDataNotFound}</Grid>
                      </Grid>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
            {listSelectedFlag && !responseDataNotFound && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              >
                <Grid item className={classes.paginationGrid}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.paginationStyle}
                  >
                    <Grid item>
                      <Pagination
                        count={totalPages}
                        className={classes.root}
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!listSelectedFlag && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              ></Grid>
            )}
            {responseDataNotFound && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              ></Grid>
            )}
          </>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="download-dialog"
            PaperProps={{
              style: {
                padding: "2%",
              },
            }}
            className={classes.dialog}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <DialogTitle
                  id="customized-dialog-title"
                  onClose={() => setOpen(false)}
                >
                  <Typography variant="h5">
                    <WarningSharpIcon
                      className={classes.warningIcon}
                    ></WarningSharpIcon>
                    Warning
                  </Typography>
                </DialogTitle>
              </Grid>
            </Grid>
            <DialogContent>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="p" color="primary">
                      Reports contain personal data. Any Unauthorized sharing of
                      Reports is Prohibited
                    </Typography>
                  </Grid>
                  <Grid item className={classes.downloadReportButtonGrid}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        reportDownload("EXCEL");
                        setOpen(false);
                      }}
                    >
                      Agree & Download Report
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </ClickAwayListener>
    </>
  );
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(ReportsOverspeed));
