// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent
} from '@material-ui/core/';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import StoreContext from '../../../context/dashboard-context';
import httpRequest from '../../../Utils/httpRequest';
import verified from '../../../Assets/Dashboard_icons/Verified_tag/Group 4958@2x.png';
import live from '../../../Assets/Dashboard_icons/Legends_icons/live and open status.png'
import outside from '../../../Assets/Dashboard_icons/Legends_icons/outside operating hrs status.png'
import offline from '../../../Assets/Images/offline.png';
import arrowRight from '../../../Assets/Images/arrow-right.png';
import red from '../../../Assets/Dashboard_icons/Legends_icons/alert-error-filled.png';
import amber from '../../../Assets/Dashboard_icons/Legends_icons/alert-warning-filled.png';
import DeviceGray from '../../../Assets/Dashboard_icons/Card_icons/Grayish/Group 4866@2x.png';
import DevStatus from '../../../Assets/Dashboard_icons/Residential/Device status.png';
import MyGate from '../../../Assets/Dashboard_icons/Residential/MyGate.png';
import Parkzeus from '../../../Assets/Dashboard_icons/Residential/Parkzeus.png';
import './ViewContainer.scss';




//@component
//Card View
const ResidentialDashboardOverview = () => {

  const [parkingData, setparkingData] = useState([]);
  const [prevDate, setprevDate] = useState(null);
  const [vehicleType, setVehicleType] = useState('CAR');

  //function to toggle ANPR 4w and 2w
  const updateAnpr = () => {
    if (vehicleType == 'CAR') {
      setVehicleType('BIKE')
    }
    else {
      setVehicleType('CAR')
    }
  }

  //upon succesfully getting dashboard data 
  const updateSuccess = (jsonResult) => {
    let parkData = jsonResult.data;
    parkData.sort((a) => (a.isDeviceCoupled ? -1 : 1));
    console.log(parkData)
    setparkingData(parkData);
    //  updateAnpr();

  };
  const errorCallback = (error) => {
    if (error) {
      // setdashboardError("Could not fetch data... try again!")
    }
  }

  //function for checkOpen
  const checkOpen = () => {
    // var timings = parkingData[index]?.operatingHoursInfo[dayArray[curDay]];
    //if not 24 hrs open
    // if (
    //   timings &&
    //   curTime > timings[0].openingTime &&
    //   curTime < timings[0].closingTime
    // ) {
    //   return live;
    // } else {
    //   return outside;
    // }
    return live;
  }
  //upon component load or unload
  useEffect(() => {
    httpRequest.get(
      "parkingListDashboardAsc",
      {},
      updateSuccess,
      errorCallback,
      {}
    );
    let date = new Date();
    let prevdate = date.setDate(date.getDate() - 1);
    setprevDate(prevdate);
    // updateme();
    const updatemeInterval = setInterval(() => {
      httpRequest.get(
        "parkingListDashboardAsc",
        {},
        updateSuccess,
        errorCallback,
        {

        }
      );
    }, 30000);
    let anprInterval = setInterval(() => {
      updateAnpr();
    }, 5000)
    return () => {
      clearInterval(anprInterval);
      clearInterval(updatemeInterval);
    }
  }, [vehicleType])
  return (
    <StoreContext.Consumer>{context => (
      <ClickAwayListener onClickAway={context.handleClickAway}>
        <Grid container className="parent" onClick={context.clickBlank} style={{ textAlign: '-webkit-center' }}>
          {parkingData.map((item, index) => (
            <Grid key={index} item xs={12}>
              <Card className="cust-card-res" onDoubleClick={() => { context.cardClicked(item.parkingId); }}>
                <Grid container direction="row" xs={12} style={{ margin: '1.2% 0% 0% 1.5%', alignItems: 'center' }}>
                  <Grid item container xs={10} >
                    <label style={{
                      fontSize: '1vw',
                      fontWeight: 'bolder',
                      color: '#102E35',
                      marginBottom: '1vw',
                      marginTop:'0.2vw'
                    }}>
                      {item.name.length > 50
                        ? `${item.name.slice(0, 50)}... `
                        : item.name}
                    </label>
                    <span className="liveTick">
                      {item.status.name === 'VERIFIED' ? (
                        <img src={checkOpen(index)} style={{ height: '65%' }} alt="image2" />
                      ) : item.status.name ===
                        'REQUEST_SUBMITTED' ? (
                        <img src={outside} alt="image2" />
                      ) : item.status.name === 'APPROVED' ? (
                        <img src={checkOpen(index)} alt="image2" />
                      ) : item.status.name ===
                        'REQUEST_REJECTED' ? (
                        <img src={offline} alt="image2" />
                      ) : (
                        <img src={offline} alt="image2" />
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    {item.isVerified ? (
                      <img
                        className="verified-image"
                        src={verified}
                        alt="image4"
                      />
                    ) : (
                      <br />
                    )}
                  </Grid>
                </Grid>
                <CardContent>
                  <Grid container direction="row" xs={12} spacing={1}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1.2fr 1.2fr',
                      gridTemplateRows: 'auto',
                    }} >

                    <Grid item
                      className="top-card"
                      style={{padding:0}}
                    >
                        <Link
                        to={
                          {
                            pathname: (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) ?
                              undefined
                              :
                              "/portal/parking-details/" + item?.parkingId,
                            state: {
                              deviceClick: (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) ?
                                undefined
                                :
                                true
                            }
                          }
                        }
                        style={{ textDecoration: 'none' }}
                      >
                      <Card raised={true} className="card-size-res"  style={{ marginRight: '1vw',marginLeft: '1vw' }}
                      >
                        {item?.isDeviceCoupled ?
                          (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'gw' })[0]?.status?.name === 'UP' &&
                            (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'anpr' })[0]?.status?.name === 'UP' &&
                              item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'ioc' })[0]?.status?.name === 'UP' &&
                              item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'boom' })[0]?.status?.name === 'UP' &&
                              item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'vms' })[0]?.status?.name === 'UP' &&
                              item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'zcu' })[0]?.status?.name === 'UP')
                            ? <>
                              <Grid item xs={12} style={{ visibility: 'hidden', textAlign: 'right', padding: '5px 5px 0 0', height: '1.4vw' }}>
                                <img src={red} alt="warning" style={{ width: '1rem', height: '1rem' }} />
                              </Grid> </>
                            : (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'gw' })[0]?.status?.name === 'UP') &&
                              (item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'anpr' })[0]?.status?.name === 'DOWN' ||
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'ioc' })[0]?.status?.name === 'DOWN' ||
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'boom' })[0]?.status?.name === 'DOWN' ||
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'vms' })[0]?.status?.name === 'DOWN' ||
                                item?.deviceHealthStatus.filter(dev => { return dev?.deviceType === 'zcu' })[0]?.status?.name === 'DOWN'
                              )
                              ?
                              <>
                                <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.4vw' }}>
                                  <img src={amber} alt="warning" style={{ width: '1rem', height: '1rem' }} />
                                </Grid>
                              </>
                              :
                              <>
                                <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.4vw' }}>
                                  <img src={red} alt="warning" style={{ width: '1rem', height: '1rem' }} />
                                </Grid>
                              </>
                          ) : (
                            <>
                              <Grid item xs={12} style={{ textAlign: 'right', padding: '5px 5px 0 0', height: '1.4vw' }}>
                                <img src={offline} alt="warning" style={{width: '1rem', height: '1rem' }} />
                              </Grid>
                            </>
                          )
                        }
                         {/* <Grid item xs={12} style={{ visibility: 'hidden', height: '1vw' }}>
                          <img src={red} alt="warning" />
                        </Grid> */}
                        <Grid
                          className="cardSizeRes"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr 1fr',
                            gridTemplateRows: 'auto',
                          }}
                        >
                          <img className="imgCardDevRes" src={item?.isDeviceCoupled ? DevStatus : DeviceGray} alt="icon" />
                          <div className="mt-3">

                            {
                              item?.isDeviceCoupled
                                ? (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0] === undefined) || (item?.deviceHealthStatus.filter(dev => { return dev.deviceType === 'gw' })[0]?.status?.name === 'DOWN')
                                  ? (<p className="devStatusOff">Offline</p>)
                                  : (<p className="devStatusRes">Online</p>)
                                : (<p className="dashCardLabelDev" >Uncommissioned</p>)
                            }
                            <span className="devLabel">{item?.isDeviceCoupled ? 'Device Status' : <span style={{ color: '#707070' }}>Device Status</span>}</span>
                          </div>
                          <img className="imgArrowRes" src={arrowRight} alt="icon" />
                        </Grid>
                        <Grid item xs={12} style={{ visibility: 'hidden', height: '1.4vw' }}>
                          <img src={red} alt="warning" />
                        </Grid>
                      </Card>
                      </Link>
                    </Grid>

                    <Grid item
                      className="top-card"
                      style={{padding:0}}
                    >
                      <Card raised={true} className="card-size-res" style={{ marginRight: '1vw' }} >
                        <Grid item xs={12} style={{ visibility: 'hidden', height: '1.4vw' }}>
                          <img src={red} alt="warning" />
                        </Grid>
                        <Grid
                          className="cardSizeRes"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr 1.2fr',
                            gridTemplateRows: 'auto',
                          }}
                        >
                          <img className="imgCardDevRes" src={MyGate} alt="icon" />
                          <div className="mt-3">
                            <p className="sourceLabel">Vehicle Added Through</p>
                            <span className="sourceValue">MyGate</span>
                          </div>
                          <div className="clientCount"><span>{item?.myGateVehicleCount}</span></div>
                        </Grid>
                        <Grid item xs={12} style={{ visibility: 'hidden', height: '1.4vw' }}>
                          <img src={red} alt="warning" />
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item
                      className="top-card"
                      style={{padding:0}}
                    >
                      <Card raised={true} className="card-size-res" style={{ marginRight: '1vw' }} >
                        <Grid item xs={12} style={{ visibility: 'hidden', height: '1.4vw' }}>
                          <img src={red} alt="warning" />
                        </Grid>
                        <Grid
                          className="cardSizeRes"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr 1.2fr',
                            gridTemplateRows: 'auto',
                          }}
                        >
                          <img className="imgCardDevRes" src={Parkzeus} alt="icon" />
                          <div className="mt-3">
                            <p className="sourceLabel">Vehicle Added Through</p>
                            <span className="pzValue">Parkzeus</span>
                          </div>
                          <div className="pzCount"><span>{item?.vehicleCount}</span></div>
                        </Grid>
                        <Grid item xs={12} style={{ visibility: 'hidden', height: '1.4vw' }}>
                          <img src={red} alt="warning" />
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ClickAwayListener>
    )}
    </StoreContext.Consumer>
  );
}

//function for redux connect
export default ResidentialDashboardOverview;

