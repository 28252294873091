// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./Sidebar.scss";

//@component
//SidebarMenuItem
export default function SidebarMenuItem(props) {
  const { name, Icon, Icon_collapsed, link, items, depth } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  //function for handleClick
  function handleClick(depth, items) {
    //let ele = evt.currentTarget;
    // if (open) {
    //   ele.classList.remove("active");
    //   setSelectedEle(false);
    // } else {
    //   ele.classList.add("active");
    //   setSelectedEle(true);
    // }
    if (items) {
      if (
        document.getElementById(depth)
          ? document.getElementById(depth).classList.contains("expanded")
          : null
      ) {
        // document
        //   .getElementById(depth)
        //   .classList.remove('expanded', 'active');
        // setSelectedEle(false);
      } else {
        // document
        //   .getElementById(depth)
        //   .classList.add('expanded', 'active');
        // setSelectedEle(true);
      }
    } else {
      let eleList = document.getElementsByClassName("left-panel");
      for (let i = 0; i < eleList.length; i++) {
        eleList[i].classList.remove("active");
      }
      let newArray = depth.split("");
      let str = "";
      for (let i = 0; i < newArray.length; i++) {
        str = str + newArray[i];
        document.getElementById(str).classList.add("active");
      }
    }
    setOpen(!open);
  }

  //function for MenuItemRoot
  const MenuItemRoot = (
    <ListItem
      className={
        link === window.location.pathname
          ? "active left-panel " + classes.listItem
          : classes.listItem + " left-panel"
      }
      divider={true}
      button
      id={depth}
      onClick={(evt) => handleClick(evt, depth, items)}
    >
      <Link
        // className="nav-link"
        className={!props.close ? "nav-link fill" : "nav-link stroke"}
        to={link}
        style={{ textDecoration: "none", color: "#21034F" }}
      >
        {!props.close ? Icon_collapsed : Icon}
        {/* {console.log('props.close', props.close)} */}
        {props.close && (
          <ListItemText
            className={classes.textSize}
            primary={name}
            inset={!Icon}
          />
        )}
      </Link>

      {/* Display the expand menu if the item has children */}
      {props.close && isExpandable && !open && (
        <AddIcon className="addremove-icon" fontSize={"small"} />
      )}
      {props.close && isExpandable && open && (
        <RemoveIcon className="addremove-icon" fontSize={"small"} />
      )}
    </ListItem>
  );
  //function for MenuItemChildren
  const MenuItemChildren = isExpandable ? (
    <Collapse in={open && props.close} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SidebarMenuItem {...item} key={index} close={props.close} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
}
//function for material-ui styles
const useStyles = makeStyles(() => ({
  listItem: {
    paddingBottom: "17px",
    paddingTop: "17px",
  },
  textSize: {
    fontSize: "15px",
    whiteSpace: "normal",
  },
}));
