// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';  
import Pagination from '@material-ui/lab/Pagination';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { updateBreadcrumbs, updateToasterConfig } from '../../../redux/modules/app/actions';
import httpRequest from '../../../Utils/httpRequest';
import Loader from '../../../Components/Preloader';
import OvernightReportUseStyles from './styles';
import { overnightHeaderCell } from '../../../Data/report-page';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import '../../SolutionAdmin/ParkingManagement/AddParkingModule/OperationalTimings/style.scss';

//@component
//Overnight violation report 
function ReportsOnp(props) {
  const classes = OvernightReportUseStyles();

  const [onpList, setOnpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [authorized, setAuthorized] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [fromTime, setFromTime] = useState(new Date().getTime());
  const [fromFill, setFromFill] = useState("");
  const [toTime, setToTime] = useState(new Date().getTime());
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [parkingId, setParkingId] = useState(null);
  const [parkingName, setParkingName] = useState(null);
  const [listSelectedFlag, setListSelectedFlag] = useState(false);

  // style to support datepicker
  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        verticalAlign: "middle",
        fontSize: "12px",
        fontWeight: '600',
        fontFamily: '\'Poppins\' !important',
        width: (params) => (params.width ? params.width : "auto"),
        "& fieldset": {
          border: "solid 2px #0070D8"
        },
        "& .MuiInputBase-root": {
          height: (params) => (params.height ? params.height : "auto"),
          color: (params) => (params.color ? params.color : "inherit")
        }
      }
    })
  );
  // for datepicker styling
  const classesDatePicker = useStyles({
    color: "red",
    width: "100%",
    height: "100%",
  });
  //style method for dialog
  const styles = (theme) => ({
    root: {
      margin: 0
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  //method for dialog
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  // when selecting authotize filter this api call is used  
  const generateReportHandler = (pageNumber, authorized, vehicleType) => {
    setPageNumber(pageNumber);
    setIsLoading(true);
    setListSelectedFlag(true);

    httpRequest.get(
      'bookingsReport',
      {
        'Content-Type': 'application/json'
      },
      successCallbackReport,
      errorCallback,
      {
        fromTime: fromTime,
        toTime: toTime || new Date(),
        page: pageNumber,
        size: 10,
        parkingId: parkingId,
      },
    )
  }
  // success callback for authorize filter api
  const successCallbackReport = jsonResponse => {
    setIsLoading(false);
    setResponseDataNotFound('');
    if(jsonResponse.data?.page?.totalPages < 1) {
      setListSelectedFlag(false);
      setOnpList([]);
      setResponseDataNotFound('');
    }
    if(jsonResponse.data?.page?.totalPages > 0 && jsonResponse.data?._embedded?.results) {
      setOnpList(jsonResponse.data._embedded.results);
      setPageNumber(jsonResponse.data.page.number);
      setTotalPages(jsonResponse.data.page.totalPages);
      setResponseDataNotFound('');
      setShowDiv(false);
    }
  }
  // error callback for authorize filter api
  const errorCallback = error => {
    setShowDiv(false);
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      //any error message
    }
    if (error) {
      setResponseDataNotFound("Data Not Found, Please Refine selection above !");
    }
  }
 
 

  //function to clear existing setter state values
  const clearHandler = () => {
    setFromFill("");
  }
  //validation and setting startdatetime
  const fromHandler = event => {
    if (new Date(event._d) <= new Date()) {
      let datems = event._d.getTime()
      if (datems <= toTime) {
        setFromTime(datems);
        setFromFill("fill");
      }
      if (datems > toTime) {
        props.dispatch(updateToasterConfig({
          show: true,
          message: 'Error! From date selected is greater than To date.',
          color: 'red'
        }))
      }
    }
  }
  //validation and setting enddatetime
  const toHandler = event => {
    if (new Date(event._d) <= new Date()) {
      setToTime(event._d.getTime())
    }
  }
 
  //updating breadcrumb and list function call
  useEffect(() => {
  
      props.dispatch(updateBreadcrumbs("bookingReport"));
     // console.log(props.location.state)
        setListSelectedFlag(true);
        setParkingName(props.location?.state?.parkingName);
        setFromTime(props.location?.state?.prevDate);
        setToTime(props.location?.state?.prevDate);
        setParkingId(props.location?.state?.parkingId);
        setIsLoading(true);
        // httpRequest.get(
        //   'bookingsReport',
        //   {
        //     'Content-Type': 'application/json'
        //   },
        //   successCallbackReport,
        //   errorCallback,
        //   {
        //     fromTime: props.location?.state?.prevDate,
        //     toTime: props.location?.state?.prevDate || new Date(),
        //     page: 0,
        //     size: 10,
        //     parkingId: props.location?.state?.parkingId,
        //   },
        // )
    
    clearHandler();
  },
  [])
  // }
  //Pagination change handle for next/previous/click on Page Number
  const handleChange = (event, value) => {
    setPageNumber(value);
    let currpage
    currpage = value - 1
    generateReportHandler(currpage, authorized, vehicleType);
  }

  // function for click away
  const handleClickAway = () => {
    setShowDiv(false);
  }
  // funtion to capitalize first letter of word
  const capitalize = vehicleType => {
    return vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1).toLowerCase();
  }

  return (
    <>
      {isLoading && <Loader />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid container
          direction="column"
          style={{
            display: 'flex',
            alignContent: 'center'
          }}
        >
          <Grid item container className={classes.outerRowGrid} justify="space-around">
            <Grid item md={3} lg={3}>
            

                  <div className={classes.selectListCard}>
                    {parkingName}
                  </div>
             
            </Grid>
            <Grid item md={2}  lg={2} className={classes.datePicker}>
              <label className={classes.dateLabel}>From: </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  InputProps={{
                    disableUnderline: true,
                    className: classesDatePicker.componentStyle
                  }}
                  disableFuture
                  onKeyDown={(e) => e.preventDefault()}
                  variant="inline"
                  label=""
                  value={fromTime}
                  onChange={fromHandler}
                  format="DD/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={2}  lg={2} className={classes.datePickerto} >
              <label className={classes.dateLabel}>To: </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  InputProps={{
                    disableUnderline: true,
                    className: classesDatePicker.componentStyle
                  }}
                  disableFuture
                  onKeyDown={(e) => e.preventDefault()}
                  minDate={fromTime}
                  variant="inline"
                  value={toTime}
                  onChange={toHandler}
                  format="DD/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={3}>
              <button
                disabled={parkingId===null||parkingId===''}
                style={{backgroundColor:parkingId===null||parkingId===''?"#A2A2A2":undefined}}
                type='button'
                className={classes.generateReportButton}
                onClick={() => generateReportHandler(pageNumber, authorized, vehicleType)}
              >
                Generate Report
              </button>
            </Grid>
          
          
          </Grid>
          <>
            <Grid container
              direction="row"
              justify="center"
              className={classes.tableGrid}
            >
              <TableContainer className={classes.paper} component={Paper}>
                <Table className={classes.table} aria-label="overnight report table">
                  <TableHead>
                    <TableRow>
                      {
                        overnightHeaderCell.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.id==='sno'?'center':'left'}
                            width={headCell.width}
                            className={classes.thData}>
                            {headCell.label}
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  {
                    listSelectedFlag &&
                    (
                      <TableBody>
                        {
                          onpList.length !== 0 ?
                            onpList.map((row, index) => {
                              let no = index;
                              function formatNo(no) {
                                return no < 10 ? (pageNumber) + '' + no : (pageNumber + 1) * no
                              }
                              return (
                                <TableRow key={index}>
                                  <TableCell align="center" className={classes.tableCell}>{formatNo(no + 1)}</TableCell>
                                  <TableCell align="left" className={classes.tableCell}>{row.user?.corpEmailId ? row.user.corpEmailId : "--"}</TableCell>
                                  <TableCell align="left" className={classes.tableCell}>{row.vehicle?.vehicleNumber ? row.vehicle.vehicleNumber : "--"}</TableCell>
                                  <TableCell align="left" className={classes.tableCell}>
                                    {row.vehicle?.vehicleType?.name ? capitalize(row.vehicle.vehicleType.name) : "--"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>{row?.actualEntryTime ? row.actualEntryTime : "--"}</TableCell>
                                  <TableCell className={classes.tableCell}>{row?.actualExitTime ? row.actualExitTime : "--"}</TableCell>
                                  <TableCell align="center" className={classes.tableCell}>{row.isViolated ? "No" : "Yes"}</TableCell>
                                  <TableCell align="center" style={{ paddingRight: "4%" }} className={classes.tableCell}>{row.totalParkedInDays}</TableCell>
                                </TableRow>
                              )
                            })
                            :
                            <Grid item>
                              <Grid container
                                direction="row"
                                justify="center"
                              >
                                <Typography variant="h5" color="error">{tableDataNotFound}</Typography>
                              </Grid>
                            </Grid>
                        }
                      </TableBody>
                    )
                  }
                  {
                    !listSelectedFlag && (
                      <TableBody className={classes.noDataTableBody}>
                        <Grid container justify="center" className={classes.noList}>
                          <Grid item style={{marginBottom:'70%'}}>
                            You don't have any list here.
                          </Grid>
                        </Grid>
                      </TableBody>
                    )
                  }
                  {
                    responseDataNotFound && (
                      <TableBody className={classes.noDataTableBody}>
                        <Grid container justify="center" className={classes.noList}>
                          <Grid item  style={{marginBottom:'70%'}}>
                            {responseDataNotFound}
                          </Grid>
                        </Grid>
                      </TableBody>
                    )
                  }
                </Table>
              </TableContainer>
            </Grid>
            {
              listSelectedFlag && !responseDataNotFound && (
                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationOuterGrid}
                >
                  <Grid item className={classes.paginationGrid}>
                    <Grid container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination count={totalPages} className={classes.root} size="small" onChange={handleChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
            {
              !listSelectedFlag && (
                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationOuterGrid}
                ></Grid>
              )
            }
            {
              responseDataNotFound && (
                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationOuterGrid}
                ></Grid>
              )
            }
          </>
        </Grid>
      </ClickAwayListener>
    </>
  )
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux  
export default withRouter(
  connect(null, mapDispatchToProps)(ReportsOnp),
);



