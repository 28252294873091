import React, { Component } from "react";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import profile from "../../../../../Assets/Images/user@2x.png";
import httpRequest from "../../../../../Utils/httpRequest";
import "./style.scss";
import { getCookie } from "../../../../../Utils/cookies";

export default class SolAdminDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setopen: false,
      userImg: "",
      userFName: "",
      userLName: "",
      address1: "",
      address2: "",
      city: "",
      pincode: "",
      userOrg: "",
      userEmail: "",
      profileImage: "",
      imgLink: "",
      userId: "",
      imgData: "",
      userPhone: "",
      orgName: "",
    };
  }
  componentDidMount = () => {
    httpRequest.get(
      "userProfile",
      {},
      this.UserDataSuccess,
      this.errorCallback,
      {
        userId: this.props.admin ? getCookie("userId") : this.props.solAdminId,
      }
    );
  };
  UserDataSuccess = (jsonResult) => {
    console.log(jsonResult);
    this.setState({
      imgLink: jsonResult.data._links.profileImage.href,
      userId: jsonResult.data.userId,
    });
    httpRequest.get("userProfile", {}, this.imageSuccess, this.errorCallback, {
      userId: getCookie("userId"),
    });
    this.setState({
      userFName: jsonResult.data.firstName,
      userLName: jsonResult.data.lastName ? jsonResult.data.lastName : "",
      userOrg: jsonResult.data.org.orgAcronym,
      userEmail: jsonResult.data.corpEmailId,
      userImg: jsonResult.data._links.profileImage.href,

      userPhone: jsonResult.data.mobileNumber,
      address1: jsonResult.data.addressDetails.addressLine1,
      address2: jsonResult.data.addressDetails.addressLine2,
      city: jsonResult.data.addressDetails.city,
      pincode: jsonResult.data.addressDetails.pincode,
      orgName: jsonResult.data.org.name,
      stateName: jsonResult.data.addressDetails.state,
    });
  };
  imageSuccess = () => {
    //not yet implemented
    // httpRequest.getImage(
    //   jsonResult.data._links.profileImage.href +'&responseType=BASE64_ENCODE',
    //   {
    //   },
    //   this.imageSuccessTwo,
    //   this.errorCallback,
    //   {
    //   },
    // );
  };
  imageSuccessTwo = (jsonResult) => {
    this.setState({
      imgData: jsonResult.data,
    });
  };
  errorCallback = () => {};
  render() {
    return (
      <div className="SolAdminDetails" style={{ paddingTop: "20px" }}>
        <Card raised={true} className="details_card">
          <CardHeader
            title="Parking Space Created By"
            titleTypographyProps={{ variant: "h6" }}
          />

          <CardContent className="details_info">
            <Grid container>
              <Grid item md={3} style={{ paddingRight: "10px" }}>
                <div style={{ marginBottom: "10px" }}>
                  <Avatar
                    style={{ width: "10em", height: "10em" }}
                    src={profile}
                    className="picture"
                  ></Avatar>
                </div>
              </Grid>
              <Grid item md={5} style={{ marginBottom: "1em" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",

                      paddingLeft: "15px",
                    }}
                  >
                    Name
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",

                      paddingLeft: "15px",
                    }}
                  >
                    {this.state.userFName !== undefined
                      ? this.state.userFName + " " + this.state.userLName
                      : "No Name"}
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",

                      paddingLeft: "15px",
                    }}
                  >
                    Organization Name
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "block",

                      paddingLeft: "15px",
                    }}
                  >
                    {this.state.orgName !== "" ? this.state.orgName : "-"}
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",

                      paddingLeft: "15px",
                    }}
                  >
                    Organization Address
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline-block",

                      paddingLeft: "15px",
                    }}
                  >
                    {this.state.address1}&nbsp;
                    {this.state.address2}&nbsp;
                    {this.state.city}&nbsp;{this.state.pincode}
                    <br />
                    {this.state.stateName}
                  </p>
                </div>
              </Grid>
              <Grid item md={4} style={{ marginBottom: "1em" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                    }}
                  >
                    Login Id
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      overflowWrap: "break-word",
                    }}
                  >
                    {this.state.userEmail}
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                    }}
                  >
                    Contact Number
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                    }}
                  >
                    {
                      /* {this.state.userPhone !== "" ? this.state.userPhone : "-"} */

                      this.props.contactNumber
                    }
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                    }}
                  >
                    Email Address
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "40px",
                    marginLeft: "30px",
                  }}
                >
                  <p
                    style={{
                      display: "inline",
                      overflowWrap: "break-word",
                    }}
                  >
                    {this.state.userEmail}
                  </p>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}
