import anprcarIcon from './Dashboard_icons/Card_icons/Enable/ANPR_car@2x.png';
import anprbikeIcon from './Dashboard_icons/Card_icons/Enable/ANPR_bike@2x.png';
import AnprIcon from './Dashboard_icons/Card_icons/Enable/Group 4941@2x.png';
import AnprGreyIcon from './Dashboard_icons/Card_icons/Grayish/Group 5140@2x.png';
import DeviceGreyIcon from './Dashboard_icons/Card_icons/Grayish/Group 4866@2x.png';
import OvernightRequestGreyIcon from './Dashboard_icons/Card_icons/Grayish/Group 4923@2x.png';
import OvernightViolationGreyIcon from './Dashboard_icons/Card_icons/Grayish/Group 4936@2x.png';
import DeviceIcon from './Dashboard_icons/Card_icons/Enable/Group 4866@2x.png';
import OvernightRequestIcon from './Dashboard_icons/Card_icons/Enable/Group 4923@2x.png';
import OvernightViolationIcon from './Dashboard_icons/Card_icons/Enable/Group 4936@2x.png';
import RedIcon from './Dashboard_icons/Legends_icons/alert-error-filled.png';
import AmberIcon from './Dashboard_icons/Legends_icons/alert-warning-filled.png';
import CarIcon from './Dashboard_icons/Car_icons/car.png';
import BikeIcon from './Dashboard_icons/Bike_icons/motorcycle.png';
import OfflineIcon from './Images/offline.png';
import OutsideHoursIcon from './Dashboard_icons/Legends_icons/outside operating hrs status.png';
import LiveIcon from './Dashboard_icons/Legends_icons/live and open status.png';
import VerifiedIcon from './Dashboard_icons/Verified_tag/Group 4958@2x.png';
import excelImgIcon from './icons/excel.png';
import pdf1ImgIcon from './icons/pdf1.png';
import excel1ImgIcon from './icons/excel1.png';
import deleteIcon from './icons/deleteIcon.png';
import EditIcon from './Images/edit.png';

import logoIcon from './Images/info.svg';
import liveIcon2 from './Images/live.png';
import outsideIcon from './Images/outside.png';
import offlineIcon2 from './Images/offline.png';
import noCustomOperatingHrsIcon from './Images/no custom operating hours.png';
import Draft from './Images/draft.png';
import infoImage from './Images/info.png';
import anprAmber from './Device_status_icons/ANPR_Camera/anpr-amber@2x.png';
import anpr from './Device_status_icons/ANPR_Camera/anpr@2x.png';
import anprGray from './Device_status_icons/ANPR_Camera/gray@2x.png';
import boomAmber from './Device_status_icons/Boom_Barrier/boom-amber@2x.png';
import bb from './Device_status_icons/Boom_Barrier/green@2x.png';
import bbGray from './Device_status_icons/Boom_Barrier/gray@2x.png';
import ledAmber from './Device_status_icons/LED_Display/led-amber@2x.png';
import led from './Device_status_icons/LED_Display/green@2x.png';
import ledGray from './Device_status_icons/LED_Display/gray@2x.png';
import gatewayAmber from './Device_status_icons/Gateway/gateway-amber@2x.png';
import flgateway from './Device_status_icons/Gateway/green@2x.png';
import flgatewayGray from './Device_status_icons/Gateway/gray@2x.png';
import zcuAmber from './Device_status_icons/ZCU/zcu-amber@2x.png';
import zcu from './Device_status_icons/ZCU/ZCU_green@2x.png';
import zcuGray from './Device_status_icons/ZCU/ZCU_gray@2x.png';
import iocAmber from './Device_status_icons/InOut_Counters/inout-amber@2x.png';
import ioc from './Device_status_icons/InOut_Counters/green@2x.png';
import iocGray from './Device_status_icons/InOut_Counters/gray@2x.png';
import address from './Images/address.png';
import VerifiedImg from './Images/verified tag.png';
import noParkingFacility from './Images/no parking facilities.png';
import noServicesNearBy from './Images/no services near by.png';
import marker from "./Images/pin.png";
import noReviews from "./Images/no reviews.png";
import OnlineDotIcon from './Device_status_icons/Device_Icons/online-status.png';
import OfflineDotIcon from './Device_status_icons/Device_Icons/offline-status.png';
import BothDotIcon from './Device_status_icons/Device_Icons/both-status.png';
import epld from './Device_status_icons/EPLD/EPLD@2x(Green).png';
import epldGray from './Device_status_icons/EPLD/EPLD@2x(Grey).png';
import epldGrayS from './Device_status_icons/EPLD/EPLD@1x(Grey).png'

const Images = {
    anprCarIcon: anprcarIcon,
    anprBikeIcon: anprbikeIcon,
    anprIcon: AnprIcon,
    anprGreyIcon: AnprGreyIcon,
    deviceGreyIcon: DeviceGreyIcon,
    overnightRequestGreyIcon: OvernightRequestGreyIcon,
    overnightViolationGreyIcon: OvernightViolationGreyIcon,
    deviceIcon: DeviceIcon,
    overnightRequestIcon: OvernightRequestIcon,
    overnightViolationIcon: OvernightViolationIcon,
    redIcon: RedIcon,
    amberIcon: AmberIcon,
    carIcon: CarIcon,
    bikeIcon: BikeIcon,
    offlineIcon: OfflineIcon,
    outsideHoursIcon: OutsideHoursIcon,
    liveIcon: LiveIcon,
    verifiedIcon: VerifiedIcon,
    excelIcon: excelImgIcon,
    excel1Icon: excel1ImgIcon,
    pdf1Icon: pdf1ImgIcon,
    deleteIcon: deleteIcon,
    editIcon: EditIcon,
    logoIcon: logoIcon,
    liveIcon2: liveIcon2,
    outsideIcon: outsideIcon,
    offlineIcon2: offlineIcon2,
    noCustomOperatingHrsIcon: noCustomOperatingHrsIcon,
    draftIcon: Draft,
    verifiedIcon: VerifiedImg,
    infoIcon: infoImage,
    anprIcon: anpr,
    anprGrayIcon: anprGray,
    boomBarrierIcon: bb,
    boomBarrierGrayIcon: bbGray,
    ledDisplayIcon: led,
    ledGrayIcon: ledGray,
    gatewayIcon: flgateway,
    gatewayGrayIcon: flgatewayGray,
    zcuIcon: zcu,
    zcuGrayIcon: zcuGray,
    inoutCounterIcon: ioc,
    inoutCounterGrayIcon: iocGray,
    addressIcon: address,
    noParkingFacilityIcon: noParkingFacility,
    noServicesNearByIcon: noServicesNearBy,
    markerIcon: marker,
    noReviewsIcon: noReviews,
    onlineDotIcon: OnlineDotIcon,
    offlineDotIcon: OfflineDotIcon,
    bothDotIcon: BothDotIcon,
    anprAmberIcon: anprAmber,
    boomAmberIcon: boomAmber,
    ledAmberIcon: ledAmber,
    gatewayAmberIcon: gatewayAmber,
    zcuAmberIcon: zcuAmber,
    iocAmberIcon: iocAmber,
    epldIcon:epld,
    epldGrayIcon:epldGray,
    epldGrayIconS:epldGrayS

}

export default Images;