// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import "./Login.scss";
import logo from "../../../Assets/Images/brand_logo.png";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import httpRequest from "../../../Utils/httpRequest";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  updateAuth,
  updateToasterConfig,
  updateShowModal,
} from "./../../../../src/redux/modules/app/actions";
import { setCookie, getCookie } from "./../../../Utils/cookies";
import { deleteAllCookies } from "./../../../Utils/cookies";
import Icon from "../../../Assets/Images/Password.png";
import Icon1 from "../../../Assets/Images/hide.png";
import TokenStorage from "./../../../Utils/tokenstorage";
import TermsAndCondition from "../../../Components/Dialog/TermsAndCondition";
import PrivacySetting from "../../../Components/Dialog/PrivacySetting"
//@component
//Login
class Login extends Component {
  constructor() {
    super();
    this.state = {
      // rememberMe: false,
      flag: "",
      role: "",
      userId: "",
      json: {},

      username: "",
      password: "",
      privacyCheck: false,
      error: "",
      token: "",
      cliSec: "",
      cliId: "",

      toasterConfig: {
        message: "",
        color: "",
        show: false,
      },
      passPop: false,
      passPopD: false,
      cookiesPop:false
    };

    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  componentDidMount() {
    window.onload = function() {
      if (getCookie("eula") == "false") {
        deleteAllCookies();
        window.location.reload();
      }
    };

    const auth = getCookie("auth");
    if (auth) {
      this.props.history.push("/portal/dashboard");
    }

    httpRequest.getPublic(
      "configUrl",
      {},
      this.configSuccessLoad,
      this.configError
    );
  }
  //function for handle submit
  handleSubmit1(evt) {
    if (
      getCookie("cliSec") === "" ||
      getCookie("cliSec") === undefined ||
      getCookie("cliSec") === null
    ) {
      httpRequest.getPublic(
        "configUrl",
        {},
        this.configSuccess,
        this.configError
      );
    } else {
      this.handleSubmit(evt);
    }
  }
  //function for error dismiss
  dismissError() {
    this.setState({ error: "" });
  }

  //function for login success
  loginSuccess = async (jsonResult) => {
    //<-------------------logic here-------------->

    this.setState({ json: jsonResult });
    // if (this.state.flag === "false") {
    //   return this.props.updateToasterConfig({
    //     show: true,
    //     message: "Please accept the terms and conditions",
    //     color: "#FF0000",
    //   });
    // }
    this.setState({ token: jsonResult.data.access_token });
    setCookie("auth", jsonResult.data.access_token, 1);
    setCookie("refresh", jsonResult.data.refresh_token, 1);
    setCookie("inactive", false);
    TokenStorage.storeRefreshToken(jsonResult.data.refresh_token);
    TokenStorage.storeToken(jsonResult.data.access_token);

    let dt = new Date();
    dt.setSeconds(dt.getSeconds() + (jsonResult.data.expires_in - 60));
    TokenStorage.setTokenExpirationTime(dt);

    this.props.updateAuth({ token: jsonResult.data.access_token });

    httpRequest.get(
      "createUser",
      {},
      this.createUserSuccess,
      this.errorCallback
    );
  };

  loginError = (error) => {
    if (error.error_description) {
      this.props.updateToasterConfig({
        show: false,
        message: "Processing...",
        color: "#ffae42",
      });
      if (error.error_description === "Account is locked") {
        this.setState({
          passPop: !this.state.passPop,
        });
      } else if (error.error_description === "Account disabled") {
        this.setState({
          passPopD: !this.state.passPopD,
        });
      } else {
        this.props.updateToasterConfig({
          show: true,
          message: error.error_description,
          color: "#FF0000",
        });
      }
    }
  };
  //function for createuser success
  createUserSuccess = (jsonResult) => {
    //console.log(jsonResult);
    this.setState({ flag: jsonResult.data.eulaAccepted.toString() });

    let newAuth = { ...this.props.auth };
    newAuth["userId"] = jsonResult.data.userId;

    this.setState({ role: jsonResult.data.roles[0] });

    if (this.state.role === "BOSCH_SUPER_ADMIN") {
      this.props.updateToasterConfig({
        show: true,
        message: "Super Admin cannot use this portal",
        color: "#FF0000",
      });
      TokenStorage.clear();

      return;
    }
    this.setState({ userId: jsonResult.data.userId });
    setCookie("eula", jsonResult.data?.eulaAccepted, 1);
    setCookie("isHardwareAvailable", jsonResult.data.org?.hardwareAvailable, 1);
    if (this.state.flag === "false") {
      return;
    }

    setCookie("userId", jsonResult.data.userId, 1);
    setCookie("role", jsonResult.data.roles[0], 1);

    if (this.state.flag === "false") {
      return;
    }
    this.props.updateAuth(newAuth);
    sessionStorage.setItem("auth", JSON.stringify(newAuth));

    if (
      jsonResult.data.roles[0] === "CLOSED_GROUP_PARKING_PROVIDER" ||
      jsonResult.data.roles[0] === "FACILITY_ADMIN" ||
      jsonResult.data.roles[0] === "MULTITENANT_ADMIN" ||
      jsonResult.data.roles[0] === "RESIDENTIAL_GROUP_PARKING_PROVIDER" ||
      jsonResult.data.roles[0] === "RESIDENTIAL_FACILITY_ADMIN"
    ) {
      this.props.updateToasterConfig({
        show: true,
        message: "Successfully logged In",
        color: "#02C194",
      });

      //<----------pop-up logic to be implemented------------------------------>

      //New dashboard route below
      // if (getCookie("userId") === "cade221a-899a-48d2-bec2-ae48b87cfb8b" || getCookie("userId") === "f570f213-db63-4a7b-af37-b5ad7a85ceaa") {
      //   this.props.history.push("/portal/dashboard_new");
      // } else {
      this.props.history.push("/portal/dashboard");
      // }
    } else if (jsonResult.data.roles[0] === "SECURITY_PERSONNEL") {
      this.props.updateToasterConfig({
        show: true,
        message: "Successfully logged In",
        color: "#02C194",
      });
      this.props.history.push("/portal/onplistview");
    } else {
      this.props.updateToasterConfig({
        show: true,
        message: "Super Admin cannot use this portal",
        color: "#FF0000",
      });
      TokenStorage.clear();

      this.props.history.push("/login");
    }
  };
  //function for error call

  errorCallback = () => {
    TokenStorage.clear();
    this.props.history.push("/login");
    this.props.updateToasterConfig({
      show: true,
      message: "User Unauthenticated",
      color: "#FF0000",
    });
  };
  //function for detect keypress
  detectKeyPress = (evt) => {
    if (evt.charCode === 13) {
      this.handleSubmit();
    }
  };
  //function for removereadonly
  removeReadOnly = () => {
    document.getElementById("username").removeAttribute("readOnly");
    document.getElementById("password").removeAttribute("readOnly");
  };

  //function for success load call
  configSuccessLoad = (jsonResult) => {
    if (
      jsonResult.data.platformClientSecret &&
      jsonResult.data.platformClientID
    ) {
      this.setState({
        cliSec: jsonResult.data.platformClientSecret,
        cliId: jsonResult.data.platformClientID,
      });
      setCookie("cliSec", jsonResult.data.platformClientSecret, 1);
      setCookie("cliId", jsonResult.data.platformClientID, 1);
      setCookie("realmName", jsonResult.data.platformRealm, 1);
    } else {
      this.props.updateToasterConfig({
        show: true,
        message: "Server not responding, please refresh page and try again!",
        color: "#FF0000",
      });
    }
  };
  //function for config success
  configSuccess = (jsonResult, event) => {
    this.setState({
      cliSec: jsonResult.data.platformClientSecret,
      cliId: jsonResult.data.platformClientID,
    });
    setCookie("cliSec", jsonResult.data.platformClientSecret, 1);
    setCookie("cliId", jsonResult.data.platformClientID, 1);
    setCookie("realmName", jsonResult.data.platformRealm, 1);

    this.handleSubmit(event);
  };
  //funciton for config error
  configError = () => {
    this.props.updateToasterConfig({
      show: true,
      message: "Server not responding, please refresh and try again!",
      color: "#FF0000",
    });
  };
  //function for handle submit
  handleSubmit() {
    if (!this.state.username) {
      return this.setState({ error: "Username is required" });
    }

    if (!this.state.password) {
      return this.setState({ error: "Password is required" });
    }

    if (getCookie("cliId") === "" && getCookie("cliSec") === "") {
      this.setState({
        cookiesPop:true
      })
      // this.props.updateToasterConfig({
      //   show: true,
      //   message: "Please allow this site to save and read cookie data in the settings",
      //   color: "#FF0000",
      // });
    } else {
      this.props.updateToasterConfig({
        show: true,
        message: "Processing...",
        color: "#ffae42",
      });

      httpRequest.auth(
        "loginUrl",
        {
          data:
            "username=" +
            this.state.username.trim() +
            "&password=" +
            this.state.password.trim() +
            "&grant_type=password&client_id=" +
            getCookie("cliId") +
            "&client_secret=" +
            getCookie("cliSec"),
        },
        {},
        this.loginSuccess,
        this.loginError
      );

      return this.setState({ error: "" });
    }
  }
  //function for password related
  myFunction() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    this.setState({
      show: !this.state.show,
    });
  }
  //function for username
  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }
  //function for password
  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }
  //function for privacycheck
  handlePrivacy = (evt) => {
    this.setState({
      privacyCheck: evt.target.checked,
    });
  };

  popUpClose = (e) => {
    e.stopPropagation();
    this.setState({
      passPop: false,
      passPopD: false,
      cookiesPop:false,
    });
  };

  render() {
    return (
      <div
        className="Login"
        style={{ display: "grid", placeContent: "center" }}
      >
        <div className="Login__wrapper">
          <Card>
            <div className="Login__wrapper_box">
              <div className="Login__wrapper_box_header">
                <div className="Login__wrapper_box_header_logo">
                  <img src={logo} alt="header-logo" />
                </div>
                <div className="Login__wrapper_box_header_text">
                  <span>Solution Admin Login</span>
                </div>
              </div>
              {this.state.error && (
                <h3
                  data-test="error"
                  className="error"
                  onClick={this.dismissError}
                >
                  <button onClick={this.dismissError}>✖</button>
                  {this.state.error}
                </h3>
              )}
              <label>Username or Email Id *</label>
              <input
                placeholder="User name"
                type="text"
                data-test="username"
                value={this.state.username}
                onChange={this.handleUserChange}
                id="username"
                autoComplete="off"
                readOnly
                onFocus={this.removeReadOnly}
              />

              <label>Password *</label>
              <div className="password-box">
                <input
                  className="password"
                  type="password"
                  data-test="password"
                  onKeyPress={this.detectKeyPress}
                  value={this.state.password}
                  onChange={this.handlePassChange}
                  placeholder="****************"
                  id="password"
                  autoComplete="new-password"
                  readOnly
                  onFocus={this.removeReadOnly}
                />
                <div className="passwordIcon">
                  <img
                    src={this.state.show ? Icon : Icon1}
                    alt="Password-Icon"
                    onClick={(evt) => {
                      this.myFunction(evt);
                    }}
                  />
                </div>
              </div>

              <div>
                {/* <Checkbox
                    color="default"
                    inputProps={{ "aria-label": "Privacy checkbox" }}
                    color="primary"
                    onChange={this.handleRememberMe}
                    value={this.state.rememberMe}
                  />
                  <span style={{ fontSize: 13 + "px", paddingTop: 3 + "px" }}>
                    Remember Me
                  </span> */}
                <Link
                  style={{
                    color: "#0A65A8",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  //color="primary"
                  to={"/forgot_password"}
                >
                  <small> Forgot&nbsp;Password?</small>
                </Link>
              </div>

              {/*<div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  inputProps={{ "aria-label": "Privacy checkbox" }}
                  color="primary"
                  onChange={this.handlePrivacy}
              />
                <span style={{ fontSize: 13 + "px", paddingTop: 3 + "px" }}>
                  I&nbsp;agree&nbsp;
                </span>
                <div
                  style={{
                    display: "flex !important",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Privacysetting displayPage="login" />
                    <LegalDataProtection displayPage="login" />
                  </div>
                </div>
                  </div>*/}
              <br />

              <Button
                disabled={!(this.state.username && this.state.password)}
                variant="contained"
                color="primary"
                type="button"
                onClick={(evt) => {
                  this.handleSubmit1(evt);
                }}
              >
                Login
              </Button>
            </div>
          </Card>
        </div>
        {this.state.passPop && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Login Error</p>
              </div>
              <p className="popup-text">
                Dear User, Your Account has been locked due to multiple failed
                login Attempts.
                <br />
                Please try again after 4 hours.
              </p>
              <div className="popup-button">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.popUpClose}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.passPopD && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Account Deactivated</p>
              </div>
              <p className="popup-text">
                Your account has been deactivated. Kindly contact the
                administrator
              </p>
              <div className="popup-button">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.popUpClose}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}
         {this.state.cookiesPop && (
          <div className="deletepup-wrapper">
            <div className="cookies-popup">
              <div className="heading">
                <p className="popup-heading" style={{fontSize:'20px'}}>This website uses cookies</p>
              </div>
              <p className="popup-text">
                This site uses cookies (and other similar technologies) to understand how to use this site and to deliver and personalize content.
                <br />
                <br />
               <b>Please allow this site to save and read cookie data in the browser settings.</b>
                <br />
                <br />
                For more information on managing and withdrawing consents, on how we handle data and to learn more about our use of cookies, 
                visit our privacy policies in the footer section.
    

              </p>
              <div className="popup-button">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.popUpClose}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}

        {this.state?.flag === "false" &&
          this.state.role !== "BOSCH_SUPER_ADMIN" && (
            <TermsAndCondition
              flag={true}
              json={this.state.json}
              role={this.state.role}
              userId={this.state.userId}
            />
          )}
      </div>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateToasterConfig, updateShowModal }
  )(Login)
);
