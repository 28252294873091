// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, List } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarMenuItem from "./SidebarMenuItem";
import { getSVG } from "../../Utils/SvgGenerator";
import { getCookie } from "../../Utils/cookies";

//Global variable declarations
const drawerWidth = 225;
//const role = getCookie('role')

const appMenuItemsSecurity = [
  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "1",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Overnight Parking Requests",
        link: "/portal/onplistview",
        depth: "10",
        Icon: getSVG("parkingManagement"),
      },
      {
        name: "ONP Report",
        link: "/portal/overnight_violation_report",
        depth: "20",
        Icon: getSVG("reports"),
      },
      // {
      //   name: "Trigger Vehicle Exit Event",
      //   link: "/portal/vehicleExitEvents",
      //   depth: "21",
      //   // Icon: IconBarChart,
      // }
    ],
  },
  {
    name: "Legal",
    //link: "/portal/configuration",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "2",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
  // {
  //   name: "Configuration",
  //   //link: "/portal/configuration",
  //   Icon: getSVG("configuration"),
  //   depth: "5",
  //   Icon_collapsed: getSVG("configuration"),
  //   items:[
  //     {
  //       name: "Alert Notifications",
  //       link: "/portal/alertNotifications",
  //       depth: "10"

  //     },
  //   ]

  // },
];

const appMenuItemsEY = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },
  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "1",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "User Management",
    // Icon: 'notification',
    Icon: getSVG("userManagement"),
    depth: "2",
    Icon_collapsed: getSVG("userCol"),
    items: [
      {
        name: "Facility Manager",
        link: "/portal/facilitymanagerlistview",
        depth: "10",
        // Icon: IconBarChart,
      },
    ],
  },

  {
    name: "Bookings",
    link: "/portal/createbooking",
    Icon: getSVG("booking"),
    depth: "3",
    Icon_collapsed: getSVG("booking"),
    items: [
      {
        name: "Create Booking",
        link: "/portal/createbooking",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "View Bookings",
        link: "/portal/viewbookings",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/Eylanding",
    Icon: getSVG("reports"),
    depth: "0",
    //Icon_collapsed: getSVG("dashboardCol"),
  },
];
const appMenuItemsEYFCM = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },
  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "1",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Bookings",
    link: "/portal/createbooking",
    Icon: getSVG("booking"),
    depth: "2",
    Icon_collapsed: getSVG("booking"),
    items: [
      {
        name: "Create Booking",
        link: "/portal/createbooking",
        depth: "12",
        // Icon: IconBarChart,
      },
      {
        name: "View Bookings",
        link: "/portal/viewbookings",
        depth: "13",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/Eylanding",
    Icon: getSVG("reports"),
    depth: "0",
    //Icon_collapsed: getSVG("dashboardCol"),
  },
];

const appMenuItemsResidential = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },

  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "2",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "User Management",
    // Icon: 'notification',
    Icon: getSVG("userManagement"),
    depth: "3",
    Icon_collapsed: getSVG("userCol"),
    items: [
      {
        name: "Facility Manager",
        link: "/portal/facilitymanagerlistview",
        depth: "10",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "4",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Access Control Management",
        link: "/portal/tempaccesslistview",
        depth: "10",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Configuration",
    //link: "/portal/configuration",
    Icon: getSVG("configuration"),
    depth: "5",
    Icon_collapsed: getSVG("configuration"),
    items: [
      {
        name: "Alert Notifications",
        link: "/portal/alertNotifications",
        depth: "10",
      },
    ],
  },

  {
    name: "Reports",
    link: "/portal/residential_reports",
    Icon: getSVG("reports"),
    depth: "5",
    Icon_collapsed: getSVG("reportCol"),
  },
  {
    name: "Legal",
    //link: "/portal/configuration",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "6",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
];
const appMenuItemsResidentialFcm = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },

  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "2",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },

  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "4",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Access Control Management",
        link: "/portal/tempaccesslistview",
        depth: "10",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Configuration",
    //link: "/portal/configuration",
    Icon: getSVG("configuration"),
    depth: "5",
    Icon_collapsed: getSVG("configuration"),
    items: [
      {
        name: "Alert Notifications",
        link: "/portal/alertNotifications",
        depth: "10",
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/residential_reports",
    Icon: getSVG("reports"),
    depth: "6",
    Icon_collapsed: getSVG("reportCol"),
  },
  {
    name: "Legal",
    //link: "/portal/configuration",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "7",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
];

const appMenuItems = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },
  // {
  //   name: "Complaint Management",
  //   link: "/portal/complaint",
  //   Icon: getSVG('serviceRequest'),
  //   depth: "1",
  //   Icon_collapsed: getSVG('serviceCol'),
  // },
  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "2",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },

  {
    name: "User Management",
    // Icon: 'notification',
    Icon: getSVG("userManagement"),
    depth: "3",
    Icon_collapsed: getSVG("userCol"),
    items: [
      {
        name: "Facility Manager",
        link: "/portal/facilitymanagerlistview",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Security Admin",
        link: "/portal/securitylistview",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "4",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Access Control Management",
        link: "/portal/tempaccesslistview",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Overnight Parking Requests",
        link: "/portal/onplistview",
        depth: "11",
        // Icon: IconBarChart,
      },
      {
        name: "Emergency Support",
        link: "/portal/soslistview",
        depth: "12",
        // Icon: IconBarChart,
      },
      {
        name: "Trigger Vehicle Exit Event",
        link: "/portal/vehicleExitEvents",
        depth: "13",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Configuration",
    //link: "/portal/configuration",
    Icon: getSVG("configuration"),
    depth: "5",
    Icon_collapsed: getSVG("configuration"),
    items: [
      {
        name: "Alert Notifications",
        link: "/portal/alertNotifications",
        depth: "10",
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/reports",
    Icon: getSVG("reports"),
    depth: "6",
    Icon_collapsed: getSVG("reportCol"),
  },
  {
    name: "Legal",
    link: "/portal/Legal",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "7",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
];

const appMenuItemsMultitenant = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },
  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "1",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "My Parking Spaces",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Tenant Parking Space",
        link: "/portal/tenantparkingmanagment",
        depth: "11",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "12",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Organization Management",
    // Icon: 'notification',
    // Icon: getSVG("organizationManagement"),
    Icon: getSVG("parkingManagement"),
    depth: "2",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Add Organization",
        link: "/portal/addOrganization",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "View Organization",
        link: "/portal/viewOrganization",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "User Management",
    // Icon: 'notification',
    Icon: getSVG("userManagement"),
    depth: "3",
    Icon_collapsed: getSVG("userCol"),
    items: [
      {
        name: "Facility Manager",
        link: "/portal/viewAccount",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Security Admin",
        link: "/portal/securitylistview",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "4",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Access Control Management",
        link: "/portal/tempaccesslistview",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Overnight Parking Requests",
        link: "/portal/onplistview",
        depth: "11",
        // Icon: IconBarChart,
      },
      {
        name: "Emergency Support",
        link: "/portal/soslistview",
        depth: "12",
        // Icon: IconBarChart,
      },
      {
        name: "Trigger Vehicle Exit Event",
        link: "/portal/vehicleExitEvents",
        depth: "13",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Configuration",
    //link: "/portal/configuration",
    Icon: getSVG("configuration"),
    depth: "5",
    Icon_collapsed: getSVG("configuration"),
    items: [
      {
        name: "Alert Notifications",
        link: "/portal/alertNotifications",
        depth: "10",
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/reports",
    Icon: getSVG("reports"),
    depth: "6",
    Icon_collapsed: getSVG("reportCol"),
  },
  {
    name: "Legal",
    //link: "/portal/configuration",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "7",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
];
const appMenuItemsFacility = [
  {
    name: "Dashboard",
    link: "/portal/dashboard",
    Icon: getSVG("dashboard"),
    depth: "0",
    Icon_collapsed: getSVG("dashboardCol"),
  },
  // {
  //   name: "Complaint Management",
  //   link: "/portal/complaint",
  //   Icon: getSVG('serviceRequest'),
  //   depth: "1",
  //   Icon_collapsed: getSVG('serviceCol'),
  // },
  {
    name: "Parking Management",
    // Icon: 'notification',
    Icon: getSVG("parkingManagement"),
    depth: "2",
    Icon_collapsed: getSVG("parkingCol"),
    items: [
      {
        name: "Parking Space Overview",
        link: "/portal/parkingmanagment",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Add a parking",
        link: "/portal/addparkingspace",
        depth: "11",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "User Management",
    // Icon: 'notification',
    Icon: getSVG("userManagement"),
    depth: "3",
    Icon_collapsed: getSVG("userCol"),
    items: [
      {
        name: "Security Admin",
        link: "/portal/securitylistview",
        depth: "10",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Services",
    Icon: getSVG("serviceRequest"),
    // link: "/portal/soslistview",
    depth: "4",
    Icon_collapsed: getSVG("serviceCol"),
    items: [
      {
        name: "Access Control Management",
        link: "/portal/tempaccesslistview",
        depth: "10",
        // Icon: IconBarChart,
      },
      {
        name: "Overnight Parking Requests",
        link: "/portal/onplistview",
        depth: "11",
        // Icon: IconBarChart,
      },
      {
        name: "Emergency Support",
        link: "/portal/soslistview",
        depth: "12",
        // Icon: IconBarChart,
      },
      {
        name: "Trigger Vehicle Exit Event",
        link: "/portal/vehicleExitEvents",
        depth: "13",
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: "Configuration",
    //link: "/portal/configuration",
    Icon: getSVG("configuration"),
    depth: "5",
    Icon_collapsed: getSVG("configuration"),
    items: [
      {
        name: "Alert Notifications",
        link: "/portal/alertNotifications",
        depth: "10",
      },
    ],
  },
  {
    name: "Reports",
    link: "/portal/reports",
    Icon: getSVG("reports"),
    depth: "6",
    Icon_collapsed: getSVG("reportCol"),
  },
  {
    name: "Legal",
    //link: "/portal/configuration",
    Icon: getSVG("legalDocumentsIcon"),
    depth: "7",
    Icon_collapsed: getSVG("legalDocumentsIcon"),
    items: [
      {
        name: "Terms and Conditions",
        link: "/portal/terms_condition",
        depth: "10",
      },
      {
        name: "Data Protection Notice",
        link: "/portal/data_protection_notice",
        depth: "11",
      },
    ],
  },
];

//function for material-ui styles
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    backgroundColor: "rgb(242, 242, 240)",
    zIndex: 1,
  },
  appMenu: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    position: "relative",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: "relative",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
}));
//@component
//Sidebar
function Sidebar(props) {
  const classes = useStyles();

  // toggle sidebar drawer
  const open = props.openState;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !open,
            [classes.drawerClose]: open,
          }),
        }}
        style={{ marginBottom: "40%" }}
      >
        <List
          component="nav"
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          {getCookie("role") === "SECURITY_PERSONNEL"
            ? appMenuItemsSecurity.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : getCookie("role") === "FACILITY_ADMIN" &&getCookie("isHardwareAvailable") !== "false"
            ? appMenuItemsFacility.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : getCookie("role") === "MULTITENANT_ADMIN"
            ? appMenuItemsMultitenant.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : getCookie("role") === "RESIDENTIAL_GROUP_PARKING_PROVIDER"
            ? appMenuItemsResidential.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : getCookie("role") === "RESIDENTIAL_FACILITY_ADMIN"
            ? appMenuItemsResidentialFcm.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : getCookie("isHardwareAvailable") === "false" &&
              getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER"
            ? appMenuItemsEY.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : (getCookie("isHardwareAvailable") === "false" &&
              getCookie("role") === "FACILITY_ADMIN")
            ? appMenuItemsEYFCM.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))
            : appMenuItems.map((item, index) => (
                <SidebarMenuItem {...item} key={index} close={!open} />
              ))}
        </List>
      </Drawer>
    </div>
  );
}

export default Sidebar;
