/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { makeStyles } from '@material-ui/core/styles'


const bulkUploadUseStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    uploadDialogTitle: {
        fontWeight: "700", borderBottom: "1px solid #808080", textAlign: "left",
    },
    fileUpload: {
        ...theme.typography.view,
        borderRadius: 50,
        backgroundColor: "#008ECF",
        height: "3.5em",
        width: "20em"
    },
    bulkUploadTopRow: {
        backgroundColor: "#e6e6fa",
        width: "100%",
        height: "50%",
    },
    InputRow: {
        padding: "32px",
        border: "2px solid #BFC0C2",
        margin: "32px",
        width: "90%",
        height: "25%",

    },

    ButtonRow: {
        padding: "32px",
    },
    bulkUploadCard: {
        zIndex: 0,
        //boxShadow: theme.shadows[10],
        boxShadow: '0px 3px 6px #00000029 !important',
        marginTop: "16px",
        marginBottom: '5%',
        width: "96%",
        height: "50%",
    },
    selectListCard: {
        width: '30%',
        border: '1px solid #93a1a4',
        height: '40px',
        display: 'flex',
        outline: 'none',
        fontSize: '12px',
        background: '#ffffff',
        // boxShadow: '1px 3px 8px 2px rgb(0 0 0 / 16%)',
        marginTop: '2.5%',
        textAlign: 'left',
        alignItems: 'center',
        fontFamily: '\'Poppins\' !important',
        fontWeight: '600',
        // borderRight: '10px solid #ffffff',
        paddingLeft: '1%',
        borderRadius: '90px',
        marginLeft: '5%'

    },
    addressDetails: {
        marginTop: '1%',
        textDecoration: 'none',
        fontSize: '1rem',
        color: 'rgb(0, 142, 207)',
        marginLeft: '5%'
    },
    // const styles = theme => ({
    textField: {
        width: '600px',
        // marginLeft: 'auto',
        // marginRight: 'auto',            
        // paddingBottom: 0,
        // marginTop: 0,
        // fontWeight: 500
    },
    input: {
        color: 'rgb(228, 106, 118)',
        width: '53vw'
    },
    sucessmsg: {
        color: '#808080'
    },
    viewBoxMap: {

        height: '100% !important'

    },
    dialogActionGrid: {
        marginTop: "0.5em",
        paddingBottom: '0',
        alignSelf:'center'
    },
    dialogConfirm: {
        color: 'white',
        background:'rgb(0, 142, 207)',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '90px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: 'none',
        textAlignLast: 'center',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        cursor:'pointer'
    },
    dialogConfirmSuccess: {
        color: 'white',
        background:'rgb(0, 142, 207)',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '90px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: 'none',
        textAlignLast: 'center',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        cursor:'pointer'
    },

    dialogCancelGrid: {
        textAlignLast: "right"
    },
    // });
}));

export default bulkUploadUseStyles;