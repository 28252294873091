// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Card } from "@material-ui/core";
import logo from "../../../Assets/Images/brand_logo.png";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import httpRequest from "../../../Utils/httpRequest";
import { setCookie } from "./../../../Utils/cookies";
import {
  updateAuth,
  updateToasterConfig,
} from "./../../../../src/redux/modules/app/actions";
import TokenStorage from "./../../../Utils/tokenstorage";
import "./Login.scss";


//@component
//ForgotPassword
function SsoLogin(props) {
  //const [isLoading, setIsLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("Establishing Connection...");
  const [customMessage, setCustomMessage] = useState("Establishing Connection...");
  const [customColor, setCustomColor] = useState('blue');
  const [urlString] = useState(new URL(window.location.href))
  const [accessToken] = useState(urlString.searchParams.get("access_token"));
  const [refreshToken] = useState(urlString.searchParams.get("refresh_token"));
  const [expiresIn] = useState(urlString.searchParams.get("expires_in"));
  // const [cliSec, setCliSec] = useState(null)
  // const [cliId, setCliId] = useState(null)



  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // api call to check ifuser is valid
  const validateUser = () => {
    //setIsLoading(true)
    httpRequest.getPublic(
      "configUrl",
      {},
      configSuccess,
      configError,
    );
  };
  //function for config success
  const configSuccess = jsonResult => {
    if(jsonResult){
      // setCliSec(jsonResult.data.platformClientSecret)
      // setCliId(jsonResult.data.platformClientID)
      setCustomColor('blue')
      setCustomMessage("Please wait while we take You to ParkZeus Solution Admin Dashboard...")

      setCookie("cliSec", jsonResult.data.platformClientSecret, 1);
      setCookie("cliId", jsonResult.data.platformClientID, 1);
      setCookie("realmName", jsonResult.data.platformRealm, 1)

      httpRequest.getSSO(
        "createUser",
        {},
        createUserSuccess,
        errorCallback,
        { 'token': accessToken }
      );
    }
  };
  //funciton for config error
  const configError = (e) => {
    if(e){
      setOpenAlert(false);
      setCustomColor('red');
      setCustomMessage("Sorry, but we’re having trouble signing you in. Please try again from the FCM Portal");
      setSeverity("error");
     // setIsLoading(false);
    }
  };
  //function for createuser success
  const createUserSuccess = (jsonResult) => {
    setCustomMessage("")
    setCustomColor('green')
    setCookie('auth', accessToken, 1);
    setCookie('refresh', refreshToken, 1);
    setCookie("userId", jsonResult.data.userId, 1);
    setCookie("eula", jsonResult.data?.eulaAccepted, 1);
    setCookie("isHardwareAvailable",jsonResult.data.org?.hardwareAvailable,1)

    TokenStorage.storeRefreshToken(refreshToken);
    TokenStorage.storeToken(accessToken);
    let dt = new Date();
    dt.setSeconds(dt.getSeconds() + (expiresIn - 60));
    TokenStorage.setTokenExpirationTime(dt);
    props.updateAuth({ token: accessToken });
    setTimeout(() => {
      props.history.push("/portal/dashboard")
    }, 2000)

    //setIsLoading(false)

  }
  //function for error call
  const errorCallback = (e) => {
    setCustomMessage("")
    setCustomColor('red')
    setCustomMessage("Sorry, but we’re having trouble signing you in. Please try again from the FCM Portal")
    setOpenAlert(false);
    setSeverity("error");
    setAlertMessage(e.message)
    //setIsLoading(false)
  };

  // component mount/update lifecycle hook method
  useEffect(() => {
    setCookie('auth', accessToken, 1);
    setCookie('refresh', refreshToken, 1);
    props.updateAuth({ token: accessToken });
    validateUser()
  }, [])

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <div className="Login">
        <div className="Login__wrapper">
          <Card>
            <div className="Login__wrapper_box">
              <div className="Login__wrapper_box_header">
                <div className="Login__wrapper_box_header_logo">
                  <img src={logo} alt="header-logo" />
                </div>
                <div className="Login__wrapper_box_header_text">
                  <span>FCM SSO Login</span>
                </div>
              </div>
              {alertMessage && (

                <h3
                  style={{ color: customColor }}
                  className="customMessage"

                >

                  {customMessage}

                </h3>


              )}
            </div>
          </Card>

        </div>
      </div>

      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>


    </>
  )
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateAuth,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(SsoLogin));