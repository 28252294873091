// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.

import { makeStyles } from '@material-ui/core/styles'

//@component
//reportUseStyles
const OvernightReportUseStyles = makeStyles(() => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#e46a76 !important',
      color: '#ffffff',
      fontWeight:'900',
     },
     '& .MuiPaginationItem-root' : {
     fontFamily: '\'Poppins\' !important',
     fontSize: '14px',
     },
    },
  paper: {
    boxShadow: '0px 3px 6px #00000029 !important',
  },
  thData: {
    fontWeight: 'bolder',
    fontSize: '12px',
    fontFamily: '\'Poppins\' !important',
    backgroundColor: 'rgba(226, 220, 242, 0.38)',
    height: '70px',
    wordWrap: 'break-word',
    letterSpacing: '0.5px',
    lineHeight: '1.6',
  },
  tableCell: {
    fontFamily: '\'Poppins\' !important',
    wordBreak: 'break-word',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '1.6',
  },
  tableCellNoSelectedData: {
    fontFamily: '\'Poppins\' !important',
    fontWeight: '500',
    fontSize: '11px'
  },
  tableCellNoResponseData: {
    fontFamily: '\'Poppins\' !important',
    fontWeight: '500',
    fontSize: '10px'
  },
  noDataTableBody: {
    height: '280px',
    marginBottom: '50px'
  },
  filter_TopGrid: {
    marginTop: '0.5%'
  },
  applyButtonFilter: {
    width: '60%',
    color: '#fff',
    background: '#e46767',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '10px',
    marginTop: '10px',
    '&:hover': {
      width: '60%',
      color: '#fff',
      background: '#e46767',
      borderRadius: '20px',
      padding: '5px 10px',
      textDecoration: 'none',
      fontSize: '0.7rem',
      marginTop: '10px',
    },
    '.MuiButton-outlinedSecondary:hover': {
      border: 'none'
    }
  },
  hrStyling: {
    marginTop: '10px'
  },
  liGrid: {
    height: '25px'
  },
  filter_box: {
    paddingTop: '10px',
    right: '0.0001%',
    width: '180px',
    listStyle: 'none',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '130%',
    zIndex: 1,
    color: '#000',
    fontSize: '10px',
    paddingBottom: '20%',
    borderRadius: '3%',
    border: '1px #A9A9A9 solid',
    '.MuiButton-outlinedSecondary': {
      border: 'none'
    },
    '& li': {
      display: 'flex',
      marginRight: '10px',
      marginLeft: '10px',
      height: '1%'
    },
    '& label': {
      // paddingTop: '12px'
    }
  },
  filter_image: {
    width: '65%'
  },
  filter_button: {
    position: 'relative',
    border: 'none',
    '&:hover': {
      border: 'none'
    },
    '& hr': {
      marginLeft: '23.8px',
      marginRight: '23.8px'
    }
  },
  dialog: {
    zIndex: 1301
  },
  downloadReportButtonGrid: {
    marginTop: '1%'
  },
  warningIcon: {
    verticalAlign: 'middle'
  },
  paginationOuterGrid: {

    padding: '2.5% 0%',
    marginBottom: '4.5%'

  },
  noResponseGrid: {
    marginBottom: '5%',
    padding: '1%',
    paddingRight: '0%'
  },
  paginationGrid: {
    marginRight: '2%'
  },
  pageTextGrid: {
    marginTop: '2.5%',
    marginRight: '20px'
  },
  toDatePicker: {
    width: '195px',
    marginLeft: '5px'
  },
  fromDatePicker: {
    width: '195px',
    marginLeft: '5px'
  },
  outerRowGrid: {
    display: 'flex',
    alignContent: 'center',
    width: '98%',
    margin: '1% 0 1% 2%',
    height: '40px',


  },

  reportExportImage: {
    width: '100%',
    height: '100%'
  },
  selectInput: {
    marginTop: '8%',
    border: 0,
    width: '100%'
  },
  tableGrid: {
    marginTop: '1%',
    width: '96%',
    height: '30%',
    marginLeft: '2%',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  aboveRowsGrid: {
    height: '40px'
  },
  excelButton: {
    width: '65%',
    cursor: 'Pointer',
    height: '36px',
    padding: '8% 17%',
    background: 'white',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginLeft: '10%',
    borderRadius: '90px'
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  table: {
    minWidth: 650,
    borderCollapse: 'collapse',
    width: '100%',
    maxHeight: '64vh',
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: 'bolder',
  },
  dateTimeCard: {
    backgroundColor: 'rgb(255, 255, 255)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '90px',
    padding: 10,
    height: 47,
    width: '80%'
  },
  dateLabel: {
    fontFamily: '\'Poppins\' !important',
    fontSize: "11px",
    marginRight: "1px"
  },
  selectListCard: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '99%',
    height: '36px',
    textAlign: 'left',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    border: 'none',
    borderRadius: '90px',
    background: '#ffffff',
    paddingLeft: '4%',
    marginTop: 0,
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: '\'Poppins\' !important',
    borderRight: '10px solid #ffffff',
  },

  datePickerfrom: {
    display: 'flex',
    width: '100%',
    height: '36px',
    textAlign: 'left',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    border: 'none',
    borderRadius: '90px',
    background: '#ffffff',
    paddingLeft: '1.0%',
    marginTop: 0,
    marginLeft: '1%',
    fontSize: '10px',
    fontFamily: 'Poppins',
    alignItems: 'center',

  },
  datePickerto: {
    width: '100%',
    border: 'none',
    height: '36px',
    display: 'flex',
    fontSize: '10px',
    fontFamily: 'Poppins',
    background: '#ffffff',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginTop: '0',
    textAlign: 'left',
    alignItems: 'center',
    paddingLeft: '1.0%',
    borderRadius: '90px',
    marginLeft: '1%'

  },
  buttonGrid: {
    marginLeft: '14.4%'
  },
  excelGrid: {
    // marginLeft:'5px'
  },
  verticalIconGrid: {
    marginTop: '1%'
  },
  filterLabel: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
    fontFamily: '\'Poppins\' !important',
  },
  generateReportGrid: {
    marginLeft: '0.5%'
  },
  generateReportButton: {
    color: '#fff',
    width: '100%',
    border: '0',
    cursor: 'pointer',
    height: '38px',
    padding: '9px 30px',
    fontSize: '11px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    fontFamily: '\'Poppins\' !important',
    fontWeight: '600',
    borderRadius: '90px',
    letterSpacing: '0.8px',
    backgroundColor: '#03A9F3'
  },
  paginationStyle: {
    backgroundColor: 'white',
    width: '100%',
    padding: '10px',
    borderRadius: '90px',
    paddingLeft: '15px',
    boxShadow: '0px 3px 6px #00000029 !important',
    fontSize: '10px',
    fontFamily: 'Poppins',
    height: '3rem',
    alignContent: 'center'
  },
  noList: {
    width: '190%',
    fontSize: '14px',
    marginTop: '110%',
    fontFamily: '\'Poppins\'',
    marginLeft: '350%',
    wordBreak: 'break-word',
    textAlign: 'center',
    fontWeight: '500'
  },
  noResponseData: {
    fontFamily: 'Poppins',
    fontSize: '11px',
    marginTop: '110%',
    marginLeft: '300%',
    width: '320%'
  }
}));
export default OvernightReportUseStyles;