// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, withRouter } from "react-router-dom";
import { getCookie } from "./../../Utils/cookies";
import { updateToasterConfig } from "./../../../src/redux/modules/app/actions";
import girl from "../../Assets/Images/user.png";
import { connect } from "react-redux";
import httpRequest from "./../../Utils/httpRequest";
import TokenStorage from "../../Utils/tokenstorage";

//function for usestyles
const useStyles = makeStyles({
  listGroup: {
    paddingTop: "0",
  },
});
//@component
//MediaCard
function MediaCard(props) {
  const [query, setQuery] = useState("");
  const [profileImg] = useState("");
  const [passPop, setPassPop] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [fullName, setfullName] = useState("");
  //function for profile data
  const getProfileData = () => {
    httpRequest.get("userProfile", {}, UserDataSuccess, errorCallback, {
      userId: getCookie("userId"),
    });
  };
  //function for success call
  const UserDataSuccess = (jsonResult) => {
    //profile image not implemented yet
    // httpRequest.getImage(
    //   jsonResult.data._links.profileImage.href +
    //   '&responseType=BASE64_ENCODE',
    //   {
    //     Accept: 'image/png',
    //   },
    //   imageSuccessTwo,
    //   errorCallbackImage,
    //   {
    //     detailsImgTwo:
    //       jsonResult.data._links.profileImage.href +
    //       '&responseType=BASE64_ENCODE',
    //   },
    // );

    const value = jsonResult.data.org.name;

    const fname = jsonResult.data
      ? jsonResult.data.firstName
        ? jsonResult.data.firstName
        : ""
      : "";
    const lname = jsonResult.data
      ? jsonResult.data.lastName
        ? jsonResult.data.lastName
        : ""
      : "";
    // const img = jsonResult.data._links.profileImage.href;
    setQuery(value);
    //setOrg(org);
    setFname(fname);
    setLname(lname);
    setfullName(fname + lname);
  };
  //error block profile
  const errorCallback = (e) => {
    if (e) {
      // add any statement if required
    }
    // console.log("error in profilr", e)
  };
  //usestyles reference
  const classes = useStyles();

  //reference variable
  const handleCloser = props.handleCloser;
  //function for deleteclick
  const deleteClickCancel = () => {
    setPassPop(!passPop);
  };
  //function for logout confirm
  const confirmLogout = () => {
    setPassPop(!passPop);
  };
  //function for multi-tab session logout
  const multiTabTrigger = () => {
    window.addEventListener("storage", function(event) {
      if (event.key === "logout-event") {
        logout();
      }
    });
  };
  //function for logout
  const logout = () => {
    let cliSec = getCookie("cliSec");
    let cliId = getCookie("cliId");
    localStorage.setItem("logout-event", "logout" + Math.random());
    httpRequest.patch(
      "logout",
      {
        refreshToken: getCookie("refresh"),
        clientId: cliId,
        clientSecret: cliSec,
      },
      logoutSuccess,
      errorCallbacklogout
    );
  };
  //function for errorcallback
  const errorCallbacklogout = () => {
    props.updateToasterConfig({
      show: true,
      message: "Successfully logged out",
      color: "#02C194",
    });

    TokenStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  };
  //function for logout success
  const logoutSuccess = () => {};
  //function for component mount
  useEffect(() => {
    getProfileData();
    multiTabTrigger();
  }, []);

  return (
    <div className={classes.listGroup}>
      <MenuItem>
        <img
          className="avatar"
          src={profileImg !== "" ? `data:image/png;base64,${profileImg}` : girl}
          alt="avatar"
          width="150"
          height="150"
        />

        {/* <img className="avatar" src={profileImg !== "" ? profileImg : girl } alt="avatar" width="150" height="150"/> */}
      </MenuItem>
      <MenuItem className="listItem" dense={true}>
        <Typography variant="h6" component="h6">
          {fullName.length > 15 ? fname + "..." : fname + " " + lname}
        </Typography>
      </MenuItem>
      <MenuItem className="listItem">
        <Typography style={{ fontWeight: "bold" }}>
          {getCookie("role") === "SECURITY_PERSONNEL"
            ? "Security Personnel"
            : "Parking Solution Admin"}
        </Typography>
      </MenuItem>
      <MenuItem className="listItem">
        <Typography>
          <p>{query}</p>
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleCloser}>
        <Typography className="profileViewer">
          {/* <Link className="link">View Profile</Link> */}
          <Link to="/portal/profile">
            <p className="link">View Profile </p>
          </Link>
        </Typography>
      </MenuItem>
      <MenuItem dense={true} disablePadding={true}>
        <Button
          variant="contained"
          className="logoutBtn"
          onClick={confirmLogout}
        >
          Logout
        </Button>
      </MenuItem>
      {passPop && (
        <div className="deletepup-wrapper">
          <div className="password-popup">
            <div className="heading">
              <p className="popup-heading">Logout Confirmation</p>
              <span
                className="logout-cross"
                onClick={deleteClickCancel}
                style={{ visibility: "hidden" }}
              >
                X
              </span>
            </div>
            <p className="popup-text">
              Are you sure want Logout from the session/portal?
            </p>
            <div className="popup-button">
              <Button
                className="cancel"
                variant="contained"
                onClick={deleteClickCancel}
              >
                Cancel
              </Button>
              <Button className="reset" variant="contained" onClick={logout}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

//export default withRouter(MediaCard);
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig }
  )(MediaCard)
);
