// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import tick from '../../../../../Assets/Images/Tick.png';
import './style.scss';
import { getCookie } from '../../../../../Utils/cookies';
//@component
//Request Submitted
export default class RequestSubmitted extends Component {
  render() {
    return (
      <div className="RequestSubmitted">
        <div className="RequestSubmitted_wrapper">
          <Card>
            <div className="RequestSubmitted_wrapper_right">
              <img alt="tick" src={tick} />
              {/* <span style={{fontSize:"100px"}}>&#10004;</span> */}
            </div>
            <h1>Request Submitted</h1>
            <br />
            {(getCookie('role') === 'RESIDENTIAL_GROUP_PARKING_PROVIDER' || getCookie('role') === "RESIDENTIAL_FACILITY_ADMIN") && (
              <h4>Your Parking Space will be Reviewed and Approved by Parkzeus.</h4>
            )}
            {(getCookie('role') === 'MULTITENANT_ADMIN' || getCookie('role') === 'CLOSED_GROUP_PARKING_PROVIDER' || getCookie('role') === 'FACILITY_ADMIN') && (
              <>
                <h4>
                  Contact details will be taken as emergency support details when the parking
                  space is approved.
                </h4>

                <h4>You can change this later by visiting the emergency support page.</h4>
              </>
            )}
            <div className="RequestSubmitted_wrapper_buttons">
              <Link
                to={'/portal/parkingmanagment'}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="RequestSubmitted_wrapper_buttons_overview"
                >
                  Parking Space Overview
                </Button>
              </Link>

              <Link
                to={'/portal/addparkingspace'}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="RequestSubmitted_wrapper_buttons_addparking"
                >
                  List my Parking Space
                </Button>
              </Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
