// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
export default {
  app: {
    auth: {},
    toasterConfig: {
      show: false,
      message: "",
      color: "",
    },
    showModal: false,
    breadcrumbsKey: "home",
    index: 0,
    bubble: null,
    filterData: [],
    appliedFilterData: [],
  },
};
