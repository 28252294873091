// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./forgot.scss";
import Loader from "../../../Components/Preloader";
//@component
//VerifyOtp
function VerifyOTP(props) {
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading] = useState(false);
  const [error, setError] = useState(false);

  // function to handle cancel button callback
  const handleCancel = () => {
    props.cancelOTPToggle();
  };
  // function to handle verify button callback
  const handleSubmit = () => {
    if (!username) {
      return setError("Email is required");
    }
    props.verifyOTPToggle();
  };
  // function to set username to react parent component state as callback
  const handleSetUsername = (event) => {
    setUsername(event.target.value);
    props.setUsername(event.target.value);
  };
  // function to set otp to react parent component state as callback
  const handleSetOTP = (event) => {
    setOtp(event.target.value);
    props.setOTP(event.target.value);
  };
  // function to remove read only property
  const removeReadOnly = () => {
    document.getElementById("username").removeAttribute("readOnly");
    document.getElementById("otp").removeAttribute("readOnly");
  };
  // function to remove error message
  const dismissError = () => {
    setError("");
  };

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__wrapper">
        {isLoading && <Loader />}
        <Card>
          <div className="ForgotPassword__wrapper_box">
            <div className="ForgotPassword__wrapper_box_header_text">
              <span>Forgot Password ?</span>
            </div>

            <div style={{ padding: "10px 16px 10px 16px", width: "500px" }}>
              {error && (
                <h3 data-test="error" className="error" onClick={dismissError}>
                  <button onClick={dismissError}>✖</button>
                  {error}
                </h3>
              )}
              <label>Enter your Email*</label>
              <input
                placeholder="Email ID"
                type="text"
                data-test="username"
                value={username}
                onChange={handleSetUsername}
                id="username"
                autoComplete="off"
                readOnly
                onFocus={removeReadOnly}
              />
              <label>Enter the OTP*</label>
              <input
                placeholder="Enter the OTP"
                type="number"
                value={otp}
                onChange={handleSetOTP}
                id="otp"
                autoComplete="off"
                readOnly
                onFocus={removeReadOnly}
              />
              <Grid container justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    type="button"
                    onClick={() => handleCancel()}
                    style={{
                      borderRadius: "25px",
                      fontSize: "0.65rem",
                      color: "#fff",
                      background: "grey",
                      margin: "1em",
                      width: "90.5px",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderRadius: "25px",
                      fontSize: "0.65rem",
                      margin: "1em",
                    }}
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={username.length === 0 || otp.length === 0}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Verify OTP
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default withRouter(connect(null, null)(VerifyOTP));
