// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component, Fragment } from 'react';
import Parking from './../Parking';
import './style.scss';
import { withRouter } from 'react-router';
import './style.scss';

//@component
//Parking Space
class ParkingSpace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkingCard: {},
      selectedParkingId: null,
      currentPage: this.props.page.number+1,
      postsPerPage: this.props.postsPerPage,
    };
  }

  //function for parkingSelectHandeler
  parkingSelectHandeler = (id, status) => {
    if (status === 'DRAFT') {
      this.props.history.push(
        '/portal/addparkingspace?parkingId=' + id,
      );
    } else {
      this.setState({ selectedParkingId: id });
      this.props.history.push('/portal/parking-details/' + id);
    }
  };
 
  render() {
    return (
      <div className="parking-Overview">
        {this.props.cardData.length ? (
          <div className="parkingSpace">
            <div
              className="parkingSpace__wrapper"
              style={{ padding: '5px 10px 0px' }}
            >
              {
                this.props.cardData.map(
                  (parking, index) => {
                    return (
                      <Fragment key={index}>
                        <Parking
                          parkingId={parking.parkingId}
                          id={index}
                          title={parking.name}
                          address={parking.address}
                          address1={
                            parking.addressDetails.addressLine1
                          }
                          address2={
                            parking.addressDetails.addressLine2
                          }
                          status={parking.status.name}
                          color={parking.currentStatus.color}
                          currentStatus={parking.currentStatus.name}
                          type={parking.parkingBusinessType.name}
                          description={parking.description}
                          contact={parking.contactNumber}
                          email={parking.contactEmail}
                          vehicleTypeInfo={parking.vehicleTypeInfo}
                          links={parking._links}
                          timings={parking.operatingHoursInfo}
                          isVerified={parking.isVerified}
                          createdBy = {parking.createdBy}
                          {...this.props}
                          clicked={() =>
                            this.parkingSelectHandeler(
                              parking.parkingId,
                              parking.currentStatus.name,
                            )
                          }
                        />
                      </Fragment>
                    );
                  },
                )
              }
            </div>

          
          </div>
        ) : (
          <div>
            {/* <div className="noParking__wrapper">
                  <img src={noSpace} alt="Parking space icon" />
                  <h4>
                    You don't have any parking space added to your account.
                  </h4>
                  <Link to="/portal/addparkingspace">
                    <Button variant="contained" color="primary">
                      List A Parking Space +
                    </Button>
                  </Link>
                </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ParkingSpace);
