// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import './style.scss';
import { updateAuth } from '../../../../../redux/modules/app/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import noParkingFac from '../../../../../Assets/Images/no parking facilities.png';
import noServicesNearBy from '../../../../../Assets/Images/no services near by.png';
import { Scrollbars } from 'react-custom-scrollbars';

//@component
//ParkingFacilitiesServices
class ParkingFacilitiesServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      facilities: [],
    };
  }
  //function for component mount
  componentDidMount() {}

  render() {
    return (
      <Grid container spacing={3} style={{marginBottom:'50px'}}>
        <Grid item md={6}>
          <Card
            raised={true}
            className="details_card"
            style={{ marginTop: '10px' }}
          >
            <CardHeader
              title="Parking Facilities"
              titleTypographyProps={{ variant: 'h6' }}
            />
            <Scrollbars style={{ height: '290px' }}>
              <CardContent className="details_info">
                <Grid container className="facilities">
                  {this.state &&
                  this.props.facilities &&
                  this.props.facilities.length > 0
                    ? this.props.facilities.map((item, index) => {
                        return (
                          <Grid key={index} item xs={6}>
                            <img
                              src={item._links.image.href}
                              alt={item.name}
                            />
                            <p>{item.name}</p>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
                {this.state &&
                this.props.facilities &&
                this.props.facilities.length === 0 ? (
                  <div
                    style={{ textAlign: 'center', marginTop: '7%' }}
                  >
                    <img src={noParkingFac} alt="noParkingFac" />
                    <p style={{ paddingTop: '20px' }}>
                      No Parking Facilities found for this parking
                      space
                    </p>
                  </div>
                ) : null}
              </CardContent>
            </Scrollbars>
          </Card>
        </Grid>

        <Grid
          className="service_details services_near"
          item
          md={6}
          style={{ paddingLeft: '5px' }}
        >
          <Card
            raised={true}
            className="details_card"
            style={{ marginTop: '10px' }}
          >
            <CardHeader
              title="Services Nearby"
              titleTypographyProps={{ variant: 'h6' }}
            />
            <Scrollbars style={{ height: '290px' }}>
              <CardContent className="details_info">
                <Grid
                  container
                  className="services"
                  style={{
                    paddingTop: '15px',
                    paddingBottom: '20px',
                  }}
                >
                  {this.props &&
                  this.props.services &&
                  this.props.services.length
                    ? this.props.services.map((item, index) => {
                        return (
                          <Grid
                            key={index}
                            className="item"
                            item
                            xs={3}
                          >
                            <img
                              style={{ width: '60px' }}
                              src={item._links.image.href}
                              alt={item.name}
                            />
                            <p
                              style={{
                                fontSize: '14px',
                                paddingTop: '10px',
                                color: '#707070',
                              }}
                            >
                              {item.name}
                            </p>
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#707070',
                              }}
                            >
                              {item.distanceInMeters}m
                            </p>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
                {this.state &&
                this.props.services &&
                this.props.services.length === 0 ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={noServicesNearBy}
                      alt="noServicesNearBy"
                    />
                    <p style={{ paddingTop: '20px' }}>
                      No Services Nearby found for this parking space
                    </p>
                  </div>
                ) : null}
              </CardContent>
            </Scrollbars>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth },
  )(ParkingFacilitiesServices),
);
