// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles } from "@material-ui/core/styles";

//@component
//landingUseStyles
const landingUseStyles = makeStyles(() => ({
  textStyling: {
    fontSize: "13px",
    fontFamily: "'Poppins' !important",
    fontWeight: "500",
    textTransform: "capitalize",
    marginTop: "10%",
    wordBreak: "break-word",
    textAlign: "center",
  },
  textStylingLong: {
    fontSize: "13px",
    fontFamily: "'Poppins' !important",
    fontWeight: "500",
    textTransform: "capitalize",
    marginTop: "6%",
    wordBreak: "break-word",
    textAlign: "center",
  },
  textGrid: {
    marginBottom: "2%",
  },
  imageStyle: {
    width: "70%",
    marginLeft: "15%",
  },
  imageGrid: {
    marginBottom: "1%",
  },
  cardContentGrid: {
    marginTop: "5%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  individualColumnBlock: {
    marginBottom: "40px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  viewReport: {
    borderRadius: 50,
    backgroundColor: "#03A9F3",
    height: "35%",
    width: "50%",
    marginBottoboxSham: "2%",
    fontSize: "0.66rem",
    color: "white",
    fontFamily: "'Poppins' !important",
  },
  reportCard: {
    width: "96%",
    height: "50%",
    padding: "4.5% 1% 3.5% 1%",
    zIndex: "0",
    marginTop: "1.2%",
    marginBottom: "5%",
    boxShadow: "0px 3px 6px #0000001a !important",
  },
  header: {
    fontSize: "18px",
    fontFamily: "'Poppins' !important",
    fontWeight: "600",
    lineHeight: "1.334",
    letterSpacing: "0em",
  },
}));

export default landingUseStyles;
