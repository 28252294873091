// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core/";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import OprationalTimmings from "./../OperationalTimings";
import Popover from "@material-ui/core/Popover";
import CustomAvailability from "./../CustomRule";
import Tip from "../../../../../Assets/Images/info.svg";
import TwoWheeler from "../../../../../Assets/Images/2 Wheeler.svg";
import FourWheeler from "../../../../../Assets/Images/4 Wheeler.svg";
import CarA from "../../../../../Assets/Images/carA.png";
import MotorA from "../../../../../Assets/Images/motorA.svg";
import "./style.scss";
import error from "../../../../../Assets/Images/error.png";
import httpRequest from "../../../../../Utils/httpRequest";
import { getCookie } from "../../../../../Utils/cookies";

//function for tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
//function for protypes tabpanel
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
//function for allyprops
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
//function for styles
const styles = {
  opacity: "0.5",
  pointerEvents: "none",
};
//function for active field styles
const activeFieldStyle = {
  opacity: "1",
  pointerEvents: "all",
};
//Global array declaration
const tabs = ["twoWheeler", "fourWheeler", "fleet"];
//function to update parent state
const updateParentState = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };

  obj[childKey] = value;
  props.updateChildrenState("step2", parentKey, obj);
};
//function to change terms
const changeTerms = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };

  if (parentKey === "twoWheeler") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
  if (parentKey === "fourWheeler") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
  if (parentKey === "fleet") {
    if (childKey === "pricePerDay") {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerWeek") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState("step2", parentKey, obj);
    }
    if (childKey === "pricePerMonth") {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState("step2", parentKey, obj);
    }
  }
};
//function to handle radio check
const handleCheckRadio = (key, props) => {
  let obj = { ...props.data[key] };
  obj.paid = !obj.paid;
  props.updateChildrenState("step2", key, obj);
};

//@component
//AddParkingStepTwo
function AddParkingStepTwo(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState("twoWheeler");
  const [showTheDiv, setTheDiv] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [slotPolicy, setSlotPolicy] = useState(null);
  const [slotFlag, setSlotFlag] = useState(false);
  const [bikeSlots, setBikeSlots] = useState(0);
  const [carSlots, setCarSlots] = useState(0);

  //function to handle change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //function to handle index
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  //function to update active tab
  const updateActiveTab = (tabName) => {
    setActiveTab(tabName);
    let data = {
      ...props.data[activeTab],
    };
    updateParentState(activeTab, "totalSlots", data.totalSlots, props);
  };
  //function to show custom rule
  const showCustomRule = () => {
    setTheDiv(showTheDiv + 1);
  };
  // function to handle i icon click event
  const handleClickSlot = (event) => {
    setSlotPolicy(event.currentTarget);
  };
  // function to handle i icon close event
  const handleCloseSlot = () => {
    setSlotPolicy(null);
  };
  // function scope variable declaration definition
  const openSlot = Boolean(slotPolicy);
  const idSlot = openSlot ? "simple-popover" : undefined;

  // function to handle i icon click event
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // function to handle i icon close event
  const handleClose = () => {
    setAnchorEl(null);
  };
  // function scope variable declaration definition
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  props.updateRootState(1, 2);

  useEffect(() => {
    //  const slotFlag = (props.data['fourWheeler'].totalSlots!==0 || props.data['twoWheeler'].totalSlots!==0)
    httpRequest.get("userProfile", {}, userDataSuccess, () => {}, {
      userId: getCookie("userId"),
    });
  });
  const userDataSuccess = (jsonResult) => {
    const { data } = jsonResult;

    if (!data.totalBikeSlots && !data.totalCarSlots) {
      setSlotFlag(false);
    } else {
      let slotFlag;
      slotFlag = data.totalBikeSlots !== 0 || data.totalCarSlots !== 0;
      console.log("Dasdadadadadasdasdad", slotFlag);
      setSlotFlag(slotFlag);
      setBikeSlots(data.totalBikeSlots);
      setCarSlots(data.totalCarSlots);
    }
  };

  return (
    <div className="details">
      <div className="tariffdetails__wrapper">
        <div className="tariffdetails__wrapper__container">
          <div className="tariffdetails__wrapper__container__leftside">
            <div className="tariffdetails__wrapper__heading">
              <p className="tariffdetails__wrapper__heading__title">
                Slot Count and Tariff Details
              </p>
            </div>
            <div className="tariffdetails__wrapper__container__leftside_content">
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  className="vehicle_tab"
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    icon={
                      <img
                        alt="twowheeler"
                        src={[activeTab] == "twoWheeler" ? MotorA : TwoWheeler}
                      />
                    }
                    label="2 Wheeler"
                    {...a11yProps(0)}
                    onClick={() => updateActiveTab("twoWheeler")}
                  />
                  <Tab
                    icon={
                      <img
                        alt="fourwheeler"
                        src={[activeTab] == "fourWheeler" ? CarA : FourWheeler}
                      />
                    }
                    label="4 Wheeler"
                    {...a11yProps(1)}
                    onClick={() => updateActiveTab("fourWheeler")}
                  />
                  {/* <Tab
                    icon={
                      <img
                        alt="fleet"
                        src={[activeTab] == 'fleet' ? FleetA : Fleet}
                      />
                    }
                    label="Fleet"
                    {...a11yProps(2)}
                    onClick={() => updateActiveTab('fleet')}
                  /> */}
                </Tabs>
              </AppBar>

              <div className="tariffdetails__wrapper__container__leftside_twowheeler">
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  {tabs.map((tab, index) => {
                    return (
                      <TabPanel
                        key={tab}
                        value={value}
                        index={index}
                        dir={theme.direction}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={5} style={{ paddingTop: "0" }}>
                            <Grid>
                              <InputLabel
                                htmlFor="filled-adornment-amount"
                                style={{
                                  display: "flex",
                                }}
                              >
                                No of Slots
                                <Button
                                  aria-describedby={id}
                                  variant="outlined"
                                  color="primary"
                                  onClick={(event) => handleClickSlot(event)}
                                  style={{
                                    float: "right",
                                    border: "none",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <img
                                    src={Tip}
                                    style={{
                                      marginBottom: "-3px",
                                      marginTop: "-12%",
                                      marginLeft: "10px",
                                      width: "15px",
                                      display: "inline",
                                    }}
                                    alt=""
                                  />
                                </Button>
                                <Popover
                                  id={idSlot}
                                  open={openSlot}
                                  anchorEl={slotPolicy}
                                  onClose={handleCloseSlot}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "1rem",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Slot Count Info
                                      <br />
                                    </span>
                                    Select at least minimum one 1 slot for any
                                    vehicle type and Maximum 99999 <br />
                                    and should not be 0 (zero) for all vehicles
                                    2W,4W & Fleet
                                  </p>
                                </Popover>
                              </InputLabel>
                            </Grid>

                            <Grid container>
                              <button
                                className="decrese btn"
                                disabled={
                                  // (props.data['fourWheeler'].totalSlots!==0 || props.data['twoWheeler'].totalSlots!==0 )
                                  slotFlag ||
                                  props.data[activeTab].totalSlots <= 0
                                }
                                style={{ top: "2px" }}
                                onClick={() => {
                                  let data = {
                                    ...props.data[activeTab],
                                  };

                                  data.totalSlots = data.totalSlots - 1;
                                  updateParentState(
                                    activeTab,
                                    "totalSlots",
                                    data.totalSlots,
                                    props
                                  );
                                }}
                              >
                                <span>-</span>
                              </button>

                              <input
                                type="number"
                                disabled={slotFlag}
                                min="0"
                                className="tariffdetails__wrapper__container__leftside_twowheeler_numoflots"
                                placeholder="100"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                value={
                                  slotFlag
                                    ? activeTab === "twoWheeler"
                                      ? bikeSlots
                                      : activeTab === "fourWheeler"
                                      ? carSlots
                                      : props.data["fleet"].totalSlots
                                    : props.data[activeTab].totalSlots
                                }
                                onChange={(evt) => {
                                  let value;
                                  value = parseInt(evt.target.value)
                                    ? parseInt(evt.target.value)
                                    : 0;

                                  updateParentState(
                                    activeTab,
                                    "totalSlots",
                                    parseInt(evt.target.value),
                                    props
                                  );
                                }}
                                onWheel={(event) => event.currentTarget.blur()}

                                //  onChange={slotInputHandle}
                              />
                              <button
                                className="increse btn"
                                disabled={slotFlag}
                                style={{ top: "2px" }}
                                onClick={() => {
                                  let data = {
                                    ...props.data[activeTab],
                                  };
                                  data.totalSlots = data.totalSlots + 1;
                                  updateParentState(
                                    activeTab,
                                    "totalSlots",
                                    data.totalSlots,
                                    props
                                  );
                                }}
                              >
                                <span>+</span>
                              </button>
                            </Grid>

                            {props.search !== "" ? (
                              <p className="notedit">
                                <div className="error-block">
                                  <img src={error} alt="error" />
                                  <span>
                                    This Information change will be subjected to
                                    approval
                                  </span>
                                </div>
                              </p>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={7} style={{ paddingTop: "0" }}>
                            <InputLabel
                              htmlFor="filled-adornment-amount"
                              style={{
                                display: "flex",
                              }}
                            >
                              Tariff
                              <Button
                                aria-describedby={id}
                                variant="outlined"
                                color="primary"
                                onClick={(event) => handleClick(event)}
                                style={{
                                  float: "right",
                                  border: "none",
                                  paddingRight: "0px",
                                }}
                              >
                                <img
                                  src={Tip}
                                  style={{
                                    marginBottom: "-3px",
                                    marginTop: "-12%",
                                    marginLeft: "10px",
                                    width: "15px",
                                    display: "inline",
                                  }}
                                  alt=""
                                />
                              </Button>
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <p
                                  style={{ padding: "1rem", fontSize: "12px" }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Tariff Info
                                    <br />
                                  </span>
                                  Select Base price value as minimum 1 and
                                  maximum 9999 rupees. <br />
                                  Additional & Long term prices are optional
                                </p>
                              </Popover>
                            </InputLabel>

                            <span>Free</span>
                            <Switch
                              //value="checkedC"
                              checked={props.data[activeTab].paid}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              onClick={() => handleCheckRadio(activeTab, props)}
                              id="myCheckOne"
                            />
                            <span>Paid</span>

                            <div
                              id="bike-tariff"
                              className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif"
                              style={
                                props.data[activeTab].paid
                                  ? activeFieldStyle
                                  : styles
                              }
                            >
                              <InputLabel htmlFor="filled-adornment-amount">
                                Base Price
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_left">
                                  <select
                                    id="baseHour"
                                    value={props.data[activeTab].baseHour}
                                    onChange={(evt) => {
                                      updateParentState(
                                        activeTab,
                                        "baseHour",
                                        evt.target.value,
                                        props
                                      );
                                    }}
                                  >
                                    <option value="1">0-1 Hour</option>
                                    <option value="2">0-2 Hour</option>
                                    <option value="3">0-3 Hour</option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_right">
                                  {/* <span style=';'>&#8377;</span> */}
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    &#8377;
                                  </span>
                                  <input
                                    type="number"
                                    // min={1}
                                    // max={9999}
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                    value={props.data[activeTab].basePrice}
                                    //min="10.00" step="10.00" max="2500" value="20.00"
                                    placeholder="10.00"
                                    onChange={(evt) => {
                                      if (
                                        evt.target.value >= 0 &&
                                        evt.target.value <= 9999
                                      ) {
                                        updateParentState(
                                          activeTab,
                                          "basePrice",
                                          evt.target.value,
                                          // parseFloat(
                                          //   1 * evt.target.value,
                                          // ),
                                          props
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {props.data[activeTab].paid === true &&
                                (props.data[activeTab].basePrice <= 0 ||
                                  props.data[activeTab].basePrice === "") ? (
                                  <p className="errorClass">
                                    Base price should be more then 0
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <InputLabel htmlFor="filled-adornment-amount">
                                Additional Hour
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_left">
                                  <select
                                    id="additionalHour"
                                    value={props.data[activeTab].additionalHour}
                                    onChange={(evt) =>
                                      updateParentState(
                                        activeTab,
                                        "additionalHour",
                                        evt.target.value,
                                        props
                                      )
                                    }
                                  >
                                    <option value="1">+1 Hour</option>
                                    <option value="2">+2 Hour</option>
                                    <option value="3">+3 Hour</option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_right">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    &#8377;
                                  </span>
                                  <input
                                    type="number"
                                    // min={0}
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                    placeholder="10.00"
                                    value={
                                      props.data[activeTab].additionalPrice
                                    }
                                    onChange={(evt) => {
                                      if (
                                        evt.target.value >= 0 &&
                                        evt.target.value <= 9999
                                      ) {
                                        updateParentState(
                                          activeTab,
                                          "additionalPrice",
                                          evt.target.value,
                                          props
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div></div>
                              <InputLabel htmlFor="filled-adornment-amount">
                                Long term
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_left">
                                  <select
                                    id="longtermHour"
                                    style={{ paddingLeft: "5px" }}
                                    value={
                                      props.data[activeTab].selectedLongterm
                                    }
                                    onChange={(evt) => {
                                      updateParentState(
                                        activeTab,
                                        "selectedLongterm",
                                        evt.target.value,
                                        props
                                      );
                                    }}
                                  >
                                    <option value="pricePerDay">
                                      Price/Day
                                    </option>
                                    <option value="pricePerWeek">
                                      Price/Week
                                    </option>
                                    <option value="pricePerMonth">
                                      Price/Month
                                    </option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_right">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    &#8377;
                                  </span>
                                  {props.data[activeTab].selectedLongterm ===
                                    "pricePerDay" && (
                                    <input
                                      type="number"
                                      // min={0}
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      placeholder="10.00"
                                      value={props.data[activeTab].pricePerDay}
                                      onChange={(evt) => {
                                        if (
                                          evt.target.value >= 0 &&
                                          evt.target.value <= 9999
                                        ) {
                                          changeTerms(
                                            activeTab,
                                            "pricePerDay",
                                            evt.target.value,
                                            props
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  {props.data[activeTab].selectedLongterm ===
                                    "pricePerWeek" && (
                                    <input
                                      type="number"
                                      // min={0}
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      placeholder="10.00"
                                      value={props.data[activeTab].pricePerWeek}
                                      onChange={(evt) => {
                                        if (
                                          evt.target.value >= 0 &&
                                          evt.target.value <= 9999
                                        ) {
                                          changeTerms(
                                            activeTab,
                                            "pricePerWeek",
                                            evt.target.value,
                                            props
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  {props.data[activeTab].selectedLongterm ===
                                    "pricePerMonth" && (
                                    <input
                                      type="number"
                                      // min={0}
                                      placeholder="10.00"
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      value={
                                        props.data[activeTab].pricePerMonth
                                      }
                                      onChange={(evt) => {
                                        if (
                                          evt.target.value >= 0 &&
                                          evt.target.value <= 9999
                                        ) {
                                          changeTerms(
                                            activeTab,
                                            "pricePerMonth",
                                            evt.target.value,
                                            props
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </SwipeableViews>
              </div>
            </div>
          </div>
          <div className="tariffdetails__wrapper__container__rightside">
            <div className="tariffdetails__wrapper__heading">
              <p className="tariffdetails__wrapper__heading__title">
                Operational Timings
              </p>
            </div>
            <div className="tariffdetails__wrapper__container__rightside__heading">
              <p>
                <b>Standard Operating Hours </b>
                <p style={{ fontSize: "0.5rem" }}>
                  (Select time duration more than 30min)
                </p>
              </p>
              <div>
                {props.data.timings.FRIDAY.isChecked ||
                props.data.timings.SATURDAY.isChecked ||
                props.data.timings.SUNDAY.isChecked ||
                props.data.timings.MONDAY.isChecked ||
                props.data.timings.TUESDAY.isChecked ||
                props.data.timings.WEDNESDAY.isChecked ||
                props.data.timings.THURSDAY.isChecked ? (
                  ""
                ) : (
                  <p className="errorClass">
                    Please select atleast one Operating Hours
                  </p>
                )}
              </div>
            </div>
            <OprationalTimmings
              data={props.data}
              updateRootState={props.updateRootState}
              updateChildrenState={props.updateChildrenState}
              updateParentState={updateParentState}
            />
            <div className="customRule">
              <p
                className="tariffdetails__wrapper__heading__title"
                style={{ fontSize: "0.95rem" }}
              >
                Custom Availability
              </p>
              <Button
                variant="contained"
                color="#E46A76"
                onClick={showCustomRule}
                style={{ fontSize: "0.75rem" }}
              >
                Add Custom Rule
              </Button>
            </div>
            {showTheDiv > 0 && (
              <CustomAvailability
                data={props.data}
                count={showTheDiv}
                updateRootState={props.updateRootState}
                updateChildrenState={props.updateChildrenState}
                updateParentState={updateParentState}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddParkingStepTwo;
