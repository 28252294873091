// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect } from "react";
import "./Pages/Login/LoginDashboard/Login.scss";
import Routes from "./Routes/Routes";
import { BrowserRouter, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toaster from "../src/Components/Toster";
import Popup from "../src/Components/PopUp";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import {
  updateToasterConfig,
  updateShowModal,
} from "./redux/modules/app/actions";
import { getCookie } from "./Utils/cookies";
import FooterNew from "./Components/Footer/FooterNew";

//App
const App = (props) => {
  // tab close detection logic
  //   let pageReloaded = window.performance
  //   .getEntriesByType('navigation')
  //   .map(function (nav) { return nav.type; })
  //   .includes('reload');

  // //if needed to clear tokens after all the tabs from same domain is closed
  // let tabCount = parseInt(localStorage.getItem("windowCount"));
  // tabCount = Number.isNaN(tabCount) ? 1 : ++tabCount;
  // localStorage.setItem('tabCount', tabCount.toString());

  useEffect(() => {}, []);

  return (
    <>
      <BrowserRouter>
        <Routes></Routes>
        {props.showToaster && <Toaster></Toaster>}
        {props.showPopup && <Popup></Popup>}
      </BrowserRouter>
      {props.location.pathname !== "/dataprivacynotice" ? (
        <div className="footer-bottom">
          {(getCookie("userId") === "cade221a-899a-48d2-bec2-ae48b87cfb8b" ||
            getCookie("userId") === "f570f213-db63-4a7b-af37-b5ad7a85ceaa") &&
          getCookie("userId") !== "" ? (
            <FooterNew />
          ) : (
            <Footer />
          )}
        </div>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateShowModal,
  updateToasterConfig,
  dispatch,
});
//function redux connect
export default withRouter(
  connect(
    (state) => ({
      showToaster: state.app.toasterConfig.show,
      showPopup: state.app.showModal,
    }),
    mapDispatchToProps
  )(App)
);
