// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import { makeStyles } from '@material-ui/core/styles'

const editUseStyles = makeStyles(() => ({

  addButton: {
    fontFamily: '\'Poppins\' !important',
    margin: '12px',
    borderRadius: '90px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    minWidth: '64px',
    boxSizing: 'border-box',
    lineHeight: '1.75',
    letterSpacing: '1px',
    padding: '9px 27px',
    textDecoration: 'none',
    verticalAlign: 'middle',
    backgroundColor: '#03A9F3',
    float: 'right',
    marginRight: '22px'


  },
  root: {
    '& .Mui-selected': {
      backgroundColor: '#e46a76 !important',
      color: '#ffffff',
      fontWeight:'900',
     },
     '& .MuiPaginationItem-root' : {
     fontFamily: '\'Poppins\' !important',
     fontSize: '14px',
     },
    },
  paper: {
    boxShadow: '0px 3px 6px #00000029 !important',
  },
  thData: {
    height: '70px',
    fontSize: '12px',
    wordWrap: 'break-word',
    fontFamily: '\'Poppins\' !important',
    fontWeight: 'bolder',
    lineHeight: '1.6',
    letterSpacing: '0.5px',
    backgroundColor: 'rgba(226, 220, 242, 0.38)'
  },
  tableCell: {
    fontSize: '12px',
    wordBreak: 'break-word',
    fontFamily: '\'Poppins\' !important',
    fontWeight: '500',
    lineHeight: '1.6'
  },
 
  tableGrid: {
    marginTop: "6%",
    width: "96%",
    marginLeft: "2%",
    display: 'flex',
    flexWrap: 'nowrap',
  },
 
  
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    width: "100%",
    maxHeight: '64vh'
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bolder",
  },

  
 
  tableCellNoSelectedData: {
    fontFamily: '\'Poppins\' !important'
    ,
    fontWeight: '500',
    fontSize: '10px'
  },
  tableCellNoResponseData: {
    fontFamily: '\'Poppins\' !important'
    ,
    fontWeight: '500',
    fontSize: '10px'
  },
  noDataTableBody: {

    marginBottom: "50px"
  },
  noList: {
    width: '190%',
    fontSize: '12px',
    marginTop: '80%',
    fontFamily: '\'Poppins\'',
    marginLeft: '400%',
    wordBreak: 'break-word',
    textAlign: 'center',
    fontWeight: '500'
  },
  noResponseData: {
    fontFamily: "Poppins",
    fontSize: "11px",
    marginTop: "110%",
    marginLeft: "300%",
    width: "370%"
  },
  delete_icon: {
    height: '13px !important',
    fontSize: '3px !important',
    marginLeft: '15%'
  },
  edit_icon: {
    height: '13px !important',
    fontSize: '3px !important',
   
  },
  paginationOuterGrid: {
    marginBottom: "6%",
    marginTop: "1.5%"

  },
  paginationGrid: {

    marginRight: "2%"
  },
  paginationStyle: {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '90px',
    paddingLeft: '15px',
    boxShadow: '0px 3px 6px #00000029 !important',
  },

  dialogCancelGrid: {
    textAlignLast: "right"
  },
  dialogConfirm: {
    fontFamily: '\'Poppins\' !important',
    borderRadius: '90px',
    cursor: 'pointer',
    border: "none",
    fontSize: "10px",
    fontWeight: "900",
    color: "#fff",
    backgroundColor: "#03A9F3",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    minWidth: "64px",
    boxSizing: "border-box",
    lineHeight: "1.75",
    letterSpacing: "1px",
    padding: "9px 27px",
    textDecoration: "none",
    verticalAlign: "middle",
    marginRight: "20px",
  },
  dialogCancel: {
    fontFamily: '\'Poppins\' !important',
    borderRadius: '90px',
    cursor: 'pointer',
    border: "none",
    fontSize: "10px",
    fontWeight: "900",
    color: "#fff",
    backgroundColor: "#707070",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    minWidth: "64px",
    boxSizing: "border-box",
    lineHeight: "1.75",
    letterSpacing: "1px",
    padding: "9px 27px",
    textDecoration: "none",
    verticalAlign: "middle",
    
  },

  dialogSubHeader: {
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '0.5px',
    fontWeight: 'bold'
  },
  dialogSubTitle: {
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '1px'
  },
  dialogSubText: {
    fontWeight: 'bold',
    fontFamily: '\'Poppins\' !important',
    fontSize: '11px',
    letterSpacing: '1px'
  },
  dialogCloseIcon: {
    cursor: "pointer",
    fontSize: "12px"
  },
  dialogHeader: {
    fontFamily: '\'Poppins\' !important',
    fontSize: "14px",
    letterSpacing: "0.5px",
    fontWeight: 900,

  },
  dialog: {
    zIndex: 0
  },
  dialogActionGrid: {
    marginTop: "0.5em",
    paddingBottom: '0',
  },
  gridContainerNoImage: {
    position: "absolute", paddingBottom: "25%", marginBottom: "50px"
  },
  tableRowNoImage: {
    height: "50%",
  },

  typographyGridNoImage: {
    marginRight: "30%",
   
    
  },
  viewTableCell: {
    cursor: 'pointer',
    textAlign: 'left',
    width: '1px',
    height: '1px'
  },
  

}));

export default editUseStyles;