// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateBreadcrumbs, updateToasterConfig } from '../../../redux/modules/app/actions';
import {
    Paper,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Divider,
    Checkbox,
} from "@material-ui/core";
import editUseStyles from "./style";
import httpRequest from '../../../Utils/httpRequest';
import { getCookie } from '../../../Utils/cookies';
import Loader from '../../../Components/Preloader/index';



//@component
//View
function AddAlertNotifications(props) {
    const SMS = "sms";
    const EMAIL = "email"
    const [contactNo1, setContactNo1] = useState('');
    const [contactNo2, setContactNo2] = useState('');
    const [contactNo3, setContactNo3] = useState('');
    const [emailId1, setEmailId1] = useState('');
    const [emailId2, setEmailId2] = useState('');
    const [emailId3, setEmailId3] = useState('');

    const [parkingName, setParkingName] = useState('0');

    const [IsdialogOpen, setIsdialogOpen] = useState(false);
    const [parkingSpaceList, setParkingSpaceList] = useState([]);
    const [parkingId, setParkingId] = useState('');

    const [invalidNo1, setInvalidNo1] = useState('');
    const [invalidNo2, setInvalidNo2] = useState('');
    const [invalidNo3, setInvalidNo3] = useState('');
    const [emailId1Error, setEmailId1Error] = useState('');
    const [emailId2Error, setEmailId2Error] = useState('');
    const [emailId3Error, setEmailId3Error] = useState('');

    const [alertMode, setAlertMode] = useState('');
    const [isSmsMode, setSmsMode] = useState(false);
    const [isEmailMode, setEmailMode] = useState(false);

    const [contactNumbers, setContactNumbers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);





    useEffect(() => {
        props.dispatch(updateBreadcrumbs("addAlertNotifications"))
        setIsLoading(true)
        populateParkingDetails()

    }, []);

    const populateParkingDetails = () => {
        httpRequest.get(
            'getParkingWithoutContacts',
            {},
            parkingListSuccess,
            errorCallback,
            {
                userId: getCookie('userId'),
            },
        );

    }

    const parkingListSuccess = (jsonResponse) => { 
        setParkingSpaceList(jsonResponse.data)
        setIsLoading(false)

    }

    const displayErrorAndRedirect=(isLoading, props) =>{
         !isLoading && props.dispatch(updateToasterConfig({
            show: true,
            message: 'No Device connected Parking found or Contacts have already been Added',
            color: '#ff0000'
         }))
            
    }
    



    const errorCallback = (error) => {
        setIsLoading(false)
        props.dispatch(updateToasterConfig({
            show: true,
            message: error.error,
            color: 'red',
        }
        ));

    }

    const parkingHandle = (event) => {
    
        if (event.target.value !== '0') {
            let parking = parkingSpaceList.filter(item => item.parkingId === event.target.value)
            console.log("dasdadad",parking)
            setParkingName(parking[0].parkingName)
            setParkingId(parking[0].parkingId)

        } else {
            setParkingName('0')
            setParkingId('0')
        }

    }

    const contactNo1Handle = (event) => {
        if (!event.target.value) {
            setInvalidNo1("");
            setContactNo1("");
        }

        else if (event.target.value?.length === 10) {
            let number = event.target.value
            setContactNo1(number);
            setInvalidNo1('')
        }
        else {
            setInvalidNo1('Enter 10 numbers only')
            setContactNo1(event.target.value)

        }

    }

    const contactNo2Handle = (event) => {
        if (!event.target.value) {
            setInvalidNo2("");
            setContactNo2("");
        }
        else if (event.target.value?.length === 10) {
            let number = event.target.value
            setContactNo2(number);
            setInvalidNo2('')
        }
        else {

            setInvalidNo2('Enter 10 numbers only')
            setContactNo2(event.target.value)

        }

    }


    const contactNo3Handle = (event) => {
        if (!event.target.value) {
            setInvalidNo3("");
            setContactNo3("");
        }
        else if (event.target.value?.length === 10) {
            let number = event.target.value
            setContactNo3(number);
            setInvalidNo3('')
        }
        else {
            setInvalidNo3('Enter 10 numbers only.')
            setContactNo3(event.target.value)

        }

    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase());
    }

    const maxNumberLengthCheck = (event) => {
        if (event.target.value.length > 10) {
            event.target.value = event.target.value.slice(0, 10)
        }
    }


    const email1Handler = (event) => {
        if (event.target.value.length > 50) {
            setEmailId1Error("Max.50 characters");
        }
        if (event.target.value.length <= 50) {
            if (validateEmail(event.target.value)) {
                setEmailId1Error("");
                let email = event.target.value;
                setEmailId1(email);

            }
            if (!validateEmail(event.target.value)) {
                setEmailId1(event.target.value);
                setEmailId1Error("Enter valid email");
            }
            if (!event.target.value) {
                setEmailId1Error("");
                setEmailId1("")

            }
        }
    };

    const email2Handler = (event) => {
        if (event.target.value.length > 50) {
            setEmailId2Error("Max.50 characters");
        }
        if (event.target.value.length <= 50) {
            if (validateEmail(event.target.value)) {
                setEmailId2Error("");
                let email = event.target.value;
                setEmailId2(email);

            }
            if (!validateEmail(event.target.value)) {
                setEmailId2(event.target.value);
                setEmailId2Error("Enter valid email");
            }
            if (!event.target.value) {
                setEmailId2Error("");
                setEmailId2("")

            }
        }
    };

    const email3Handler = (event) => {
        if (event.target.value.length > 50) {
            setEmailId3Error("Max.50 characters");
        }
        if (event.target.value.length <= 50) {
            if (validateEmail(event.target.value)) {
                setEmailId3Error("");
                let email = event.target.value;
                setEmailId3(email);

            }
            if (!validateEmail(event.target.value)) {
                setEmailId3(event.target.value);
                setEmailId3Error("Enter valid email");
            }

            if (!event.target.value) {
                setEmailId3Error("");
                setEmailId3("")

            }
        }
    };

    const alertModeHandler = (mode) => {



        if (mode === SMS) {
            if (isSmsMode === true) {
                isEmailMode ? setAlertMode(SMS) : setAlertMode('')
                setSmsMode(false)
                resetContactNo()
            } else {
                setSmsMode(true)
                setAlertMode(mode)
            }
            if (isEmailMode === false) {
                resetEmails()
            }
        } else if (mode === EMAIL) {
            if (isEmailMode === true) {

                isSmsMode ? setAlertMode(SMS) : setAlertMode('')
                setEmailMode(false)
                resetEmails()
            } else {
                setEmailMode(true)
                setAlertMode(mode)
            }
            if (isSmsMode === false) {
                resetContactNo()
            }
        }
    }

    const resetEmails = () => {
        setEmails([])
        setEmailId1('')
        setEmailId2('')
        setEmailId3('')
        setEmailId1Error("")
        setEmailId2Error("")
        setEmailId3Error("")

    }

    const resetContactNo = () => {
        setContactNumbers([])
        setContactNo1('')
        setContactNo2('')
        setContactNo3('')
        setInvalidNo1("")
        setInvalidNo2("")
        setInvalidNo3("")

    }


    const submitAlertNotificationDetails = () => {
        if (isSmsMode) {
            populateContactNumbers();
        }
        if (isEmailMode) {

            populateEmails();
        }

        setIsdialogOpen(true);

    }


    const classes = editUseStyles()

    const handleClose = () => {
        setIsdialogOpen(false);
        setIsLoading(false)
        setContactNumbers([]);
        setEmails([]);

    };

    const submitDetails = (e) => {
        setIsLoading(true)
        setIsdialogOpen(false)
        console.log(e)
        postAlertNotifications()

    }
    const populateContactNumbers = () => {

        const contacts = [];
        if (contactNo1?.length === 10 && !contacts.includes(contactNo1)) {
            contacts.push(contactNo1);
        }
        if (contactNo2?.length === 10 && !contacts.includes(contactNo2)) {
            contacts.push(contactNo2);
        }
        if (contactNo3?.length === 10 && !contacts.includes(contactNo3)) {
            contacts.push(contactNo3);
        }
        setContactNumbers(contacts)


    }

    const populateEmails = () => {
        const emailIds = [];
        if (emailId1?.length > 5 && !emailIds.includes(emailId1)) {
            emailIds.push(emailId1);
        }
        if (emailId2?.length > 5 && !emailIds.includes(emailId2)) {
            emailIds.push(emailId2);
        }
        if (emailId3?.length > 5 && !emailIds.includes(emailId3)) {
            emailIds.push(emailId3);
        }
        setEmails(emailIds)
    }


    const postAlertNotifications = () => {
        httpRequest.post(
            'saveAlertNotificationDetails',
            {
                "parkingId": parkingId,
                "emails": emails,
                "mobileNumber": contactNumbers,
                "sms": isSmsMode,
                "email": isEmailMode

            },
            {
                'Content-Type': 'application/json',
            },
            successCallbackSave,
            errorCallbackSave,
            {


            },
        )
    }

    const successCallbackSave = () => {
        setIsLoading(false)
        setIsdialogOpen(false);
        props.history.push("/portal/alertNotifications")
        props.dispatch(updateToasterConfig({
            show: true,
            message: 'Alert Notification added succesfully.',
            color: 'green'
        }));
        resetEmails()
        resetContactNo()

    }

    const errorCallbackSave = (error) => {
        setIsLoading(false)
        setIsdialogOpen(false);
        props.dispatch(updateToasterConfig({
            show: true,
            message: error.error === 'Not Found' ? 'API Not Found' : error.message,
            color: 'red',
        }
        ));
    }





    return (
        <>
            {
                isLoading && <Loader />}

            <div className="exit-event-root ">
                <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={5} style={{
                        width: '96%',
                        margin: '2%',
                        marginBottom: '10%',

                    }}>
                        <Grid item md={12} className={classes.headerText}>
                            <label>Add Alert Notification</label>
                        </Grid>

                        <Grid container direction="row" >

                            <Grid item xs={12} sm={6} md={6} lg={6} >

                                <Grid container direction="column" className={classes.inputArea} style={{ marginLeft: '10%' }}>
                                    <label className={classes.label} >Parking Space Details*</label>
                                    <select onChange={parkingHandle} required className={classes.textFieldSelect} id="sel-val">
                                        <option value='0'>Select Parking</option>
                                        { parkingSpaceList.length !== 0 ? (parkingSpaceList.map((option, index) => {
                                            return (
                                                <option key={index} value={option.parkingId}>{option.parkingName}</option>

                                            )
                                        })) : displayErrorAndRedirect(isLoading, props)}

                                    </select>
                                </Grid>

                                <Grid container direction="row" className={classes.contactBlock}>
                                    <Grid container direction="row" style={{ marginBottom: '1%' }}>

                                        <div >
                                            <Checkbox
                                                color='default'
                                                size="small"
                                                type="checkbox"
                                                data-target="filter"
                                                onChange={() => alertModeHandler(SMS)}



                                            />
                                            <label className={classes.label}>SMS</label>
                                        </div>


                                        <div>
                                            <Checkbox
                                                color='default'
                                                size="small"
                                                type="checkbox"
                                                data-target="filter"
                                                style={{ display: "inline-block" }}
                                                onChange={() => alertModeHandler(EMAIL)}

                                            />
                                            <label className={classes.label}>Emails</label>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} >
                                        <Grid container direction="column" className={classes.inputArea}>
                                            <input
                                                onInput={maxNumberLengthCheck}
                                                required
                                                className={classes.textField}
                                                id='contactNo1'
                                                type="number"
                                                name="contactNo1"
                                                value={contactNo1}
                                                disabled={isSmsMode === false}
                                                placeholder="Enter Contact Number"
                                                width={10}
                                                onChange={contactNo1Handle}
                                                onWheel={event =>
                                                    event.currentTarget.blur()
                                                  }
                                            />
                                            <Grid item className={classes.validationBlock}>{invalidNo1}</Grid>
                                            <input
                                                
                                                required
                                                onInput={maxNumberLengthCheck}
                                                className={classes.textField}
                                                id='contactNo2'
                                                type="number"
                                                name="contactNo2"
                                                value={contactNo2}
                                                disabled={isSmsMode === false}
                                                placeholder="Enter Contact Number"
                                                onChange={contactNo2Handle}
                                                onWheel={event =>
                                                    event.currentTarget.blur()
                                                  }
                                            />
                                             <Grid item className={classes.validationBlock}>{invalidNo2}</Grid>
                                            <input
                                                
                                                required
                                                onInput={maxNumberLengthCheck}
                                                className={classes.textField}
                                                id='contactNo3'
                                                type="number"
                                                name="contactNo3"
                                                value={contactNo3}
                                                disabled={isSmsMode === false}
                                                placeholder="Enter Contact Number"
                                                onChange={contactNo3Handle}
                                                onWheel={event =>
                                                    event.currentTarget.blur()
                                                  }
                                            />
                                             <Grid item className={classes.validationBlock}>{invalidNo3}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} >
                                        <Grid container direction="column" className={classes.inputArea}>

                                            <input
                                                
                                                required
                                                className={classes.textField}
                                                id='emailId1'
                                                type="text"
                                                name="emailId1"
                                                value={emailId1}
                                                disabled={isEmailMode === false}
                                                placeholder="Enter Email Id"
                                                onChange={email1Handler}
                                            />
                                             <Grid item className={classes.validationBlock}>{emailId1Error}</Grid>
                                            <input
                                                
                                                required
                                                className={classes.textField}
                                                id='emailId2'
                                                type="text"
                                                name="emailId2"
                                                value={emailId2}
                                                placeholder="Enter Email Id"
                                                disabled={isEmailMode === false}
                                                onChange={email2Handler}
                                            />
                                            <Grid item className={classes.validationBlock}>{emailId2Error}</Grid>

                                            <input
                                                
                                                required
                                                className={classes.textField}
                                                id='emailId3'
                                                type="text"
                                                name="emailId3"
                                                value={emailId3}
                                                disabled={isEmailMode === false}
                                                placeholder="Enter Email Id"
                                                onChange={email3Handler}
                                            />
                                             <Grid item className={classes.validationBlock}>{emailId3Error}</Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container direction="row" className={classes.inputActionArea}>
                                <Button
                                    variant="contained"
                                    className={classes.updateButton}
                                    onClick={submitAlertNotificationDetails}
                                    disabled={
                                        parkingName === '0' ||
                                        parkingName?.length < 1 ||
                                        alertMode?.length === 0 ||
                                        invalidNo1?.length > 0 ||
                                        invalidNo2?.length > 0 ||
                                        invalidNo3?.length > 0 ||
                                        emailId1Error?.length > 0 ||
                                        emailId2Error?.length > 0 ||
                                        emailId3Error?.length > 0 ||
                                        ((isSmsMode === true && isEmailMode === false) ? (contactNo1?.length !== 10 && contactNo2?.length !== 10 && contactNo3?.length !== 10) : (emailId1?.length < 5 && emailId2.length < 5 && emailId3.length < 5)) ||
                                        ((isSmsMode === false && isEmailMode === true) ? (emailId1?.length < 5 && emailId2.length < 5 && emailId3.length < 5) : (contactNo1?.length !== 10 && contactNo2?.length !== 10 && contactNo3?.length !== 10)) ||
                                        ((isSmsMode === true && isEmailMode === true) ? (emailId1?.length < 5 && emailId2.length < 5 && emailId3.length < 5 && contactNo1?.length !== 10 && contactNo2?.length !== 10 && contactNo3?.length !== 10) : false)


                                    }
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        props.history.push({
                                            pathname: "/portal/alertNotifications",

                                        });
                                    }}
                                    className={classes.cancelButton}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Dialog

                                open={IsdialogOpen}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        padding: "1.5% 3%",
                                    },
                                }}
                                className={classes.dialog}
                            >
                                <DialogTitle style={{ paddingBottom: "8px", paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px" }}>

                                    <Grid container direction="row" justify="space-between">
                                        <Grid item>
                                            <span className={classes.dialogHeader}>Submit Alert Notifications Contact Details</span>
                                        </Grid>

                                    </Grid>

                                </DialogTitle>
                                <Divider />
                                <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Grid item>
                                        <Grid container direction="column" justify="center">
                                            <Grid item>
                                                <Typography className={classes.dialogSubHeader}>Are you sure you want to submit alert notification contact details?  <span style={{ fontWeight: 'bold' }}>{ } </span></Typography>
                                            </Grid >


                                            <Grid item container direction='row' xs={12} style={{ marginTop: '1%' }}>

                                                <Grid item xs={4} className={classes.dialogSubHeader}>Parking Space Name:</Grid>

                                                <Grid item xs={8} className={classes.dialogSubTitle}>{parkingName}
                                                </Grid>
                                            </Grid>
                                            {isSmsMode ?
                                                <Grid item container direction='row' xs={12} style={{ marginTop: '1%' }}>

                                                    <Grid item xs={4} className={classes.dialogSubHeader}>Contact Numbers:</Grid>

                                                    <Grid item container xs={8} className={classes.dialogSubTitle}>
                                                        {contactNumbers.map(item => {
                                                            return (<>{item}<br /></>)
                                                        })}</Grid>

                                                </Grid> : <p></p>}
                                            {isEmailMode ?
                                                <Grid item container direction='row' xs={12} style={{ marginTop: '1%' }}>

                                                    <Grid item xs={4} className={classes.dialogSubHeader}>Emails:</Grid>

                                                    <Grid item container xs={8} className={classes.dialogSubTitle}>
                                                        {emails.map(item => {
                                                            return (<>{item}<br /></>)
                                                        })}</Grid>

                                                </Grid> : <p></p>}


                                        </Grid>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Grid item>
                                        <Grid container
                                            direction="row"
                                            justify="flex-end"
                                            className={classes.dialogActionGrid}>
                                            <Grid item className={classes.dialogCancelGrid}>
                                                <button type="button" onClick={handleClose} className={classes.dialogCancel}>
                                                    Cancel
                                                </button>
                                            </Grid>
                                            <Grid item className={classes.dialogConfirmGrid}>
                                                <button type="button" className={classes.dialogConfirm} onClick={submitDetails}>
                                                    Confirm
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>

                        </Grid>
                    </Paper>
                </Grid>
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs,
    updateToasterConfig,
    dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)((AddAlertNotifications)));

