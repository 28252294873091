// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../../redux/modules/app/actions";
import listviewUseStyles from "./style";
import Loader from "../../../Components/Preloader/index";
import httpRequest from "../../../Utils/httpRequest";
import { getCookie } from "../../../Utils/cookies";
import { securityListHeaderCell } from "../../../Data/report-page";
import Pagination from "@material-ui/lab/Pagination";
import deleteIcon from "../../../Assets/icons/deleteIcon.png";
import { withStyles } from "@material-ui/core";


//@component
//SecurityListView
function SecurityListView(props) {
  const classes = listviewUseStyles();

  const [viewList, setViewList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("datetime");
  const [pageNumber, setPagenumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [internetFlag, setInternetFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogEmail, setDialogEmail] = useState("");
  const [dialogRole, setDialogRole] = useState("");

  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //mui custom styling
  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
    },
  }))(MuiDialogActions);

  // while component is loading this api is called
  const getSecurityList = () => {
    httpRequest.get(
      "securityListView",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        pageNumber: pageNumber,
      }
    );
  };
  // success callback for get list
  const successCallbackList = (jsonResponse) => {
    console.log(jsonResponse);
    setIsLoading(false);
    if (!jsonResponse) {
      setTableDataNotFound("No data here");
      setResponseDataNotFound("No data here");
      setInternetFlag(true);
      return;
    }
    if (jsonResponse.data._embedded === undefined) {
      setTableDataNotFound("No data here");
      setResponseDataNotFound("No data here");
      setInternetFlag(true);
      return;
    }

    setTableDataNotFound("");
    setResponseDataNotFound("");
    setPagenumber(jsonResponse.data.page.number);
    setViewList(jsonResponse.data._embedded.results);
    setTotalPages(jsonResponse.data.page.totalPages);
    setTotalElements(jsonResponse.data.page.totalElements);
    setInternetFlag(false);

    // getUserDetails(jsonResponse?.data?._embedded?.results)
  };
  // const getUserDetails =  (data) => {

  //   data.map((data) => {
  //       httpRequest.get(
  //         'userProfile', {},
  //         userDataSuccess,
  //         ()=>{},
  //         {
  //           userId: data.parentId
  //         }
  //     )
  //     }
  //     )
  // // })

  // }
  //   const userDataSuccess = () => {

  //     // console.log("dasdasdadadasd",jsonResponse?.data.corpEmailId)
  //     // console.log("first",createdEmail)

  //  //createdEmail.push('key':'value')
  //  setCreatedByObj(createdEmail);

  //   }
  // error callback for get list
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };

  // callback api call when pagination button is clicked
  const pageHandler = (number) => {
    let pageNumber = number || 0;
    setPagenumber(number);
    httpRequest.get(
      "securityListView",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        pageNumber: pageNumber,
      }
    );
  };
  // when pagination button is clicked here page number is set and api callback is triggered
  const handleChange = (event, value) => {
    let currpage;
    currpage = value - 1;
    pageHandler(currpage);
  };
  // when component loading it updates breadcrumb and get list callback
  useEffect(() => {
    setIsLoading(true);
    props.dispatch(updateBreadcrumbs("securitylistview"));
    props.dispatch(updateSidebar(2));
    props.dispatch(updateBubble(3));
    getSecurityList();
  }, []);

  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };
    return (
      <TableHead>
        <TableRow>
          {securityListHeaderCell.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.id === "sno" ? "center" : "left"}
              width={headCell.width}
              className={classes.thData}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // on clicking create user button it redirects to create page
  const createHandler = () => {
    props.history.push({
      pathname: "/portal/securitycreate",
    });
  };

  // when we click on confirm button in delete popup
  const submitDetails = () => {
    httpRequest.deleteData(
      "deleteSecurity",
      {
        userIds: [deleteId],
        adminRemarks: "delete this user",
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackDelete,
      errorCallbackDelete,
      {}
    );
    setDialogOpen(false);
  };
  // user delete success callback
  const successCallbackDelete = (jsonResponse) => {
    if (jsonResponse) {
      setViewList([]);
      getSecurityList();
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `User deleted successfully`,
          color: "green",
        })
      );
    }
  };
  // user delete error callback
  const errorCallbackDelete = (error) => {
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };
  // when we click on delete icon
  const deleteHandler = (id, name, email, role) => {
    setDeleteId(id);
    setDialogOpen(true);
    setDialogTitle(name);
    setDialogEmail(email);
    setDialogRole(role);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ClickAwayListener>
        <Grid container direction="column" className={classes.container}>
          <ClickAwayListener>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.header}
            >
              <Grid item xs={10} sm={10} md={10} lg={10}></Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <button
                  type="button"
                  className={classes.createuserButton}
                  onClick={createHandler}
                >
                  Create User +
                </button>
              </Grid>
            </Grid>
          </ClickAwayListener>

          {/* // <Grid item className={classes.notFoundGrid}>
            //   <Grid
            //     container
            //     direction="column"
            //     justify="center"
            //     alignItems="center"
            //   >
            //     <Grid item>
            //       <Typography variant="body1">
            //         {responseDataNotFound}
            //         {tableDataNotFound}
            //       </Typography>
            //     </Grid>
            //   </Grid>
            // </Grid> */}

          <React.Fragment>
            <Grid item className={classes.aboveTableGrid}>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer className={classes.paper} component={Paper}>
                  <Table className={classes.table} aria-label="View table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {viewList.length !== 0 && internetFlag === false ? (
                        viewList.map((row, index) => {
                          let no = index;
                          function formatNo(no) {
                            return no < 10
                              ? pageNumber + "" + no
                              : (pageNumber + 1) * no;
                          }

                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                {formatNo(no + 1)}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.designation ? row?.designation : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.parking.length !== 0
                                  ? row.parking?.map((data) => {
                                      return data
                                        ? data.name
                                          ? data.name
                                          : "--"
                                        : "--";
                                    })
                                  : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.corpEmailId ? row?.corpEmailId : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {row?.role?.name ? row?.role?.name : "--"}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {/* {getCookie('role')==='MULTITENANT_ADMIN'?(getCookie('userId')===row?.parentId?'Multi-Tenant Admin':'Facility Admin'):(getCookie('role')==='CLOSED_GROUP_PARKING_PROVIDER' && getCookie('userId')===row?.parentId)?'Single-Tenant Admin':'Facilty Admin'} */}
                                <grid>
                                  {(row?.parentDto?.firstName
                                    ? row?.parentDto?.firstName
                                    : "") +
                                    " " +
                                    (row?.parentDto?.lastName
                                      ? row?.parentDto?.lastName
                                      : " ")}
                                </grid>
                                <br />
                                <grid>
                                  {row?.parentDto?.corpEmailId
                                    ? row?.parentDto?.corpEmailId
                                    : "--"}
                                </grid>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                              >
                                {moment(
                                  row?.createdDate ? row?.createdDate : "--",
                                  "x"
                                ).format("DD MMMM YYYY") || ""}
                              </TableCell>
                              <TableCell className={classes.viewTableCell}>
                                {getCookie("userId") === row?.parentId && (
                                  <img
                                    alt="delete_icon"
                                    src={deleteIcon}
                                    className={classes.delete_icon}
                                    onClick={() =>
                                      deleteHandler(
                                        row?.userId,
                                        row?.firstName,
                                        row?.corpEmailId,
                                        row?.role?.name
                                      )
                                    }
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow className={classes.tableRowNoImage}>
                          <TableCell>
                            <Grid
                              container
                              direction="column"
                              className={classes.gridContainerNoImage}
                              alignItems="center"
                              style={{
                                width: viewList.length === 0 ? "80vw" : "",
                              }}
                            >
                              <Grid
                                item
                                className={classes.typographyGridNoImage}
                              >
                                <Typography variant="body1">
                                  {tableDataNotFound}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {responseDataNotFound.length === 0 && (
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              >
                <Grid item className={classes.paginationGrid}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.paginationStyle}
                  >
                    <Grid item>
                      <Pagination
                        count={totalPages}
                        className={classes.root}
                        page={pageNumber + 1}
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
          {/* <TableRow className={classes.tableRowNoImage}>
            <TableCell>
              <Grid
                container
                direction="column"
                className={classes.gridContainerNoImage}
                alignItems="center"
              >
                <Grid item className={classes.typographyGridNoImage}>
                  <Typography variant="body1" style={{ height: "50px" }}>
                    
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow> */}

          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
            PaperProps={{
              style: {
                padding: "1.5% 3%",
              },
            }}
            className={classes.dialog}
          >
            <MuiDialogTitle
              style={{
                paddingBottom: "8px",
                paddingTop: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>
                    Delete User Details
                  </span>
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Divider />
            <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Grid item>
                <Grid container direction="column" justify="center">
                  <Grid item>
                    <Typography className={classes.dialogSubHeader}>
                      Are you sure you want to delete the selected user details?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Title:</span>{" "}
                      <span className={classes.dialogSubText}>
                        {dialogTitle}
                      </span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Email Id:</span>{" "}
                      <span className={classes.dialogSubText}>
                        {dialogEmail}
                      </span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Role:</span>{" "}
                      <span className={classes.dialogSubText}>
                        {dialogRole}
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button
                      type="button"
                      onClick={() => setDialogOpen(false)}
                      className={classes.dialogCancel}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item className={classes.dialogConfirmGrid}>
                    <button
                      type="button"
                      className={classes.dialogConfirm}
                      onClick={submitDetails}
                    >
                      Confirm
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </ClickAwayListener>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(SecurityListView));
