/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
//import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FileViewer from "../pdf/FileViewer";
import ossDoc from "../../Assets/pdf/BGSW_EAA_PZ_Multitenancy_AdminPortal_Delta4_3812_OSS_Scan_Report_2023_12_08.pdf";
import "./style.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// const DialogContent = withStyles(() => ({
//   root: {
//     // padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

export default function HelpSupportDialog(props) {
  const displayPage = props.displayPage;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {displayPage === "footer" ? (
        <a
          style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          OSS attribution
        </a>
      ) : displayPage === "footerTM" ? (
        <a
          style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          OSS attribution <span>|</span>
        </a>
      ) : displayPage === "ossDoc" ? (
        <a
          onClick={handleClickOpen}
          className="underline_hover"
          style={{ color: "blue", textDecoration: "none", cursor: "pointer" }}
        >
          OSS attribution
        </a>
      ) : (
        <a
          className="underline_hover"
          style={{
            color: "rgba(0, 0, 0, 0.87)",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          {" "}
          <small>OSS attribution</small>
        </a>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="OSS"
        open={open}
        maxWidth={"md"}
      >
        <DialogTitle id="OSS" onClose={handleClose}>
          {displayPage === "footer"
            ? "OSS Attribution"
            : displayPage === "ossDoc"
            ? "OSS Attribution Doc"
            : "Terms & conditions"}
        </DialogTitle>

        <FileViewer pdfFile={ossDoc} />
      </Dialog>
    </div>
  );
}
