// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Paper, withStyles } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import listviewUseStyles from '../styles';
import "./style.scss";
import { updateBreadcrumbs, updateToasterConfig } from '../../../../redux/modules/app/actions';
import Loader from '../../../../Components/Preloader/index'
import httpRequest from '../../../../Utils/httpRequest'


// wrapper function for enable-diable toggle switch
const EnableSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .MuiSwitch-switchBase': {

    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'green',

      },

    },
  },

  '& .MuiSwitch-track': {
    backgroundColor: 'red',
  },
}));
//mui custom styling
const DialogActions = withStyles(() => ({
  root: {
      padding:'8px 0px !important'
  }
}))(MuiDialogActions);

//@component
//ViewSOS
function ViewSOS(props) {
  const classes = listviewUseStyles()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [parkingId, setParkingId] = useState("")
  const [status, setStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [defaultSos, setDefaultSos] = useState(Boolean)
  const [data,setData] = useState({})
  const [disabled,setDisabled] = useState(false)
  const [nameError,setNameError] = useState("")
  const [contactError,setContactError] = useState("")
  const [emailError,setEmailError] = useState("")

  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("sosEditview"))
    setIsLoading(true)
   
    httpRequest.get(
      'sosDetails',
      {
        'Content-Type': 'application/json',
      },
      successCallbackDetails,
      errorCallbackDetails,
      {
        parkingId: props.location.state.detail.parkingId
      },
    )

  }, [])
  //function to handle data upon success
  const successCallbackDetails = jsonResponse => {
    // console.log(jsonResponse.data)
    setIsLoading(false)
    if (jsonResponse) {
      setData(jsonResponse.data)
      setParkingId(jsonResponse.data.parkingId)
      setStatus(jsonResponse.data?.sosInfo?.isSosEnabled)
      setDefaultSos(jsonResponse.data?.sosInfo?.isSosDefault)
      if (jsonResponse.data?.sosInfo?.isSosDefault===false){
        setDisabled(false)
      setContactName(jsonResponse.data?.sosInfo?.contactName)
      setContactNumber(jsonResponse.data?.sosInfo?.contactNumber)
      setContactEmail(jsonResponse.data?.sosInfo?.contactEmail)}
      else {
        setDisabled(true)
        setContactName(jsonResponse.data?.contactName)
      setContactNumber(jsonResponse.data?.contactNumber)
      setContactEmail(jsonResponse.data?.contactEmail)
      }
    }
  }
  //function to shoe err msg upon failure
  const errorCallbackDetails = error => {
    setIsLoading(false)
    if (error) {

      // console.log(error)
    }
  }
  //function to edit SOS details 
  const submitDetailsConfirm = () => {
    setDialogOpen(true)
    setEditFlag(true)
  }
  // default switch handler 
  // function to handle enable disable state
  const enableHandlerDefault = () => {
    if(defaultSos===false){
    setDefaultSos(!defaultSos)
  }
    else {
      setDefaultSos(false)
    }
    if (!defaultSos === false) {
      setDisabled(false)
      setContactName(data?.sosInfo?.contactName)
      setContactNumber(data?.sosInfo?.contactNumber)
      setContactEmail(data?.sosInfo?.contactEmail)
    }
    else {
      setDisabled(true)
      setContactName(data?.contactName)
      setContactNumber(data?.contactNumber)
      setContactEmail(data?.contactEmail)
    }

  }
  // function to handle enable disable state
  const enableHandler = () => {
    setDialogOpen(true)
  }
  // function for enable/disable api
  const actionHandler = () => {
    setIsLoading(true)
    if (editFlag === true && disabled===false) {
      httpRequest.put(
        'sosUpdate',
        {
          "contactNumber": contactNumber,
          "contactEmail": contactEmail,
          "contactName": contactName,
          "isSosEnabled": status,
          "isSosDefault": defaultSos
        },
        {
          'Content-Type': 'application/json'
        },
        successCallbackAction,
        errorCallbackAction,
        {
          parkingId: parkingId
        },
      )
    }
    else if (editFlag === true && disabled===true) {
      httpRequest.put(
        'sosUpdate',
        {
          "isSosDefault": defaultSos,
          "isSosEnabled": status,
        },
        {
          'Content-Type': 'application/json'
        },
        successCallbackAction,
        errorCallbackAction,
        {
          parkingId: parkingId
        },
      )
    }
    else {
      let activate = false
      if (status === true) {
        activate = false;
      }
      if (status === false) {
        activate = true
      }
      httpRequest.put(
        'sosEnableDisable',
        {},
        {
          'Content-Type': 'application/json'
        },
        successCallbackAction,
        errorCallbackAction,
        {
          parkingId: parkingId,
          action: activate
        },
      )
    }
    setDialogOpen(false)
  }
  // success callback for actionHandler
  const successCallbackAction = jsonResponse => {
    setIsLoading(false)
    if (editFlag === true) {
      if (jsonResponse) {
        props.dispatch(updateToasterConfig({
          show: true,
          message: `Emergency Support contact details updated successfully`,
          color: 'green'
        }))

      }
      props.history.push("/portal/soslistview")
    }
    else {
      let alertStatus = ""
      if (status === true) {
        alertStatus = "Disabled"
        setStatus(false)
      }
      if (status === false) {
        alertStatus = "Enabled"
        setStatus(true)
      }
      if (jsonResponse) {
        props.dispatch(updateToasterConfig({
          show: true,
          message: `Emergency Support ${contactName} ${alertStatus} successfully`,
          color: alertStatus === "Enabled" ? 'green' : 'red'
        }))

      }
    }
  }
  // failure callback for actionHandler
  const errorCallbackAction = error => {
    setIsLoading(false)
    if (error) {
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))
    }
  }

//contact name,contact number and email handlers 
 const nameHandle = event => {
  var inputBox = document.getElementById(event.target.id); 
  var format = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;    
  
  inputBox.addEventListener('paste', e => e.preventDefault());
  
  if(event.target.value.length > 50) {
    setNameError("Max.Char length is 50");
  }
  if(event.target.value.length <= 50) {
    if(format.test(event.target.value)){
      event.preventDefault();
      setNameError("");
      setContactName(contactName);
    }else{
      setNameError("");
      setContactName(event.target.value);
    }    
  }
};

const numberHandle = (event) => {
  var inputBox = document.getElementById(event.target.id);
   
  var invalidChars = [
    "-","_","+","e","~","`","!","@","#","$","%","^","&","*","(",")",
    "<",">","?","/","{","}","[","]","|",",",".",":",";","'","",",","\\","\""
  ];  
  inputBox.addEventListener("keydown", function(e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }    
  });
  if (event.target.value.length <8) {
    setContactError("Contact number can be in the range of 8 to 14 digits")
    setContactNumber(event.target.value)
  }
  if (event.target.value.length>=8 && event.target.value.length<15 ) {
    setContactNumber(event.target.value)
    setContactError("")
    
  }

  if (event.target.value.length < 1) {
    setContactError("")
    setContactNumber(event.target.value)
  }  
}

const emailHandle = (event) => {
  if (event.target.value.length > 100) {
    setEmailError("Enter less than 100 charecters");
  }
  if(event.target.value.length <= 100) {
    if(validateEmail(event.target.value)) {
     
      setEmailError("");
      setContactEmail(event.target.value);
    }
    if(!validateEmail(event.target.value)){
      setEmailError("Enter valid Email Id")
      
      setContactEmail(event.target.value);
    }
    if(event.target.value.length < 1) {
      setEmailError("")
      
      setContactEmail(event.target.value);
    }      
  }
};
 //function for generic email regex validation 
 const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase());
 }
//  when user cancels form
const formCancelHandler = () => {
  props.history.push("/portal/soslistview");
} 

 return (
    <>
    <div className="sos-root">
        <Grid container>
          {isLoading && <Loader />}
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Edit Emergency Support Contact Details</h2>
              </div>
              <form className="form-field" style={{ padding: 0 }}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">

                    <label>Select Parking Space</label><br/>
                    <input
                      
                      className='text-field-item'
                      disabled={true}
                      type="text"
                      name="accountFName"
                      value={data.name}
                    />
                    <br/>
                    <label>Address</label>
                    <p>{data.address}</p><br />
                    <Grid container className='toggle-container' >
                      <Grid item xs={6}>
                        <label>Make Emergency Support contact details same as the parking space contact details</label></Grid>
                      <Grid item xs={2} className='toggle-grid' >
                        <FormControlLabel
                          control={
                            <EnableSwitch
                              sx={{ m: 1 }}
                              checked={defaultSos}
                              size="medium"
                              onChange={() => enableHandlerDefault()}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container className='toggle-container'  >
                      <Grid item xs={6} style={{marginBottom:'1%'}}>
                        <label>Enable/Disable Emergency Support contact for this Parking space</label></Grid>
                      <Grid item xs={2} className='toggle-grid' >
                        <FormControlLabel
                          control={
                            <EnableSwitch
                              sx={{ m: 1 }}
                              checked={status}
                              size="medium"
                              onChange={() => enableHandler()}
                            />
                          }
                        />
                      </Grid>
                    </Grid>


                    <label>Contact Name</label><br/>

                    <input
                      id='contactname'
                      
                      className='text-field-item'
                      type="text"                    
                      disabled={disabled}
                      value={contactName}
                      onChange={nameHandle}
                    />
                    <p><span className="text-field-error">{nameError}</span></p>
                    <label>Contact Number</label><br/>
                    <input
                      id='contactnumber'
                      style={{marginBottom:'0px'}}
                      className='text-field-item'
                      type="number"
                      onWheel={event =>
                        event.currentTarget.blur()
                      }
                      name="contactNumber"
                      disabled={disabled}
                      value={contactNumber}
                      onChange={numberHandle}
                    />
                    <p style={{marginBottom:'10px'}}><span className="text-field-error">{contactError}</span></p>
                    <label>Email Id</label><br/>
                    <input
                      
                      className='text-field-item'
                      style={{marginBottom:'0px'}}
                      type="email"
                      disabled={disabled}
                      name="contactEmail"
                      value={contactEmail}
                      onChange={emailHandle}
                    />
                    <p><span className="text-field-error">{emailError}</span></p>
                    <div className="sosform-button-section">
                      <button type="button" className="form-button-cancel" onClick={formCancelHandler}>
                        Cancel
                      </button>
                      <button type="button" className="form-button-submit" onClick={submitDetailsConfirm}>
                        Update
                      </button>

                    </div>
                  </div>
                </div>

              </form>
            </Paper>
          </Grid>
        </Grid>
    </div>
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false)
      }}
      PaperProps={{
        style: {
          padding: "2.5% 3.25%",
        },
      }}
      style={{ zIndex: 0 }}
    >

      <MuiDialogTitle className="dialog-title" style={{paddingBottom:"0px",paddingTop:"2px",paddingLeft:"2px",paddingRight:"2px"}}>
        <Grid item>
          <Grid container direction="row" justify="space-between">
            <Grid item className="dialog-create-user-text">{(editFlag === false && status === false) && "Enable Emergency Support"}{(editFlag === false && status === true) && "Disable Emergency Support"}{editFlag === true && " Edit Emergency Support contact Details"}</Grid>
            <Grid item style={{display:'none'}}>
              <CloseIcon className="dialog-close-icon" onClick={() => setDialogOpen(false)} />
            </Grid> 
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <Divider />
      <DialogContent style={{paddingLeft:"0px",paddingRight:"0px"}} className="dialog-content">
        <Grid container direction="column" justify="center">
          <Grid item>
            <p className="dialog-content-text">Are you sure you want to
              <span style={{ fontWeight: "bold" }}>{(editFlag === false && status === false) && " Enable"}{(editFlag === false && status === true) && " Disable"}{editFlag === true && " Edit"}</span> the selected Emergency Support contact details?
            </p>
          </Grid >
          <Grid item>
            <p>
              <span className="dialog-sub-title">Contact Name: </span><span className="dialog-sub-text">{contactName.substring(0, 50)}</span>
            </p>
          </Grid>
          <Grid item>
            <p>
              <span className="dialog-sub-title">Contact No: </span> <span className="dialog-sub-text">{contactNumber}</span>
            </p>
          </Grid>
          <Grid item>
            <p>
              <span className="dialog-sub-title">Contact Email: </span><span className="dialog-sub-text">{contactEmail.substring(0, 50)}</span>
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Grid item>
          <Grid container
            direction="row"
            justify="flex-end"
            className="dialogActionGrid"
          >
            <Grid item>
              <button type="button" onClick={() => setDialogOpen(false)} className={classes.dialogCancel}>
                Cancel
              </button>
            </Grid>
            <Grid item className="dialogSubmitGrid">
              <button type="button" className={classes.dialogConfirm} onClick={actionHandler}>
                Update
              </button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
    </>
  )
}


const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)((ViewSOS)));