// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { deleteCookie, getCookie, setCookie } from './cookies';

export default {
  storeToken: (accessToken) => {
    setCookie('auth', accessToken);
    
  },
  storeRefreshToken: (refreshToken) => {
    setCookie('refresh', refreshToken);
    
  },
  clear(){
    deleteCookie('refresh');
    deleteCookie('userId');
    deleteCookie('auth');
    deleteCookie('cliId');
    deleteCookie('cliSec');
    deleteCookie('remUserId')
    deleteCookie('role')
    deleteCookie('isHardwareAvailable')
    deleteCookie('eula')
    deleteCookie('inactive')
    deleteCookie('tokenExpTime')
  },
  getRefreshToken() {
    return getCookie('refresh');
  },
  getToken() {
    return getCookie('auth');
  },

  setTokenExpirationTime(expTime) {
    setCookie('tokenExpTime', expTime);
  },
  getTokenExpirationTime() {
    return getCookie('tokenExpTime');
  },
};
