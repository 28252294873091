// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import httpRequest from './../../Utils/httpRequest';
import StoreContext from '../../context/dashboard-context'
import LinearProgress from '@material-ui/core/LinearProgress';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import Ratings from '@material-ui/lab/Rating';
import FlexiText from '../../Components/FlexiText/index';
import { withRouter } from "react-router";
import {
  CardHeader,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import './Ratings.scss';
import { Scrollbars } from 'react-custom-scrollbars';

//@component
//Rating
class Rating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      count: [],
      reviews: [],
      avgRating: 0,
      precision: 0.5,
      parkingId:'',
      reviewError:''
    };
  }
  static contextType = StoreContext;

  //function to set parking ID 
  handleClick =(e) =>{
    
    this.setState({
      parkingId:e.target.value
    },()=>{
      httpRequest.get(
        'ratingParking',
        {},
        this.UserDataSuccessRatingsData,
        this.errorCallback,
        {
          parkingId: this.state.parkingId
        },
      );
      httpRequest.get(
        'detailReview',
        {},
        this.UserDataSuccessReviewData,
        this.errorCallbackReview,
        {
          parkingId: this.state.parkingId,
          page:0,
          size:5
        },
      );
    })
    
   
  }
  //function for success call Ratings
  UserDataSuccessRatingsData = async (jsonResult) => {
    let precision = jsonResult && jsonResult.data && jsonResult.data.avgRating?(jsonResult.data.avgRating + '').split('.')[0]
      .length:1;
    precision = parseFloat('0.' + precision);
    this.setState({
      value: jsonResult.data,
      count: jsonResult.data.ratingCounts,
      avgRating: jsonResult.data.avgRating,
      precision: precision,
      //showDiv: false,
    
    });
  };
  // function for date format
  dateFormat = (str) => {
    const newdate = new Date(str);
    const date = newdate.toString().split(" ");
    const one = new Date(str);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const time = one.toLocaleString("en-US", options);
    const timeString = [date[2], date[1], date[3], time].join(" ");
    // ;
    return timeString;
  };
  // function for api success block
  UserDataSuccessReviewData = async (jsonResult) => {
    this.setState({
      reviews: jsonResult.data._embedded.results,
      //showDiv: false
      reviewError:''
    });
  
  };
  //function for error Review
  errorCallbackReview = (error) => {
    this.setState({
     reviewError:error.message,
     reviews:[]
    })
  };

  //function for component mount
  componentDidMount = () => {
    httpRequest.get(
      "parkingListDashboard",
      {},
      this.ParkingDataSuccess,
      (error) => {console.log(error)},
      {
       
      }
    );
  };

  // function for 'rating parking' and 'detail review' api
  ParkingDataSuccess = (jsonResult) =>{

    this.setState({
      parkingId:jsonResult.data._embedded.results[0].parkingId
    })

    httpRequest.get(
      'ratingParking',
      {},
      this.UserDataSuccessRatingsData,
      this.errorCallback,
      {
        parkingId: this.state.parkingId
      },
    );
    httpRequest.get(
      'detailReview',
      {},
      this.UserDataSuccessReviewData,
      this.errorCallbackReview,
      {
        parkingId: this.state.parkingId,
        page:0,
        size:5
      },
    );
  }

  render() {
    //Reference variable
    const count = this.state.count;
    return (
      <StoreContext.Consumer>{
        context => (
      <>
        <Card raised={true} className="rating-review-card" >
          <CardHeader           
            title="Ratings and Reviews"
            className="rating-card-header"
            style={{  fontWeight: '700',fontFamily: '\'Poppins\' !important',color:'#102E35'}}
            disableTypography={true}
            // titleTypographyProps={{ variant: 'h6' }}
          />
          <CardContent className='rating-card-content'>
            <div className='select-parking-div' id='chart-filter'>
              <select onChange={this.handleClick} className='select-parking-input'>
              {
                context.parkingData.map((item, index) => ( 
                  <option key={index} value={item.parkingId}>
                    {item.name}
                  </option>
              ))} 
              </select>
            </div>
            <div className="ratings_container">
              <div className="ratings">
                <h2 className='rating-h2'>{this.state.value.avgRating?Math.round(this.state.value.avgRating):<span style={{color:'gray'}}>--</span>}</h2>
                <p>Average Rating</p>
              </div>
              <div className='ratings-component-div'>
                <Ratings
                  name="half-rating-pz"
                  value={this.state.avgRating}
                  precision={this.state.precision}
                  readOnly
                />
              </div>             
            </div>
            <div className="progressbar_div">
              <Grid container spacing={1} className="progressbar_container">
                <Grid item xs={2} className="star_rating">
                  <span>5</span>
                  <span>
                    <StarOutlinedIcon className='star_rating_icon'/>
                  </span>
                </Grid>
                <Grid item xs={8} className="linear_progress">
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={count.fiveStar}
                  />
                </Grid>
                <Grid item xs={1} className="percentage">
                  {count.fiveStar}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="progressbar_container">
                <Grid item xs={2} className="star_rating">
                  <span>4</span>
                  <span>
                    <StarOutlinedIcon className='star_rating_icon'/>
                  </span>
                </Grid>
                <Grid item xs={8} className="linear_progress">
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={count.fourStar}
                  />
                </Grid>
                <Grid item xs={1} className="percentage">
                  {count.fourStar}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="progressbar_container">
                <Grid item xs={2} className="star_rating">
                  <span>3</span>
                  <span>
                    <StarOutlinedIcon className='star_rating_icon'/>
                  </span>
                </Grid>
                <Grid item xs={8} className="linear_progress">
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={count.threeStar}
                  />
                </Grid>
                <Grid item xs={1} className="percentage">
                  {count.threeStar}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="progressbar_container">
                <Grid item xs={2} className="star_rating">
                  <span>2</span>
                  <span>
                    <StarOutlinedIcon className='star_rating_icon'/>
                  </span>
                </Grid>
                <Grid item xs={8} className="linear_progress">
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={count.twoStar}
                  />
                </Grid>
                <Grid item xs={1} className="percentage">
                  {count.twoStar}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="progressbar_container">
                <Grid item xs={2} className="star_rating">
                  <span>1</span>
                  <span>
                    <StarOutlinedIcon className='star_rating_icon'/>
                  </span>
                </Grid>
                <Grid item xs={8} className="linear_progress">
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={count.firstStar}
                  />
                </Grid>
                <Grid item xs={1} className="percentage">
                  {count.firstStar}
                </Grid>
              </Grid>
            </div>
            
            <Scrollbars style={{ height: '17vw' }}>
              {this.state.reviewError!=='' && (
                <div className='review-error-div'>
                  <p>{this.state.reviewError}</p>
                </div>
              )}
              <div>
                {this.state.reviews.map((data, index) => (
                  <div key={index} className="comment_container">
                    <Grid container className="comment">
                      <Grid item xs={6}>
                        <p className='p-lastname'>{data.user.firstName +' ' +data.user.lastName}</p>
                        <p className='p-lastupdateddate'>{this.dateFormat(data.lastUpdatedDate)}</p>
                      </Grid>
                      <Grid item xs={6} className="each-rating-star" style={{textAlign: 'center'}}>
                        <Ratings name="read-only" value={data.rating} readOnly/>
                      </Grid>
                      <Grid item xs={11} className='comment-flexitext-grid'>
                        <FlexiText text={data.review?data.review :"--"} length={80}/>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <Grid item xs={6} className='allreview-link-grid'>
                  {(this.state.reviewError==='' && this.state.value.totalRatings>5) && (
                    <Link to={{
                      pathname: "/portal/parking-details/" + this.state.parkingId,
                      state:{ratings: true}
                    }}
                    >
                      See all Reviews
                    </Link>
                  )}
                </Grid>
              </div>
            </Scrollbars>
          </CardContent>
        </Card>
      </>
    )}
        </StoreContext.Consumer>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    {  }
  )(Rating)
);
