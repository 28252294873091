// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ParkingDetails from "./../Pages/SolutionAdmin/ParkingManagement/ParkingSpace/ParkingDetails";
import ParkingOverview from "./../Pages/SolutionAdmin/ParkingManagement/ParkingOverview/ParkingOverview";
import Dashboard from "./../Pages/Dashboard/Dashboard";
import Breadcrumbs from "../Components/Breadcrumbs";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import AddParking from "./../Pages/SolutionAdmin/ParkingManagement/AddParkingModule";
import EditParking from "./../Pages/SolutionAdmin/ParkingManagement/EditParkingModule";
import RequestSubmitted from "./../Pages/SolutionAdmin/ParkingManagement/AddParkingModule/RequestSubmitted";
import Profile from "../Pages/Profile/Profile";
import EditProfile from "../Pages/Profile/EditProfile";
import RoleManagement from "./../Pages/RoleManagement/RoleManagementOverview";
import Reports from "../Pages/Reports/LandingPage/index";
import RegisterUsers from "../Pages/Reports/RegisterEndUser";
import ParkingSpace from "../Pages/Reports/ParkingSpace";
import ReportsOnp from "../Pages/Reports/OvernightReport";
import UnregisterReport from "../Pages/Reports/UnRegister";
import SOSListView from "../Pages/ServicesRequest/SOSAlert/index";
import EditSOS from "../Pages/ServicesRequest/SOSAlert/Edit/index";
import ONPListView from "../Pages/ServicesRequest/OvernightParking/View";
import ONPListViewDashboard from "../Pages/ServicesRequest/OvernightParkingDashboard/View";
import ONPRequestDetails from "../Pages/ServicesRequest/OvernightParking/Details";
import ONPRequestDetailsDashboard from "../Pages/ServicesRequest/OvernightParkingDashboard/DetailsDashboard";
import SecurityListView from "../Pages/SecurityModule/View/index";
import SecurityCreate from "../Pages/SecurityModule/Create/index";
import ReportsOverspeed from "../Pages/Reports/Overspeed";
import FacilityManagerListView from "../Pages/FacilityManager/View/index";
import FacilityManagerCreate from "../Pages/FacilityManager/Create/index";
import FacilityManagerEdit from "../Pages/FacilityManager/Create/editFacility";
import TempAccessListView from "../Pages/TemporaryAccess/View/view";
import TempAccessBulkUpload from "../Pages/TemporaryAccess/BulkUpload/BulkUpload";
import TempAccessReports from "../Pages/Reports/TemporaryAccessReport";
import ViewOrg from "../Pages/OrgManagement/ViewOrg/ViewOrg";
import AddOrg from "../Pages/OrgManagement/AddOrg/AddOrg";
import EditOrg from "../Pages/OrgManagement/EditOrg/EditOrg";
import CreateAccount from "../Pages/FacilityManagerMTA/CreateAccount/index";
import ViewAccount from "../Pages/FacilityManagerMTA/View/View";
import EditAccount from "../Pages/FacilityManagerMTA/EditAccount/index";
import ViewAccDetails from "../Pages/FacilityManagerMTA/ViewDetails/Details";
import ViewTempAccDetails from "../Pages/TemporaryAccess/ViewDetails/Details";
import TempAccessEditDetails from "../Pages/TemporaryAccess/Edit/editDetails";
import VehicleExitEvents from "../Pages/ServicesRequest/VehicleExitEvents/view/vehicleExitEvents";
import DeviceReports from "../Pages/Reports/DeviceReport";
import VehicleAccessReport from "../Pages/Reports/VehicleAccessReport";
import AlertNotifications from "../Pages/Configuration/View/index";
import AddAlertNotifications from "../Pages/Configuration/add/index";
import EditAlertNotifications from "../Pages/Configuration/Edit/index";
import BookingReport from "../Pages/Reports/BookingReport";
import ResidentialReports from "../Pages/Reports/LandingPage/residential_landing";
import TermsAndConditions from "../Pages/LegalDocuments/TermsAndConditions";
import DataProtectionNotice from "../Pages/LegalDocuments/Data Protection Notice";
import { getCookie } from "../Utils/cookies";
import tokenStorage from "../Utils/tokenstorage";
import DashboardNew from "../Pages/Dashboard/DashboardNew";
import Sidebar2 from "../Components/Sidebar/Sidebar2";
import Header2 from "../Components/Header/Header2";
import Booking from "../Components/Booking/Booking";
import ViewBooking from "../Pages/BookingManagement/ViewBooking";
import Checkin from "../Pages/BookingManagement/Checkin";
import Eyreports from "../Pages/Reports/LandingPage/EY_landing";
import Noshow from "../Pages/Reports/EY/Noshow";
import Manualcancel from "../Pages/Reports/EY/Manualcancel";
import Releasefail from "../Pages/Reports/EY/Releasefail";
//@component
//Admin Routes
class AdminRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      setIsOpen: false,
    };
  }
  //sidebar items expand/collapse togle drawer
  toggleDrawer = () => {
    this.setState((prevState) => ({
      setIsOpen: !prevState.setIsOpen,
    }));
  };

  popUpClose = () => {
    tokenStorage.clear();
    window.location.href = "/login";
  };
  render() {
    return (
      <Fragment>
        {getCookie("eula") === "true" ? (
          <Router>
            {getCookie("userId") === "cade221a-899a-48d2-bec2-ae48b87cfb8b" ||
            getCookie("userId") === "f570f213-db63-4a7b-af37-b5ad7a85ceaa" ? (
              <div
                style={{
                  width: "100vw",
                }}
              >
                <Header2 />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    //height: "88.3vw",
                    background: "#FFFFFF",
                  }}
                >
                  <Sidebar2 />
                  {/* </div> */}
                  <div
                    style={{
                      width: "85.3515vw",
                      background: "#EFF6F9",
                    }}
                  >
                    <Switch>
                      <Route
                        path="/portal/dashboard_new"
                        exact
                        component={DashboardNew}
                      />
                      <Route path="/portal/profile" exact component={Profile} />
                      <Route
                        path="/portal/reportsOverspeed"
                        component={ReportsOverspeed}
                      />
                      <Route
                        path="/portal/viewOrganization"
                        component={ViewOrg}
                      />
                      <Route
                        path="/portal/addOrganization"
                        component={AddOrg}
                      />
                      <Route
                        path="/portal/editOrganization"
                        component={EditOrg}
                      />
                      <Route
                        path="/portal/facilitymanagerlistview"
                        component={FacilityManagerListView}
                      />
                      <Route
                        path="/portal/facilitymanagercreate"
                        component={FacilityManagerCreate}
                      />
                      <Route
                        path="/portal/facilitymanageredit"
                        component={FacilityManagerEdit}
                      />
                      <Route
                        path="/portal/createaccount"
                        component={CreateAccount}
                      />
                      <Route
                        path="/portal/viewaccount"
                        component={ViewAccount}
                      />
                      <Route
                        path="/portal/editaccount"
                        component={EditAccount}
                      />
                      <Route
                        path="/portal/viewaccountdetails"
                        component={ViewAccDetails}
                      />
                      <Route
                        path="/portal/securitylistview"
                        component={SecurityListView}
                      />
                      <Route
                        path="/portal/securitycreate"
                        component={SecurityCreate}
                      />
                      <Route
                        path="/portal/tempaccesslistview"
                        component={TempAccessListView}
                      />
                      <Route
                        path="/portal/viewtempaccountdetails"
                        component={ViewTempAccDetails}
                      />
                      <Route
                        path="/portal/tempaccessedit"
                        component={TempAccessEditDetails}
                      />
                      <Route
                        path="/portal/tempaccessbulkupload"
                        component={TempAccessBulkUpload}
                      />
                      <Route
                        path="/portal/onplistview"
                        component={ONPListView}
                      />
                      <Route
                        path="/portal/onplistviewdashboard"
                        component={ONPListViewDashboard}
                      />
                      <Route
                        path="/portal/onpRequestDetails"
                        component={ONPRequestDetails}
                      />
                      <Route
                        path="/portal/onpRequestDetailsDashboard"
                        component={ONPRequestDetailsDashboard}
                      />
                      <Route path="/portal/editsos" component={EditSOS} />
                      <Route
                        path="/portal/soslistview"
                        component={SOSListView}
                      />
                      <Route
                        key="overview"
                        path="/portal/parkingmanagment"
                        component={ParkingOverview}
                      />
                      <Route
                        key="tenant-overview"
                        path="/portal/tenantparkingmanagment"
                        component={ParkingOverview}
                      />
                      <Route
                        path="/portal/parking-details/:id"
                        exact
                        component={ParkingDetails}
                      />
                      <Route
                        path="/portal/addparkingspace"
                        component={AddParking}
                      />
                      <Route
                        path="/portal/editparkingspace"
                        component={EditParking}
                      />
                      <Route path="/portal/reports" component={Reports} />
                      <Route
                        path="/portal/residential_reports"
                        component={ResidentialReports}
                      />
                      <Route
                        path="/portal/booking_report"
                        component={BookingReport}
                      />
                      <Route
                        path="/portal/reportsRegisterUsers"
                        component={RegisterUsers}
                      />
                      <Route
                        path="/portal/unregistered_nonstd_report"
                        component={UnregisterReport}
                      />
                      <Route
                        path="/portal/reportsParkingSpace"
                        component={ParkingSpace}
                      />
                      <Route
                        path="/portal/overnight_violation_report"
                        component={ReportsOnp}
                      />
                      <Route
                        path="/portal/temporary_access_report"
                        component={TempAccessReports}
                      />
                      <Route
                        path="/portal/vehicleExitEvents"
                        component={VehicleExitEvents}
                      />

                      <Route
                        path="/portal/residential_reports"
                        component={ResidentialReports}
                      />
                      <Route
                        path="/portal/device_report"
                        component={DeviceReports}
                      />
                      <Route
                        path="/portal/vehicle_access_report"
                        component={VehicleAccessReport}
                      />

                      <Route
                        path="/portal/alertNotifications"
                        component={AlertNotifications}
                      />
                      <Route
                        path="/portal/addAlerttNotifications"
                        component={AddAlertNotifications}
                      />
                      <Route
                        path="/portal/editAlerttNotifications"
                        component={EditAlertNotifications}
                      />

                      <Route path="/portal/role" component={RoleManagement} />

                      <Route
                        path="/portal/profileedit"
                        component={EditProfile}
                      />
                      <Route
                        path="/portal/success"
                        component={RequestSubmitted}
                      />
                      <Route
                        path="/portal/terms_condition"
                        component={TermsAndConditions}
                      />
                      <Route
                        path="/portal/data_protection_notice"
                        component={DataProtectionNotice}
                      />
                    </Switch>
                  </div>
                </div>
                {/* {<FooterNew />} */}
              </div>
            ) : (
              <>
                <Header toggleMenu={this.toggleDrawer} />
                {/* <Sidebar openState={this.state.setIsOpen} /> */}
                <div className="block" style={{ display: "flex" }}>
                  <Sidebar openState={this.state.setIsOpen} />
                  <div
                    className="box-screens"
                    style={{ width: "100%", backgroundColor: "#f2f2f0" }}
                  >
                    <Breadcrumbs />
                    {getCookie("inactive") === "false" ? (
                      <Switch>
                        <Route
                          path="/portal/dashboard"
                          exact
                          component={Dashboard}
                        />

                        <Route
                          path="/portal/reportsOverspeed"
                          component={ReportsOverspeed}
                        />
                        <Route path="/portal/profile" component={Profile} />
                        <Route
                          path="/portal/viewOrganization"
                          component={ViewOrg}
                        />
                        <Route
                          path="/portal/addOrganization"
                          component={AddOrg}
                        />
                        <Route
                          path="/portal/editOrganization"
                          component={EditOrg}
                        />
                        <Route
                          path="/portal/facilitymanagerlistview"
                          component={FacilityManagerListView}
                        />
                        <Route
                          path="/portal/facilitymanagercreate"
                          component={FacilityManagerCreate}
                        />
                        <Route
                          path="/portal/facilitymanageredit"
                          component={FacilityManagerEdit}
                        />
                        <Route
                          path="/portal/createaccount"
                          component={CreateAccount}
                        />
                        <Route
                          path="/portal/viewaccount"
                          component={ViewAccount}
                        />
                        <Route
                          path="/portal/editaccount"
                          component={EditAccount}
                        />
                        <Route
                          path="/portal/viewaccountdetails"
                          component={ViewAccDetails}
                        />
                        <Route
                          path="/portal/securitylistview"
                          component={SecurityListView}
                        />
                        <Route
                          path="/portal/securitycreate"
                          component={SecurityCreate}
                        />
                        <Route
                          path="/portal/tempaccesslistview"
                          component={TempAccessListView}
                        />
                        <Route
                          path="/portal/viewtempaccountdetails"
                          component={ViewTempAccDetails}
                        />
                        <Route
                          path="/portal/tempaccessedit"
                          component={TempAccessEditDetails}
                        />
                        <Route
                          path="/portal/tempaccessbulkupload"
                          component={TempAccessBulkUpload}
                        />
                        <Route
                          path="/portal/onplistview"
                          component={ONPListView}
                        />
                        <Route
                          path="/portal/onplistviewdashboard"
                          component={ONPListViewDashboard}
                        />
                        <Route
                          path="/portal/onpRequestDetails"
                          component={ONPRequestDetails}
                        />
                        <Route
                          path="/portal/onpRequestDetailsDashboard"
                          component={ONPRequestDetailsDashboard}
                        />
                        <Route path="/portal/editsos" component={EditSOS} />
                        <Route
                          path="/portal/soslistview"
                          component={SOSListView}
                        />
                        <Route
                          key="overview"
                          path="/portal/parkingmanagment"
                          component={ParkingOverview}
                        />
                        <Route
                          key="tenant-overview"
                          path="/portal/tenantparkingmanagment"
                          component={ParkingOverview}
                        />
                        <Route
                          path="/portal/parking-details/:id"
                          exact
                          component={ParkingDetails}
                        />
                        <Route
                          path="/portal/addparkingspace"
                          component={AddParking}
                        />
                        <Route
                          path="/portal/editparkingspace"
                          component={EditParking}
                        />
                        <Route path="/portal/reports" component={Reports} />
                        <Route
                          path="/portal/residential_reports"
                          component={ResidentialReports}
                        />
                        <Route
                          path="/portal/booking_report"
                          component={BookingReport}
                        />
                        <Route
                          path="/portal/reportsRegisterUsers"
                          component={RegisterUsers}
                        />
                        <Route
                          path="/portal/unregistered_nonstd_report"
                          component={UnregisterReport}
                        />
                        <Route
                          path="/portal/reportsParkingSpace"
                          component={ParkingSpace}
                        />
                        <Route
                          path="/portal/overnight_violation_report"
                          component={ReportsOnp}
                        />
                        <Route
                          path="/portal/temporary_access_report"
                          component={TempAccessReports}
                        />
                        <Route
                          path="/portal/vehicleExitEvents"
                          component={VehicleExitEvents}
                        />

                        <Route
                          path="/portal/residential_reports"
                          component={ResidentialReports}
                        />
                        <Route
                          path="/portal/device_report"
                          component={DeviceReports}
                        />
                        <Route
                          path="/portal/vehicle_access_report"
                          component={VehicleAccessReport}
                        />

                        <Route
                          path="/portal/alertNotifications"
                          component={AlertNotifications}
                        />
                        <Route
                          path="/portal/addAlerttNotifications"
                          component={AddAlertNotifications}
                        />
                        <Route
                          path="/portal/editAlerttNotifications"
                          component={EditAlertNotifications}
                        />

                        <Route path="/portal/role" component={RoleManagement} />

                        <Route
                          path="/portal/profileedit"
                          component={EditProfile}
                        />
                        <Route
                          path="/portal/success"
                          component={RequestSubmitted}
                        />
                        <Route
                          path="/portal/terms_condition"
                          component={TermsAndConditions}
                        />
                        <Route
                          path="/portal/data_protection_notice"
                          component={DataProtectionNotice}
                        />
                        <Route
                          path="/portal/viewbookings"
                          component={ViewBooking}
                        />
                        <Route
                          path="/portal/createbooking"
                          component={Booking}
                        />
                        <Route path="/portal/checkin" component={Checkin} />
                        <Route path="/portal/Eylanding" component={Eyreports} />

                        <Route path="/portal/Noshows" component={Noshow} />
                        <Route
                          path="/portal/Manualcancellation"
                          component={Manualcancel}
                        />
                        <Route
                          path="/portal/Releasefailure"
                          component={Releasefail}
                        />
                      </Switch>
                    ) : (
                      <div className="deletepup-wrapper">
                        <div className="password-popup">
                          <div className="heading">
                            <p className="popup-heading">Account Deactivated</p>
                          </div>
                          <p className="popup-text">
                            Your account has been deactivated. Kindly contact
                            the administrator
                          </p>
                          <div className="popup-button">
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={this.popUpClose}
                            >
                              OK
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <Footer /> */}
              </>
            )}
          </Router>
        ) : (
          <Redirect to="/login" />
        )}
      </Fragment>
    );
  }
}
export default AdminRoutes;
