// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./forgot.scss";
import Loader from "../../../Components/Preloader";
import showIcon from '../../../Assets/Images/eye for password.png';
import Tip from '../../../Assets/Images/info.svg';
import Popover from '@material-ui/core/Popover';

//@component
//NewPassword
function NewPassword(props){
  const [ isLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ newPwd, setNewPwd ] = useState("")
  const [ confirmPwd, setConfirmPwd ] = useState("")
  const [ showValNew, setShowValNew ] = useState(false)
  const [ showValConfirm, setShowValConfirm ] = useState(false)
  const [ showErrorMsg, setShowErrorMsg ] = useState(false)

  // function scope variable declaration definition
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // function to handle cancel button callback
  const handleCancel = () => {
    props.cancelPwdToggle()
  }
  // function to handle submit button callback
  const handleSubmit = () => {
    props.pwdChangeToggle()
  }
  // function to handle i icon click event
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  // function to handle i icon close event
  const handleClose = () => {
    setAnchorEl(null)
  }
  // function to remove error message
  const dismissError = () => {
    setError("")
  }
  // function to remove read only property
  // const removeReadOnly = () => {
  //   document.getElementById("username").removeAttribute("readOnly");
  // };
  // function for new password textbox
   const newPwdChange = (e) => {
   setNewPwd(e.target.value); 
   props.setNewPwd(e.target.value)
  }
  // function for confirm password
  const confirmPwdChange = (e) => {
    setConfirmPwd(e.target.value);
  }



  // function to toggle show password
  const toggleShowNew = (event) => {
    event.preventDefault();
    setShowValNew(!showValNew)
  }
  // function to toggle confirm password
  const toggleShowConfirm = (event) => {
    event.preventDefault();
    setShowValConfirm(!showValConfirm)
  }

useEffect(() => {
  if(newPwd.length>0&&confirmPwd.length>0){
  if(newPwd!==confirmPwd){
    setShowErrorMsg(true)
  } else {
    setShowErrorMsg(false)
  }
  } else {
    setShowErrorMsg(false)
  }
}, [newPwd,confirmPwdChange]);
  return (
    <div className="ForgotPassword">
        <div className="ForgotPassword__wrapper">
        {isLoading && <Loader />}
          <Card>
            <div className="ForgotPassword__wrapper_box">
              <div className="ForgotPassword__wrapper_box_header_text">
                <span>Forgot Password ?</span>
                  <Button 
                    aria-describedby={id} 
                    variant="outlined" 
                    color="primary" 
                    onClick={(event)=>handleClick(event)}  
                    style={{float:'right',border:'none',paddingRight:'0px'}}
                  >
                    <img src={Tip}/>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <p style={{padding:'1rem',fontSize:'12px'}}>
                      <span style={{fontWeight:'bold',fontSize:'14px'}}>
                      Password Policy:<br/>
                      </span>
                      a. minimum 8 characters<br/>
                      b. At least one upper case<br/>
                      c. At least one lower case<br/>
                      d. At least one number<br/>
                      e. Admin should not be able to set current password as new.<br/>
                      {'f. At least one special character (~`!@#$%^&*()+=_-{}[]\|:;”’?/<>,.)'}
                    </p>
                  </Popover>
              </div>
              <div style={{padding:'10px 16px 10px 16px',width:'500px'}}>
              {error && (
                <h3
                  data-test="error"
                  className="error"
                  onClick={dismissError}
                >
                  <button 
                    onClick={dismissError}
                  >
                      ✖
                  </button>
                  {error}
                </h3>
              )}
              <label>New Password*</label>
              <input
                placeholder="Enter New Password"
                type={showValNew ? 'text' : 'password'}
                value={newPwd}
                onChange={(e)=>newPwdChange(e)}
                style={{display:'inline'}}
              />
               <span
                  className="btnCustom"
                  onClick={toggleShowNew}
                  style={{borderLeft:'1px solid #f2f2f0',lineHeight:'47px',paddingLeft:'4px'}}
                >
                  <img style={{ width: '100%' }} alt="showIcon" src={showIcon} />
                </span>
              <label>Confirm New Password*</label>
              <input
                placeholder="Enter Confirm Password"
                type={showValConfirm ? 'text' : 'password'}
                data-test="username"
                value={confirmPwd}
                onChange={confirmPwdChange}
                id="username"
                style={{display:'inline'}}
              />
               <span
                  className="btnCustom"
                  onClick={toggleShowConfirm}
                  style={{borderLeft:'1px solid #f2f2f0',lineHeight:'47px',paddingLeft:'4px'}}
                >
                  <img style={{ width: '100%' }} alt="showIcon" src={showIcon} />
                </span>
               
                {showErrorMsg&& <><Grid container justify={'flex-end'}><span style={{color:'red',fontSize:'12px'}}>Password Mismatch. Please try again.</span></Grid></>}
              <Grid container justify={'flex-end'}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    type="button"
                    onClick={() => handleCancel()}
                    style={{borderRadius:"25px",fontSize:"0.65rem",color:'#fff',background:'grey',margin:'1em',width:'90.5px'}}
                  >
                    Cancel
            </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={{  borderRadius:"25px",fontSize:"0.65rem",margin:'1em 0em 1em 1em' }}
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={!(newPwd.length>0) || !(confirmPwd.length>0||newPwd===confirmPwd)}
                    // disabled = {disableFunc()}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
              </div>
            </div>
          </Card>
        </div>
      </div>
  )
}

export default withRouter(
  connect(
    null,
    null
  )(NewPassword)
);
