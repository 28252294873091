// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { updateBreadcrumbs, updateToasterConfig } from "../../../redux/modules/app/actions";
// import MuiAlert from "@material-ui/lab/Alert";
// import Snackbar from "@material-ui/core/Snackbar";
import Divider from '@material-ui/core/Divider';
import Loader from '../../../Components/Preloader/index';
import { editOrgApi, getOrgById } from "../api/orgManagementApis";

import "./style.scss";

//@component
//Edit organization
const EditOrg = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [name, setName] = useState("");
  const [ nameError ] = useState("");
  const [acr, setAcr] = useState("");
  const [ dialogOpen,setDialogOpen ] = useState(false);

  const orgId =
    props && props.location && props.location.state && props.location.state.id;

  //function to api call org detail by id
  const generateDatatHandler = async() => {
  try {
    let data = await getOrgById(orgId);
    setName(data.name);
    setAcr(data.orgAcronym);
  }
  catch (error){
    props.dispatch(updateToasterConfig({
      show: true,
      message: error.message || error.error,
      color: 'red'
    }))
  }
  };

 

  
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("editorganization"));
    generateDatatHandler();
  }, []);


  //function to edit organization api
  const editOrg = async (name,acr) => {
    try {
    setIsLoading(true);
    //eslint-disable-next-line
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
    if(format.test(name)){
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(updateToasterConfig({
        show: true,
        message: 'Error! Please remove special characters in organization name and proceed.',
        color: 'red'
      }))     
    }else{
     const data = await editOrgApi(name,acr,orgId)
      if(data){
        //const modifiedName = name.replace(/[^a-zA-Z ]/g, "");
        setIsLoading(false);
        setDialogOpen(false);
        
        props.dispatch(updateToasterConfig({
          show: true,
          message: `Organization details updated successfully`,
          color:'green'
        }))
        props.history.push("/portal/vieworganization")
      }

     
    } 
  }
  catch(error){
    if(error){
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))
    }

  }   
  }
 
 
  
  //function for dialog confirm button  
  const submitOrgConfirm = () => {
    setDialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setDialogOpen(false);
  };
  //function for pre-details submit api call
  const submitOrg = () => {
    editOrg(name,acr);
  };
  
  //function for name handler
  const nameHandler = (event) => {
    setName(event.target.value);
  };
 

  // function for form cancel button
  const formCancelHandler = () => {
    props.history.push("/portal/vieworganization");
  }
  

  return (
    <>
      {isLoading && <Loader />}
      <div className="org-root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Edit Organization Account</h2>
              </div>
              <form className="form-field" style={{padding:0}}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Organization Name*</label><br/>
                    <input
                                            
                      className='text-field-item'
                      id='orgId'
                      data-testid="name"
                      type="text"
                      name="orgName"
                      value={name}
                      onChange={nameHandler}
                    />
                    <p>
                      <span className="error_style">
                        {nameError}
                      </span>
                    </p>
                  </div>
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Organization Acronym*</label><br/>
                    <input
                                            
                      className='text-field-item'
                      // id='acrId'
                      type="text"
                      // name="orgAcr"
                      value={acr}
                     
                      disabled
                    />
                  </div>
                </div>
                <div className="button-section">
                <button 
                   type="button"                  
                   className="form-button-submit" 
                   id="submitButton"
                   onClick={submitOrgConfirm}
                   disabled={
                    name.length===0 
                   }
                   style={{
                     backgroundColor:
                        name.length===0 ?
                        "#a2a2a2" :
                        undefined
                   }}
                >
                  Save
                </button>
                <button type="button" id="cancelButton" className="form-button-cancel" onClick={formCancelHandler}>
                  Cancel
                </button>
                
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          PaperProps={{
            style: {
              padding: "1% 2%",
            },
          }}
          onClose={handleClose}
          aria-labelledby="create-security-admin-dialog"
        >
            <DialogTitle
              className="dialog-title"
              id="create admin dialog-header"
              style={{paddingBottom:"0px",paddingTop:"2px",paddingLeft:"2px",paddingRight:"2px"}}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <span className="dialog-create-user-text">Update Organization</span>
                </Grid>
                {/* <Grid item>
                  <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                </Grid> */}
              </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent style={{paddingLeft:"0px",paddingRight:"0px"}} className="dialog-content">
              <p className="dialog-content-text" data-testid="popup-text">Do you want to edit this organization?</p>
            </DialogContent>
            <DialogActions className="dialog-actions">
              <button type="button" onClick={submitOrg} className="confirm-button" data-testid="confirm-button" id="confirmSubmit">
                Confirm
              </button>
              <button type="button" onClick={handleClose} className="cancel-button" id="cancelPopup" >
                Cancel
              </button>
            </DialogActions>
      </Dialog>
    </>
  )
}


// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   updateToasterConfig,
//   dispatch,
// });

//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(EditOrg),
);
