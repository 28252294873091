// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";

import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import { Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../../redux/modules/app/actions";
import detailsUseStyles from "./style";
import httpRequest from "../../../../Utils/httpRequest";
import Loader from "../../../../Components/Preloader";

//material ui overides
const DialogActions = withStyles(() => ({
  root: {
    padding: "8px 0px !important",
  },
}))(MuiDialogActions);

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

//@component
//OvernightParkingDetails
function ONPDetails(props) {
  const classes = detailsUseStyles();
  const [network, setNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButtonStatus, setDialogButtonStatus] = useState("");
  const [adminRemarks, setAdminRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [statusLogs, setStatusLogs] = useState([{}]);
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(true);

  //api for data filter list as per view complaint Id
  const requestDetails = (id) => {
    setIsLoading(true);
    httpRequest.get(
      "onpDetails",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        requestId: id,
      }
    );
  };

  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    setFilterList(jsonResponse.data);
    setStatus(jsonResponse.data.status.name);
    httpRequest.get(
      "onpStatusLogs",
      {
        "Content-Type": "application/json",
      },
      successCallbackStatusLog,
      errorCallbackList,
      {
        requestId: props.location.state.rowData.requestId,
      }
    );
    setIsLoading(false);
    setDisabled(false);
    setNetwork(true);
  };

  //error callback status update
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
      setOpen(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "please try again from the list page",
          color: "#FF0000",
        })
      );
      setAdminRemarks("");
    }
  };
  // handling success response for status logs
  const successCallbackStatusLog = (jsonResponse) => {
    setStatusLogs(jsonResponse.data._embedded.results);
  };

  //success callback for status update api handler
  const successCallbackStatus = (jsonResponse) => {
    if (jsonResponse) {
      setOpen(false);
      setIsLoading(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "Status Updated Succesfully",
          color: "#67b561",
        })
      );
      setFilterList([]);
      requestDetails(id);
      setDialogButtonStatus("");
      setAdminRemarks("");
    }
  };
  //error callback status update
  const errorCallbackStatus = (error) => {
    if (error) {
      setIsLoading(false);
      setOpen(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "#FF0000",
        })
      );
      setAdminRemarks("");
      setDialogButtonStatus("");
    }
  };
  //status update handler
  const statusChange = (status) => {
    if (status === "approve") {
      setDialogButtonStatus("APPROVED");
      setDialogTitle("Approve Request");
    } else if (status === "reject") {
      setDialogButtonStatus("REJECTED");
      setDialogTitle("Reject Request");
    } else if (status === "approveExt") {
      setDialogButtonStatus("EXTENSION_APPROVED");
      setDialogTitle("Approve Extension Request");
    } else if (status === "rejectExt") {
      setDialogButtonStatus("EXTENSION_REJECTED");
      setDialogTitle("Reject Extension Request");
    } else {
      setDialogButtonStatus("COMPLETED");
      setDialogTitle("Complete Request");
    }
    setOpen(true);
  };
  // function to update status
  const updateStatus = () => {
    setIsLoading(true);
    httpRequest.put(
      "onpStatusUpdate",
      {
        status: {
          name: dialogButtonStatus,
        },
        remarks: adminRemarks,
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackStatus,
      errorCallbackStatus,
      {
        requestId: id,
      }
    );
  };
  // function for back button which is in 'hidden' state
  const backHandler = () => {
    props.history.push({
      pathname: "/portal/onplistviewdashboard",
      state: {
        currentPage: props.location.state.currentPage,
        pressed: true,
        search: props.location.state.search,
        parkingId: props.location.state.parkingId,
        onpRequest: true,
        parkingName: props.location.state.parkingName,
      },
    });
  };
  // function for dialog popup close icon
  const dialogCloseIcon = () => {
    setOpen(false);
    setAdminRemarks("");
    setDialogButtonStatus("");
  };

  //adding component to breadcrumb and displaying filtered data as per view complaint id
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("onpdetails"));
    requestDetails(props.location.state.rowData.requestId);
    setId(props.location.state.rowData.requestId);
    setFilterList(props.location.state.rowData);
  }, []);

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.headerGrid}>
          <p className={classes.headerText}>
            {filterList.length !== 0 ? filterList?.parking?.name : ""}
          </p>
        </Grid>
        <Card className={classes.OvernightDetailCard}>
          <Container xs={12} sm={12} md={12} lg={12}>
            {network ? (
              <>
                <Grid item container direction="row">
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.detailsGrid}
                    xs={12}
                    sm={10}
                    md={10}
                    lg={10}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>Email-Id </p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList?.user?.corpEmailId
                          ? filterList?.user?.corpEmailId
                          : " --"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>Vehicle Number</p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList.length !== 0
                          ? filterList?.vehicle?.vehicleNumber
                          : "--"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>Start Date</p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList.length !== 0 ? filterList?.startDate : "--"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>End Date</p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList.length !== 0 ? filterList?.endDate : "--"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>Reason Category</p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList.length !== 0
                          ? filterList?.reasonCategory
                          : "--"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>
                        Reason Description
                      </p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item>
                      <p className={classes.value}>
                        {filterList.length !== 0
                          ? filterList?.reasonDescription
                          : "--"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.heading}
                    >
                      <p className={classes.subHeaderColumn}>Status Log</p>
                      <p style={{ marginLeft: "auto" }}>:</p>
                    </Grid>
                    <Grid item container direction="column">
                      {statusLogs.map((row, index) => (
                        <>
                          <Grid
                            item
                            container
                            direction="row"
                            key={index}
                            style={{ justifyContent: "space-between" }}
                          >
                            <Grid item>
                              <p
                                className={classes.value}
                                style={{ fontWeight: "bolder" }}
                              >
                                {row?.remarksBy + " - "}
                                <span
                                  style={{
                                    fontFamily: "Poppins !important",
                                    color:
                                      row?.status?.name === "PENDING"
                                        ? "#67B561"
                                        : row?.status?.name ===
                                          "EXTENSION_PENDING"
                                        ? "#67B561"
                                        : row?.status?.name === "APPROVED" ||
                                          row?.status?.name ===
                                            "EXTENSION_APPROVED"
                                        ? "#67b561"
                                        : row?.status?.name === "CANCELLED" ||
                                          row?.status?.name ===
                                            "EXTENSION_CANCELLED"
                                        ? "#F30626"
                                        : row?.status?.name === "REJECTED" ||
                                          row?.status?.name ===
                                            "EXTENSION_REJECTED"
                                        ? "#E46A76"
                                        : row?.status?.name === "COMPLETED" ||
                                          row?.status?.name ===
                                            "EXTENSION_COMPLETED"
                                        ? "#67b561"
                                        : undefined,
                                  }}
                                >
                                  {row?.status?.name.match("EXT")
                                    ? "Extension "
                                    : ""}
                                  Request{" "}
                                  {row?.status?.name === "PENDING" ||
                                  row?.status?.name === "EXTENSION_PENDING"
                                    ? "Submitted"
                                    : row?.status?.name === "EXTENSION_APPROVED"
                                    ? "Approved"
                                    : row?.status?.name === "EXTENSION_REJECTED"
                                    ? "Rejected"
                                    : row?.status?.name ===
                                      "EXTENSION_CANCELLED"
                                    ? "Cancelled"
                                    : row?.status?.displayName}
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                className={classes.value}
                                style={{ fontWeight: "bolder" }}
                              >
                                {moment(
                                  row?.lastUpdatedDate
                                    ? row?.lastUpdatedDate
                                    : "",
                                  "x"
                                ).format("DD MMM, hh:mm a") || "--"}
                              </p>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <p
                              className={classes.valueRemarks}
                              style={{ marginTop: "-15px" }}
                            >
                              {row?.remarks}
                            </p>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                  >
                    <Grid item>
                      <p className={classes.headingRight}>Status: </p>
                    </Grid>
                    <Grid item>
                      <p
                        className={classes.valueRight}
                        style={{
                          color:
                            filterList?.status?.name === "PENDING"
                              ? "orange"
                              : filterList?.status?.name === "APPROVED"
                              ? "#67b561"
                              : filterList?.status?.name === "CANCELLED"
                              ? "#F30626"
                              : filterList?.status?.name === "REJECTED"
                              ? "#E46A76"
                              : filterList?.status?.name === "COMPLETED"
                              ? "#67b561"
                              : filterList?.status?.name ===
                                "EXTENSION_APPROVED"
                              ? "#67b561"
                              : filterList?.status?.name === "EXTENSION_PENDING"
                              ? "orange"
                              : filterList?.status?.name ===
                                "EXTENSION_REJECTED"
                              ? "#E46A76"
                              : filterList?.status?.name ===
                                "EXTENSION_CANCELLED"
                              ? "#F30626"
                              : undefined,
                        }}
                      >
                        {filterList?.status?.displayName}
                      </p>
                    </Grid>
                    {!status.match("EXT") ? (
                      <>
                        <Grid item className={classes.gridValues}>
                          <button
                            type="button"
                            disabled={
                              disabled ||
                              status === "REJECTED" ||
                              status === "APPROVED" ||
                              status === "COMPLETED" ||
                              status === "CANCELLED"
                            }
                            className={classes.statebtnapprove}
                            style={{
                              backgroundColor:
                                disabled ||
                                status === "REJECTED" ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED"
                                  ? "#A2A2A2"
                                  : "#67b561",
                              cursor:
                                disabled ||
                                status === "REJECTED" ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED"
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => statusChange("approve")}
                          >
                            Approve
                          </button>
                        </Grid>
                        <Grid item className={classes.gridValues}>
                          <button
                            type="button"
                            id={"approve"}
                            disabled={
                              disabled ||
                              status === "APPROVED" ||
                              status === "COMPLETED" ||
                              status === "CANCELLED" ||
                              status === "REJECTED"
                            }
                            className={classes.statebtnreject}
                            style={{
                              backgroundColor:
                                disabled ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "REJECTED"
                                  ? "#A2A2A2"
                                  : "#E46A76",
                              cursor:
                                disabled ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "REJECTED"
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => statusChange("reject")}
                          >
                            Reject
                          </button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item className={classes.gridValues}>
                          <button
                            type="button"
                            disabled={
                              disabled ||
                              status === "REJECTED" ||
                              status === "APPROVED" ||
                              status === "COMPLETED" ||
                              status === "CANCELLED" ||
                              status === "EXTENSION_REJECTED" ||
                              status === "EXTENSION_APPROVED" ||
                              status === "EXTENSION_CANCELLED"
                            }
                            className={classes.statebtnapprove}
                            style={{
                              backgroundColor:
                                disabled ||
                                status === "REJECTED" ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "EXTENSION_REJECTED" ||
                                status === "EXTENSION_APPROVED" ||
                                status === "EXTENSION_CANCELLED"
                                  ? "#A2A2A2"
                                  : "#67B561",
                              cursor:
                                disabled ||
                                status === "REJECTED" ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "EXTENSION_REJECTED" ||
                                status === "EXTENSION_APPROVED" ||
                                status === "EXTENSION_CANCELLED"
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => statusChange("approveExt")}
                          >
                            Approve Extension
                          </button>
                        </Grid>
                        <Grid item className={classes.gridValues}>
                          <button
                            type="button"
                            id={"approve"}
                            disabled={
                              disabled ||
                              status === "APPROVED" ||
                              status === "COMPLETED" ||
                              status === "CANCELLED" ||
                              status === "REJECTED" ||
                              status === "EXTENSION_REJECTED" ||
                              status === "EXTENSION_APPROVED" ||
                              status === "EXTENSION_CANCELLED"
                            }
                            className={classes.statebtnreject}
                            style={{
                              backgroundColor:
                                disabled ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "REJECTED" ||
                                status === "EXTENSION_REJECTED" ||
                                status === "EXTENSION_APPROVED" ||
                                status === "EXTENSION_CANCELLED"
                                  ? "#A2A2A2"
                                  : "#E46A76",
                              cursor:
                                disabled ||
                                status === "APPROVED" ||
                                status === "COMPLETED" ||
                                status === "CANCELLED" ||
                                status === "REJECTED" ||
                                status === "EXTENSION_REJECTED" ||
                                status === "EXTENSION_APPROVED" ||
                                status === "EXTENSION_CANCELLED"
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => statusChange("rejectExt")}
                          >
                            Reject Extension
                          </button>
                        </Grid>
                      </>
                    )}
                    <Grid item className={classes.gridValues}>
                      <button
                        type="button"
                        disabled={
                          disabled ||
                          status === "PENDING" ||
                          status === "REJECTED" ||
                          status === "CANCELLED" ||
                          status === "COMPLETED"
                        }
                        className={classes.statebtnapprove}
                        onClick={() => statusChange("complete")}
                        style={{
                          backgroundColor:
                            disabled ||
                            status === "PENDING" ||
                            status === "REJECTED" ||
                            status === "CANCELLED" ||
                            status === "COMPLETED"
                              ? "#A2A2A2"
                              : "#67b561",
                          cursor:
                            disabled ||
                            status === "PENDING" ||
                            status === "REJECTED" ||
                            status === "CANCELLED" ||
                            status === "COMPLETED"
                              ? "default"
                              : "pointer",
                        }}
                      >
                        Complete
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <div style={{ height: "422px" }}>
                <Loader />
              </div>
            )}
          </Container>
        </Card>
        <Grid item className={classes.backButtonGrid}>
          <button
            type="button"
            style={{ padding: "9px 14px" }}
            className={classes.backButton}
            onClick={backHandler}
          >
            Back
          </button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        //BackdropProps={{ style: { backgroundColor: '#DEDEDE' } }}
        PaperProps={{
          style: { padding: "2.5% 3.25%", width: "40%", marginLeft: "25%" },
        }}
        style={{ zIndex: 0 }}
      >
        {isLoading && <Loader />}
        <MuiDialogTitle style={{ padding: 0 }}>
          <Grid item container direction="row" justify="space-between">
            <Grid item className={classes.dialogTitle}>
              {dialogTitle}
            </Grid>
            <Grid item style={{ visibility: "hidden" }}>
              <CloseIcon
                className={classes.dialogCloseIcon}
                onClick={dialogCloseIcon}
              />
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Grid item>
            <Divider />
            <Grid item className={classes.dialogHeading}>
              Enter your Comment
            </Grid>
            <Grid container direction="row">
              <Grid item className={classes.textareaGrid}>
                <textarea
                  placeholder="Enter atleast 2 and maximum 200 characters"
                  className={classes.dialogTextArea}
                  value={adminRemarks}
                  onChange={(e) => setAdminRemarks(e.target.value)}
                ></textarea>
              </Grid>
            </Grid>
          </Grid>
          {adminRemarks.length > 200 && (
            <p className={classes.textareaError}>
              Enter atleast 2 and maximum 200 characters
            </p>
          )}
          {adminRemarks.length === 1 && (
            <p className={classes.textareaError}>
              Enter atleast 2 and maximum 200 characters
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              className="dialogActionGrid"
            >
              <Grid item>
                <button
                  type="submit"
                  onClick={dialogCloseIcon}
                  className={classes.dialogCancel}
                >
                  Cancel
                </button>
              </Grid>
              <Grid item>
                <button
                  type="submit"
                  className={classes.dialogSubmit}
                  disabled={
                    adminRemarks.length <= 1 || adminRemarks.length > 200
                  }
                  style={{
                    backgroundColor:
                      adminRemarks.length <= 1 || adminRemarks.length > 200
                        ? "#A2A2A2"
                        : undefined,
                  }}
                  onClick={updateStatus}
                >
                  Confirm
                </button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(ONPDetails));
