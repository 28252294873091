// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import app from './modules/app/reducers';

//@component
//combineReducers
export default combineReducers({
  app,
  router,
});
