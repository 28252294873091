import React from "react";
import "./style.scss";
const Violation = ({ color, text, value = 0 }) => {
  return (
    <div className="violation-container">
      <div style={{ backgroundColor: color }} className="violation-code">
        {value}
      </div>

      <div style={{ position: "relative" }} className="violation-message">
        <div>
          <p style={{ fontSize: "1.09vw" }}>{text}</p>
          <p style={{ fontSize: "1.02vw", color: "#919191" }}>Today</p>
        </div>
      </div>
    </div>
  );
};

export default Violation;
