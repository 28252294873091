// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles} from '@material-ui/core/styles'

//@component
//reportUseStyles
const listviewUseStyles = makeStyles(()=>({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#e46a76 !important',
      color: '#ffffff',
      fontWeight:'900',
     },
     '& .MuiPaginationItem-root' : {
     fontFamily: '\'Poppins\' !important',
     fontSize: '14px',
     },
    },
  labelStyle:{
    paddingTop: "12px"
  },
  liStyle:{
    display: "flex"
  },
  applyButtonFilter: {
    cursor:"pointer",
    fontFamily: '\'Poppins\' !important',
    fontSize: "11px",
    fontWeight: "bolder",
    borderRadius: "90px",
    border:"none",
    width: "60%",
    color: "#fff",
    background: "#e46767",    
    padding: "5px 10px",    
    marginTop:"4px",
    '&:hover': {
      cursor:"pointer",
      fontFamily: '\'Poppins\' !important',
      fontSize: "11px",
      fontWeight: "bolder",
      borderRadius: "90px",
      border:"none",
      width: "60%",
      color: "#fff",
      background: "#e46767",      
      padding: "5px 10px",
      textDecoration: "none",
      marginTop:"4px",
    },
    '.MuiButton-outlinedSecondary:hover': {
      border: "none"
    }
  },
  hrStyling:{
    marginTop:"20px"
  },
  liGrid: {
    height:"25px"
  },
  filterLabel:{
    fontFamily: '\'Poppins\' !important',
    fontSize: "10px",
    color:'#21034F',
  },
  filter_box: {
    right:"0.0001%",
    width: "180px",
    listStyle: "none",
    backgroundColor: "#fff",
    position: "absolute",
    top: "98%",
    zIndex: 1,
    color: "#21034F",
    fontSize: "10px",
    paddingBottom: "20%",
    borderRadius: "15px",
    boxShadow:'0px 3px 6px #00000029 !important',
    '.MuiButton-outlinedSecondary': {
      border: "none"
    },
    '& li': {
      display: "flex",
      marginRight: "10px",
      marginLeft: "10px"
    },
    '& label': {
      paddingTop: "12px"
    }
  },
  filter_image: {
    width: "65%"
  },
  filter_button: {
    position: "relative",
    border: "none",
    '&:hover': {
      border: "none"
    },
    '& hr': {
      marginLeft: "23.8px",
      marginRight: "23.8px"
    }
  },
  dialog:{
    zIndex: 0
  },
  topRowGrid:{
    // marginTop: "1px"
  },
 
  boldText:{
    textWeight:"bold"
  },
  closeIcon:{
    cursor:"pointer"
  },
  notFoundGrid:{
    marginTop: "5%"
  },
  viewTableCell:{
    cursor: 'pointer', 
    textAlign: 'left'
  },
  viewSpan:{
    color: '#03A9F3', 
    fontFamily: '\'Poppins\' !important',
    fontSize:"12px",    
    wordBreak:'break-word',
    fontWeight:'500',
    padding:'1%',
  },
  statusRow:{
    fontFamily: '\'Poppins\' !important',
    fontSize:"12px",    
    wordBreak:'break-word',
    fontWeight:'500',
    padding:'1%',
  },
  tableRowNoImage:{
    height:"60vh"
  },
  gridContainerNoImage:{
    position:"absolute",
    paddingBottom:"1%",
    marginBottom: '10px'
  },
  typographyGridNoImage:{
    marginRight:"20%"
  },
  paginationGrid:{
    marginRight:"3%"
  },
  paginationOuterGrid:{
    marginBottom:"6%",
    padding: '2% 0% 1% 0%',
    paddingRight:'0%'
  },
  pageTextGridSelect:{
    marginTop:"3.8%",
    //marginRight:'1%'
    
  },
  pageFilterGrid:{
    marginTop:"10%"
  },
  pageTextGrid:{
    marginTop:"3%",
    //marginRight:'1%'
    
  },
  tableCell:{
    fontFamily: '\'Poppins\' !important',
    fontSize:"12px",  
    color:'#21034F',  
    wordBreak:'break-word',
    fontWeight:'500',
    padding:'1%',
  },
  editCross:{
    visibility:"hidden"
  },
  input_cross:{
    // marginLeft:"1em"
  },
  topRowGridParent:{
    height:"50px",
    marginTop:'1%',
    marginBottom:'0.5%',
  },
  selectNavcards:{
      width: "30%",
      height: "80%",
      textAlign: "center",
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
      marginRight: "5%",
      border: "none",
      borderRadius: "6px",
      background: "#ffffff",
      padding: "0 1px",
      paddingRight: "2%",
      marginTop: "2%",
  },
  aboveRowsGrid:{
    height:"40px"
  },
  aboveTableGrid:{
    
  },
  input: {
    width: '100%',
    borderRadius: '20px',
    border: 'none',
    outline: 'none',
    padding: '10px',
    backgroundColor: 'lightgrey',
  },
  rowsLabel:{
    fontSize:"0.7rem",
    marginRight:"3%",
    fontWeight: "bold"
  },
  dropdownNumber: {
    border: "none",
    color: "#a2a2a2",
    outline: "none",
    marginTop:"5%"
  },
  '&.MuiPaper-rounded':{
    borderRadius:"50%"
  },
  selectListCard:{
    fontFamily: '\'Poppins\' !important',
    fontSize:'small',
    width: "76%",
    height: '42px',
    textAlign: "left",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    border: "none",
    borderRadius: "90px",
    background: "#fff",
    color:'#21034F',
    padding:"4px 10px",
    marginTop: 0,
    borderRight: '10px solid #ffffff',
    '&:focus':{
      outline:'none'
    },
    
  },
  
  selectDiv: {
    padding: '4px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "75%",
    backgroundColor:"#fff",
    borderRadius: "50px",
    variant:'elevation',
    
    // boxShadow:'0px 3px 6px #00000029 !important'
  },
  searchInput: {
    fontFamily: '\'Poppins\' !important',
    fontSize:'12px',
    color:'#21034F',
    letterSpacing: '0.5px',
    borderRadius: '90px',
    width: '190%',        
    border: 'none',
    outline: 'none',
    padding: '10px',
    backgroundColor: 'lightgrey',
  },
  searchDiv: {
    padding: '1px 1px',
    width: "70%",
    marginLeft:"1.7%",
    backgroundColor:"lightgrey",
    color:'#21034F',
    borderRadius: "90px",
    variant:'elevation',
  },
  searchIconButton: {
    width: '20px',
    fontSize: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '0px',
    color: "#a1a1a1",
    marginLeft:"5px"
  },
    tableGrid:{
      marginTop:"1%",
      width:"96%",
      maxHeight:"30%",
      marginLeft:"1%",
      display: 'flex',
      flexWrap: 'nowrap',
    },

    table: {
       // minWidth: 650,
       
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft:"8px"
    },
    thData:{
      fontFamily: '\'Poppins\' !important',
      fontWeight: 'bolder',
      fontSize: '12px',
      color:'#21034F !important',
      padding: '1%',
      backgroundColor: 'rgba(226, 220, 242, 0.38)',
      height:'70px',
      wordWrap: 'break-word',
      lineHeight: '1.6', 
    },
    paper:{
      boxShadow:'0px 3px 6px #00000029 !important',
    },
    paginationStyle:{
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '90px',
      paddingLeft: '15px',
      boxShadow: '0px 3px 6px #00000029 !important',
    },
}));

export default listviewUseStyles;