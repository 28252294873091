// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";
import { emergencyListHeaderCell } from "../../../Data/report-page";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../../redux/modules/app/actions";
import listviewUseStyles from "./styles";
import Loader from "../../../Components/Preloader/index";
import httpRequest from "../../../Utils/httpRequest";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "../../../Assets/Images/edit.png";

var classData = "";

//@component
//SOSListView
function SOSListView(props) {
  const classes = listviewUseStyles();

  const [viewList, setViewList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("datetime");
  const [pageNumber, setPagenumber] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState(0);
  const [contactEmail, setContactEmail] = useState("");
  const [parkingId, setParkingId] = useState("");
  const [status, setStatus] = useState(null);
  const [internetFlag, setInternetFlag] = useState(false);

  // wrapper function for enable-diable toggle switch
  const EnableSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "green",
        },
      },
    },

    "& .MuiSwitch-track": {
      backgroundColor: "red",
    },
  }));

  //mui custom styling
  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
    },
  }))(MuiDialogActions);
  // function to set initial table value
  const getSOSList = () => {
    setIsLoading(true);
    httpRequest.get(
      "sosList",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: postsPerPage,
        pageNumber: pageNumber,
      }
    );
  };
  // success callback get list
  const successCallbackList = (jsonResponse) => {
    if (!jsonResponse) {
      setInternetFlag(true);
      setResponseDataNotFound(
        "You don't have any Emergency Support contact added here."
      );
    }
    if (jsonResponse.data?.page) {
      setTotalElements(jsonResponse.data.page.totalElements);
      setViewList(jsonResponse.data._embedded.results);
      const te = jsonResponse.data.page.totalElements;
      setIsLoading(false);

      httpRequest.get(
        "sosList",
        {
          "Content-Type": "application/json",
        },
        successCallbackAll,
        errorCallbackList,
        {
          size: te,
          pageNumber: pageNumber,
        }
      );
    }
    if (jsonResponse.data._embedded.results.length === 0) {
      setResponseDataNotFound(
        "You don't have any Emergency Support contact added here."
      );
    }
  };
  //get all data for client search and filter
  const successCallbackAll = (jsonResponse) => {
    setInternetFlag(false);
    setTotalPages(jsonResponse.data.page.totalPages);
  };
  // error callback get list
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };
  // function for enable/disable api
  const actionHandler = () => {
    setIsLoading(true);
    let activate = false;
    if (status === true) {
      activate = false;
    }
    if (status === false) {
      activate = true;
    }

    httpRequest.put(
      "sosEnableDisable",
      {},
      {
        "Content-Type": "application/json",
      },
      successCallbackAction,
      errorCallbackAction,
      {
        parkingId: parkingId,
        action: activate,
      }
    );
    setDialogOpen(false);
  };
  // success callback for actionHandler
  const successCallbackAction = (jsonResponse) => {
    setIsLoading(false);
    let alertStatus = "";
    if (status === true) {
      alertStatus = "Disabled";
    }
    if (status === false) {
      alertStatus = "Enabled";
    }
    if (jsonResponse) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `Emergency Support ${contactName} ${alertStatus} successfully`,
          color: alertStatus === "Enabled" ? "green" : "red",
        })
      );
      setViewList([]);
      getSOSList();
    }
  };
  // failure callback for actionHandler
  const errorCallbackAction = (error) => {
    setIsLoading(false);
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };
  // function to handle enable disable state
  const enableHandler = (parkingId, status) => {
    let contactList = viewList.filter((item) => item.parkingId === parkingId);
    contactList.forEach((item) => {
      setParkingId(item?.parkingId);
      setContactName(item?.sosInfo?.contactName);
      setContactNumber(item?.sosInfo?.contactNumber);
      setContactEmail(item?.sosInfo?.contactEmail);
      setStatus(status);
    });
    setDialogOpen(true);
  };
  // callback to set page number and size for pagination
  const pageHandler = (number) => {
    let pageSize = postsPerPage || 10;
    let pageNumber = number || 0;
    setPagenumber(number);
    httpRequest.get(
      "sosList",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: pageSize,
        pageNumber: pageNumber,
      }
    );
  };
  // function to handle pagination change
  const handleChange = (event, value) => {
    let currpage;
    currpage = value - 1;

    pageHandler(currpage);
  };
  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("soslistview"));
    props.dispatch(updateSidebar(3));
    props.dispatch(updateBubble(5));
    getSOSList();
  }, [totalElements, totalPages]);

  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };

    return (
      <TableHead>
        <TableRow>
          {emergencyListHeaderCell.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "s.no" || headCell.id === "actions"
                  ? "center"
                  : "left"
              }
              width={headCell.width}
              className={classes.thData}
              // style={{paddingLeft:headCell.label==='Email ID'?"1em":headCell.id==='pname'?'4%':undefined,color:'#21034F'}}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // function for clearing search
  const clearSearch = () => {
    setSearchKey("");
    classData = "";
    setFilterList([]);
    setViewList([]);
    getSOSList();
  };
  //function to handle search functionality
  const handleSearchKeyChange = (e) => {
    var inputBox = document.getElementById(e.target.id);
    var invalidChars = [
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ".",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());
    if (e.target.value.length <= 30) {
      if (invalidChars.includes(e.target.value)) {
        e.preventDefault();
        setSearchKey("");
        classData = "";
      } else {
        classData = e.target.value;
        if (e.target.value) {
          setSearchKey(e.target.value);
          let resultFirstName = viewList.filter((item) => {
            if (item) {
              if (item.name) {
                return (
                  item?.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  item?.contactName
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  item?.contactNumber.includes(e.target.value) ||
                  item?.contactEmail
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
                );
              }
            }
          });
          if (resultFirstName.length === 0) {
            setTableDataNotFound("No Results Found");
          }
          setFilterList(resultFirstName);
        }
        if (!e.target.value) {
          setSearchKey(null);
          setFilterList([]);
          getSOSList();
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Grid container direction="row" className={classes.topRowGrid}>
            <Grid item>
              <Grid
                container
                justify="space-between"
                className={classes.searchDiv}
              >
                <Grid item>
                  <input
                    id="searchbox"
                    className={classes.searchInput}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search account" }}
                    value={searchKey}
                    onChange={(e) => handleSearchKeyChange(e)}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    className={classes.searchIconButton}
                    aria-label="search-emergency-contact"
                  >
                    {classData === "" && <SearchIcon />}
                    <p
                      className={
                        classData === ""
                          ? classes.editCross
                          : classes.input__cross
                      }
                      onClick={clearSearch}
                    >
                      <ClearIcon />
                    </p>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {responseDataNotFound.length === 0 ? (
          <React.Fragment>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer component={Paper} className={classes.paper}>
                  <Table className={classes.table} aria-label="View table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {(searchKey ? filterList : viewList).length !== 0 &&
                      internetFlag === false ? (
                        (searchKey ? filterList : viewList).map(
                          (row, index) => {
                            // viewList.length !== 0 && internetFlag === false
                            //   ?
                            //   viewList.map((row, index) => {
                            let no = index;
                            function formatNo(no) {
                              return no < 10
                                ? pageNumber + "" + no
                                : (pageNumber + 1) * no;
                            }
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                >
                                  {formatNo(no + 1)}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row.sosInfo.contactName}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row.sosInfo.contactEmail}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row.sosInfo.contactNumber}
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell}
                                  style={{ paddingLeft: "5%" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <EnableSwitch
                                        size="small"
                                        sx={{ m: 1 }}
                                        checked={row.sosInfo.isSosEnabled}
                                        onChange={() =>
                                          enableHandler(
                                            row.parkingId,
                                            row.sosInfo.isSosEnabled
                                          )
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.actionRow}
                                  align="center"
                                >
                                  <Link
                                    to={{
                                      pathname: "/portal/editsos",
                                      state: { detail: row },
                                    }}
                                  >
                                    <IconButton size="small">
                                      <img
                                        alt="edit"
                                        src={EditIcon}
                                        className={classes.editIconList}
                                      />
                                    </IconButton>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      ) : (
                        <TableRow className={classes.tableRowNoImage}>
                          <TableCell>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                className={classes.gridContainerNoImage}
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  className={classes.typographyGridNoImage}
                                >
                                  <Typography variant="body1">
                                    {tableDataNotFound}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item className={classes.paginationGrid}>
              <Grid container direction="row" justify="flex-end">
                <Grid item className={classes.paginationStyle}>
                  <Pagination
                    count={totalPages}
                    page={pageNumber + 1}
                    className={classes.root}
                    size="small"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item className={classes.notFoundGrid}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1">{responseDataNotFound}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
          PaperProps={{
            style: {
              padding: "2.5% 3.25%",
            },
          }}
          className={classes.dialog}
        >
          <MuiDialogTitle
            style={{
              paddingBottom: "0px",
              paddingTop: "2px",
              paddingLeft: "2px",
              paddingRight: "2px",
            }}
          >
            <Grid item>
              <Grid container direction="row" justify="space-between">
                <Grid item className={classes.dialogHeaderTitle}>
                  {status === false && "Enable Emergency Support"}
                  {status === true && "Disable Emergency Support"}
                </Grid>
              </Grid>
            </Grid>
          </MuiDialogTitle>
          <Divider />
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Grid item>
              <Grid container direction="column" justify="center">
                <Grid item>
                  <p className={classes.dialogSubHeader}>
                    Are you sure you want to
                    <span>
                      {status === false && " Enable"}
                      {status === true && " Disable"}
                    </span>{" "}
                    the selected Emergency Support contact details?
                  </p>
                </Grid>
                <Grid item>
                  <p>
                    <span className={classes.dialogSubTitle}>
                      Contact Name:
                    </span>{" "}
                    <span className={classes.dialogSubText}>
                      {contactName.substring(0, 50)}
                    </span>
                  </p>
                </Grid>
                <Grid item>
                  <p>
                    <span className={classes.dialogSubTitle}>Contact No:</span>{" "}
                    <span className={classes.dialogSubText}>
                      {contactNumber}
                    </span>
                  </p>
                </Grid>
                <Grid item>
                  <p>
                    <span className={classes.dialogSubTitle}>
                      Contact Email:
                    </span>{" "}
                    <span className={classes.dialogSubText}>
                      {contactEmail.substring(0, 50)}
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.dialogActionGrid}
              >
                <Grid item className={classes.dialogCancelGrid}>
                  <button
                    type="button"
                    onClick={() => setDialogOpen(false)}
                    className={classes.dialogCancel}
                  >
                    Cancel
                  </button>
                </Grid>
                <Grid item className={classes.dialogConfirmGrid}>
                  <button
                    type="button"
                    className={classes.dialogConfirm}
                    onClick={actionHandler}
                  >
                    Confirm
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(SOSListView));
