// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../Components/Preloader/index";
import { createOrg } from "../api/orgManagementApis";

import "./style.scss";

//@component
//Add organization
function AddOrg(props) {
  const [isLoading, setIsLoading] = useState(false);
  // const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [acr, setAcr] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  //function to add organization api
  const addOrg = async (name, acrHandle) => {
    try {
      setIsLoading(true);
      var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
      if (format.test(name)) {
        setIsLoading(false);
        setDialogOpen(false);
        props.dispatch(
          updateToasterConfig({
            show: true,
            message:
              "Error! Please remove special characters in organization name and proceed.",
            color: "red",
          })
        );
      } else {
        await createOrg(name, acrHandle);
        //const modifiedName = name.replace(/[^a-zA-Z ]/g, "");
        setIsLoading(false);
        setDialogOpen(false);
        clearHandler();

        props.dispatch(
          updateToasterConfig({
            show: true,
            message: `Organization added successfully`,
            color: "green",
          })
        );
        props.history.push("/portal/vieworganization");
      }
    } catch (error) {
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.message || error.error,
          color: "red",
        })
      );
    }
  };

  //function to clear fields after creating account
  const clearHandler = () => {
    setName("");
    setAcr("");
  };
  //function for dialog confirm button
  const submitOrgConfirm = () => {
    setDialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setDialogOpen(false);
  };
  //function for pre-details submit api call
  const submitOrg = () => {
    addOrg(name, acr);
  };
  //functions for name handler
  const nameHandler = (event) => {
    var inputBox = document.getElementById(event.target.id);
    var invalidChars = [
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());

    if (event.target.value.length <= 50) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      } else {
        setNameError("");
        setName(event.target.value);
      }
    }
    if (event.target.value.length > 50) {
      setNameError("Maximum 50 characters allowed");
    }
    if (event.target.value.length < 3) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      } else {
        setNameError("");
        setName(event.target.value);
      }
    }
    if (!event.target.value) {
      setNameError("");
    }
  };
  //function for acronym handler
  const acrHandler = (event) => {
    setAcr(event.target.value);
  };

  // function for form cancel button
  const formCancelHandler = () => {
    props.history.push("/portal/vieworganization");
  };
  //function call on component render/re-render
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("addorganization"));
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="org-root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Add Organization Account</h2>
              </div>
              <form className="form-field" style={{ padding: 0 }}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Organization Name*</label>
                    <br />
                    <input
                      className="text-field-item"
                      id="orgId"
                      type="text"
                      name="orgName"
                      placeholder="Enter the input field"
                      value={name}
                      onChange={nameHandler}
                    />
                    <p>
                      <span className="error_style">{nameError}</span>
                    </p>
                  </div>
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Organization Acronym*</label>
                    <br />
                    <input
                      className="text-field-item"
                      id="acrId"
                      type="text"
                      name="orgAcr"
                      placeholder="Enter the input field"
                      value={acr}
                      onChange={acrHandler}
                    />
                  </div>
                </div>
                <div className="button-section">
                  <button
                    type="button"
                    className="form-button-submit"
                    data-testid="select-service-btn"
                    id="submitButton"
                    onClick={submitOrgConfirm}
                    disabled={
                      name.length === 0 ||
                      nameError.length > 0 ||
                      acr.length === 0
                    }
                    style={{
                      backgroundColor:
                        name.length === 0 ||
                        nameError.length > 0 ||
                        acr.length === 0
                          ? "#a2a2a2"
                          : undefined,
                    }}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    id="cancelButton"
                    data-testid="cancel-button"
                    className="form-button-cancel"
                    onClick={formCancelHandler}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={handleClose}
        aria-labelledby="create-security-admin-dialog"
      >
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">Add Organization</span>
            </Grid>
            {/* <Grid item>
                  <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                </Grid> */}
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
          className="dialog-content"
        >
          <p className="dialog-content-text" data-testid="popup-text">
            Do you want to add this organization?
          </p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button
            type="button"
            onClick={submitOrg}
            id="confirmSubmit"
            data-testid="confirm-button"
            className="confirm-button"
          >
            Confirm
          </button>
          <button
            type="button"
            onClick={handleClose}
            id="cancelPopup"
            data-testid="cancel-popup"
            className="cancel-button"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   updateToasterConfig,
//   dispatch,
// });

//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(AddOrg)
);
