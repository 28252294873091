// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { updateBreadcrumbs } from '../../../redux/modules/app/actions'
import detailsUseStyles from './styles'
import httpRequest from '../../../Utils/httpRequest'
import Loader from '../../../Components/Preloader/index'
import editIcon from '../../../Assets/icons/edit_white.png'
import deleteIcon from '../../../Assets/icons/delete_white.png'



//@component
//ViewDetails
const ViewAccDetails = React.memo(props => {
    const classes = detailsUseStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [filterList, setFilterList] = useState({})
    const [orgDetails, setOrgDetails] = useState({})
    const [userCount, setUserCount] = useState(0)
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverity] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const [userId, setUserId] = useState([])
    const [orgId, setOrgId] = useState("")
    const [showDialog, setShowDialog] = useState(false)

    //alert toaster function
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    //function for alert toaster
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false)
    }
    //function to close delete confirm popup
    const handleClose = () => {
        setShowDialog(false);
    };


    //api for data filter list as per view user Id
    const generateListHandler = () => {
        setIsLoading(true)
        if (props.location.state) {
            httpRequest.get(
                'mtFcmdetails',
                {
                    'Content-Type': 'application/json',
                },
                successCallbackList,
                errorCallback,
                {
                    userId: props.location.state.id || props.location.state.viewId
                },
            )
        }
    }

    //success callback for list handler
    const successCallbackList = jsonResponse => {
        setIsLoading(false)
        if (jsonResponse) {
            setFilterList(jsonResponse.data)
        }
    }

    //error callback
    const errorCallback = error => {
        if (error) {
            setIsLoading(false)
        }
    }

    //function for user count details by orgid
    const generateUserCount = () => {
        setIsLoading(true)
        if (props.location.state) {
            httpRequest.get(
                'mtOrgUserCount',
                {
                    'Content-Type': 'application/json',
                },
                successCallbackCount,
                errorCallbackCount,
                {
                    'orgId': props.location.state.orgId || props.location.state.editOrgId
                },
            )
        }
    }

    //success callback for user count details by orgid 
    const successCallbackCount = jsonResponse => {
        setIsLoading(false)
        if (jsonResponse) {
            jsonResponse.data.map(countValue => {
                countValue.userCount.map(roleValue => {
                    if (roleValue.role.name === "FACILITY_ADMIN") {
                        setUserCount(roleValue.active)
                    }
                })
            })
        }
    }

    //error callback for user count details by orgid
    const errorCallbackCount = error => {
        setIsLoading(false)
        if (error) {
            // error message if any
        }
    }

    //function for api call org details by id
    const generateDatatHandlerOrg = () => {
        if (props.location.state) {
            httpRequest.get(
                'mtOrgDetailsbyId',
                {
                    'Content-Type': 'application/json',
                },
                successCallbackOrgDetails,
                errorCallbackOrgDetails,
                {
                    'orgId': props.location.state.orgId || props.location.state.editOrgId
                },
            )
        }
    }

    //success callback for org api call
    const successCallbackOrgDetails = jsonResponse => {
        //setIsLoading(false)
        if (jsonResponse) {
            setOrgDetails(jsonResponse.data)
        }
    }

    //error callback for org api call
    const errorCallbackOrgDetails = error => {
        if (error) {
            // error message if any
        }
    }
    //function for back button 
    const backHandler = () => {
        if (props.location.state) {
            props.history.push({
                pathname: '/portal/viewaccount',
                state: {
                    currentPage: props.location.state.currentPage,
                    pressed: true
                }
            });
        }
    }
    //function for edit handler
    const editHandler = (id) => {
        props.history.push({
            pathname: '/portal/editaccount',
            state: {
                id: `${id}` || props.location.state.viewId,
                solOrgId: props.location.state.orgId || props.location.state.editOrgId,
                source: "edit"
            }
        });
    }

    //function for edit account alert
    const editAlert = () => {
        setOpenAlert(true)
        setAlertMessage("Account updated successfully")
        setSeverity("Success")
    }

    //api call to delete account  
    const deleteHandler = () => {
        setIsLoading(true)
        if (props.location.state) {
            httpRequest.deleteAdmin(
                'adminDelete',
                {
                    "userIds": userId,
                    "adminRemarks": "left the company",

                    "roles": ["CLOSED_GROUP_PARKING_PROVIDER"],
                    "org": {
                        "orgId": orgId
                    }
                },
                {
                    'Content-Type': 'application/json'
                },
                successCallbackDelete,
                errorCallbackDelete,
                {},
            )
        }

    }
    //error callback to delete account
    const successCallbackDelete = jsonResponse => {
        if (jsonResponse) {
            setIsLoading(false)
            props.history.push({
                pathname: "/portal/viewaccount",
                state: {
                    trigger: "delete"
                }
            })
        }
    }

    //error callback for deleting account
    const errorCallbackDelete = error => {
        setIsLoading(false)
        if (error.message !== "Internal Server Error, Please try again later") {
            props.history.push({
                pathname: "/portal/viewaccount",
                state: {
                    trigger: "delete"
                }
            })
        }
    }

    const deleteButtonHandler = (data1, data2) => {
        let tempId = [];
        setShowDialog(true)
        setUserId(tempId.concat(data1))
        setOrgId(data2)
    }


    //adding component to breadcrumb and displaying filtered data as per view user id
    useEffect(() => {
        props.dispatch(updateBreadcrumbs("viewaccdetails"))
        generateListHandler()
        generateDatatHandlerOrg()
        generateUserCount()
        if (props.location.state) {
            if (props.location.state.destination === "edit") {
                editAlert()
            }
        }
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <Grid container
                direction="column"
                className={classes.container}
            >
                {/* View Account Details */}
                <Card className={classes.detailsCard} style={{ marginBottom: "2em" }}>
                    <Grid item>
                        <Grid container
                            direction="column"

                        >
                            <Grid item>
                                <Grid container
                                    direction="row"
                                    style={{ backgroundColor: "#E5E2EF", height: "3.5em" }}
                                >
                                    <Grid item style={{ marginTop: "1em", marginLeft: "2em" }}>
                                        <Typography style={{ fontWeight: "600" }}>View Account Details</Typography>
                                    </Grid>
                                    <Grid item container
                                        justify="flex-end"
                                        style={{ width: "80%" }}
                                    >
                                        <Grid item style={{ marginTop: "1em", marginRight: "2em", visibility: "hidden" }}>
                                            <Button
                                                className={classes.editButton}
                                                variant="contained"
                                                onClick={() => editHandler(filterList.userId ? filterList.userId : "")}
                                            >
                                                <img style={{ width: "1.5em", marginRight: "0.5em" }} src={editIcon} alt="edit-icon" />
                                            Edit
                                        </Button>
                                        </Grid>
                                        <Grid item style={{ marginTop: "1em", marginRight: "1em",  visibility: "hidden" }}>
                                            <Button
                                                className={classes.editButton}
                                                variant="contained"
                                                onClick={() => {
                                                    deleteButtonHandler(
                                                        props.location.state && props.location.state.id,
                                                        props.location.state && props.location.state.orgId
                                                    )
                                                }}
                                            >
                                                <img style={{ width: "1.5em", marginRight: "0.5em" }} src={deleteIcon} alt="edit-icon" />
                                            Delete
                                        </Button>
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container
                                    direction="row"
                                    justify="space-between"
                                >
                                    {/* <Grid item>
                                        <Grid container
                                            direction="column"
                                            style={{ marginTop: "2em", marginLeft: "1em" }}
                                        >
                                            <img src={detailsIcon} style={{ width: "15em", height: "15em" }} alt="admin" />
                                        </Grid>
                                    </Grid> */}
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                            style={{ marginBottom: "2em", marginLeft: "2em" }}
                                        >
                                            {/* <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "700", fontSize: "0.90rem", color: 'darkblue' }}>Account Information </p>
                                            </Grid> */}
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Solution Admin Name </p>
                                            </Grid>
                                            
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Email Address</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Contact Number</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Alt Contact Person</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Alt Contact Number </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Address </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Country</p>
                                            </Grid>
                                            {/* <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>No. of End Users </p>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                        >
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {filterList.firstName ? filterList.firstName + " " : "--"}
                                                    {filterList.lastName ? filterList.lastName : ""}
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>{filterList.corpEmailId ? filterList.corpEmailId : "--"}</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {filterList.mobileNumber ? filterList.mobileNumber : "--"}</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {filterList.altContactPerson ? filterList.altContactPerson : "--"}
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {filterList.altContactNumber ? filterList.altContactNumber : "--"}

                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.addressDetails ?
                                                            filterList.addressDetails.addressLine1 + " " +
                                                            filterList.addressDetails.addressLine2 + " "

                                                            :
                                                            "--"
                                                    }
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.addressDetails ? filterList.addressDetails.country : "--"
                                                    }
                                                </p>
                                            </Grid>
                                            {/* <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {userCount ? userCount : "--"}
                                                </p>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                            // style={{ marginBottom: "2em", marginLeft: '1em' }}
                                        >
                                            {/* <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "700", fontSize: "0.90rem", color: 'darkblue' }}>Other Information </p>
                                            </Grid> */}
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>State</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>City </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Postal Code </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Organization Name </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Organization Acronym</p>
                                            </Grid>
                                            
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Whitelist Domains </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem", marginBottom:'2.5em' }}>No of Users </p>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                             style={{ marginRight: "5em" }}
                                        >
                                            {/* <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}></p>
                                            </Grid> */}
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.addressDetails ? filterList.addressDetails.state : "--"
                                                    }
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.addressDetails ? filterList.addressDetails.city : "--"
                                                    }
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.addressDetails ? filterList.addressDetails.pincode : "--"
                                                    }
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>{orgDetails.name ? orgDetails.name : "--"}</p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>{orgDetails.orgAcronym ? orgDetails.orgAcronym : "--"}</p>
                                            </Grid>
                                           
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {orgDetails.emailDomains ? orgDetails.emailDomains.join() : "--"}
                                                </p>
                                            </Grid>
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>{userCount ? userCount : "--"}</p>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                {/* Parking Space Allocation */}
                <Card className={classes.detailsCard} style={{ marginBottom: "2em" }}>
                    <Grid item>
                        <Grid container
                            direction="column"

                        >
                            <Grid item>
                                <Grid container
                                    direction="row"
                                    style={{ backgroundColor: "#E5E2EF", height: "3.5em" }}
                                >
                                    <Grid item style={{ marginTop: "1em", marginLeft: "2em" }}>
                                        <Typography style={{ fontWeight: "600" }}>Parking Space Allocation</Typography>
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container
                                    direction="row"
                                   // justify="space-between"
                                >
                                 
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                            style={{ marginBottom: "2em", marginLeft: "2em" }}
                                        >
                                           
                                            <Grid item className={classes.labelMargin} style={{marginRight: '4em'}}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>Total Bike Slots </p>
                                            </Grid>
                                            
                                        
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                        >
                                           
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                                    {
                                                        filterList.totalBikeSlots ? filterList.totalBikeSlots : "0"
                                                    }
                                                </p>
                                            </Grid>
                                          
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                            // style={{ marginBottom: "2em", marginLeft: '1em' }}
                                        >
                                           
                                           
                                            
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontWeight: "600", fontSize: "0.80rem", marginBottom:'2.5em',marginLeft: '14em' }}>Total Car Slots </p>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container
                                            direction="column"
                                             style={{ marginLeft: "4em" }}
                                        >
                                          
                                            
                                           
                                            <Grid item className={classes.labelMargin}>
                                                <p style={{ fontSize: "0.80rem" }}>
                                               { filterList.totalCarSlots ? filterList.totalCarSlots : "0"}
                                                </p>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
              
                <Grid item style={{ marginLeft: "1.8em", marginBottom: "10em", visibility: "hidden" }}>
                    <Button variant="contained" color="primary" className={classes.backButton} onClick={backHandler} >
                        <p>Back</p>
                    </Button>
                </Grid>
                <Dialog
                    // fullScreen={fullScreen}
                    open={showDialog}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"


                >
                    <DialogTitle id="responsive-dialog-title dialog-header" style={{ borderBottom: '1px solid #000' }}>Delete Account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this account?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => deleteHandler()} variant="contained" className="confirm-button" autoFocus>
                            Confirm
                </Button>
                        <Button autoFocus variant="contained" onClick={handleClose} className="cancel-button">
                            Cancel
                </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity={severity}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    )
})

// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//     updateBreadcrumbs,
//     dispatch,
// });
//method to connect react with redux
export default withRouter(
    connect(state => {
        return {};
    })(ViewAccDetails),
);