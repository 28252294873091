// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import axios from './axiosInterceptor';
import UrlConfig from '../Constants/apiUrls';
import { getCookie } from './cookies';
import { Promise } from 'es6-promise';
import TokenStorage from './tokenstorage';

axios.defaults.baseURL = '';
const defaultMsg = {
  message: 'Internal Server Error, Please try again later',
};

const makeRequest = (options, successCallback, errorCallback) => {

  return axios(options)
    .then(response => {
      successCallback(response);
    })
    .catch(error => {
      errorCallback &&
        errorCallback(
          error.response ? error.response.data : defaultMsg,
          error.response ? error.response?.status : ""
        );
    });
};

export default {
  get: (url, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'get',
        ...config,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: 'bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  getSSO: (url, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'get',
        ...config,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: 'bearer ' + params.token,
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
    
  },
  getImage: (url, config, successCallback, errorCallback,params) => {
    return makeRequest(
      {
        url: url,
        method: 'get',
        ...config,
        headers: {
          Authorization: 'bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  getPublic: (url, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'get',
        ...config,
        headers: {
          'content-type': 'application/json',
           realmname:  (window.location.host === "dev-admin.parkzeus.in" || window.location.host === "localhost:3000")?'Rlm_ROBOIN_Parking_Admin_DEV_IN':'Rlm_ROBOIN_Parking_Admin_IN' ,
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  auth: (
    url,
    data,
    config,
    successCallback,
    errorCallback,
    params,
  ) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'post',
        data: data['data'],
        ...config,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
      successCallback,
      errorCallback,
    );
  },
  post: (
    url,
    data,
    config,
    successCallback,
    errorCallback,
    params,
  ) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'post',
        data,
        ...config,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  postPublic: (url, data, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'post',
        data,
        ...config,
        headers: {
          'content-type': 'application/json',
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  patch: (
    url,
    data,
    config,
    successCallback,
    errorCallback,
    params,
  ) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'patch',
        data,
        ...config,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  put: (
    url,
    data,
    config,
    successCallback,
    errorCallback,
    params,
  ) => {
    return makeRequest(
      { url: UrlConfig(url, params), 
        method: 'put', 
        data, 
        ...config,
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + getCookie('auth'),
        realmname: getCookie('realmName'),
        ...config,
      }, },
      successCallback,
      errorCallback,
    );
  },
  delete: (url, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'delete',
        ...config,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  deleteData: (url, data, config, successCallback, errorCallback, params) => {
    return makeRequest(
      {
        url: UrlConfig(url, params),
        method: 'delete',
        data,
        ...config,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
          ...config,
        },
      },
      successCallback,
      errorCallback,
    );
  },
  getNewToken: () => {
    return new Promise((resolve, reject) => {
      let cliSec = getCookie('cliSec');
    let cliId = getCookie('cliId');
      axios
        .post(
          UrlConfig('loginUrl'),
          'refresh_token=' +
          TokenStorage.getRefreshToken() +
          '&grant_type=refresh_token&client_id='+cliId+'&client_secret=' +
          cliSec,
          { 'content-type': 'application/x-www-form-urlencoded' },
        )
        .then(response => {
          TokenStorage.storeToken(response.data.access_token);
          TokenStorage.storeRefreshToken(response.data.refresh_token);
          resolve(response.data.access_token);
          
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
