// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import './style.scss';
//Global variable declarations
var storeEditData = [];
var counting = 1;

//@component
//CustomAvailability
function CustomAvailability(props) {
  const [arraySplice, setSplice] = useState(false);
  //function for component mount
  useEffect(() => { }, [arraySplice]);
  //function scope array declaration
  var appendedArray = [
    {
      startDate: '0000-00-00',
      endDate: '0000-00-00',
      openingTime: '00:00:00',
      closingTime: '00:00:00',
    },
  ];
  //conditional array related
  if (props.count === 0) {
    storeEditData = [];
    for (var iCustomData in props.data.customData) {
      storeEditData.push(props.data.customData[iCustomData]);
    }
  }
  //conditional assignment 
  if (storeEditData.length < 5 && props.count > 0) {
    for (var iArray in appendedArray) {
      counting = counting + 1;
      storeEditData.push(appendedArray[iArray]);
    }
  }
  //conditional assignment
  if (counting > props.count) {
    storeEditData.pop();
    counting = counting - 1;
  }
  //function for deleteDiv
  const deleteDiv = (e) => {
    setSplice(!arraySplice);
    if (e.target.id > -1) {
      storeEditData.splice(e.target.id, 1);
    }
    props.updateChildrenState('step2', 'customData', storeEditData);
    return storeEditData;
  };

  return (
    <div className="CustomAvailability">
      {storeEditData.map((item, index) => {
        var startDate = item.startDate.split('-').reverse().join('-');
        var endDate = item.endDate.split('-').reverse().join('-');
        return (
          <div key={index}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell >
                      <input
                        type="date"
                        className="CustomAvailability__wrapper_form_timeside_opening_input"
                        value={startDate}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[
                            index
                          ].startDate = startDate = evt.target.value
                            .split('-')
                            .reverse()
                            .join('-');
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell >
                      <span> to</span>
                    </TableCell>
                    <TableCell >
                      <input
                        type="date"
                        className="CustomAvailability__wrapper_form_timeside_opening_input"
                        value={endDate}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[
                            index
                          ].endDate = endDate = evt.target.value
                            .split('-')
                            .reverse()
                            .join('-');
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell >
                      <input
                        type="time"
                        className="OperationalTimings__wrapper_form_timeside_opening_input"
                        defaultValue="09:00:00"
                        value={item.openingTime.substring(0,item.openingTime?.length-3)}
                        step="900"
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[index].openingTime =
                            evt.target.value + ':00';
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <span> to </span>
                    </TableCell>
                    <TableCell>
                      <input
                        type="time"
                        defaultValue="09:00:00"
                        className="CustomAvailability__wrapper_form_timeside_closing_input"
                        value={item.closingTime.substring(0,item.closingTime?.length-3)}
                        step="900"
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[index].closingTime =
                            evt.target.value + ':00';
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell >
                      <input
                        className="deleteBtn"
                        type="submit"
                        name="delete"
                        value="delete"
                        id={index}
                        onClick={deleteDiv}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </div>
  )
}
export default CustomAvailability;
