// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import { pdfjs, Document, Page } from "react-pdf";
//Global variable declaration
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//@component
//File Viewer
export default class FileViewer extends Component {
  //react state declaration
  state = {
    numPages: null,
    pageNumber: 1,
  };
  //function for document load success
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    return (
      <div style={{ width: "140%", marginLeft: "-20%" }}>
        <Document
          style={{ width: "100%" }}
          file={this.props.pdfFile}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.apply(null, Array(this.state.numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page width="900" pageNumber={page} key={page} />
            ))}
        </Document>
      </div>
    );
  }
}
