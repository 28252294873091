// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import LegalDataProtection from "./LegalDataProtection";
import { deleteAllCookies } from "../../Utils/cookies";
import HelpSupport from "./HelpSupport";
import "./style.scss";

import DPK from "../../Assets/pdf/PZ_Data_Protection_Notice_Common_V2.3_15122023.pdf";
import FileViewerPop from "../pdf/FileViewerpop";

//function for material-ui styles
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
//function for dialogtitle
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
//function for dialogcontent

//function for dialogactions
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

//@component
//PrivacySettingDialog
export default function PrivacySettingDialog(props) {
  const displayPage = props.displayPage;
  const [open, setOpen] = React.useState(props.flag);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const userStatusSuccssCallback = (json) => {
  //   console.log(json.data);
  // };
  const handleDisagree = () => {
    deleteAllCookies();
    handleClose();
  };
  const handleAgree = () => {
    handleClose();
  };

  return (
    <div>
      {displayPage === "footer" ? (
        <a
          style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          &nbsp;Privacy settings&nbsp;
        </a>
      ) : (
        <a
          onClick={handleClickOpen}
          className="underline_hover"
          style={{ color: "#0A65A8", cursor: "pointer" }}
        >
          <small>&nbsp;privacy settings ,</small>
        </a>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="Cookie Agreement"
        aria-describedby="Cookie Agreement-description"
        style={{ overflowX: "hidden" }}
      >
        <DialogTitle id="customized-dialog-title">
          <span style={{ paddingLeft: "4%" }}>Privacy Settings</span>
        </DialogTitle>

        <Typography gutterBottom>
          {/* <pre style={{ overflow: "hidden" }}> */}
          <div style={{ paddingLeft: "7%" }}>
            <LegalDataProtection displayPage="loginDialog" />
            <HelpSupport displayPage="ossDoc" />
          </div>

          <FileViewerPop pdfFile={DPK} />
          {/* {`
Cookies that are essential for technical reasons are set on this webpage so we can provide users with our offers. A cookie that stores your current privacy settings is also set (selection cookie).

With the exception of the selection cookie, technically non-essential cookies and tracking mechanisms, that enable us to provide you with customized offers (marketing cookies and tracking mechanisms), are only used if you have given prior consent to such use: `}
              <LegalDataProtection displayPage="loginDialog" />
              <br />
              <br />
              <strong>Withdrawal:</strong>
              {`
In our Privacy settings you may withdraw your consent given here at any time with effect for the future. For further information please refer to our `}
              <LegalDataProtection displayPage="loginDialog" />
              <br /> <br />
              <HelpSupport displayPage="ossDoc" /> */}
          {/* </pre> */}
        </Typography>

        <DialogActions>
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              padding: "5px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={handleDisagree} color="primary">
              DISAGREE
            </Button>
            <Button variant="contained" onClick={handleAgree} color="primary">
              I AGREE
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
