// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import { getCookie } from "../../../Utils/cookies";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import detailsUseStyles from "./styles";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader/index";
import editIcon from "../../../Assets/icons/edit_white.png";
import deleteIcon from "../../../Assets/icons/delete_white.png";
import synced from "../../../Assets/Images/live.png";
import notSynced from "../../../Assets/Images/Non-Sync.png";

//@component
//ViewDetails
const ViewTempAccDetails = React.memo((props) => {
  const classes = detailsUseStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState({});
  const [parkDetails, setParkDetails] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [vehicleNumber] = useState(props.location.state.vehicleNumber);
  const [empty, setEmpty] = useState(true);

  //function to close delete confirm popup
  const handleClose = () => {
    setShowDialog(false);
    setDeleteId([]);
  };

  //api for data filter list as per view user Id
  const generateListHandler = () => {
    setIsLoading(true);

    // if (props.location.state) {
    httpRequest.get(
      "viewtempaccountdetails",

      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallback,
      {
        vehicle: vehicleNumber,
      }
    );
    // }
  };

  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    setIsLoading(false);
    if (jsonResponse.data || jsonResponse.data !== {}) {
      setEmpty(false);
      setFilterList(jsonResponse.data);
      setParkDetails(jsonResponse.data?.accessedParkingList);
    }
  };

  //error callback
  const errorCallback = (error) => {
    if (error) {
      setIsLoading(false);
      setEmpty(true);
    }
  };

  //checkbox to select parking handler

  const deleteParkingHandler = (id) => {
    if (deleteId.includes(id)) {
      let index = deleteId.indexOf(id);
      let numberOfElementToRemove = 1;
      if (index !== -1) {
        deleteId.splice(index, numberOfElementToRemove);
      }
    } else deleteId.push(id);
  };

  //function for back button
  const backHandler = () => {
    // if (props.location.state) {

    props.history.push({
      pathname: "/portal/tempaccesslistview",
      state: {
        back: "true",
        loadpage: props.location.state?.page,
        searchKey:props.location.state?.searchKey
      },
    });
    // }
  };
  //function for edit handler
  const editHandler = () => {
    props.history.push({
      pathname: "/portal/tempaccessedit",
      state: {
        accessControlId: props.location.state?.vehicleNumber
          ? props.location.state?.vehicleNumber
          : "",
        page: props.location.state?.page,
        searchKey:props.location.state?.searchKey
      },
    });
  };

  //api call to delete account
  const deleteHandler = () => {
    setShowDialog(false);
    setIsLoading(true);
    httpRequest.deleteData(
      "deleteTempAccessUser",
      {
        parkingIds: deleteId,

        vehicleNumber: vehicleNumber,
        adminRemarks: "manual deletion of Vehicle access",
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackDelete,
      errorCallbackDelete,
      {}
    );
  };
  //error callback to delete account
  const successCallbackDelete = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);

      setDeleteId([]);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `Vehicle access removed for the selected parking spaces`,
          color: "green",
        })
      );
      props.history.push({
        pathname: "/portal/tempaccesslistview",
        state: {
          back: "true",
          loadpage: props.location.state.page,
        },
      });
    }
  };

  //error callback for deleting account
  const errorCallbackDelete = (error) => {
    setIsLoading(false);

    setDeleteId([]);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: error.message || "Something went wrong, Try again.",
        color: "red",
      })
    );
  };

  const deleteButtonHandler = () => {
    setShowDialog(true);
  };

  //adding component to breadcrumb and displaying filtered data as per view user id
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("viewtempaccdetails"));
    generateListHandler();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column" className={classes.container}>
        {/* View Account Details */}
        <Card className={classes.detailsCard} style={{ marginBottom: "2%" }}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container direction="row" className={classes.header}>
                  <Grid item md={8}>
                    <Typography className={classes.headerName}>
                      User Vehicle Details
                    </Typography>
                  </Grid>

                  {props.location.state.userId === getCookie("userId") &&
                  !empty ? (
                    <Grid item md={2} style={{ textAlign: "right" }}>
                      <Button
                        className={classes.editButton}
                        variant="contained"
                        onClick={() => editHandler()}
                        style={{ backgroundColor: "#03A9F3" }}
                      >
                        <img
                          src={editIcon}
                          alt="edit-icon"
                          style={{ width: "1vw" }}
                        />
                        <span style={{ marginLeft: "0.5vw" }}>Edit</span>
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item md={2}></Grid>
                  )}
                  {!empty && (
                    <Grid item md={2} style={{ textAlign: "center" }}>
                      <Button
                        className={classes.editButton}
                        variant="contained"
                        onClick={() => {
                          deleteButtonHandler();
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete-icon"
                          style={{ width: "1.1vw" }}
                        />
                        <span style={{ marginLeft: "0.5vw" }}>Delete</span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!empty && (
                <Grid container direction="row" md={12}>
                  <Grid
                    item
                    container
                    direction="column"
                    md={4}
                    style={{ paddingLeft: "4vw" }}
                  >
                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Organization Name </p>
                    </Grid>
                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Email Address </p>
                    </Grid>

                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Vehicle Number </p>
                    </Grid>

                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Vehicle Type </p>
                    </Grid>

                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Vehicle Make and Model</p>
                    </Grid>
                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Access Type</p>
                    </Grid>
                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>RFID Tag</p>
                    </Grid>

                    <Grid item className={classes.labelMargin}>
                      <p className={classes.keyStyle}>Access Granted To</p>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" md={8}>
                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.organizaionName
                          ? filterList.organizaionName
                          : "--"}
                      </p>
                    </Grid>

                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.emailId ? filterList.emailId : "--"}
                      </p>
                    </Grid>

                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.vehicleNumber
                          ? filterList.vehicleNumber
                          : "--"}
                      </p>
                    </Grid>

                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.vehicleType?.name
                          ? filterList.vehicleType.name
                          : "--"}
                      </p>
                    </Grid>

                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.vehicleMakeModel
                          ? filterList.vehicleMakeModel
                          : "--"}
                      </p>
                    </Grid>
                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.accessType?.accessTypes
                          ? filterList.accessType?.accessTypes
                          : "--"}
                      </p>
                    </Grid>
                    <Grid item container className={classes.labelMargin}>
                      <p style={{ fontSize: "12px", fontWeight: "600" }}>:</p>
                      <p style={{ fontSize: "12px", marginLeft: "0.5VW" }}>
                        {filterList.rfId ? filterList.rfId : "--"}
                      </p>
                    </Grid>

                    <Grid container style={{ marginBottom: "4vh" }}>
                      {parkDetails.map((items, index) => {
                        return (
                          <Grid
                            item
                            container
                            style={{ marginBottom: "1vh" }}
                            direction="column"
                          >
                            <Grid
                              item
                              container
                              className={classes.labelMargin}
                            >
                              <p
                                style={{ fontSize: "11px", fontWeight: "600" }}
                              >
                                {index + 1}:
                              </p>
                              <p
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "600",
                                  marginLeft: "8px",
                                }}
                              >
                                {items.parkingName ? items.parkingName : "--"}
                              </p>
                            </Grid>
                            <Grid
                              item
                              //className={classes.labelMargin}
                              style={{
                                display: "flex",
                                marginTop: "0.2vw",
                                marginLeft: "14px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "10px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {items.parkingAddress
                                  ? "(" + items.parkingAddress + ".)"
                                  : ""}
                              </p>
                            </Grid>
                            <Grid item container md={6}>
                              <Grid container md={6} direction="column">
                                <Grid item className={classes.labelMargin}>
                                  <p
                                    className={classes.keyStyle}
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "14px",
                                    }}
                                  >
                                    Sync Status{" "}
                                  </p>
                                </Grid>

                                <Grid item className={classes.labelMargin}>
                                  <p
                                    className={classes.keyStyle}
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "14px",
                                    }}
                                  >
                                    Start Date{" "}
                                  </p>
                                </Grid>

                                <Grid item className={classes.labelMargin}>
                                  <p
                                    className={classes.keyStyle}
                                    style={{
                                      fontSize: "11px",
                                      marginLeft: "14px",
                                    }}
                                  >
                                    End Date
                                  </p>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                md={6}
                                direction="column"
                                style={{
                                  alignContent: "flex-start",
                                  alignSelf: "end",
                                }}
                              >
                                <Grid item style={{ marginTop: "1em" }}>
                                  <p style={{ fontSize: "11px" }}>
                                    :{" "}
                                    {items?.syncStatus ? (
                                      <img
                                        alt="synced"
                                        src={synced}
                                        className={classes.edit_icon}
                                      />
                                    ) : (
                                      <img
                                        alt="not_synced"
                                        src={notSynced}
                                        className={classes.edit_icon}
                                      />
                                    )}
                                  </p>
                                </Grid>
                                <Grid item className={classes.labelMargin}>
                                  <p style={{ fontSize: "11px" }}>
                                    :{items?.startDate ? items.startDate : "--"}
                                  </p>
                                </Grid>
                                <Grid item className={classes.labelMargin}>
                                  <p style={{ fontSize: "11px" }}>
                                    :{items?.endDate ? items.endDate : "--"}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {empty && !isLoading && (
                <Grid md={12}>
                  <p className={classes.noData}>No Data Found.</p>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>

        <Grid item className={classes.backGrid}>
          <Button className={classes.backButton} onClick={backHandler}>
            Back
          </Button>
        </Grid>
        <Dialog
          open={showDialog}
          onClose={handleClose}
          PaperProps={{
            style: {
              padding: "1.5% 3%",
            },
          }}
          className={classes.dialog}
        >
          <MuiDialogTitle
            style={{
              paddingBottom: "8px",
              paddingTop: "2px",
              paddingLeft: "2px",
              paddingRight: "2px",
            }}
          >
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <span className={classes.dialogHeader}>
                  Remove Vehicle Access
                </span>
              </Grid>
            </Grid>
          </MuiDialogTitle>
          <Divider />
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Grid item>
              <Grid container direction="column" justify="center">
                <Grid item>
                  <Typography className={classes.dialogSubHeader}>
                    Select the parking spaces to remove the Vehicle access
                  </Typography>
                </Grid>
                {parkDetails.map((item) => {
                  return (
                    <Grid container>
                      <Checkbox
                        color="default"
                        size="small"
                        type="checkbox"
                        data-target="filter"
                        onChange={() => deleteParkingHandler(item?.parkingId)}
                      />
                      <span className={classes.dialogSubTitle}>
                        {item.parkingName}
                      </span>
                      <br />
                      <span className={classes.dialogAddress}>
                        {"(" + item.parkingAddress + ".)"}
                      </span>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.dialogActionGrid}
              >
                <Grid item className={classes.dialogCancelGrid}>
                  <button
                    type="button"
                    onClick={handleClose}
                    className={classes.dialogCancel}
                  >
                    Cancel
                  </button>
                </Grid>
                <Grid item className={classes.dialogConfirmGrid}>
                  <button
                    type="button"
                    disabled={deleteId.length === 0}
                    style={{
                      backgroundColor:
                        deleteId.length === 0 ? "#A2A2A2" : "#03A9F3",
                    }}
                    className={classes.dialogConfirm}
                    onClick={deleteHandler}
                  >
                    Confirm
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
});

// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//     updateBreadcrumbs,
//     updateToasterConfig,
//     dispatch,
// });
//method to connect react with redux
export default withRouter(
  connect(() => {
    return {};
  })(ViewTempAccDetails)
);
