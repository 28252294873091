// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./style.scss";
import FileViewerPop from "../pdf/FileViewerpop";
import TermsConditions from "../../Assets/pdf/PZ Microapp - Terms of Use.pdf";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// Dialog
// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: "absolute",
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

//@component
//CustomizedDialogs
export default function CustomizedDialogs(props) {
  const displayPage = props.displayPage;
  // const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  //function for handleChange
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // function for handleChangeIndex
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // Dialog
  const [open, setOpen] = React.useState(props.flag);

  //function for handleClickOpen
  const handleClickOpen = () => {
    setOpen(true);
  };
  //function for handleClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {displayPage === "footer" ? (
        <a
          onClick={handleClickOpen}
          style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
        >
          <div className="footer__wrapper_right">
            <span> | </span>Terms and conditions
          </div>
        </a>
      ) : displayPage === "loginDialog" ? (
        <a
          onClick={handleClickOpen}
          className="underline_hover"
          style={{ color: "blue", cursor: "pointer" }}
        >
          terms and conditions
        </a>
      ) : (
        <a
          onClick={handleClickOpen}
          className="underline_hover"
          style={{ color: "#0A65A8", cursor: "pointer" }}
        >
          <small>&nbsp;terms and conditions</small>
        </a>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <span className="TermsnConditions-cross" onClick={handleClose}>
          X
        </span>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          fullWidth
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Corporate information" {...a11yProps(0)} />
          <Tab label="Terms and conditions" {...a11yProps(1)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          style={{ padding: "0" }}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <pre>
              <strong>{`          Name and address`}</strong>
              {`
          Bosch Mobility Platform and Solutions India Private Limited`} <em>{`formerly 
          known as Automobility Services and Solutions Private Limited`}</em>
            
<br>
</br>
{`
`}
          {`          Responsible for content`}

          {`
          Bosch Mobility Platform and Solutions India Private Limited`} <em>{`formerly 
          known as Automobility Services and Solutions Private Limited`}</em>
<br></br>
{`
`}
          {`          Telephone number`}<br></br>
          {`          +91 80 22 22 00 88`}

          <br></br>
          {`
          `}
          {`Your contact to Bosch`}
          <br></br>
    
              <a
                target="_blank"
                href="mailto:bosch.corporate@in.bosch.com"
                rel="noopener noreferrer"
                style={{ paddingLeft: "4%" }}
              >
               {`    bosch.corporate@in.bosch.com`}     
              </a>
              {`

          Register Entries
          U50102KA2016FTC086337`}
            </pre>
          </TabPanel>

          <TabPanel
            style={{ overflow: "hidden" }}
            value={value}
            index={1}
            dir={theme.direction}
          >
            <FileViewerPop pdfFile={TermsConditions} />
          </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
}
