// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCookie } from "../../Utils/cookies";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import moment from "moment";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../redux/modules/app/actions";
import listviewUseStylesBooking from "./style";
import Loader from "../../Components/Preloader/index";
import httpRequest from "../../Utils/httpRequest";
import Pagination from "@material-ui/lab/Pagination";
import Filtericon from "../../Assets/Images/funnel-hz.png";
import Clickedfilter from "../../Assets/Images/Path 1482.png";
import "../../Pages/SolutionAdmin/ParkingManagement/AddParkingModule/OperationalTimings/style.scss";

var classData = "";

//@component
//ONPListView
function ViewBooking(props) {
  const classes = listviewUseStylesBooking();

  const [viewList, setViewList] = useState([]);
  const [parkingSpaceList, setParkingSpaceList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [postsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("datetime");
  const [pageNumber, setPagenumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState(null);
  const [internetFlag, setInternetFlag] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterFlag, setFilterFlag] = useState(false);
  const [onLoad, setOnLoad] = useState(true);

  const [dropDownFlag, setDropDownFlag] = useState(false);
  const [pageNumberSelect, setPageNumberSelect] = useState(0);
  const [totalPagesSelect, setTotalPagesSelect] = useState(0);
  const [selectValue, setSelectValue] = useState("");

  // function to set initial table value
  const onpSelectHandler = (event) => {
    setSelectValue(event.target.value);
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    setFilterFlag(false);
    setSearchKey("");
    setPagenumber(0);
    setPageNumberSelect(0);
    setTableDataNotFound("");
    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      successCallbackSelectFilter,
      errorCallbackSelectFilter,
      {
        size: postsPerPage,
        page: 0,
        status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        userId: getCookie("userId"),
        parkingId: event.target.value,
        startTime: "",
        endTime: "",
      }
    );
  };
  // success callback get list
  const successCallbackSelectFilter = (jsonResponse) => {
    setIsLoading(false);
    setDropDownFlag(true);
    setFilterFlag(false);
    if (!jsonResponse) {
      setTableDataNotFound("No Booking Requests Found");
      setViewList([]);
      setInternetFlag(true);
    }
    if (!jsonResponse.data._embedded) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Booking Requests Found");
      setViewList([]);
    }
    //
    if (onLoad === true) {
      const te = jsonResponse.data.page.totalElements;
      httpRequest.get(
        "viewAllBookings",
        {
          "Content-Type": "application/json",
        },
        successCallbackListAll,
        errorCallbackList,
        {
          size: te,
          page: pageNumber,
          status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
          userId: getCookie("userId"),
          parkingId: selectValue,
          startTime: "",
          endTime: "",
        }
      );
    }

    if (jsonResponse.data?.page?.totalPages < 1) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found!..Please Refine Filter Above")
        : setTableDataNotFound("No Booking Requests Found");
      setTotalPages(0);
    } else {
      setTableDataNotFound("");
      setPageNumberSelect(jsonResponse.data.page.number);
      setViewList(jsonResponse.data._embedded.results);
      setTotalPagesSelect(jsonResponse.data.page.totalPages);
      setInternetFlag(false);
    }
  };
  // error callback get list
  const errorCallbackSelectFilter = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };
  // when we click on page number while dropdown select is being used
  const handleChangeSelect = (event, value) => {
    let currpage;
    currpage = value - 1;
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    setFilterFlag(false);
    setDropDownFlag(true);
    setSearchKey("");
    setPageNumberSelect(currpage);
    setPagenumber(currpage);
    setTableDataNotFound("");
    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      successCallbackSelectFilter,
      errorCallbackSelectFilter,
      {
        size: postsPerPage,
        page: currpage,
        status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        userId: getCookie("userId"),
        parkingId: selectValue,
        startTime: "",
        endTime: "",
      }
    );
  };
  // function to set initial table value
  const getONPList = (page) => {
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    if (filterValue !== "") {
      setFilterFlag(true);
      setDropDownFlag(false);
    }
    if (filterValue === "") {
      setFilterFlag(false);
      setDropDownFlag(false);
    }
    setSearchKey("");
    setPagenumber(0);
    setTableDataNotFound("");

    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: postsPerPage,
        page: page,
        parkingId: "",
        status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        userId: getCookie("userId"),
        startTime: "",
        endTime: "",
      }
    );
  };
  // success callback get list
  const successCallbackList = (jsonResponse) => {
    if (!jsonResponse) {
      setTableDataNotFound("No Bookings Found");
      setViewList([]);
      setInternetFlag(true);
    }
    if (!jsonResponse.data._embedded) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Bookings Found");
      setViewList([]);
    }
    setIsLoading(false);

    if (onLoad === true) {
      const te = jsonResponse.data.page.totalElements;
      httpRequest.get(
        "viewAllBookings",
        {
          "Content-Type": "application/json",
        },
        successCallbackListAll,
        errorCallbackList,
        {
          size: te,
          page: pageNumber,
          status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
          userId: getCookie("userId"),
          parkingId: selectValue,
          startTime: "",
          endTime: "",
        }
      );
    }
    if (jsonResponse.data?.page?.totalPages < 1) {
      setInternetFlag(true);

      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Booking Requests Found");
      setTotalPages(0);
      setViewList([]);
    } else {
      setTableDataNotFound("");
      setPagenumber(jsonResponse.data.page.number);
      setViewList(jsonResponse.data._embedded.results);

      setTotalPages(jsonResponse.data.page.totalPages);
      setInternetFlag(false);
    }
  };
  //get all data for client search and filter
  const successCallbackListAll = (jsonResponse) => {
    const list = jsonResponse.data._embedded.results;

    setOnLoad(false);
  };
  // error callback get list
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
    }
    if (error.error === "Not Found") {
      setTableDataNotFound("No Results Found");
    }
  };
  // callback to set page number and size for pagination
  const pageHandler = (number) => {
    let pageSize = postsPerPage || 10;
    let pageNumber = number || 0;
    setPagenumber(number);
    httpRequest.get(
      "viewAllBookings",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: pageSize,
        page: pageNumber,
        status: "COMPLETED,CANCELLED,CONFIRMED,PARKED",
        userId: getCookie("userId"),
        parkingId: selectValue,
        startTime: "",
        endTime: "",
      }
    );
  };
  // function to handle pagination change
  const handleChange = (event, value) => {
    let currpage;
    currpage = value - 1;
    pageHandler(currpage);
  };

  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("viewBookings"));
    getSelectListHandler();

    let back_page = props.history.location.state?.currentPage;
    back_page
      ? getONPList(props.history.location.state?.currentPage)
      : getONPList(0);
  }, []);
  //data for row header
  const headCells = [
    { id: "sno", label: "S.no", width: "5%" },
    { id: "pname", label: "Parking Space", width: "10%" },
    { id: "buser", label: "Booked By", width: "8%" },
    { id: "startend", label: "Booking Start/End", width: "12%" },
    { id: "email", label: "Email Id", width: "10%" },
    { id: "vno", label: "Vehicle No", width: "10%" },
    { id: "slotno", label: "Slot No", width: "7%" },
    { id: "cin", label: "Check-in", width: "8%" },
    { id: "cout", label: "Check-out", width: "8%" },
    { id: "status", label: "Status", width: "10%" },
    { id: "actions", label: "Actions", width: "7%" },
  ];
  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.id === "sno" ? "center" : "left"}
              width={headCell.width}
              className={classes.thData}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //function for filter
  const filterOptionSetter = () => {
    let toggleShowDiv = !showDiv;
    setShowDiv(toggleShowDiv);
  };
  // Status filter handler function
  const statusFilterHandler = (status) => {
    setFilterValue(status);
    setFilterFlag(false);
  };
  // function for click away
  const handleClickAway = () => {
    setShowDiv(false);
  };
  //redirect to details page click handler
  const itemClicked = (data) => {
    props.history.push({
      pathname: "/portal/checkin",
      state: {
        rowData: data,
        currentPage: pageNumber,
        search: filterFlag,
      },
    });
  };
  // function for clearing search
  const clearSearch = () => {
    classData = "";
    setSearchKey(null);
    setFilterList([]);
    setFilterFlag(false);
    getONPList(0);
  };

  //api call when component loads first time on page
  const getSelectListHandler = () => {
    httpRequest.get(
      "bookingparkingList",
      {
        "Content-Type": "application/json",
      },
      successCallbackSelectList,
      errorCallbackSelectList,
      {
        userId:getCookie('userId')
      }
    );
  };
  // success callback for component page load api
  const successCallbackSelectList = (jsonResponse) => {
    setIsLoading(false);
    setParkingSpaceList(jsonResponse.data._embedded.results);
  };
  // error callback for component page load api
  const errorCallbackSelectList = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      // any error message
    }
    if (error) {
      setResponseDataNotFound("");
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          direction="column"
          spacing="1"
          className={classes.container}
        >
          <Grid item className={classes.topRowGridParent}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>
                {/* <Grid
                  item
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  style={{ paddingLeft: "1vw" }}
                >
                  <select
                    onChange={onpSelectHandler}
                    className={classes.selectListCard}
                  > */}
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  style={{ paddingLeft: "1vw" }}
                >
                  <select
                    onChange={onpSelectHandler}
                    //className={classes.selectListCard}
                    //disabled={this.state.parkingList.length === 0}
                    style={{
                      webkitAppearance: "auto",
                      width: "50%",
                      fontSize: "14px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      paddingBottom: "5px",
                      outline: "transparent",
                      paddingTop: "5px",
                      borderColor: "#eee",
                      backgroundColor: "white",
                      height: "45px",
                      borderRadius: "90px",
                      borderRight: "10px solid white",
                      paddingLeft: "14px",
                    }}
                  >
                    {props.location?.state?.onpRequest ? (
                      <option value="">
                        {props.location?.state?.parkingName}
                      </option>
                    ) : getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
                      getCookie("role") === "FACILITY_ADMIN" ||
                      getCookie("role") === "MULTITENANT_ADMIN" ? (
                      <option value="">All</option>
                    ) : (
                      undefined
                    )}
                    {parkingSpaceList
                      .sort((a, b) => {
                        return a - b;
                      })
                      .map((list, index) => {
                        return (
                          <option key={index} value={list.parkingId}>
                            {list.name}
                          </option>
                        );
                      })}
                  </select>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  style={{ visibility: "hidden" }}
                >
                  <ButtonGroup
                    color="secondary"
                    aria-label="outlined secondary button group"
                  >
                    <Button className={classes.filter_button}>
                      <img
                        src={
                          // Filtericon
                          filterValue.length ? Clickedfilter : Filtericon
                        }
                        alt="Filtericon"
                        onClick={() => filterOptionSetter()}
                        className={classes.filter_image}
                      />
                      <Grid
                        item
                        container
                        direction="column"
                        className={classes.filter_box}
                        style={{
                          display: showDiv ? "block" : "none",
                        }}
                      >
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("")}
                                checked={filterValue === ""}
                              />
                            </div>
                            <label className={classes.filterLabel}>All</label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("APPROVED")}
                                checked={filterValue === "APPROVED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Approved
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("COMPLETED")
                                }
                                checked={filterValue === "COMPLETED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Completed
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("PENDING")}
                                checked={filterValue === "PENDING"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Pending
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("CANCELLED")
                                }
                                checked={filterValue === "CANCELLED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Cancelled
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("REJECTED")}
                                checked={filterValue === "REJECTED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Rejected
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_APPROVED")
                                }
                                checked={filterValue === "EXTENSION_APPROVED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Approved
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_REJECTED")
                                }
                                checked={filterValue === "EXTENSION_REJECTED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Rejected
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_PENDING")
                                }
                                checked={filterValue === "EXTENSION_PENDING"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Pending
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_CANCELLED")
                                }
                                checked={filterValue === "EXTENSION_CANCELLED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Cancelled
                            </label>
                          </li>
                        </Grid>
                        <hr className={classes.hrStyling} />
                        <button
                          type="button"
                          onClick={() => getONPList(0)}
                          className={classes.applyButtonFilter}
                        >
                          Apply
                        </button>
                      </Grid>
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ paddingRight: "30px" }}
                >
                  <button
                    type="button"
                    className={classes.createuserButton}
                    onClick={() => {
                      props.history.push({
                        pathname: "/portal/createbooking",
                      });
                    }}
                  >
                    Create Booking +
                  </button>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Grid>
          {responseDataNotFound.length === 0 ? (
            <>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer className={classes.paper} component={Paper}>
                  <Table className={classes.table} aria-label="View table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {(searchKey ? filterList : viewList).length !== 0 &&
                      internetFlag === false ? (
                        (searchKey ? filterList : viewList).map(
                          (row, index) => {
                            let no = index;
                            function formatNo(no) {
                              return no < 10
                                ? pageNumber + "" + no
                                : (pageNumber + 1) * no;
                            }
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                >
                                  {formatNo(no + 1)}
                                </TableCell>
                                {/* <TableCell className={classes.tableCell} align="left" >{row?.user?.firstName + " " + row?.user?.lastName}</TableCell> */}
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.parking?.name ? (
                                    row?.parking?.name
                                  ) : (
                                    <span syle={{ align: "center" }}>--</span>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.bookedBy ? (
                                    row?.bookedBy?.displayName
                                  ) : (
                                    <span className={classes.nullSpan}>--</span>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  <div>
                                    {moment(row?.startTime).format(
                                      "MMM D, YYYY h:mm A"
                                    )}
                                    <br />
                                    {moment(row?.endTime).format(
                                      "MMM D, YYYY h:mm A"
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.emailId ? row?.emailId : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.vehicle?.vehicleNumber
                                    ? row?.vehicle?.vehicleNumber
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.checkInCheckOutDto?.slotNo
                                    ? row?.checkInCheckOutDto?.slotNo
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.checkInCheckOutDto?.checkInTime
                                    ? moment(
                                        row?.checkInCheckOutDto?.checkInTime
                                      ).format("MMM D, YYYY h:mm A")
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.checkInCheckOutDto?.checkOutTime
                                    ? moment(
                                        row?.checkInCheckOutDto?.checkOutTime
                                      ).format("MMM D, YYYY h:mm A")
                                    : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.statusRow}
                                  align="left"
                                  style={{
                                    color: row?.bookingStatus?.color,
                                  }}
                                >
                                  {row?.bookingStatus?.displayName}
                                </TableCell>
                                <TableCell
                                  className={classes.viewTableCell}
                                  onClick={() => {
                                    itemClicked(row);
                                  }}
                                >
                                  <span className={classes.viewSpan}>View</span>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      ) : (
                        <TableRow className={classes.tableRowNoImage}>
                          <TableCell>
                            <Grid
                              container
                              direction="column"
                              className={classes.gridContainerNoImage}
                              alignItems="center"
                              style={{
                                width: viewList.length === 0 ? "80vw" : "",
                                display: "grid",
                                justifyContent: "center",
                              }}
                            >
                              <Grid
                                item
                                className={classes.typographyGridNoImage}
                              >
                                <Typography variant="body1">
                                  {tableDataNotFound}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              >
                {dropDownFlag === true && filterFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPagesSelect}
                          page={pageNumberSelect + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChangeSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {filterFlag === false && dropDownFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPages}
                          page={pageNumber + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {filterFlag === true && dropDownFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPages}
                          page={pageNumber + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChangeSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Grid item className={classes.notFoundGrid}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">
                    {responseDataNotFound}
                    {tableDataNotFound}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ClickAwayListener>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(ViewBooking));
