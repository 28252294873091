// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import { getCookie } from "../Utils/cookies";
const UrlConfig = function(key, params) {
  let urlsEndPoints = {
    configUrl: "user-vehicle-service/api/v1/config",
    loginUrl:
      "auth/realms/" +
      getCookie("realmName") +
      "/protocol/openid-connect/token",
    createUser: "user-vehicle-service/api/v1/user",
    userData: "user-vehicle-service/api/v1/user/",
    subscriptionDetails:
      "subscription-service/api/v1/getsubscription?organizationId=" +
      (params ? params.orgId : "") +
      "&roleName=" +
      (params ? params.role : ""),

    forgotPassword:
      "aaaservice/api/v1/realms/" +
      getCookie("realmName") +
      "/otpChangePassword",
    generateOTP:
      "aaaservice/api/v1/realms/" + getCookie("realmName") + "/sendEmailOtp",
    changePassword: "user-vehicle-service/api/v1/user/changePassword",
    dashboardData: "b2b-parking-service/api/v1/dashboardCount",
    services: "b2b-parking-service/api/v1/listOfValues",
    sosList:
      "b2b-parking-service/api/v1/parking/sos?size=" +
      (params ? params.size : "") +
      "&page=" +
      (params ? params.pageNumber : ""),
    sosListAll: "b2b-parking-service/api/v1/parking/sos",
    sosDetails:
      "b2b-parking-service/api/v1/parking/sos/" +
      (params ? params.parkingId : ""),
    onpList:
      "b2b-parking-service/api/v1/overnightParkingRequest?size=" +
      (params ? params.size : "") +
      "&page=" +
      (params ? params.pageNumber : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC" +
      "&status=" +
      (params ? params.onpStatus : "") +
      "&parkingId=" +
      (params ? params.parkingId : ""),
    onpDetails:
      "b2b-parking-service/api/v1/overnightParkingRequest/" +
      (params ? params.requestId : ""),
    onpStatusLogs:
      "b2b-parking-service/api/v1/overnightParkingRequest/" +
      (params ? params.requestId : "") +
      "/statusLog",
    onpStatusUpdate:
      "b2b-parking-service/api/v1/overnightParkingRequest/" +
      (params ? params.requestId : "") +
      "/statusUpdate",
    sosEnableDisable:
      "b2b-parking-service/api/v1/parking/sos/" +
      (params ? params.parkingId : "") +
      "/activate?value=" +
      (params ? params.action : ""),
    sosUpdate:
      "b2b-parking-service/api/v1/parking/sos/" +
      (params ? params.parkingId : ""),
    parkingList:
      "b2b-parking-service/api/v1/parking/listView?sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO,LOCATION_INFO,PRICE_INFO,SLOT_UTILIZATION,SLOT_INFO,OPERATING_HOURS_INFO,BUSINESS_TYPE_INFO&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "") +
      "&myParkingSpace=" +
      (params ? params.admin : "") +
      "&tenantParkingSpace=" +
      (params ? params.tenant : ""),
    parkingListBasic:
      "b2b-parking-service/api/v1/parking/listView?sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO&isPaginationRequired=false",
    parkingListDashboard:
      "b2b-parking-service/api/v1/parking/listView?status=APPROVED&sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO,LOCATION_INFO,SLOT_UTILIZATION,SLOT_INFO,OPERATING_HOURS_INFO&isPaginationRequired=false&myParkingSpace=false&tenantParkingSpace=false",
    parkingListDashboardAsc: "b2b-parking-service/api/v1/parking/overview",
    parkingListUtilization:
      "b2b-parking-service/api/v1/parking/listView?status=APPROVED&sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO,DEVICE_INFO&isPaginationRequired=false",
    parkingListDashboardSlot:
      "b2b-parking-service/api/v1/parking/listView?selectAttributes=SLOT_INFO,OPERATING_HOURS_INFO&status=APPROVED&isPaginationRequired=false",
    devCoupledParkingList: "b2b-parking-service/api/v1/parking/deviceCoupled",
    userDevCoupledParkingList:
      "b2b-parking-service/api/v1/parking/deviceCoupled/user/" +
      (params ? params.userId : ""),
    parkingdetail:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/completeDetails",
    parkingdetailSlot:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "?selectAttributes=SLOT_INFO",
    addParking: "b2b-parking-service/api/v1/parking",
    deleteParking:
      "b2b-parking-service/api/v1/parking/" + (params ? params.parkingId : ""),
    ratingData: "b2b-parking-service/api/v1/parking/rating",
    ratingParking:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/rating",
    reviewData:
      "b2b-parking-service/api/v1/parking/review?sortBy=lastModifiedDate.DESC&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    uploadsImages:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/image",
    deleteImages:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/image?" +
      (params ? params.imageId : ""),
    detailReview:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/review?sortBy=lastModifiedDate.DESC&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    detailReviewTopRated:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/review?sortBy=RATING.DESC&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    slotUtilization:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/slotUtilization?fromDate=" +
      (params ? params.date : "") +
      "&vehicleTypeId=" +
      (params ? params.vehicleType : ""),
    detailsImg: params ? params.detailsImg : "",
    detailsImgTwo: params ? params.detailsImgTwo : "",
    editParking:
      "b2b-parking-service/api/v1/parking/" + (params ? params.parkingId : ""),
    userProfile:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    deactivatePS:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/deActivate/",
    activatePS:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/activate/",
    gatewayDisplay:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management/status/all",

    logout: "user-vehicle-service/api/v1/user/logout",
    facility: "b2b-parking-service/api/v1/listOfValues",
    eulaText:
      "user-vehicle-service/api/v1/role/CLOSED_GROUP_PARKING_PROVIDER/eulaText",
    eulaAcceptFlag:
      "user-vehicle-service/api/v1/role/" +
      (params ? params.role : "") +
      "/user/" +
      (params ? params.userId : "") +
      "/eulaAcceptanceFlag",
    reportParkingList:
      "b2b-parking-service/api/v1/parking/listView?selectAttributes=NAME&status=APPROVED&isPaginationRequired=false",
    reportParkingListMt:
      "b2b-parking-service/api/v1/parking/listView?selectAttributes=NAME&status=APPROVED&isPaginationRequired=false&mtaSecurityFlag=true",
    reportParkedUser:
      "booking-service/api/v1/report/parkedSummary?parkingIdList=" +
      (params ? params.selectList : "") +
      "&selectAttributes=USER_INFO,VEH_INFO&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : ""),
    reportRegisterList:
      "user-vehicle-service/api/v1/report/user?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&reportType=END_USER_REGISTRATION_REPORT&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    reportRegisterListCheck:
      "user-vehicle-service/api/v1/report/user?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&reportType=END_USER_REGISTRATION_REPORT&isPaginationRequired=false",

    reportDeregisterList:
      "user-vehicle-service/api/v1/report/user?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&reportType=END_USER_DEREGISTRATION_REPORT&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    onpReportListCheck:
      "b2b-parking-service/api/v1/report/overnightParking?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=true&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "") +
      "&isViolated=" +
      (params ? params.authorized : "") +
      "&vehicleTypes=" +
      (params ? params.vehicleType : ""),
    onpReportDownload:
      "b2b-parking-service/api/v1/report/overnightParking?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : ""),
    overSpeedDetectionReport:
      "b2b-parking-service/api/v1/report/overSpeedDetectionReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=true&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    bookingsReport:
      "booking-service/api/v1/booking?status=" +
      (params ? params.status : "") +
      "&displayPortal=true&sortBy=LAST_MODIFIED_DATE.DESC" +
      "&filterBy.parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "10"),
    bookingsReportStatus:
      "booking-service/api/v1/booking?status=" +
      (params ? params.status : "COMPLETED,CANCELLED,CONFIRMED,PARKED") +
      "&displayPortal=true&sortBy=LAST_MODIFIED_DATE.DESC" +
      "&filterBy.parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "0") +
      "&size=" +
      (params ? params.size : "10"),
    overSpeedDetectionReportDownload:
      "b2b-parking-service/api/v1/report/overSpeedDetectionReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : ""),
    reportTempAccessList:
      "access-control-service/api/v1/report/temporaryaccessControl?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=true&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : ""),
    tempAccessReportDownload:
      "access-control-service/api/v1/report/temporaryaccessControl?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : ""),
    reportParkingSpace:
      "b2b-parking-service/api/v1/report/parking?isPaginationRequired=false",
    reportParkingSpaceDownload:
      "b2b-parking-service/api/v1/report/parking?isPaginationRequired=false&format=" +
      (params ? params.formatType : ""),
    reportRegisterDownload:
      "user-vehicle-service/api/v1/report/user?isPaginationRequired=false&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&reportType=END_USER_REGISTRATION_REPORT&format=" +
      (params ? params.formatType : ""),
    reportDeregisterDownload:
      "user-vehicle-service/api/v1/report/user?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=false&reportType=END_USER_DEREGISTRATION_REPORT&format=" +
      (params ? params.formatType : ""),
    reportParkedUserDownload:
      "booking-service/api/v1/report/parkedSummary?parkingIdList=" +
      (params ? params.selectList : "") +
      "&selectAttributes=USER_INFO,VEH_INFO&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=" +
      (params ? params.formatType : ""),
    complaint:
      "b2b-parking-service/api/v1/complaint?page=" +
      (params ? params.pageNumber : "") +
      "&size=" +
      (params ? params.size : ""),
    complaintSort:
      "b2b-parking-service/api/v1/complaint?sortBy=CREATED_DATE." +
      (params ? params.status : "") +
      "&page=" +
      (params ? params.pageNumber : "") +
      "&size=" +
      (params ? params.size : ""),
    complaintStatus:
      "b2b-parking-service/api/v1/complaint/" +
      (params ? params.complaintId : ""),
    orgList:
      "user-vehicle-service/api/v1/org?size=10&page=" +
      (params ? params.page : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC",
    addOrg: "user-vehicle-service/api/v1/org",
    editOrg:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    orgUpdate:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    orgDelete:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    orgDetailsbyId:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    orgListdropdown: "user-vehicle-service/api/v1/org?page=0&size=999",
    mtfacilitymanagerlist:
      "user-vehicle-service/api/v1/userManagement?roles=FACILITY_ADMIN&size=10&page=" +
      (params ? params.page : "") +
      "&selectAttributes=ORG_INFO,ADDRESS_INFO&sortBy=LAST_MODIFIED_DATE.DESC",
    facilityManagerMtDelete: "user-vehicle-service/api/v1/userManagement",
    editMtFacilityManager:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    createMtFacilityManager: "user-vehicle-service/api/v1/userManagement",
    mtFcmdetails:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    mtOrgDetailsbyId:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    mtOrgUserCount:
      "user-vehicle-service/api/v1/org/user/count?orgIds=" +
      (params ? params.orgId : ""),
    createSecurity: "user-vehicle-service/api/v1/userManagement",
    editFcm:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    securityListView:
      "user-vehicle-service/api/v1/userManagement?roles=SECURITY_PERSONNEL&size=10&page=" +
      (params ? params.pageNumber : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=ROLE_INFO",

    facilityManagerListView:
      "user-vehicle-service/api/v1/userManagement?roles=FACILITY_ADMIN&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "10"),
    resFacilityManagerListView:
      "user-vehicle-service/api/v1/userManagement?roles=RESIDENTIAL_FACILITY_ADMIN&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "10"),
    deleteSecurity: "user-vehicle-service/api/v1/userManagement",
    tempAccessListView:
      "access-control-service/api/v1/getAllTempAccessList?size=10&page=" +
      (params ? params.page : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC&searchData=" +
      (params ? params.searchData : ""),
    tempAccessListViewSync:
      "access-control-service/api/v1/getAllTempAccessList?isPaginationRequired=false",
    viewtempaccountdetails:
      "access-control-service/api/v1/accessControl/" +
      (params ? params.vehicle : "") +
      "/completeDetails",
    manualtempAccessSync:
      "access-control-service/api/v1/manualRetryVehicleSync",
    setAvailability:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/slotAvailability",
    unregisterReport:
      "b2b-parking-service/api/v1/report/unregisteredNonstdVehicles?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=true&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=" +
      (params ? params.size : "") +
      "&format=JSON",
    unregisterReportDownload:
      "b2b-parking-service/api/v1/report/unregisteredNonstdVehicles?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : "") +
      "&format=EXCEL",
    l2DeviceStatus:
      "b2b-parking-service/api/v1/parking/device-management/l2error/" +
      (params ? params.parkingId : "") +
      "?deviceType=" +
      (params ? params.deviceType : ""),
    tempAccessBulkUpload:
      "access-control-service/api/v1/vehicle/bulkUpload?parkingId=" +
      (params ? params.parkingId : "") +
      "&dryRun=false",
    accessList: "access-control-service/api/v1/accessType",
    vehicleList: "access-control-service/api/v1/vehicleType",
    deleteTempAccessUser: "access-control-service/api/v1/accessControl",
    editVehicleDetails: "access-control-service/api/v1/updateAccessControl",
    saveVehicleExitEvents:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/vehicleNumber/" +
      (params ? params.vehicleNumber : "") +
      "?toDate=" +
      (params ? params.dateTime : ""),
    deviceOffilineReportGeneration:
      "b2b-parking-service/api/v1/offlineReport/parking?size=" +
      (params ? params.size : "") +
      "&page=" +
      (params ? params.page : "") +
      "&isPaginationRequired=true&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&format=JSON",
    deviceOffilineReportDownload:
      "b2b-parking-service/api/v1/offlineReport/parking?isPaginationRequired=false&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&format=EXCEL",
    vehicleAccessReportGeneration:
      "access-control-service/api/v1/report/vehicleaccess?size=" +
      (params ? params.size : "") +
      "&page=" +
      (params ? params.page : "") +
      "&isPaginationRequired=true&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&format=JSON",
    vehicleAccessReportDownload:
      "access-control-service/api/v1/report/vehicleaccess?isPaginationRequired=false&fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&format=EXCEL",
    saveAlertNotificationDetails:
      "b2b-parking-service/api/v1/parking/alertNotification",
    getAlertNotificationDetails:
      "b2b-parking-service/api/v1/parking/viewAllContact?size=" +
      (params ? params.size : "") +
      "&page=" +
      (params ? params.page : "") +
      "&isPaginationRequired=true",
    editAlertNotificationDetails:
      "b2b-parking-service/api/v1/parking/updateAllContact",
    deleteAlertNotification: "b2b-parking-service/api/v1/parking/deleteContact",
    getParkingWithoutContacts:
      "b2b-parking-service/api/v1/parking/parkingSpace-Without-contact-detals/user/" +
      (params ? params.userId : ""),
    Booking: "booking-service/api/v1/booking",
    BookingAvailabilityCount:
      "booking-service/api/v1/bookingAvaialbleCount?parkingIds=" +
      (params ? params.parkingId : "") +
      "&fromTime=" +
      (params ? params.from : "") +
      "&toTime=" +
      (params ? params.to : ""),
    viewAllBookings:
      "booking-service/api/v1/viewAllBookings?status=" +
      (params ? params.status : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC" +
      "&filterBy.parkingId=" +
      (params ? params.parkingId : "") +
      "&startTime=" +
      (params ? params.startTime : "") +
      "&endTime=" +
      (params ? params.endTime : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=10",
    bookingDetails:
      "booking-service/api/v1/booking/" + (params ? params.bookingId : ""),
    cancelBooking:
      "booking-service/api/v1/booking/" +
      (params ? params.bookingId : "") +
      "/cancel",
      bookingparkingList:"b2b-parking-service/api/v1/user/"+(params?params.userId:"")+"/parking?status=APPROVED",
    Checkin: "booking-service/api/v1/booking/checkIn",
    Checkout: "booking-service/api/v1/booking/checkOut",
    noShowViewReports:
      "booking-service/api/v1/report/noShowBookingReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=10" +
      "&operatedBy=" +
      (params ? params.operatedBy : ""),
    noshowReportDownload:
      "booking-service/api/v1/report/noShowBookingReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : "") +
      "&operatedBy=SYSTEM",

    manualCancelViewReports:
      "booking-service/api/v1/report/manualCancelReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=10",

    manualCancelReportDownload:
      "booking-service/api/v1/report/manualCancelReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : ""),

      releaseFailureReport:
      "booking-service/api/v1/report/releaseFailureReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&parkingId=" +
      (params ? params.parkingId : "") +
      "&page=" +
      (params ? params.page : "") +
      "&size=10",

      releaseFailureReportDownload:
      "booking-service/api/v1/report/releaseFailureReport?fromDate=" +
      (params ? params.fromTime : "") +
      "&toDate=" +
      (params ? params.toTime : "") +
      "&format=EXCEL&isPaginationRequired=false&parkingId=" +
      (params ? params.parkingId : ""),
  };

  //DEV ENV
  if (
    window.location.host === "localhost:3000" ||
    window.location.host === "dev-admin.parkzeus.in"
  ) {
    const baseUrlPZ = "https://dev.parkzeus.in/";
    const baseUrlMCP = "https://qa-aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://qa.boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword" || key === "generateOTP") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
  //QA ENV
  else if (
    window.location.host === "localhost:3001" ||
    window.location.host === "beta-admin.parkzeus.in"
  ) {
    const baseUrlPZ = "https://beta.parkzeus.in/";
    const baseUrlMCP = "https://qa-aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://qa.boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword" || key === "generateOTP") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
  //PRODUCTION ENV
  else if (window.location.host === "admin.parkzeus.in") {
    const baseUrlPZ = "https://services.parkzeus.in/";
    const baseUrlMCP = "https://aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword" || key === "generateOTP") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
};

export default UrlConfig;
