// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import * as Scroll from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Card,
  Paper,
  TableContainer,
  Grid,
  Tooltip,
  Button,
} from "@material-ui/core";
import ParkingFacilitiesServices from "./../ParkingFacilitiesServices";
import SlotCount from "./../SlotCount";
import OperationTimings from "./../OperationTimings";
import address from "../../../../../Assets/Images/address.png";
import Ratingsandreviews from "./../Ratingsandreviews";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VarifideImg from "../../../../../Assets/Images/verified tag.png";
import { updateAuth } from "../../../../../redux/modules/app/actions";
import { withRouter } from "react-router";
import httpRequest from "./../../../../../Utils/httpRequest";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../../redux/modules/app/actions";
import live from "../../../../../Assets/Images/live.png";
//import outside from '../../../../../Assets/Images/outside.png';
import offline from "../../../../../Assets/Images/offline.png";
import info from "../../../../../Assets/Images/info.png";
import { getCookie } from "../../../../../Utils/cookies";
import { updateToasterConfig } from "./../../../../../../src/redux/modules/app/actions";
import StoreContext from "../../../../../context/dashboard-context";
import { Link } from "react-router-dom";
import Loader from "../../../../../Components/Preloader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import FlexiText from "../../../../../Components/FlexiText";
import L2Devices from "../L2Devices/index";
import SolAdminDetails from "./../SolAdminDetails";
import BookingsCard from "../BookingsCard";

// Global variable declaration
let parkingId = null;


//@component
//Parking Details
class ParkingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingPop: false,
      parkingDetail: {},
      gatewayDetail: [{}],
      imgLink: "",
      pageImg: [],
      imgData: [],
      passPop: false,
      setopen: false,
      passPopA: false,
      passPopD: false,
      open: false,
      dialogTitle: "",
      dialogContent: "",
      mainGateway: null,
      isLoad: false,
      adminParking: false,
    };
  }
  // react-scroll library variables to scroll to bottom on clicking see all reviews button in rating component dashboard
  scroll = Scroll.animateScroll;
  //function for getDetails
  getDetails = () => {
    this.setState({
      isLoad: true,
    });
    parkingId = this.props.match.params.id;
    httpRequest
      .get("parkingdetail", {}, this.UserDataSuccess, this.errorCallback, {
        parkingId: this.props.match.params.id,
        userId: getCookie("userId"),
      })
      .then(() => {})
      .catch(() => {});
    httpRequest
      .get(
        "gatewayDisplay",
        {},
        this.gatewayDisplaySuccess,
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
        }
      )
      .then(() => {})
      .catch(() => {});
  };
  //function for gatewayDisplaySuccess
  gatewayDisplaySuccess = (gateWayresponse) => {
    // let mainGateway =
    this.setState({
      gatewayDetail: gateWayresponse["data"],
      mainGateway: gateWayresponse["data"].filter((dev) => {
        return dev.deviceType === "gw";
      })[0],
    });
    // let test = this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="anpr"})[0]
    // console.log("dfdsfdsfdsfdsfdsfsdfsdfsdfsdf",test)
  };
  //function for activatePSMethod
  activatePSMethod = () => {
    parkingId = this.props.match.params.id;
    httpRequest
      .patch(
        "activatePS",
        {
          Authorization: "Bearer " + getCookie("auth"),
          'realmname': getCookie('realmName'),
        },
        this.props.updateToasterConfig({
          show: true,
          message: "Activating...",
          color: "rgb(103, 181, 97)",
        }),
        //console.log(this.props.match.params.id),
        this.errorCallback,

        {
          parkingId: this.props.match.params.id,
        }
      )
      .then(() => {
        this.setState({
          displayStatus: "Approved",
          isActive: true,
        });

        window.location.href =
          "/portal/parking-details/" + this.props.match.params.id;

        this.props.updateToasterConfig({
          show: true,
          message: "The Parking has been activated",
          color: "rgb(103, 181, 97)",
        });
      })
      .catch(() => {
        this.props.updateToasterConfig({
          show: true,
          message: "Something went wrong, try again",
          color: "#ff0000",
        });
      });
  };
  //function for updateMe
  updateMe = () => {
    this.interval = setInterval(() => {
      httpRequest.get(
        "parkingdetailSlot",
        {},
        this.UserDataSuccess,
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
          userId: getCookie("userId"),
        }
      );
    }, 10000);
  };
  //function for component mount
  componentDidMount() {
     

    if (this.props.location?.state?.ratings) {
      scroll.scrollTo(2000);
    }
    if (this.props.location?.state?.deviceClick) {
      scroll.scrollTo(500);
    }
    if (this.props.location?.state?.slotCard) {
      scroll.scrollTo(1000);
    }
    if (this.props.location?.state?.bookingCard) {
      scroll.scrollTo(2800);
    }
    this.props.updateBreadcrumbs("parkingDetails");
    this.getDetails();
    // this.updateMe();
  }
  //function for component unmount
  componentWillUnmount = () => {
    // clearInterval(this.interval);
  };
  //function for imageSuccess
  imageSuccess = (jsonResult) => {
    this.setState({
      pageImg: jsonResult.data.imageLinks,
    });

    if (this.state.pageImg.length <= 0) {
      this.setState({
        isLoad: false,
      });
    }
    this.state.pageImg
      .forEach(function(each) {
        httpRequest.getImage(
          each.link + "?responseType=BASE64_ENCODE",
          {
            Accept: "image/png",
          },
          this.imageSuccessTwo,
          this.errorCallback,
          {
            detailsImgTwo: each.link + "?responseType=BASE64_ENCODE",
          }
        );
      }, this)
      .then(() => {})
      .catch(() => {});
  };
  //function for imageSuccessTwo
  imageSuccessTwo = (jsonResult) => {
    let imgData = this.state.imgData;
    imgData.indexOf(jsonResult.data) == -1 && imgData.push(jsonResult.data);

    this.setState({
      imgData,
      isLoad: false,
    });
  };
  //function for UserDataSuccess
  UserDataSuccess = (jsonResult) => {
    if (jsonResult.data?.createdBy === getCookie("userId")) {
      this.setState({
        adminParking: true,
      });
    }

    this.setState({
      parkingDetail: jsonResult.data,
      imgLink: jsonResult.data._links.parkingImages.href,
    });

    httpRequest
      .getImage(this.state.imgLink, {}, this.imageSuccess, this.errorCallback, {
        detailsImg: this.state.imgLink,
      })
      .then(() => {})
      .catch(() => {});
    var weekdays = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];

    //////////////////////////////////////
    console.log(this.state.parkingDetail);
    var today = new Date().getDay();
    var curTime =
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds();

    var days = [];
    var listOperatingHour = [];
    for (var i in jsonResult.data.operatingHoursInfo) {
      days.push(i);
      listOperatingHour.push(jsonResult.data.operatingHoursInfo[i]);
    }

    days.map((item, index) => {
      item === weekdays[today] &&
        curTime > listOperatingHour[index][0].openingTime &&
        curTime < listOperatingHour[index][0].closingTime &&
        this.setState({ setopen: true });
      return <div key={index}></div>;
    });
  };
  //function for error call
  errorCallback = () => {};
  //function for deleteClickCancel
  deleteClickCancel = () => {
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  //function for deleteClick
  deleteClick = () => {
    this.setState({
      isLoadingPop: !this.state.isLoadingPop,
    });
    httpRequest
      .delete("deleteParking", {}, this.deleteSuccess, this.deleteError, {
        parkingId: this.props.match.params.id,
        userId: getCookie("userId"),
      })
      .then(() => {})
      .catch(() => {
        this.props.updateToasterConfig({
          show: true,
          message: "Something went wrong, please try again",
          color: "#03A9F3",
        });
      });
  };

  //function for deleteParking
  deleteParking = () => {
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  //function for deleteSuccess
  deleteSuccess = () => {
    setTimeout(() => {
      this.setState({
        isLoadingPop: !this.state.isLoadingPop,
      });
      this.props.updateToasterConfig({
        show: true,
        message: "The Parking has been deleted",
        color: "#03A9F3",
      });
    }, 3000);
    this.props.history.push("/portal/parkingmanagment");
  };
  //function for deleteError
  deleteError = (error) => {
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: error.message,
        color: "#ff0000",
      });
    }, 2000);
    this.setState({
      passPop: !this.state.passPop,
      isLoadingPop: !this.state.isLoadingPop,
    });
  };
  //function for deactivatePSMethod
  deactivatePSMethod = () => {
    parkingId = this.props.match.params.id;
    httpRequest
      .patch(
        "deactivatePS",
        {
          Authorization: "Bearer " + getCookie("auth"),
          'realmname': getCookie('realmName'),
        },
        this.props.updateToasterConfig({
          show: true,
          message: "Deactivating...",
          color: "#E46A76",
        }),
        //console.log(this.props.match.params.id),
        this.errorCallback,

        {
          parkingId: this.props.match.params.id,
        }
      )
      .then(() => {
        this.setState({
          displayStatus: "Deactivated",
          isActive: true,
        });
        window.location.href =
          "/portal/parking-details/" + this.props.match.params.id;

        this.props.updateToasterConfig({
          show: true,
          message: "The Parking has been deactivated",
          color: "#E46A76",
        });
      })
      .catch(() => {
        this.props.updateToasterConfig({
          show: true,
          message: "Something went wrong, try again",
          color: "#ff0000",
        });
      });
  };
  //function for confirmDeactivateParking
  confirmDeactivateParking = () => {
    this.setState({
      passPopD: !this.state.passPopD,
    });
  };
  //function for deActivateClickCancel
  deActivateClickCancel = () => {
    this.setState({
      passPopD: !this.state.passPopD,
    });
  };
  //function for confirmActivateParking
  confirmActivateParking = () => {
    this.setState({
      passPopA: !this.state.passPopA,
    });
  };
  //function for activateClickCancel
  activateClickCancel = () => {
    this.setState({
      passPopA: !this.state.passPopA,
    });
  };
  //function for toggleButtonMethodActivate
  toggleButtonMethodActivate = () => {
    this.setState({
      passPopA: false,
    });
    this.activatePSMethod();
  };
  //function for toggleButtonMethodDeactivate
  toggleButtonMethodDeactivate = () => {
    this.setState({
      passPopD: false,
    });
    this.deactivatePSMethod();
  };

  // function for popup
  openPopup = (openStatus, title, content) => {
    this.setState({
      open: openStatus,
      dialogTitle: title,
      dialogContent: content,
    });
  };

  render() {
    const parkingDetail = this.state.parkingDetail;
    return (
      <StoreContext.Consumer>
        {() => (
          <div>
            {!!Object.keys(this.state.parkingDetail).length && (
              <div className="DetailParking">
                <div className="DetailParking__wrapper">
                  <div className="DetailParking__wrapper_title">
                    <p className="DetailParking__wrapper_title_parking-name">
                      {parkingDetail.name}
                      {parkingDetail.status.name === "VERIFIED" ? (
                        this.state.setopen ? (
                          <img src={live} alt="image3" />
                        ) : (
                          <img src={live} alt="image3" />
                        )
                      ) : parkingDetail.status.name === "REQUEST_SUBMITTED" ? (
                        <img src={offline} alt="image2" />
                      ) : parkingDetail.status.name === "REQUEST_REJECTED" ? (
                        <img src={offline} alt="image2" />
                      ) : parkingDetail.status.name === "APPROVED" ? (
                        this.state.setopen ? (
                          <img src={live} alt="image3" />
                        ) : (
                          <img src={live} alt="image3" />
                        )
                      ) : (
                        <img src={offline} alt="image2" />
                      )}
                      {parkingDetail.isVerified ? (
                        <img
                          src={VarifideImg}
                          alt="image3"
                          style={{ width: "70px" }}
                        />
                      ) : null}
                    </p>

                    <div className="DetailParking__wrapper_title_actions">
                      {parkingDetail.currentStatus.name === "DEACTIVATED" &&
                        parkingDetail.createdBy === getCookie("userId") && (
                          <Button
                            variant="contained"
                            className="activateBtn"
                            onClick={this.confirmActivateParking}
                          >
                            Activate
                          </Button>
                        )}
                      {parkingDetail.currentStatus.name === "APPROVED" &&
                        parkingDetail.createdBy === getCookie("userId") && (
                          <Button
                            variant="contained"
                            className="activateBtn"
                            onClick={this.confirmDeactivateParking}
                          >
                            Deactivate
                          </Button>
                        )}
                      {(parkingDetail.currentStatus.name == "APPROVED" ||
                        parkingDetail.currentStatus.name === "DEACTIVATED" ||
                        parkingDetail.currentStatus.name ===
                          "ADDITIONAL_INFO_REQUIRED") &&
                      parkingDetail.createdBy === getCookie("userId") ? (
                        <Button
                          variant="contained"
                          className="DetailParking__wrapper_title_actions_edit"
                          onClick={() => {
                            this.props.history.push(
                              "/portal/editparkingspace?parkingId=" + parkingId
                            );
                          }}
                        >
                          <AssignmentIcon /> Edit
                        </Button>
                      ) : null}
                      {/* disable edit button */}
                      {/* {parkingDetail.status.name ==
                  'REQUEST_REJECTED' ? null : (
                    <Button
                      disabled={
                        parkingDetail.status.name ==
                        'REQUEST_SUBMITTED'
                      }
                      variant="contained"
                      className="DetailParking__wrapper_title_actions_edit"
                      onClick={() => {
                        this.props.history.push(
                          '/portal/editparkingspace?parkingId=' +
                            parkingId,
                        );
                      }}
                    >
                      <AssignmentIcon /> Edit
                    </Button>
                  )} */}
                      {parkingDetail.createdBy === getCookie("userId") && (
                        <Button
                          variant="contained"
                          className="DetailParking__wrapper_title_actions_delete"
                          onClick={() =>
                            this.deleteParking(parkingDetail.parkingId)
                          }
                        >
                          <DeleteOutlinedIcon />
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                  <Paper className="pm-pso-content">
                    <div className="DetailParking__wrapper_basicdetails">
                      <div className="DetailParking__wrapper_basicdetails_left">
                        <Card>
                          {this.state.isLoad ? (
                            <Grid
                              className="noPreview"
                              item
                              md={6}
                              style={{ marginBottom: "1em" }}
                            >
                              <TableContainer>
                                <div
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "poppins",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    Loading Images...
                                  </p>
                                </div>
                              </TableContainer>
                            </Grid>
                          ) : this.state.pageImg.length <= 0 &&
                            this.state.isLoad === false ? (
                            <Grid
                              className="noPreview"
                              item
                              md={6}
                              style={{ marginBottom: "1em" }}
                            >
                              <TableContainer>
                                <div
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "poppins",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    No image to preview for this parking space.
                                  </p>
                                </div>
                              </TableContainer>
                            </Grid>
                          ) : (
                            <Carousel
                              className="DetailParking__wrapper_basicdetails_left_carousel-box"
                              showThumbs={false}
                            >
                              {this.state.imgData.map((item, i) => (
                                <div key={i} className="carousel__images">
                                  <img
                                    alt="item"
                                    src={`data:image/png;base64,${item}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          )}
                        </Card>
                      </div>

                      <div className="DetailParking__wrapper_basicdetails_right">
                        <div className="DetailParking__wrapper_basicdetails_right_address">
                          <div className="DetailParking__wrapper_basicdetails_right_address_map_">
                            <Link
                              to={
                                "/portal/parkingmanagment?" +
                                parkingDetail.parkingId
                              }
                            >
                              <img src={address} alt="map-img" />
                            </Link>
                          </div>
                          <div className="DetailParking__wrapper_basicdetails_right_address_text">
                            <p className="DetailParking__wrapper_basicdetails_right_address_text_heading">
                              <b>Address</b>
                            </p>
                            <p className="DetailParking__wrapper_basicdetails_right_address_text_details">
                              {parkingDetail.addressDetails
                                ? parkingDetail.addressDetails?.addressLine1 +
                                  "," +
                                  (parkingDetail.addressDetails.addressLine2
                                    ? parkingDetail.addressDetails
                                        ?.addressLine2 + ","
                                    : "") +
                                  parkingDetail.addressDetails?.city +
                                  "," +
                                  parkingDetail.addressDetails?.state +
                                  "," +
                                  parkingDetail.addressDetails?.pincode
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="DetailParking__wrapper_basicdetails_right_moredetails">
                          <div className="DetailParking__wrapper_basicdetails_right_moredetails_box">
                            <ul>
                              <li>
                                <b>Status</b>
                              </li>
                              <li style={{ marginLeft: "0.8em" }}>
                                {parkingDetail.status.name === "VERIFIED" ? (
                                  <span
                                    style={{
                                      color: `${parkingDetail.status.color}`,
                                    }}
                                  >
                                    Verified
                                  </span>
                                ) : parkingDetail.status.name ===
                                  "ADDITIONAL_INFO_REQUIRED" ? (
                                  <span
                                    style={{
                                      color: `${parkingDetail.status.color}`,
                                    }}
                                  >
                                    Additional info required
                                  </span>
                                ) : parkingDetail.status.name ===
                                  "REQUEST_SUBMITTED" ? (
                                  <span style={{ color: "#F49D3E" }}>
                                    Request Submitted
                                  </span>
                                ) : parkingDetail.status.name === "APPROVED" &&
                                  parkingDetail.currentStatus.name ===
                                    "APPROVED" ? (
                                  <span style={{ color: "#67B561" }}>
                                    Approved
                                  </span>
                                ) : parkingDetail.status.name === "APPROVED" &&
                                  parkingDetail.currentStatus.name ===
                                    "REQUEST_SUBMITTED" ? (
                                  <span style={{ color: "#F49D3E" }}>
                                    Approved
                                  </span>
                                ) : parkingDetail.status.name ===
                                  "REQUEST_REJECTED" ? (
                                  <span
                                    style={{
                                      color: `${parkingDetail.status.color}`,
                                    }}
                                  >
                                    Request Rejected
                                  </span>
                                ) : parkingDetail.status.name ===
                                  "DEACTIVATED" ? (
                                  <span style={{ color: "brown" }}>
                                    Deactivated
                                  </span>
                                ) : (
                                  <span style={{ color: "#A2A2A2" }}>
                                    Draft
                                  </span>
                                )}
                              </li>
                              <li>
                                <Tooltip
                                  disableFocusListener
                                  title={
                                    parkingDetail?.currentStatus?.displayName
                                      ? parkingDetail?.currentStatus
                                          ?.displayName
                                      : ""
                                  }
                                  classes={{
                                    popper: "statusInfoTooltip",
                                  }}
                                  arrow
                                  placement="top-start"
                                >
                                  <Button className="iIconstyle">
                                    <img alt="info" src={info} />
                                  </Button>
                                </Tooltip>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b>Type</b>
                              </li>
                              <li className="type">
                                {parkingDetail?.parkingBusinessType?.name
                                  ? parkingDetail?.parkingBusinessType?.name
                                  : ""}
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b>Description</b>
                              </li>
                              <li className="description">
                                <span>
                                  {parkingDetail?.description
                                    ? parkingDetail?.description?.substring(
                                        0,
                                        25
                                      )
                                    : ""}
                                </span>
                                {parkingDetail?.description.length > 25 && (
                                  <span
                                    className="view-more"
                                    onClick={() =>
                                      this.openPopup(
                                        true,
                                        "Description",
                                        parkingDetail.description
                                      )
                                    }
                                  >
                                    view more
                                  </span>
                                )}
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b>Contact Name</b>
                              </li>
                              <li className="contact-name">
                                <span>
                                  {parkingDetail?.contactName
                                    ? parkingDetail?.contactName
                                    : ""}
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b>Contact No.</b>
                              </li>
                              <li className="contact-number">
                                <FlexiText
                                  text={
                                    parkingDetail?.contactNumber
                                      ? parkingDetail?.contactNumber
                                      : ""
                                  }
                                  length={40}
                                />
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <b>Email ID</b>
                              </li>
                              <li className="email-id">
                                <span>
                                  {parkingDetail?.contactEmail
                                    ? parkingDetail?.contactEmail?.substring(
                                        0,
                                        25
                                      )
                                    : ""}
                                </span>
                                {parkingDetail?.contactEmail.length > 25 && (
                                  <span
                                    className="view-more"
                                    onClick={() =>
                                      this.openPopup(
                                        true,
                                        "Email",
                                        parkingDetail?.contactEmail
                                      )
                                    }
                                  >
                                    view more
                                  </span>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paper>
                  {this.state.mainGateway && (
                    //         <Grid container direction="column" xs={12} className="gateways" style={{
                    //           display: 'flex',
                    //           justifyContent: 'center'
                    //         }}  >
                    //           <Grid item container xs={2} style={{
                    //             display: 'flex',
                    //             justifyContent: 'center',
                    //             textAlign: 'center'
                    //           }} >
                    //             {this.state.mainGateway?.status?.name==="UP"?(
                    //               <>
                    //             <img src={flgateway} className="edgeDeviceImg" />
                    //             <Grid container direction="column">
                    //             <div className="gateways_text">
                    //               <p className="gateways_heading">
                    //                 {this.state.mainGateway?.deviceName} is Online
                    //               </p>
                    //               <p className="gateways_details">
                    //                 Last Offline : {this.state.mainGateway?.lastGatewayOfflineDate===0?"--":moment(this.state.mainGateway?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    //               </p>
                    //             </div>
                    //             </Grid>
                    //             </>):(
                    //               <>
                    //               <img src={flgatewayGray} className="edgeDeviceImg" />
                    //               <Grid container direction="column">
                    //               <div className="gateways_text">
                    //                 <p className="gateways_heading">
                    //                 {this.state.mainGateway?.deviceName} is Offline
                    //                 </p>
                    //                 <p className="gateways_details">
                    //                   Last Offline : {this.state.mainGateway?.lastGatewayOfflineDate===0?"--":moment(this.state.mainGateway?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    //                 </p>
                    //               </div>
                    //               </Grid>
                    //               </>
                    //             )}
                    //           </Grid>
                    //           <Grid container direction="row" justifyContent="space-around"  style={{marginTop: '1.5rem'}}>
                    //           {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="boom"})[0] && (
                    //            <Grid item >
                    //           <Grid container direction="column" className="edgeCenter" >
                    //             {(this.state.mainGateway?.status?.name==='DOWN' || (this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="boom"}))[0]?.status?.name==='DOWN')?
                    //           (<><img src={bbGray} className="edgeDeviceImg" />
                    //           <label className="gateways_heading">Boom Barrier is Offline</label></>):
                    //           (<><img src={bb} className="edgeDeviceImg" />
                    //           <label className="gateways_heading">Boom Barrier is Online</label></>)
                    //             }

                    //            <p className="gateways_details">
                    //             Last Offline : {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="boom"})[0]?.lastGatewayOfflineDate===0?"--":moment(this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="boom"})[0]?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    //           </p>
                    //            </Grid>
                    //             </Grid>
                    //             )}
                    //             {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="anpr"})[0] && (
                    //             <Grid item >
                    //             <Grid container direction="column" className="edgeCenter" >
                    //             {(this.state.mainGateway?.status?.name==='DOWN' || (this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="anpr"}))[0]?.status?.name==='DOWN')?
                    //           (<><img src={anprGray} className="edgeDeviceImg" />
                    //           <label className="gateways_heading">ANPR Camera is Offline</label></>):
                    //           (<><img src={anpr} className="edgeDeviceImg" />
                    //           <label className="gateways_heading">ANPR Camera is Online</label></>)
                    //             }
                    //              <p className="gateways_details">
                    //   Last Offline : {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="anpr"})[0]?.lastGatewayOfflineDate===0?"--":moment(this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="anpr"})[0]?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    // </p>
                    //             </Grid>
                    //             </Grid>
                    //             )}
                    //             {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="ioc"})[0] && (
                    //             <Grid item >
                    //             <Grid container direction="column" className="edgeCenter" >
                    //             {(this.state.mainGateway?.status?.name==='DOWN' || (this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="ioc"}))[0]?.status?.name==='DOWN')?
                    //            (<><img src={iocGray} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">Camera counter  is Offline</label></>):
                    //            (<><img src={ioc} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">Camera counter is Online</label></>)
                    //             }
                    //            <p className="gateways_details">
                    //             {/* Last Offline : -- */}
                    //             Last Offline : {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="ioc"})[0]?.lastGatewayOfflineDate===0?"--":moment(this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="ioc"})[0]?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    //           </p>
                    //             </Grid>
                    //             </Grid>
                    //             )}
                    //             {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="vms"})[0] && (
                    //             <Grid item >
                    //             <Grid container direction="column" className="edgeCenter" >
                    //             {(this.state.mainGateway?.status?.name==='DOWN' || (this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="vms"}))[0]?.status?.name==='DOWN')?
                    //            (<><img src={ledGray} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">LED display is Offline</label></>):
                    //            (<><img src={led} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">LED display is Online</label></>)
                    //             }
                    //            <p className="gateways_details">
                    //   Last Offline : {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="vms"})[0]?.lastGatewayOfflineDate===0?"--":moment(this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="vms"})[0]?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    // </p>
                    //             </Grid>
                    //             </Grid>
                    //             )}
                    //             {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="zcu"})[0] && (
                    //             <Grid item >
                    //             <Grid container direction="column" className="edgeCenter" >
                    //             {(this.state.mainGateway?.status?.name==='DOWN' || (this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="zcu"}))[0]?.status?.name==='DOWN')?
                    //            (<><img src={zcuGray} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">Zone Controller is Offline</label></>):
                    //            (<><img src={zcu} className="edgeDeviceImg" />
                    //            <label className="gateways_heading">Zone Controller is Online</label></>)
                    //             }
                    //            <p className="gateways_details">
                    //   Last Offline : {this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="zcu"})[0]?.lastGatewayOfflineDate===0?"--":moment(this.state.gatewayDetail.filter(dev=>{return dev.deviceType==="zcu"})[0]?.lastGatewayOfflineDate).format("hh:mm a DD MMM YYYY")}
                    // </p>
                    //             </Grid>
                    //             </Grid> )}
                    //             </Grid>

                    //         </Grid>
                    <L2Devices
                      parkingId={this.props.match.params.id}
                      mainGateway={this.state.mainGateway}
                      gatewayDetail={this.state.gatewayDetail}
                    />
                  )}
                  <SolAdminDetails
                    address={this.props.address}
                    solAdminId={parkingDetail.createdBy}
                    admin={this.state.adminParking}
                    contactNumber={parkingDetail?.contactNumber}
                  />
                  <SlotCount
                    parkingId={parkingDetail.parkingId}
                    vehicleTypeInfo={parkingDetail.vehicleTypeInfo}
                    gateway={parkingDetail.isGatewayOutOfSync}
                    createdBy={parkingDetail.createdBy}
                  />

               
                  <OperationTimings
                    timings={parkingDetail.operatingHoursInfo}
                    customData={parkingDetail.customOperatingHoursInfo}
                    statusName={parkingDetail.currentStatus}
                  />
                  <ParkingFacilitiesServices
                    facilities={parkingDetail.facilities}
                    services={parkingDetail.services}
                  />
                  {getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER" &&
                    getCookie("role") !== "RESIDENTIAL_FACILITY_ADMIN" && (
                      <Ratingsandreviews
                        parkingId={this.props.match.params.id}
                        rating={parkingDetail.rating}
                      />
                    )}
                       {//ey QR CODE BOOKING TABLE
                  getCookie("isHardwareAvailable") === "false" && (
                    <BookingsCard
                      parkingId={parkingDetail.parkingId}
                      vehicleTypeInfo={parkingDetail.vehicleTypeInfo}
                      gateway={parkingDetail.isGatewayOutOfSync}
                      createdBy={parkingDetail.createdBy}
                      bookingStatus={this.props.location?.state?.parked===true?'PARKED':'COMPLETED,CANCELLED,CONFIRMED,PARKED'}
                      parked={this.props.location?.state?.parked}
                    />
                  )}
                </div>
              </div>
            )}
            {this.state.passPop && (
              <div className="deletepup-wrapper">
                {this.state.isLoadingPop && <Loader />}
                <div className="password-popup">
                  <div className="heading">
                    <p className="popup-heading">Delete Parking</p>
                    {/* <span
                  className="cross"
                  onClick={() => this.deleteClickCancel()}
                >
                  X
                </span> */}
                  </div>
                  <p className="popup-text">
                    If you want to delete this Parking Space then please press
                    Delete button
                  </p>
                  <div className="popup-button">
                    <Button
                      className="cancel"
                      variant="contained"
                      onClick={() => this.deleteClickCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.isLoadingPop === true}
                      className="reset"
                      variant="contained"
                      onClick={() => this.deleteClick()}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {this.state.passPopA && (
              <div className="deletepup-wrapper">
                <div className="password-popup">
                  <div className="heading">
                    <p className="popup-heading">Activate Parking</p>
                    {/* <span
                  className="cross"
                  onClick={() => this.activateClickCancel()}
                >
                  X
                </span> */}
                  </div>
                  <p className="popup-text">
                    Do you really want to activate the {parkingDetail.name}{" "}
                    parking space?
                  </p>
                  <div className="popup-button">
                    <Button
                      className="cancel"
                      variant="contained"
                      onClick={() => this.activateClickCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="reset"
                      variant="contained"
                      onClick={() => this.toggleButtonMethodActivate()}
                    >
                      Activate
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {this.state.passPopD && (
              <div className="deletepup-wrapper">
                <div className="password-popup">
                  <div className="heading">
                    <p className="popup-heading">Deactivate Parking</p>
                    {/* <span
                  className="cross"
                  onClick={() => this.deActivateClickCancel()}
                >
                  X
                </span> */}
                  </div>
                  <p className="popup-text">
                    Do you really want to deactivate the {parkingDetail.name}{" "}
                    parking space?
                  </p>
                  <div className="popup-button">
                    <Button
                      className="cancel"
                      variant="contained"
                      onClick={() => this.deActivateClickCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="reset"
                      variant="contained"
                      onClick={() => this.toggleButtonMethodDeactivate()}
                    >
                      Deactivate
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Dialog
              open={this.state.open}
              onClose={() => {
                this.setState({
                  open: false,
                });
              }}
              PaperProps={{
                style: {
                  padding: "32px",
                },
              }}
              style={{ zIndex: 2 }}
            >
              <MuiDialogTitle>
                <Grid item>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>{this.state.dialogTitle}</Grid>
                    <Grid item>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ open: false })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
              <DialogContent>
                <textarea disabled rows="15" cols="50">
                  {this.state.dialogContent}
                </textarea>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </StoreContext.Consumer>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateBreadcrumbs, updateToasterConfig }
  )(ParkingDetails)
);
