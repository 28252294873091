// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import {
  CardHeader,
  Card,
  IconButton,
  CardContent,
  Typography,
} from "@material-ui/core/";
import lineIcon from "../../../Assets/Images/Line graph.png";
import barIcon from "../../../Assets/Images/bar graph.png";
import areaIcon from "../../../Assets/Images/pie chart.png";
import lineIconSelected from "../../../Assets/Images/selected line graph.png";
import barIconSelected from "../../../Assets/Images/selected bar graph.png";
import areaIconSelected from "../../../Assets/Images/selected pie chart.png";
import bike from "../../../Assets/Dashboard_icons/Bike_icons/motorcycle.png";
import car from "../../../Assets/Dashboard_icons/Car_icons/car.png";
import fleet from "../../../Assets/Images/Fleet.svg";
import DateSlider from "../DateSlider/DateSlider";
import Grid from "@material-ui/core/Grid";
import httpRequest from "../../../Utils/httpRequest";
import { getCookie } from "../../../Utils/cookies";
import Chart from "./Chart.js";
import Loader from "../../Preloader";
import "./Chart.scss";

//@component
//ChartContainer
export class ChartContainer extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      dropdownOpen: false,
      chartType: "Bar",
      line: false,
      bar: true,
      area: false,
      parkingList: [],
      parkingData: {},
      id: "",
      vehicleType: "1",
      date: "",
      fmtDate: "",
      yAxesMaxVal: 100,
      yAxesStepSize: 10,
      noDataFound: "",
      onLoad: true,
      totalSlots: "",
      loading: false,
    };
  }

  //function for component mount
  componentDidMount() {
    this.getParkingList();
  }

  //function for parking list
  getParkingList = () => {
    httpRequest.get(
      "devCoupledParkingList",
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {}
    );
  };
  //function for api list
  parkingListSuccess = (jsonResult) => {
    let list = jsonResult.data;
    if (list.length >= 1) {
      this.setState(
        {
          parkingList: list,
          id: list[0].parkingId,
          vehicleType: 1,
        },
        () => this.graphData()
      );
      // console.log("parking list",this.state.parkingList,this.state.id,data.length)
    } else
      this.setState({
        noDataFound: "You don't have any data here.",
        id: "",
      });
  };
  //function for graph data
  graphData = () => {
    this.setState({ loading: true });
    if (this.state.id !==""){
    httpRequest.get(
      "slotUtilization",
      {},
      this.UserDataSuccess,
      this.errorCallback,
      {
        userId: getCookie("userId"),
        parkingId: this.state.id,
        date: this.state.date,
        vehicleType: this.state.vehicleType,
      }
    );
    }
  };
  //function for error callback
  errorCallback = (error) => {
    if (error.type === "ERROR") {
      this.setState({
        noDataFound: "You don't have any data here.",
      });
    }
    this.setState({ loading: false });
  };
  //after fetching utilization values from api to render chart or text 'no data found'
  UserDataSuccess = async (jsonResult) => {
    // console.log("first",jsonResult.data)
    let value = jsonResult.data?.utilization;

    if (Object.keys(value).length === 0 && value.constructor === Object) {
      this.setState({
        noDataFound: "You don't have any data here.",
      });
    }
    if (Object.keys(value).length !== 0 && value.constructor === Object) {
      this.setState({
        noDataFound: "",
      });
    }

    const hours = jsonResult.data.utilization[this.state.date];
    let newHours = [];
    let max = 0;
    if (hours != undefined) {
      newHours = hours.sort((a, b) => a.hour - b.hour);
      max = Math.max.apply(
        Math,
        hours.map(function(o) {
          return o.occupiedSlots;
        })
      );
    }
    let stepSize = "1";
    if (max > 0) {
      let noOfZerosToAdd = max.toString().length - 1;
      for (let i = 0; i < noOfZerosToAdd; i++) {
        stepSize += "0";
      }
      stepSize = parseInt(stepSize);
      max = (parseInt(max / stepSize, 10) + 1) * stepSize;
    } else {
      stepSize = 10;
      max = 100;
    }

    let totalSlots = 0;
    totalSlots = jsonResult.data?.vehicleTypeInfo.filter(
      (x) => x.vehicleType?.uid == this.state.vehicleType
    )[0]?.totalSlots;
    this.setState(
      {
        parkingData: newHours,
        yAxesMaxVal: max,
        yAxesStepSize: stepSize,
        totalSlots: totalSlots,
      }
      // () => this.graphData()
      //added
    );
    this.setState({ loading: false });
  };

  //function for parking select and load  graphdata
  handleClick = (e) => {
    this.setState(
      {
        id: e.target.value,
      },
      () => this.graphData()
    );
  };
  //function for vehicle type select and load  graphdata
  handleClicktype = (e) => {
    this.setState(
      {
        vehicleType: e.target.value,
        noDataFound: "",
      },
      () => this.graphData()
    );
  };

  //aysnc function for line
  async handleLine() {
    this.setState(
      {
        chartType: "Line",
        line: true,
        bar: false,
        area: false,
      }
      // async () => {
      //   await this.myRef.current.graphData();
      //   this.myRef.current.graphType();
      // }
    );
    await this.myRef.current.graphData();
    this.myRef.current.graphType();
  }

  //aysnc function for bar
  async handleBar() {
    this.setState(
      {
        chartType: "Bar",
        line: false,
        bar: true,
        area: false,
      }
      // async () => {
      //   await this.myRef.current.graphData();
      //   this.myRef.current.graphType();
      // }
    );
    await this.myRef.current.graphData();
    this.myRef.current.graphType();
  }
  //aysnc function for pie
  async handlePie() {
    this.setState(
      {
        chartType: "Pie",
        line: false,
        bar: false,
        area: true,
      }
      // async () => {
      //   await this.myRef.current.graphData();
      //   this.myRef.current.graphType();
      // }
    );

    await this.myRef.current.graphData();
    this.myRef.current.graphType();
  }

  //function for dateformat
  dateFormat = (str) => {
    const mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      },
      date = str.toString().split(" ");
    return [date[2], mnths[date[1]], date[3]].join("-");
  };
  //function for selectedDay
  selectedDay = async (val) => {
    const date = this.dateFormat(val);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let formatted_date = val.getDate() + "-" + months[val.getMonth()];
    this.setState(
      {
        date: date,
        fmtDate: formatted_date,
        vehicleType: this.state.vehicleType,
      },
      () => this.graphData()

      //added to fix graph loading issue
    );
    // if (this.state.id !== "") {
    //   this.graphData();
    //   console.log(this.state.id);
    // }
  };

  render() {
    const vehicleType = [
      { name: "Car", value: 1 },
      { name: "Bike", value: 2 },
    ];
    const { line, bar, area } = this.state;
    return (
      <Card
        raised={true}
        className="card-cont selectBoxA"
        style={{ height: "100%", borderRadius: "25px" }}
      >
        <CardHeader
          className="puHeader"
          action={
            <>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "right" }}
              >
                <Grid
                  item
                  id="chart-filter"
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ marginTop: "4.3%" }}
                >
                  <select
                    onChange={this.handleClick}
                    className="selectBoxA"
                    disabled={this.state.parkingList.length === 0}
                    style={{
                      webkitAppearance: "auto",
                      fontSize: "12px",
                      width: "150px",
                      marginLeft: "30px",
                      marginRight: "40px",
                      paddingBottom: "5px",
                      outline: "transparent",
                      paddingTop: "5px",
                      borderColor: "#eee",
                      backgroundColor: "#E6E6EF",
                      height: "35px",
                      borderRadius: "90px",
                      borderRight: "10px solid #e6e6ef",
                    }}
                  >
                    {this.state.parkingList.map((item, index) => (
                      <option key={index} value={item.parkingId}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid
                  item
                  id="chart-filter"
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ marginLeft: "22%", marginTop: "4.3%" }}
                >
                  <select
                    onChange={this.handleClicktype}
                    className="selectBoxB"
                    disabled={this.state.parkingList.length === 0}
                    style={{
                      webkitAppearance: "auto",
                      width: "100px",
                      fontSize: "12px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      paddingBottom: "5px",
                      outline: "transparent",
                      paddingTop: "5px",
                      borderColor: "#eee",
                      backgroundColor: "#E6E6EF",
                      height: "35px",
                      borderRadius: "90px",
                      borderRight: "10px solid #e6e6ef",
                    }}
                  >
                    {vehicleType.map((d, index) => (
                      <option key={index} value={d.value}>
                        {d.name}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ marginLeft: "2%", marginTop: "2%" }}
                >
                  <IconButton
                    className="noPadBtn"
                    onClick={() => {
                      this.handleBar();
                    }}
                  >
                    <img
                      src={bar ? barIconSelected : barIcon}
                      style={{ width: "29px" }}
                      alt="icon"
                    />
                  </IconButton>

                  <IconButton
                    className="noPadBtn"
                    onClick={() => {
                      this.handleLine();
                    }}
                  >
                    <img
                      src={line ? lineIconSelected : lineIcon}
                      style={{ width: "29px" }}
                      alt="icon"
                    />
                  </IconButton>
                  <IconButton
                    className="noPadBtn"
                    onClick={() => {
                      this.handlePie();
                    }}
                    style={{ marginRight: "4px" }}
                  >
                    <img
                      src={area ? areaIconSelected : areaIcon}
                      style={{ width: "29px" }}
                      alt="icon"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          }
          style={{
            paddingBottom: "10px",
            fontWeight: "700",
            fontFamily: "'Poppins' !important",
            color: "#102E35",
          }}
          disableTypography={true}
          title="Parking Utilisation"
          // titleTypographyProps={{ variant: 'h6' }}
        />
        <img
          src={
            this.state.vehicleType == 1
              ? car
              : this.state.vehicleType == 2
              ? bike
              : fleet
          }
          className="vehicle_type_image"
          alt="icon"
        />
        <label className="label_slots">
          Total Slots :{" "}
          <span className="slot_value">
            {this.state.totalSlots ? this.state.totalSlots : "--"}
          </span>
        </label>
        <CardContent
          style={{ height: "81%", margin: "10px", borderRadius: "10px" }}
        >
          <DateSlider shouldScroll={true} getSelectedDay={this.selectedDay} />
          {/* getSelectedDay */}
          {this.state.id && this.state.noDataFound ? (
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: "5em", marginBottom: "5em", height: "14em" }}
            >
              <Grid item>
                <Typography>{this.state.noDataFound}</Typography>
              </Grid>
            </Grid>
          ) : this.state.loading ? (
            <Loader />
          ) : (
            <Chart
              ref={this.myRef}
              chartType={this.state?.chartType}
              parkingData={this.state?.parkingData}
              selectedDate={this.state?.fmtDate}
              yAxesStepSize={this.state?.yAxesStepSize}
              yAxesMaxVal={this.state?.yAxesMaxVal}
            />
          )}
        </CardContent>
      </Card>
    );
  }
}

export default ChartContainer;
