import React from "react";
import DeviceCoupledContext from "./DeviceCoupledContext";
import { useState } from "react";
import { useEffect } from "react";
import httpRequest from "../Utils/httpRequest";
const DeviceCoupledReducer = (props) => {
  const [deviceCoupleList, setdeviceCoupled] = useState([]);

  const getDeviceCoupledList = () => {
    httpRequest.get(
      "devCoupledParkingList",
      {},
      (json) => {
        setdeviceCoupled(json.data);
      },
      () => {},
      {
        // userId: getCookie("userId"),
      }
    );
  };

  useEffect(() => {
    getDeviceCoupledList();
  }, []);
  return (
    <DeviceCoupledContext.Provider value={{ deviceCoupleList }}>
      {props.children}
    </DeviceCoupledContext.Provider>
  );
};

export default DeviceCoupledReducer;
