// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';

//import Routes from './Routes';
import AdminRoutes from './../../../Routes/AdminRoutes';
import httpRequest from '../../../Utils/httpRequest';
import { updateToasterConfig } from '../../../../src/redux/modules/app/actions';
import { getCookie } from '../../../Utils/cookies';
import tokenstorage from '../../../Utils/tokenstorage';

//@component
//Solution Admin
class SolutionAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userOrg: '',
      timeout: 1800000,
      userLoggedIn: false,
      isTimedOut: false
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  //function for logout
  logout = () => {
    let cliSec = getCookie('cliSec');
    let cliId = getCookie('cliId');
    httpRequest.patch(
      'logout',
      {
        refreshToken: getCookie('refresh'),
        clientId: cliId,
        clientSecret: cliSec,
      },
      this.logoutSuccess,
      this.errorCallback,
    );
  };
  //function for logout success
  logoutSuccess() {
  }
  //function for error callback
  errorCallback() {
  }
  //function for timeout action
  _onAction() {
    this.setState({ isTimedOut: false })
  }
  //function for timeout active
  _onActive() {
    this.setState({ isTimedOut: false })
  }
  //function for idle session timeout
  _onIdle() {
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.logout();
      tokenstorage.clear();
      window.location.href = '/login';
      // console.log(" i am called2")
      this.props.updateToasterConfig({
        show: true,
        message: 'Your Session Expired due to Inactivity',
        color: '#02C194',
      });
    } else {
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }
  }
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <AdminRoutes />
      </>
    );
  }
}

//function for redux connect
export default connect(null, { updateToasterConfig })(SolutionAdmin);
