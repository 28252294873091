// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import {
  UPDATE_AUTH,
  UPDATE_MODAL,
  UPDATE_BREADCRUMBS,
  UPDATE_TOASTER_CONFIG,
  UPDATE_APPLIED_FILTER_DATA,
  UPDATE_FILTER_DATA,
  UPDATE_SIDEBAR,
  UPDATE_BUBBLE,
} from "./types";

export function updateAuth(data) {
  return (dispatch) => {
    dispatch(setState(UPDATE_AUTH, data));
  };
}

export function updateShowModal(boolVal) {
  return (dispatch) => {
    dispatch(setState(UPDATE_MODAL, boolVal));
  };
}

export function updateToasterConfig(obj) {
  return (dispatch) => {
    dispatch(setState(UPDATE_TOASTER_CONFIG, obj));
  };
}

export function updateBreadcrumbs(key) {
  return (dispatch) => {
    dispatch(setState(UPDATE_BREADCRUMBS, key));
  };
}

export function updateFilterData(arr) {
  return (dispatch) => {
    dispatch(setState(UPDATE_FILTER_DATA, arr));
  };
}

export function updateAppliedFilterData(arr) {
  return (dispatch) => {
    dispatch(setState(UPDATE_APPLIED_FILTER_DATA, arr));
  };
}

export function updateSidebar(index) {
  console.log(index);
  return (dispatch) => {
    dispatch(setState(UPDATE_SIDEBAR, index));
  };
}
export function updateBubble(index) {
  return (dispatch) => {
    dispatch(setState(UPDATE_BUBBLE, index));
  };
}

function setState(type, response) {
  return { type, response };
}
