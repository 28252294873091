// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Scrollbars } from "react-custom-scrollbars";
import { Grid, withStyles, Card, CardHeader } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import httpRequest from "./../../../../../Utils/httpRequest";
import { updateBreadcrumbs } from "../../../../../redux/modules/app/actions";
import { updateToasterConfig } from "./../../../../../../src/redux/modules/app/actions";
import Loader from "../../../../../Components/Preloader";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { capitalizeFirstLetter } from "../../../../../Constants/reusable-functions";
import Images from "../../../../../Assets/index";
import "./style.scss";

function L2Devices(props) {
  // Set local state variables here
  const [isLoading, setIsLoading] = useState(false);
  const [boomflag, setBoomflag] = useState("");
  const [anprflag, setAnprflag] = useState("");
  const [ledflag, setLedflag] = useState("");
  const [epldflag, setEpldflag] = useState("");
  const [zcuflag, setZcuflag] = useState("");
  const [iocflag, setIocflag] = useState("");
  const [dialogopen, setDialogopen] = useState(false);
  const [dialogTitle, setDialogtitle] = useState("");
  const [l2ErrorList, setL2errorlist] = useState([]);
  const [iconTypepopup, setIcontypepopup] = useState("");

  // Destructure properties from props here
  const { gatewayDetail } = props;

  // defining dialog actions
  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
    },
  }))(MuiDialogActions);

  // after clicking icon to get those devices status in popup
  const getDeviceStatus = (deviceType) => {
    setIsLoading(true);
    httpRequest.get("l2DeviceStatus", {}, successCallback, errorCallback, {
      parkingId: props.parkingId,
      deviceType: deviceType,
    });
  };
  // getDeviceStatus success block
  const successCallback = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setL2errorlist(jsonResponse?.data?.l2ErrorList || []);
    }
  };
  // getDeviceStatus error block
  const errorCallback = (error) => {
    if (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // Whether to show green, gray or amber device status icons is handled here
  const deviceStatusHandler = () => {
    gatewayDetail.forEach((item) => {
      // boom
      if (item.deviceType === "boom") {
        if (item.status.name === "UP") {
          setBoomflag("green");
        }
        if (item.status.name === "DOWN") {
          setBoomflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setBoomflag("amber");
        }
      }

      // anpr
      if (item.deviceType === "anpr") {
        if (item.status.name === "UP") {
          setAnprflag("green");
        }
        if (item.status.name === "DOWN") {
          setAnprflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setAnprflag("amber");
        }
      }

      // inout
      if (item.deviceType === "ioc") {
        if (item.status.name === "UP") {
          setIocflag("green");
        }
        if (item.status.name === "DOWN") {
          setIocflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setIocflag("amber");
        }
      }

      // led or vms
      if (item.deviceType === "vms") {
        if (item.status.name === "UP") {
          setLedflag("green");
        }
        if (item.status.name === "DOWN") {
          setLedflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setLedflag("amber");
        }
      }
      //epld
      if (item.deviceType === "epld") {
        if (item.status.name === "UP") {
          setEpldflag("green");
        }
        if (item.status.name === "DOWN") {
          setEpldflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setEpldflag("amber");
        }
      }

      // zcu devices
      if (
        item.deviceType === "zcu" ||
        item.deviceType === "sszcu" ||
        item.deviceType === "ioczcu"
      ) {
        if (item.status.name === "UP") {
          setZcuflag("green");
        }
        if (item.status.name === "DOWN") {
          setZcuflag("gray");
        }
        if (item.status.name === "UP" && item.status.name === "DOWN") {
          setZcuflag("amber");
        }
      }
    });
  };
  // when L2 device icon is clicked what should happen is here
  const clickIconHandler = (deviceType) => {
    if (props.mainGateway?.status?.name === "DOWN") {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "Gateway device is offline.",
          color: "#e46a76",
        })
      );
    } else {
      if (deviceType === "boom") {
        if (boomflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } else {
          setDialogopen(true);
          getDeviceStatus("boom");
          setDialogtitle("Boom Barrier status");
        }
      }
      if (deviceType === "anpr") {
        if (anprflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } else {
          setDialogopen(true);
          getDeviceStatus("anpr");
          setDialogtitle("ANPR Cameras status");
        }
      }
      if (deviceType === "zcu") {
        if (zcuflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } else {
          setDialogopen(true);
          getDeviceStatus("zcu");
          setDialogtitle("Zone Controller Units status");
        }
      }
      if (deviceType === "ioc") {
        if (iocflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } else {
          setDialogopen(true);
          getDeviceStatus("ioc");
          setDialogtitle("Camera Counters status");
        }
      }
      if (deviceType === "vms") {
        if (ledflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } else {
          setDialogopen(true);
          getDeviceStatus("vms");
          setDialogtitle("LED Displays status");
          setIcontypepopup("vms");
        }
      }
      if (deviceType === "epld") {
        if (epldflag === "green") {
          props.dispatch(
            updateToasterConfig({
              show: true,
              message: "All devices are online.",
              color: "green",
            })
          );
        } 
        else {
          setDialogopen(true);
          getDeviceStatus("epld");
          setDialogtitle("EPLD Cameras status");
          setIcontypepopup("epld");
        }
      }
    }
  };

  // page loads initial what to trigger is handled here
  useEffect(() => {
    // Write some code here
    deviceStatusHandler();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Card raised={true} className="device_card">
        <CardHeader
          title="Device Status"
          titleTypographyProps={{ variant: "h6" }}
        />
        <Grid container>
          <Grid item container xs={12} md={12} style={{textAlign: 'center',marginTop: '10px'}}>
          {props.mainGateway?.status?.name === "UP" ? (
            <>
            <Grid item xs={12} md={12}>
              <img src={Images.gatewayIcon} className="edgeDeviceImg" />
            </Grid>
            <Grid  item xs={12} md={12}>
              <label className="gateways_heading" style={{color: '#03A9F3'}}>
                {capitalizeFirstLetter(props.mainGateway?.deviceName)}
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <p className="gateways_details"  style={{fontSize:'12px'}}>Online</p>
            </Grid>
            </>
          ):(
            <>
             <Grid item xs={12} md={12}>
              <img src={Images.gatewayGrayIcon} className="edgeDeviceImg" />
            </Grid>
            <Grid  item xs={12} md={12}>
              <label className="gateways_heading" style={{color: '#03A9F3'}}>
                {capitalizeFirstLetter(props.mainGateway?.deviceName)}
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <p className="gateways_details" style={{fontSize:'12px'}} >Offline</p>
            </Grid>
            </>

          )}
          </Grid>
          
          <Grid
            container
            justifyContent="space-around"
            className="individual-gateways-inner-grid"
            xs={12} md={12} 
            style={{   gridGap: "15px"}}
          >
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "boom";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        boomflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : boomflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="boom-icon"
                      onClick={() => clickIconHandler("boom")}
                    >
                      <img
                        src={
                          boomflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.boomBarrierGrayIcon
                            : boomflag == "green"
                            ? Images.boomBarrierIcon
                            : Images.boomAmberIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">Boom Barriers</label>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "anpr";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        anprflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : anprflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="anpr-icon"
                      onClick={() => clickIconHandler("anpr")}
                    >
                      <img
                        src={
                          anprflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.anprGrayIcon
                            : anprflag == "green"
                            ? Images.anprIcon
                            : Images.anprAmberIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">ANPR Cameras</label>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "zcu";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        zcuflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : zcuflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="zcu-icon"
                      onClick={() => clickIconHandler("zcu")}
                    >
                      <img
                        src={
                          zcuflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.zcuGrayIcon
                            : zcuflag == "green"
                            ? Images.zcuIcon
                            : Images.zcuAmberIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">
                      Zone Controller Units
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "ioc";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        iocflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : iocflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="ioc-icon"
                      onClick={() => clickIconHandler("ioc")}
                    >
                      <img
                        src={
                          iocflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.inoutCounterGrayIcon
                            : iocflag == "green"
                            ? Images.inoutCounterIcon
                            : Images.iocAmberIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">Camera Counters</label>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "vms";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        ledflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : ledflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="led-icon"
                      onClick={() => clickIconHandler("vms")}
                    >
                      <img
                        src={
                          ledflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.ledGrayIcon
                            : ledflag == "green"
                            ? Images.ledDisplayIcon
                            : Images.ledAmberIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">LED Displays</label>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.gatewayDetail.filter((dev) => {
              return dev.deviceType === "epld";
            })[0] && (
              <Grid item>
                <Grid item container direction="column" className="edgeCenter">
                  <Grid item className="dot-icon-grid">
                    <img
                      src={
                        epldflag == "gray" ||
                        props.mainGateway?.status?.name === "DOWN"
                          ? Images.offlineDotIcon
                          : epldflag == "green"
                          ? Images.onlineDotIcon
                          : Images.bothDotIcon
                      }
                      className="dot-icon"
                      alt="dot-icon"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      className="iconbutton"
                      aria-label="led-icon"
                      onClick={() => clickIconHandler("epld")}
                    >
                      <img
                        src={
                          epldflag == "gray" ||
                          props.mainGateway?.status?.name === "DOWN"
                            ? Images.epldGrayIcon
                            : epldflag == "green"
                            ? Images.epldIcon
                            : Images.epldGrayIcon
                        }
                        className="edgeDeviceImg"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <label className="gateways_heading">EPLD Cameras</label>
                  </Grid>
                </Grid>
              </Grid>
            )}
      
          </Grid>
          <Grid
              container
              justifyContent="center"
              className="device-status-legend-grid"
              
            >
              <Grid item>
                <img
                  src={Images.onlineDotIcon}
                  alt="green-dot"
                  className="status-icons-legend"
                />
              </Grid>
              <Grid item className="online-text-grid">
                <span>Status is online</span>
              </Grid>
              <Grid item>
                <img
                  src={Images.offlineDotIcon}
                  alt="gray-dot"
                  className="status-icons-legend"
                />
              </Grid>
              <Grid item className="online-text-grid">
                <span>Status is offline</span>
              </Grid>
              {/* <Grid item><img src={Images.bothDotIcon} alt="amber-dot" className="status-icons-legend"/></Grid>
                    <Grid item className="both-text-grid"><span>Status is online and offline both.</span></Grid> */}
            </Grid>
        </Grid>
      </Card>

      <Dialog
        open={dialogopen}
        onClose={() => {
          setDialogopen(false);
        }}
        PaperProps={{
          style: {
            padding: "1%",
          },
        }}
        className="dialog-grid"
      >
        <MuiDialogTitle
          style={{
            paddingBottom: "8px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <span className="dialog-header">{dialogTitle}</span>
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <Divider />
        {isLoading && <Loader />}
        <DialogContent
          className="dialog-content-grid"
          style={{ paddingLeft: "0px", paddingRight: "0px",overflowX:'hidden'}}
        >
          <Scrollbars style={{ height: "50vh", width: "35vw" }}>
            <Grid container direction="column">
              <Grid item>
                <Grid item container justifyContent="space-around">
                  {l2ErrorList.map((item) => {
                    return (
                      <Grid item style={{ textAlign: "center" }}>
                        <Grid
                          item
                          container
                          direction="column"
                          style={{ padding: "1vw" }}
                        >
                          <Grid item>
                            {/* device logo */}
                            <img
                              className="devices-logo"
                              src={iconTypepopup==='epld'?Images.epldGrayIcon:Images.ledGrayIcon}
                              alt="devices-logo"
                            />
                          </Grid>
                          <Grid item style={{ fontWeight: "bolder" }}>
                            {/* device name */}
                            {item.label ? item.label : "--"}
                          </Grid>
                          <Grid item className="offline-text">
                            {/* device status */}
                            Offline since{" "}
                            {item.errorTimeStamp
                              ? moment(item.errorTimeStamp).format(
                                  " Do MMMM, h:mm a"
                                )
                              : "--"}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Scrollbars>
        </DialogContent>
        <DialogActions style={{ zIndex: 1 }}>
          <Grid
            container
            direction="row"
            justify="center"
            className="dialog-action-grid"
          >
            <Grid item>
              <button
                type="button"
                onClick={() => setDialogopen(false)}
                className="dialog-close"
              >
                Close
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(L2Devices));
