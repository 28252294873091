// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect, useContext } from "react";
import httpRequest from "../Utils/httpRequest";
import StoreContext from "./dashboard-context";
import { getCookie } from "../Utils/cookies";
import { TableCell } from "@material-ui/core";

//context api implementation for ASC dashboard
const DashboardReducer = (props) => {
  const [username, setUsername] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [cardData, setCardData] = useState({});
  const [parkingData, setparkingData] = useState([]);
  const [listType, setlistType] = useState(false);
  const [cardType, setcardType] = useState(true);
  const [mapType, setmapType] = useState(false);
  const [filterOption, setfilterOption] = useState(1);
  const [dropDownId, setdropDownId] = useState(null);
  const [passPop, setpassPop] = useState(false);
  const [deleteParkingId, setdeleteParkingId] = useState(null);
  const [selectedParkingId, setselectedParkingId] = useState("");
  const [overview, setOverview] = useState([]);
  const [isReady] = useState(false);

  const [loading, setloading] = useState(true);
  const context = useContext(StoreContext);
  const [empty, setEmpty] = useState(false);
  const [deviceCoupleList, setdeviceCoupleList] = useState([]);
  const getDashboardDetails = () => {
    //get user data
    httpRequest.get(
      "userData",
      {},
      (jsonResponse) => {
        if (jsonResponse) {
          setUsername(
            (jsonResponse.data
              ? jsonResponse.data.firstName
                ? jsonResponse.data.firstName
                : ""
              : "") +
              " " +
              (jsonResponse.data
                ? jsonResponse.data.lastName
                  ? jsonResponse.data.lastName
                  : ""
                : "")
          );
          setLastLogin(
            jsonResponse.data
              ? jsonResponse.data?.lastLoggedInDate
                ? jsonResponse.data.lastLoggedInDate
                : ""
              : ""
          );
        }
      },
      (error) => {
        console.log(error);
      },
      {}
    );
    //get dashboard and parking list data
    httpRequest.get(
      "dashboardData",
      {},
      (jsonResponse) => {
        setCardData(jsonResponse.data);
      },
      (error) => {
        console.log(error);
      },
      {}
    );

    ////////////// coupled device list ///////////////////////////////////

    // const parkingListSuccess = (jsonResponse) => {
    //   // console.log(jsonResponse);
    //   setdeviceCoupleList(jsonResponse.data);
    // };

    const errorCallback = (error) => {
      console.log(error);
    };
    httpRequest.get(
      "devCoupledParkingList",
      {},
      (json) => {
        setdeviceCoupleList(json.data);
      },
      errorCallback,
      {
        // userId: getCookie("userId"),
      }
    );

    httpRequest.get(
      "parkingListDashboard",
      {},
      ParkingDataSuccess,
      (error) => {
        console.log(error);
      },
      {}
    );
  };
  //function upon recieving api data
  const ParkingDataSuccess = async (jsonResult) => {
    
    setloading(false);
    if (jsonResult.data?._embedded?.results) {
      let data = jsonResult.data?._embedded?.results;
      setselectedParkingId(data[0]?.parkingId);
      setparkingData(data);
    }

    parkingData !== undefined && checkNow();
  };

  //function for handle card
  const handleCard = () => {
    setlistType(false);
    setcardType(true);
    setmapType(false);
  };
  //function for handleList
  const handleList = () => {
    setlistType(true);
    setcardType(false);
    setmapType(false);
  };
  //function for handleMap
  const handleMap = () => {
    setlistType(false);
    setcardType(false);
    setmapType(true);
  };
  //function for handle card Click
  const handleClick = (e) => {
    setfilterOption(e.target.value);
  };
  //function for cardClicked
  const cardClicked = (id) => {
    setselectedParkingId(id);
  };
  //function for setting anchorEl
  const handleClickCard = () => {};
  //function to check if its open or outside operating hours
  const checkNow = () => {
    for (let i in parkingData) {
      context.TempArray.push({
        Parkingname: parkingData[i].name,
        daysOfApproved: parkingData[i].operatingHoursInfo,
      });
    }
    context.TempArray.map((item, index) => {
      for (let j in item.daysOfApproved) {
        j === context.weekdays[context.today] &&
        context.curTime > item.daysOfApproved[j][0].openingTime &&
        context.curTime < item.daysOfApproved[j][0].closingTime
          ? context.list.push({
              Parkingname: item.Parkingname,
              icon: "live",
            })
          : context.list.push({
              Parkingname: item.Parkingname,
              icon: "outside",
            });
      }
      return <div key={index}></div>;
    });
  };
  //function for clickButton
  const clickButton = (e, id) => {
    e.stopPropagation();
    if (id !== dropDownId) {
      setdropDownId(id);
    } else {
      setdropDownId(null);
    }
  };
  //function to close dropdown div on blank spaces click
  const clickBlank = (e) => {
    if (e) {
      setdropDownId(null);
    }
  };
  //function for showEditPage
  const showEditPage = (e, id) => {
    e.stopPropagation();
    props.history.push("/portal/editparkingspace?parkingId=" + id);
  };
  //function for deleteParking
  const deleteParking = (e, id) => {
    e.stopPropagation();
    setdeleteParkingId(id);
    setpassPop(!passPop);
  };
  //function to close delete popup
  const deleteClickCancel = (e) => {
    e.stopPropagation();
    setpassPop(!passPop);
  };
  //function deleteClick
  const deleteClick = (e) => {
    e.stopPropagation();
    httpRequest.delete(
      "deleteParking",
      {},
      deleteSuccess,
      (e) => {
        console.log(e);
      },
      {
        parkingId: deleteParkingId,
        userId: getCookie("userId"),
      }
    );
  };
  //function deleteSuccess
  const deleteSuccess = () => {
    setpassPop(false);
    setTimeout(() => {
      props.updateToasterConfig({
        show: true,
        message: "The Parking has been deleted",
        color: "#03A9F3",
      });
    }, 100);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };
  // function for click away
  const handleClickAway = () => {
    setdropDownId(null);
  };
  //repeated calls
  const getSlotInfo = (info, label) => {
    let value = 0;
    info.map((each) => {
      if (each.vehicleType.uid == filterOption) {
        value = each.slotUtilization[label];
      }
    });
    return <TableCell style={{ textAlign: "center" }}>{value}</TableCell>;
  };
  context.iconChange = context.list.filter(function(i) {
    return i.icon === "live";
  });
  useEffect(() => {
    if (getCookie("role") !== "SECURITY_PERSONNEL") {
      getDashboardDetails();
    }

    // updateme();

    // return () => {
    //   clearInterval();
    // }
  }, []);
  return (
    //store providing context to be consumed by consumer
    <StoreContext.Provider
      value={{
        username: username,
        lastLogin: lastLogin,
        cardData: cardData,
        vehicleType: context.vehicleType,
        parkingData: parkingData,
        filterOption: filterOption,
        listType: listType,
        cardType: cardType,
        mapType: mapType,
        handleCard: handleCard,
        handleList: handleList,
        handleMap: handleMap,
        handleClick: handleClick,
        TempArray: context.TempArray,
        iconChange: context.iconChange,
        today: context.today,
        curTime: context.curTime,
        list: context.list,
        getSlotInfo: getSlotInfo,
        handleClickCard: handleClickCard,
        cardClicked: cardClicked,
        handleClickAway: handleClickAway,
        deleteSuccess: deleteSuccess,
        deleteClick: deleteClick,
        deleteClickCancel: deleteClickCancel,
        deleteParking: deleteParking,
        showEditPage: showEditPage,
        clickBlank: clickBlank,
        clickButton: clickButton,
        dropDownId: dropDownId,
        selectedParkingId: selectedParkingId,
        isReady: isReady,
        loading: loading,
        empty: empty,
        deviceCoupleList: deviceCoupleList,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};
export default DashboardReducer;
