// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";

import "./style.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../../../redux/modules/app/actions";
import {
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
} from "@material-ui/core";
import editUseStyles from "../../../TemporaryAccess/Edit/style";
import httpRequest from "../../../../Utils/httpRequest";
import { getCookie } from "../../../../Utils/cookies";
import MomentUtils from "@date-io/moment";
import AccessTime from "@material-ui/icons/AccessTime";

// import CoupledContext from "../../../../context/DeviceCouple-context";
// import Reducer from "../../../../context/DeviceCoupleReducer";

// import CoupledContext from "../../../../context/dashboard-context";
// import Reducer from "../../../../context/DashboardReducer";
import CoupledContext from "../../../../context/DeviceCoupledContext";
import Reducer from "../../../../context/DeviceCoupledReducer";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

//@component
//View

function VehicleExitEvents(props) {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [parkingName, setParkingName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [parkingSpaceList, setParkingSpaceList] = useState([]);
  const [parkingId, setParkingId] = useState("");
  const [invalidNo, setInvalidNo] = useState("");
  const [formattedDate, setformattedDate] = useState("");
  const [formattedTime, setformattedTime] = useState("");

  //const value = useContext(deviceCoupleContext);
  //console.log(value);
  // const value = useContext(Reducer);
  // console.log(value);
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("vehicleExitEvents"));
    props.dispatch(updateSidebar(3));
    props.dispatch(updateBubble(6));
    populateParkingDetails();
  }, []);

  const populateParkingDetails = () => {
    httpRequest.get(
      "userDevCoupledParkingList",
      {},
      parkingListSuccess,
      errorCallback,
      {
        userId: getCookie("userId"),
      }
    );
  };

  const parkingListSuccess = (jsonResponse) => {
    // console.log(jsonResponse);
    setParkingSpaceList(jsonResponse.data);
  };

  const errorCallback = (error) => {
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: error.error,
        color: "red",
      })
    );
  };

  const parkingHandle = (event) => {
    console.log(event.target.value);
    if (event.target.value !== "0") {
      let parking = parkingSpaceList.filter(
        (item) => item.parkingId === event.target.value
      );
      setParkingName(parking[0].name);
      setParkingId(parking[0].parkingId);
      // setParkingAdd(parking[0].address)
    } else {
      setParkingName("0");
      setParkingId("0");
    }
  };

  const vehicleNumberHandle = (event) => {
    if (event.target.value?.length < 5) {
      setInvalidNo("Please Enter Valid Vehicle Number, e.g: KA01XX1234");
      setVehicleNumber(event.target.value);
    } else if (event.target.value?.length > 13) {
      setInvalidNo("Exceeded Maximum Limit Allowed");
    } else {
      let valid = validateNumber(event.target.value);
      if (valid === true) {
        let number = event.target.value.toUpperCase();
        setVehicleNumber(number);
        setInvalidNo("");
      } else {
        setVehicleNumber(event.target.value);
        setInvalidNo("Please Enter Valid Vehicle Number, e.g: KA01XX1234");
      }
    }
  };

  const validateNumber = (vno) => {
    const re = /^([A-Z,a-z]{2}[0-9]{1,2}[A-Z,a-z]{1,2}[0-9]{1,6}|[A-Z,a-z]{2}\d{6}|([1-9][0-9]|10)[Bb][Hh][0-9]{4}[A-HJ-NP-Za-hj-np-z]{1,2})$/i;
    return re.test(String(vno).toLowerCase());
  };

  const dateFormatter = (date) => {
    const strDate = new Date(date);
    const day = strDate.toLocaleString("default", { day: "2-digit" });
    const month = strDate.toLocaleString("default", { month: "short" });
    const year = strDate.toLocaleString("default", { year: "numeric" });
    const formatDate = day + "-" + month + "-" + year;
    console.log(formatDate);
    return formatDate;
  };
  const dateHandle = (event) => {
    // console.log(event._d)

    setformattedDate(dateFormatter(event?._d));
    setDate(event?._d);
  };

  // const timeHandle = (event) => {
  //   setTime(event.target.value)
  //   console.log(event.target.value)
  //   let hrs = event.target.value.split(':')
  //   let hours = hrs[0];
  //   let minutes = hrs[1];
  //   let ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12;
  //   let strTime = hours + ':' + minutes + ' ' + ampm;
  //   console.log(strTime)
  //   setformattedTime(strTime)

  // }

  const timeHandle = (event) => {
    let dateString = event?._d;
    setTime(dateString);
    let hours = dateString.getHours();
    let minutes = dateString.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = "0" + hours + ":" + minutes + " " + ampm;
    setformattedTime(strTime);
  };

  const submitVehicleExitDetails = () => {
    setIsdialogOpen(true);
  };

  const classes = editUseStyles();

  const handleClose = () => {
    setIsdialogOpen(false);
  };

  const submitDetails = () => {
    postVehicleExitEvents();
  };

  const postVehicleExitEvents = () => {
    httpRequest.post(
      "saveVehicleExitEvents",
      {},
      {
        "Content-Type": "application/json",
      },
      successCallbackSave,
      errorCallbackSave,
      {
        parkingId: parkingId,
        vehicleNumber: vehicleNumber,
        dateTime: formattedDate + " " + formattedTime,
      }
    );
  };

  const successCallbackSave = () => {
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "Vehicle exit event has been triggered succesfully.",
        color: "green",
      })
    );
    setIsdialogOpen(false);
  };

  const errorCallbackSave = (error) => {
    setIsdialogOpen(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: error.error === "Not Found" ? "API Not Found" : error.message,
        color: "red",
      })
    );
  };

  return (
    <Reducer>
      <CoupledContext.Consumer>
        {(context) => (
          <>
            <div className="exit-event-root ">
              <Grid container xs={12} sm={12} md={12} lg={12}>
                <Paper
                  elevation={5}
                  style={{
                    width: "96%",
                    margin: "2%",
                    marginBottom: "10%",
                  }}
                >
                  <Grid item md={12} className={classes.headerText}>
                    <label className={classes.label}>
                      Trigger Vehicle Exit Event
                    </label>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Grid
                        container
                        direction="column"
                        className={classes.inputArea}
                      >
                        <label className={classes.label}>
                          Parking Space Details*
                        </label>
                        <select
                          onChange={parkingHandle}
                          disabled={context.deviceCoupleList.length === 0}
                          required
                          className={classes.textFieldSelect}
                        >
                          <option value="0">
                            {context.deviceCoupleList.length === 0
                              ? "No Parking spaces connected"
                              : "Select Parking"}
                          </option>
                          {context.deviceCoupleList.map((option, index) => {
                            return (
                              <option key={index} value={option.parkingId}>
                                {option.name}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid
                        container
                        direction="column"
                        className={classes.inputArea}
                      >
                        <label className={classes.label}>Date and Time*</label>
                        <Grid container direction="row">
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                autoOk={true}
                                value={date}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disableFuture
                                cancelLabel
                                style={{
                                  width: "90%",
                                  paddingTop: "5%",
                                  backgroundColor:
                                    context.deviceCoupleList.length === 0
                                      ? "#d5d5d5"
                                      : "",
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={dateHandle}
                                format="DD/MM/yyyy"
                                invalidDateMessage=""
                                className={classes.textField}
                                placeholder="DD/MM/YYYY"
                                disabled={context.deviceCoupleList.length === 0}
                                // maxDate={new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardTimePicker
                                autoOk={true}
                                value={time}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                ampm={true}
                                disableFuture
                                cancelLabel
                                style={{
                                  width: "90%",
                                  paddingTop: "5%",
                                  backgroundColor:
                                    parkingSpaceList.length === 0
                                      ? "#d5d5d5"
                                      : "",
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={timeHandle}
                                invalidDateMessage=""
                                className={classes.textField}
                                placeholder="00:00 AM"
                                keyboardIcon={<AccessTime />}
                                disabled={context.deviceCoupleList.length === 0}
                              />
                            </MuiPickersUtilsProvider>
                            {/* <input
                        type="time"
                        required
                        id='time'
                        name='time'
                        className={classes.textField}
                        style={{ width: '90%',paddingRight: '10px' }}
                        defaultValue="00:00:00"
                        value={time}
                        onChange={timeHandle} /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Grid
                        container
                        direction="column"
                        className={classes.inputArea}
                      >
                        <label className={classes.label}>Vehicle Number*</label>
                        <input
                          fullWidth
                          required
                          className={classes.textField}
                          id="vehicleNumber"
                          type="text"
                          name="vehicleNumber"
                          value={vehicleNumber}
                          onChange={vehicleNumberHandle}
                          disabled={context.deviceCoupleList.length === 0}
                        />
                        {invalidNo !== "" && (
                          <label style={{ color: "red", marginTop: "5px" }}>
                            {invalidNo}
                          </label>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid
                      container
                      direction="row"
                      className={classes.inputActionArea}
                    >
                      <Button
                        variant="contained"
                        className={classes.updateButton}
                        onClick={submitVehicleExitDetails}
                        disabled={
                          vehicleNumber?.length === 0 ||
                          parkingName === "0" ||
                          date?.length === 0 ||
                          time?.length === 0 ||
                          invalidNo !== ""
                        }
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Dialog
                      open={IsdialogOpen}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          padding: "1.5% 3%",
                        },
                      }}
                      className={classes.dialog}
                    >
                      <DialogTitle
                        style={{
                          paddingBottom: "8px",
                          paddingTop: "2px",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        <Grid container direction="row" justify="space-between">
                          <Grid item>
                            <span className={classes.dialogHeader}>
                              Submit Vehicle Exit Event Details
                            </span>
                          </Grid>
                        </Grid>
                      </DialogTitle>
                      <Divider />
                      <DialogContent
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <Grid item>
                          <Grid container direction="column" justify="center">
                            <Grid item>
                              <Typography className={classes.dialogSubHeader}>
                                Are you sure you want to trigger vehicle exit
                                event for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {vehicleNumber}{" "}
                                </span>
                                Vehicle Details?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  Parking Space Name:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {parkingName}
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  Vehicle Number:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {vehicleNumber}
                                </span>
                              </p>
                            </Grid>
                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  Date:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {formattedDate}
                                </span>
                              </p>
                            </Grid>

                            <Grid item>
                              <p>
                                <span className={classes.dialogSubHeader}>
                                  Time:
                                </span>{" "}
                                <span className={classes.dialogSubTitle}>
                                  {formattedTime}
                                </span>
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            className={classes.dialogActionGrid}
                          >
                            <Grid item className={classes.dialogCancelGrid}>
                              <button
                                type="button"
                                onClick={handleClose}
                                className={classes.dialogCancel}
                              >
                                Cancel
                              </button>
                            </Grid>
                            <Grid item className={classes.dialogConfirmGrid}>
                              <button
                                type="button"
                                className={classes.dialogConfirm}
                                onClick={submitDetails}
                              >
                                Confirm
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </>
        )}
      </CoupledContext.Consumer>
    </Reducer>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(VehicleExitEvents));
