// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { updateBreadcrumbs, updateToasterConfig } from "../../../redux/modules/app/actions";
import "./style.scss";
import Loader from '../../../Components/Preloader/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import { createFacilityManager } from '../api/facilityManagerApis'
import { getCookie } from "../../../Utils/cookies";


//@component
//Create Facility Manager User
function CreateFacilityUser(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  // function for create facility manager api
  async function createFacilityManagerAPI(name, email) {
    try {
      setIsLoading(true);
      var format = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (format.test(name)) {
        setIsLoading(false);
        setDialogOpen(false);
        props.dispatch(updateToasterConfig({
          show: true,
          message: 'Error! Please remove special characters in designation and proceed.',
          color: 'red'
        }))
      } else {
         await createFacilityManager(name, email);
        //const modifiedName = name.replace(/[^a-zA-Z ]/g, "");
        setIsLoading(false);
        setDialogOpen(false);
        clearHandler();
        props.history.push("/portal/facilitymanagerlistview")
        props.dispatch(updateToasterConfig({
          show: true,
          message: `Facility Manager added successfully`,
          color: 'green'
        }))
      }
      
     
    } catch (error) {
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))
    }
  }

  //function to clear fields after creating account
  const clearHandler = () => {
    setName("");
    setEmailId("");
  };
  //variable to handle mediaquery and theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //function for dialog confirm button  
  const submitDetailsConfirm = () => {
    setDialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setDialogOpen(false);
  };
  //function for pre-details submit api call
  const submitDetails = () => {
    createFacilityManagerAPI(name, emailId);
  };
  //functions for name handler
  const nameHandler = event => {
    var inputBox = document.getElementById(event.target.id); 
    var invalidChars = [
      "0","1","2","3","4","5","6","7","8","9","-","_","+","~","`","!","@","#","$","%","^","&","*","(",")",
      "<",">","?","/","{","}","[","]","|",",",":",";","'","",",","\\","\"","="
    ];  
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }    
    });
    inputBox.addEventListener('paste', e => e.preventDefault());

    if (event.target.value.length <= 100) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      }
      else {
        setNameError("");
        setName(event.target.value);
      }
    }
    if (event.target.value.length > 100) {
      setNameError("Maximum of 100 characters are allowed");
    }
    if (event.target.value.length < 5) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      } else {
        setNameError("");
        setName(event.target.value);
      }
    }
    if (!event.target.value) {
      setNameError("");
    }
  };
  //function for generic email regex validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase());
  }
  //functions for email handler
  const emailHandler = (event) => {
    if (event.target.value.length > 100) {
      setEmailError("Max.100 characters");
    }
    if (event.target.value.length <= 100) {
      if (validateEmail(event.target.value)) {
        setEmailError("");
        setEmailId(event.target.value);
      }
      if (!validateEmail(event.target.value)) {
        setEmailId(event.target.value);
        setEmailError("Enter valid email");
      }
      if (event.target.value.length < 5) {
        setEmailError("Minimum 5 characters allowed")
        setEmailId(event.target.value);
      }
      if (!event.target.value) {
        setEmailError("");
      }
    }
  };
 
  // function for form cancel button
  const formCancelHandler = () => {
    props.history.push("/portal/facilitymanagerlistview");
  }
  //function call on component render/re-render
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("facilitymanagercreate"));
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className="facilitymanager-root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Create Account</h2>
              </div>
              <form className="form-field" style={{ padding: 0 }}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Facility Manager Name*</label><br />
                    <input
                      
                      className='text-field-item'
                      id='firstInput'
                      type="text"
                      name="accountFName"
                      placeholder="First-Name Last-Name"
                      value={name}
                      onChange={nameHandler}
                    />
                    <p>
                      <span className="error_style">
                        {nameError}
                      </span>
                    </p>
                    <label>Email Address*</label><br />
                    <input
                      
                      id="emailInput"
                      className='text-field-item'
                      style={{
                        width: "34.5vw",
                        paddingLeft: "15px"
                      }}
                      type="email"
                      value={emailId}
                      autoComplete="off"
                      name="contactEmail"
                      placeholder="Enter Valid email ID"
                      onChange={emailHandler}

                    />
                    <p>
                      <span className="error_style" >
                        {emailError}
                      </span>
                    </p>
                    <label>Role*</label><br />
                    <FormControl >
                      <input
                        disabled
                        id="lastInput"
                        
                        className='text-field-item'
                        style={{
                          width: "34.5vw",
                          paddingLeft: "15px"
                        }}
                        type="text"
                        value={getCookie('role')==='RESIDENTIAL_GROUP_PARKING_PROVIDER'?"RESIDENTIAL_FACILITY_ADMIN":'FACILITY_ADMIN'}
                        name="role"

                      >

                      </input>
                    </FormControl>
                    <p></p>
                  </div>
                </div>
                <div className="button-section">
                  <button
                    type="button"
                    data-testid="select-service-btn"
                    id="submitButton"
                    className="form-button-submit"
                    onClick={submitDetailsConfirm}
                    disabled={
                      name.length === 0 ||
                      nameError.length > 0 ||
                      emailId.length === 0 ||
                      emailError.length > 0

                    }
                    style={{
                      backgroundColor:
                        name.length === 0 ||
                          nameError.length > 0 ||
                          emailId.length === 0 ||
                          emailError.length > 0 ?
                          "#707070" :
                          undefined
                    }}
                  >
                    Submit
                  </button>
                  <button type="button" id="cancelButton" data-testid="cancel-button" className="form-button-cancel" onClick={formCancelHandler}>
                    Cancel
                  </button>

                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={handleClose}
        aria-labelledby="create-security-admin-dialog"
      >
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{ paddingBottom: "0px", paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px" }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">Create Account</span>
            </Grid>
            {/* <Grid item>
                  <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                </Grid> */}
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }} className="dialog-content">
          <p className="dialog-content-text">Do you want to create this User ?</p>
          <p className="dialog-content-text" style={{ marginTop: '10px' }}>Facility Manager Name: <span style={{ fontWeight: 'bolder' }}>{name}</span></p>
          <p className="dialog-content-text">Email Address:<span style={{ fontWeight: 'bolder' }}>{emailId}</span> </p>
          <p className="dialog-content-text">Role:<span data-testid="role" style={{ fontWeight: 'bolder' }}>{getCookie('role')==='RESIDENTIAL_GROUP_PARKING_PROVIDER'?"RESIDENTIAL FACILITY ADMIN":'FACILITY ADMIN'}</span></p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button type="button" id ="confirmSubmit" onClick={submitDetails} data-testid="confirm-button" className="confirm-button" >
            Confirm
          </button>
          <button type="button" id="cancelPopup" onClick={handleClose} data-testid="cancel-popup" className="cancel-button" >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}
// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   updateToasterConfig,
//   dispatch,
// });
//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(CreateFacilityUser)
);