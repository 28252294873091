// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { getCookie } from "../../../../../Utils/cookies";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../../../redux/modules/app/actions";
import httpRequest from "../../../../../Utils/httpRequest";
import Typography from "@material-ui/core/Typography";
import "./style.scss";
import Bike from "../../../../../Assets/Images/2 Wheeler.svg";
import Car from "../../../../../Assets/Images/4 Wheeler.svg";
import Fleet from "../../../../../Assets/Images/Fleet.svg";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import StoreContext from "../../../../../context/dashboard-context";

//@component
//Slot Count
function SlotCount(props) {
  const [slotAvailableFlag, setSlotAvailableFlag] = useState(false);
  const [parkingDetail, setParkingDetail] = useState([]);
  const [availableType1, setAvailableType1] = useState(0);
  const [availableType2, setAvailableType2] = useState(0);
  const [type1Label, setType1Label] = useState("");
  const [type2Label, setType2Label] = useState("");
  const [totalSlotType1, setTotalSlotType1] = useState(0);
  const [totalSlotType2, setTotalSlotType2] = useState(0);
  const [occupiedType1, setOccupiedType1] = useState(0);
  const [occupiedType2, setOccupiedType2] = useState(0);
  const [toccupiedType1, setTOccupiedType1] = useState("");
  const [toccupiedType2, setTOccupiedType2] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [type1Flag, setType1Flag] = useState(false);
  const [type2Flag, setType2Flag] = useState(false);
  const [carEnabled, setCarEnabled] = useState(false);
  const [bikeEnabled, setbikeEnabled] = useState(false);

  /* We are setting flag here so that the two button sets are conditionally 
   rendered */
  const setButtonClick = (flag) => {
    setSlotAvailableFlag(flag);
  };

  const cancelButtonClick = (flag) => {
    setSlotAvailableFlag(flag);
    setAvailableType1(availableType1);
    setAvailableType2(availableType2);
  };

  // get count of number of times same value appears
  // function getOccurrence(array, value) {
  //   return array.filter((v) => (v === value)).length;
  // }

  /* In edit box when we change live availability count then those changed 
 values are set into react state here */
  const editValueSetter = (event) => {
    let typedValue = event.target.value;
    let vehicleType = event.target.id;

    var inputBox = document.getElementById(vehicleType);
    var invalidChars = ["-", "+", "e", "."];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());

    if (vehicleType === "type1") {
      if (
        typedValue <= totalSlotType1 &&
        typedValue >= 0 &&
        typedValue.length <= 7
      ) {
        setTOccupiedType1(typedValue);
      }
    }

    if (vehicleType === "type2") {
      if (
        typedValue <= totalSlotType2 &&
        typedValue >= 0 &&
        typedValue.length <= 7
      ) {
        setTOccupiedType2(typedValue);
      }
    }
  };

  /* We are calling this api to send 
  the edited availability count */
  const apiCaller = () => {
    setDialogOpen(false);
    setSlotAvailableFlag(false);

    let uidType1 = 0;
    let uidType2 = 0;

    if (type2Label === "4 Wheeler") {
      uidType2 = 1;
    }
    if (type2Label === "2 Wheeler") {
      uidType2 = 2;
    }

    if (type1Label === "4 Wheeler") {
      uidType1 = 1;
    }
    if (type1Label === "2 Wheeler") {
      uidType1 = 2;
    }

    httpRequest.patch(
      "setAvailability",
      [
        {
          occupiedSlots: toccupiedType1,
          vehicleType: {
            uid: uidType1,
          },
        },
        {
          occupiedSlots: toccupiedType2,
          vehicleType: {
            uid: uidType2,
          },
        },
      ],
      {
        "Content-Type": "application/json",
      },
      successCallback,
      errorCallback,
      {
        parkingId: props.parkingId,
      }
    );
  };

  const successCallback = (jsonResponse) => {
    if (jsonResponse) {
      // console.log(jsonResponse);
    }
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: `Occupancy count set successfully`,
        color: "green",
      })
    );
    setTOccupiedType1("");
    setTOccupiedType2("");
  };

  const errorCallback = (error) => {
    if (error) {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
      setTOccupiedType1("");
      setTOccupiedType2("");
    }
  };

  const startMe = () => {
    httpRequest.get("parkingdetailSlot", {}, UserDataSuccess, errorCallback, {
      parkingId: props.parkingId,
      userId: getCookie("userId"),
    });
  };

  const UserDataSuccess = (jsonResult) => {
    if (jsonResult) {
      setParkingDetail(jsonResult.data.vehicleTypeInfo);
      jsonResult.data.vehicleTypeInfo[0]?.availableSlots
        ? setCarEnabled(true)
        : setCarEnabled(false);
      jsonResult.data.vehicleTypeInfo[1]?.availableSlots
        ? setbikeEnabled(true)
        : setbikeEnabled(false);
      let availableType1 = jsonResult.data.vehicleTypeInfo[0]?.availableSlots
        ? jsonResult.data.vehicleTypeInfo[0]?.availableSlots
        : 0;
      let availableType2 = jsonResult.data.vehicleTypeInfo[1]?.availableSlots
        ? jsonResult.data.vehicleTypeInfo[1]?.availableSlots
        : 0;

      let type1DisplayName = jsonResult.data.vehicleTypeInfo[0]?.vehicleType
        ?.displayName
        ? jsonResult.data.vehicleTypeInfo[0]?.vehicleType?.displayName
        : "";
      let type2DisplayName = jsonResult.data.vehicleTypeInfo[1]?.vehicleType
        ?.displayName
        ? jsonResult.data.vehicleTypeInfo[1]?.vehicleType?.displayName
        : "";

      let totalSlotType1 = jsonResult.data.vehicleTypeInfo[0]?.totalSlots
        ? jsonResult.data.vehicleTypeInfo[0]?.totalSlots
        : 0;
      let totalSlotType2 = jsonResult.data.vehicleTypeInfo[1]?.totalSlots
        ? jsonResult.data.vehicleTypeInfo[1]?.totalSlots
        : 0;

      let occupiedType1 = totalSlotType1 - availableType1;
      let occupiedType2 = totalSlotType2 - availableType2;

      if (jsonResult.data.vehicleTypeInfo) {
        if (jsonResult.data.vehicleTypeInfo.length == 0) {
          setType1Flag(false);
          setType2Flag(false);
        }
        if (jsonResult.data.vehicleTypeInfo.length == 1) {
          setType1Flag(true);
          setType2Flag(false);

          setAvailableType1(availableType1);
          setType1Label(type1DisplayName);
          setTotalSlotType1(totalSlotType1);
          setOccupiedType1(occupiedType1);
        }
        if (jsonResult.data.vehicleTypeInfo.length == 2) {
          setType1Flag(true);
          setType2Flag(true);

          setAvailableType1(availableType1);
          setType1Label(type1DisplayName);
          setTotalSlotType1(totalSlotType1);
          setOccupiedType1(occupiedType1);

          setAvailableType2(availableType2);
          setType2Label(type2DisplayName);
          setTotalSlotType2(totalSlotType2);
          setOccupiedType2(occupiedType2);
        }
      }
    }
  };

  const errorUserCallback = (error) => {
    if (error) {
      console.log(error);
    }
  };
  // To validate if input value is number or not
  const hasNumber = (myString) => {
    return /\d/.test(myString);
  };

  const confirmButton = () => {
    if (hasNumber(availableType1) && hasNumber(availableType2)) {
      setDialogOpen(true);
    } else {
      setAvailableType1(availableType1);
      setAvailableType2(availableType2);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message:
            "Error! Please remove non-number input in the set slot and proceed",
          color: "red",
        })
      );
    }
  };

  useEffect(() => {
    startMe();
    let interval = setInterval(() => {
      httpRequest.get(
        "parkingdetailSlot",
        {},
        UserDataSuccess,
        errorUserCallback,
        {
          parkingId: props.parkingId,
          userId: getCookie("userId"),
        }
      );
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="slotcount">
      <div className="slotcount__wrapper">
        <Grid container className="grid-outer-container" spacing={2}>
          <StoreContext.Consumer>
            {() => (
              <Grid item md={12}>
                <Card raised={true} className="details_card">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    className="slot-count-header-grid"
                  >
                    <Grid item className="slot-count-text-grid">
                      <Typography variant="h6">Slot Count</Typography>
                    </Grid>
                    {!slotAvailableFlag &&
                      !props.gateway &&
                      props.createdBy === getCookie("userId") &&
                      getCookie("role") !== "FACILITY_ADMIN" &&
                      getCookie("role") !== "MULTITENANT_ADMIN" && (
                        <Grid item className="set-button-grid">
                          <button
                            onClick={() => setButtonClick(true)}
                            className="slot-count-set-button"
                          >
                            Set Parking Occupancy
                          </button>
                        </Grid>
                      )}
                    {slotAvailableFlag && (
                      <>
                        <Grid item className="confirm-button-grid">
                          <button
                            onClick={confirmButton}
                            className="slot-count-confirm-button"
                          >
                            Confirm
                          </button>
                          <button
                            onClick={() => cancelButtonClick(false)}
                            className="slot-count-cancel-button"
                          >
                            Cancel
                          </button>
                        </Grid>
                        {/* <Grid item className='cancel-button-grid'>
                      <button onClick={()=>cancelButtonClick(false)} className='slot-count-cancel-button'>
                        Cancel
                      </button>
                      </Grid> */}
                      </>
                    )}
                  </Grid>
                  <CardContent className="details_info">
                    {parkingDetail.length ? (
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow className="table-row">
                              <TableCell align="left">
                                Type of Vehicle
                              </TableCell>
                              <TableCell
                                className="table-row-header"
                                align="left"
                              >
                                Total Slots
                              </TableCell>
                              <TableCell
                                className="table-row-header"
                                align="left"
                              >
                                Occupied Slots
                              </TableCell>
                              <TableCell
                                className="table-row-header"
                                align="left"
                              >
                                Available Slots
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {type1Flag && (
                              <TableRow>
                                <TableCell align="left">
                                  {JSON.stringify(availableType1).length >
                                    0 && (
                                    <img
                                      className="list-icon iconsAlignm"
                                      src={
                                        type1Label === "4 Wheeler" ? Car : Bike
                                      }
                                      alt="type1-icon"
                                    />
                                  )}
                                  {type1Label}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {totalSlotType1}
                                </TableCell>

                                {!slotAvailableFlag && (
                                  <TableCell
                                    align="left"
                                    className="available-slot-table-cell"
                                    style={{
                                      color:
                                        props.gateway === false
                                          ? "#F30626"
                                          : "orange",
                                    }}
                                  >
                                    {!props.gateway ||
                                    getCookie("isHardwareAvailable") === "false"
                                      ? occupiedType1
                                      : "--"}
                                  </TableCell>
                                )}
                                {slotAvailableFlag && (
                                  <TableCell
                                    align="left"
                                    className="available-slot-table-cell"
                                  >
                                    <input
                                      id="type1"
                                      type="text"
                                      maxLength={totalSlotType1.length}
                                      className="available-slot-input"
                                      onChange={editValueSetter}
                                      value={toccupiedType1}
                                    />
                                  </TableCell>
                                )}
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                  style={{
                                    color:
                                      props.gateway === false
                                        ? "#67B561"
                                        : "orange",
                                  }}
                                >
                                  {(!props.gateway && carEnabled) ||
                                  getCookie("isHardwareAvailable") === "false"
                                    ? availableType1
                                    : "--"}
                                </TableCell>
                              </TableRow>
                            )}
                            {type2Flag && (
                              <TableRow>
                                <TableCell align="left">
                                  {JSON.stringify(availableType2).length >
                                    0 && (
                                    <img
                                      className="list-icon iconsAlignm"
                                      src={
                                        type2Label === "4 Wheeler" ? Car : Bike
                                      }
                                      alt="type2-icon"
                                    />
                                  )}
                                  {type2Label}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                >
                                  {totalSlotType2}
                                </TableCell>

                                {!slotAvailableFlag && (
                                  <TableCell
                                    align="left"
                                    className="available-slot-table-cell"
                                    style={{
                                      color:
                                        props.gateway === false
                                          ? "#F30626"
                                          : "rgb(112, 112, 112)",
                                    }}
                                  >
                                    {!props.gateway ||
                                    getCookie("isHardwareAvailable") === "false"
                                      ? occupiedType2
                                      : "--"}
                                  </TableCell>
                                )}

                                {slotAvailableFlag && (
                                  <TableCell
                                    align="left"
                                    className="available-slot-table-cell"
                                  >
                                    <input
                                      id="type2"
                                      type="text"
                                      maxLength={totalSlotType2.length}
                                      className="available-slot-input"
                                      onChange={editValueSetter}
                                      value={toccupiedType2}
                                    />
                                  </TableCell>
                                )}
                                <TableCell
                                  align="left"
                                  className="total-slot-table-cell"
                                  style={{
                                    color:
                                      props.gateway === false
                                        ? "#67B561"
                                        : "rgb(112, 112, 112)",
                                  }}
                                >
                                  {(!props.gateway && bikeEnabled) ||
                                  getCookie("isHardwareAvailable") === "false"
                                    ? availableType2
                                    : "--"}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <h4>No Data Found</h4>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </StoreContext.Consumer>

          <Grid item md={6} style={{ display: "none" }}>
            <Card
              raised={true}
              className="details_card"
              style={{ marginTop: "10px" }}
            >
              <CardHeader
                title="Tariff Details"
                titleTypographyProps={{ variant: "h6" }}
              />

              <CardContent className="details_info">
                {props.vehicleTypeInfo ? (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead
                        style={{
                          background: "#E5E2EF",
                          fontWeight: "700",
                        }}
                      >
                        <TableCell align="left">Type of Vehicle</TableCell>
                        <TableCell align="left">Base Price</TableCell>
                        <TableCell align="left">Standard Price</TableCell>
                        <TableCell align="left">Long Term</TableCell>
                      </TableHead>
                      <TableBody>
                        {props.vehicleTypeInfo.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              <img
                                className="list-icon iconsAlignm"
                                src={
                                  item.vehicleType.name === "BIKE"
                                    ? Bike
                                    : item.vehicleType.name === "CAR"
                                    ? Car
                                    : item.vehicleType.name === "FLEET"
                                    ? Fleet
                                    : Bike
                                }
                                alt="iconimg"
                              />
                              {item.vehicleType.displayName
                                ? item.vehicleType.displayName
                                : "0"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "24px",
                                color: "#102E35",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                              align="left"
                            >
                              {item.priceInfo ? (
                                <ul className="data-list">
                                  <li>
                                    <span style={{ fontWeight: "200" }}>₹</span>
                                    {item.priceInfo.basePrice}
                                  </li>
                                  <li className="data_unit">
                                    0-{item.priceInfo.baseHour}
                                    Hr
                                  </li>
                                </ul>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "24px",
                                color: "#102E35",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                              align="left"
                            >
                              {item.priceInfo ? (
                                <ul className="data-list">
                                  <li>
                                    <span style={{ fontWeight: "200" }}>₹</span>
                                    {item.priceInfo.additionalPrice}
                                  </li>
                                  <li className="data_unit">
                                    per
                                    {" " + item.priceInfo.additionalHour + " "}
                                    Hr
                                  </li>
                                </ul>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "24px",
                                color: "#102E35",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                              align="left"
                            >
                              {item.priceInfo ? (
                                <ul className="data-list">
                                  <li>
                                    {item.priceInfo.pricePerDay !== 0 ||
                                    item.priceInfo.pricePerWeek !== 0 ||
                                    item.priceInfo.pricePerMonth !== 0 ? (
                                      <span style={{ fontWeight: "200" }}>
                                        ₹
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                    {item.priceInfo.pricePerDay !== 0 &&
                                      item.priceInfo.pricePerDay}
                                    {item.priceInfo.pricePerWeek !== 0 &&
                                      item.priceInfo.pricePerWeek}
                                    {item.priceInfo.pricePerMonth !== 0 &&
                                      item.priceInfo.pricePerMonth}
                                  </li>
                                  <li className="data_unit">
                                    {item.priceInfo.pricePerDay !== 0 && (
                                      <span>per Day</span>
                                    )}
                                    {item.priceInfo.pricePerWeek !== 0 && (
                                      <span>per Week</span>
                                    )}
                                    {item.priceInfo.pricePerMonth !== 0 && (
                                      <span>per Month</span>
                                    )}
                                  </li>
                                </ul>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <h4>No Data Found</h4>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        className="dialog-outer-grid"
      >
        <MuiDialogTitle
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ alignItems: "center" }}
          >
            <Grid item>
              <p className="dialog-title">Set Parking Occupancy</p>
            </Grid>
            <Grid item>
              <CloseIcon
                className="dialog-close-icon"
                onClick={() => setDialogOpen(false)}
              />
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <Divider variant="fullWidth" />
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <p className="dialog-content-text">
            Are you sure you want to set the entered parking occupancy?
          </p>
          <p className="dialog-content-text">
            4 Wheeler Occupancy: {toccupiedType1}
          </p>
          <p className="dialog-content-text">
            2 Wheeler Occupancy: {toccupiedType2}
          </p>
        </DialogContent>
        <DialogActions>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              className="dialog-actionbutton-grid"
            >
              <Grid item className="dialog-confirm-button-grid">
                <button onClick={apiCaller} className="dialog-confirm-button">
                  Confirm
                </button>
              </Grid>
              <Grid item>
                <button
                  onClick={() => setDialogOpen(false)}
                  className="dialog-cancel-button"
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(SlotCount));
