// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { updateBreadcrumbs } from '../../../redux/modules/app/actions';
import { ResidentialReportData } from '../../../Data/report-page';
import landingUseStyles from './styles';

//@component
//report landing page 
function ResidentialLandingPage(props) {
    const classes = landingUseStyles();
    
    //adding component to breadcrumb
    useEffect(() => {
        props.dispatch(updateBreadcrumbs('landing'));
    }, []);

    return (
        <Grid container
            direction="column"
            alignItems="center"
        >
            <Card className={classes.reportCard}>
                <Grid item >
                    <Grid container
                        direction="row"
                        justify="center"
                    >
                        <Typography variant="h5" className={classes.header}                      >
                            Reports
                        </Typography>
                    </Grid>
                </Grid>
                <CardContent style={{padding: '0vw 10vw 0vw 10vw'}}>
                    <Grid item>
                        <Grid container
                            direction="row"
                            className={classes.cardContentGrid}
                            xs={12} sm={12} md={12} lg={12}
                        >
                            {
                                ResidentialReportData.map((item, index) => (
                                    <Grid item key={index} xs={4} sm={4} md={4} lg={4} className={classes.individualColumnBlock}>
                                        <Grid container  direction="column" alignItems="center" justify="space-evenly">
                                            <Grid item className={classes.imageGrid}>
                                                <img src={item.image} alt="parked_users" className={classes.imageStyle} />
                                            </Grid>
                                            <Grid item className={classes.textGrid}>
                                                <p className={item.text=='Unregistered/Non-Std Vehicles'?classes.textStylingLong:classes.textStyling}>{item.text}</p>
                                            </Grid>
                                            <Grid item container justify="center">
                                                <Button
                                                    component={Link}
                                                    to={item.to}
                                                    className={classes.viewReport}
                                                    variant="contained"
                                                >
                                                    View Report
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs,
    dispatch,
});

//method to connect react with redux
export default withRouter(
    connect(null, mapDispatchToProps)(ResidentialLandingPage),
);