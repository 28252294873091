// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from 'react'
import './style.scss'

//@component
//Index
export default class index extends Component {
    render() {
        return (
           
                <div className="pz-loader">
                    <div className="div-spread"></div>
                    <div className="div-spread"></div>
                    <div className="div-spread"></div>
                </div>
          
        )
    }
}





