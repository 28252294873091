// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  CardContent,
  CardHeader,
  Card,
  Button,
  IconButton,
} from "@material-ui/core";
import StoreContext from "../../../context/dashboard-context";
import outside from "../../../Assets/Images/outside.png";
import offline from "../../../Assets/Images/offline.png";
import amber from "../../../Assets/Dashboard_icons/Legends_icons/alert-warning-filled.png";
import red from "../../../Assets/Dashboard_icons/Legends_icons/alert-error-filled.png";
import live from "../../../Assets/Images/live.png";
import cardviewIcon from "../../../Assets/Images/card view deactive.png";
import listviewIcon from "../../../Assets/Images/list view deactive.png";
import mapviewIcon from "../../../Assets/Images/map view deactive.png";
import cardviewIconActive from "../../../Assets/Images/card view active.png";
import listviewIconActive from "../../../Assets/Images/list view active.png";
import mapviewIconActive from "../../../Assets/Images/map view active.png";
import "./ViewContainer.scss";
import DashboardOverview from "./DashboardOverview";
import ResidentialDashboardOverview from "./ResidentialDashboardOverview";
import EYDashboard from "./EYDashboard";
import { getCookie } from "../../../Utils/cookies";
import Loader from "../../../Components/Preloader/index";
import Noparking from "../../../Assets/Images/parkingSpace.svg";
//@component
//View Container
const ViewContainer = () => {
  //  flag to switch between platform original view or Client view for dashboard over
  const [ascFlag] = useState(true);
  //function for component mount
  useEffect(() => {}, []);
  return (
    <StoreContext.Consumer>
      {(context) =>
        getCookie("isHardwareAvailable") === "false" ? (
          <Card raised={true} className="card-cont-ey">
            <CardHeader
              className="psoHeaderEY"
              style={{
                paddingBottom: "10px",
                fontWeight: "700",
                fontSize: "1vw",
                fontFamily: "'Poppins' !important",
                color: "#102E35",
              }}
              title="Parking Space Overview"
              disableTypography={true}
              // titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Scrollbars autoHide className="viewBoxMapEy">
                {context.loading ? <Loader /> : <EYDashboard />}
              </Scrollbars>
              <div className="card-footer" id="pso-card-footer">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#03A9F3", borderRadius: "50px" }}
                >
                  <Link className="location_link" to="/portal/parkingmanagment">
                    View All Locations
                  </Link>
                </Button>
                <div className="legend-cont">
                  <div className="legend">
                    <img src={live} alt="logo" />
                    <span>Live and open</span>
                  </div>
                  <div className="legend">
                    <img src={outside} alt="logo" />
                    <span>Outside Operating Hours</span>
                  </div>

                  <div className="legend">
                    <img src={offline} alt="logo" />
                    <span>Offline/Deactivated</span>
                  </div>
                  {/* <Grid item xs={2} sm={2} md={2} lg={2} className="legend_grid">
              <img alt="offline" src={offline} />
              <p className="legend">Offline/Deactivated</p>
            </Grid> */}
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card raised={true} className="card-cont">
            <CardHeader
              className="psoHeader"
              action={
                !ascFlag && (
                  <div>
                    <div className="pso-filter" style={{ display: "inline" }}>
                      <select
                        onChange={context.handleClick}
                        className="selectBoxA"
                        style={{
                          width: "100px",
                          marginRight: "1em",
                          paddingBottom: "5px",
                          outline: "transparent",
                          paddingTop: "5px",
                          borderColor: "#eee",
                          backgroundColor: "#E6E6EF",
                          height: "35px",
                          borderRadius: "15px",
                        }}
                      >
                        {context.vehicleType.map((d, index) => (
                          <option key={index} value={d.value}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <IconButton onClick={context.handleCard}>
                      <img
                        src={
                          context.cardType ? cardviewIconActive : cardviewIcon
                        }
                        style={{ width: "25px" }}
                        alt="icon"
                      />
                    </IconButton>
                    <IconButton onClick={context.handleList}>
                      <img
                        src={
                          context.listType ? listviewIconActive : listviewIcon
                        }
                        style={{ width: "25px" }}
                        alt="icon"
                      />
                    </IconButton>
                    <IconButton onClick={context.handleMap}>
                      <img
                        src={context.mapType ? mapviewIconActive : mapviewIcon}
                        style={{ width: "25px" }}
                        alt="icon"
                      />
                    </IconButton>
                  </div>
                )
              }
              style={{
                paddingBottom: "10px",
                fontWeight: "700",
                fontFamily: "'Poppins' !important",
                color: "#102E35",
              }}
              title="Parking Space Overview"
              disableTypography={true}
              // titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              {ascFlag &&
                getCookie("role") !== "RESIDENTIAL_GROUP_PARKING_PROVIDER" &&
                getCookie("role") !== "RESIDENTIAL_FACILITY_ADMIN" &&
                getCookie("isHardwareAvailable") === "true" && (
                  <Scrollbars autoHide className="viewBoxMap">
                    {context.loading ? (
                      <Loader />
                    ) : context.parkingData &&
                      context.parkingData.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          height: "80vh",
                        }}
                      >
                        <img src={Noparking} />
                        <h3 style={{ color: "black" }}>
                          You don't have any approved parking space added to
                          your account
                        </h3>
                      </div>
                    ) : (
                      <DashboardOverview />
                    )}
                  </Scrollbars>
                )}
              {(getCookie("role") === "RESIDENTIAL_GROUP_PARKING_PROVIDER" ||
                getCookie("role") === "RESIDENTIAL_FACILITY_ADMIN") && (
                <Scrollbars autoHide className="viewBoxMap">
                  {context.loading ? (
                    <Loader />
                  ) : (
                    <ResidentialDashboardOverview />
                  )}
                </Scrollbars>
              )}
              <div className="card-footer" id="pso-card-footer">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#03A9F3",
                    borderRadius: "50px",
                  }}
                >
                  <Link className="location_link" to="/portal/parkingmanagment">
                    View All Locations
                  </Link>
                </Button>
                <div className="legend-cont">
                  <div className="legend">
                    <img src={live} alt="logo" />
                    <span>Live and open</span>
                  </div>
                  <div className="legend">
                    <img src={outside} alt="logo" />
                    <span>Outside Operating Hours</span>
                  </div>
                  <div className="legend">
                    <img src={amber} alt="logo" />
                    <span>Pending/ Device(s) Down</span>
                  </div>
                  <div className="legend">
                    <img src={red} alt="logo" />
                    <span>Gateway Device Down</span>
                  </div>
                  <div className="legend_last">
                    <img src={offline} alt="logo" />
                    <span>Uncommissioned/ Deactivated</span>
                  </div>
                </div>
              </div>

              {/* {(context.listType && !ascFlag) && (
                    <ListView/>
                  )}
                  {(context.cardType && !ascFlag) && (
                    <CardView/>
                  )}
                  {(context?.mapType && !ascFlag) && (
                    <MapContainer
                      stores={context.parkingData}
                      filterOption={context.filterOption}
                    />
                  )} */}
            </CardContent>
          </Card>
        )
      }
    </StoreContext.Consumer>
  );
};

export default ViewContainer;
