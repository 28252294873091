// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import { Card, Typography, Grid, Button } from "@material-ui/core";
import BeautyStars from "beauty-stars";
import "./style.scss";
import httpRequest from "./../../../../../Utils/httpRequest";
import noReviews from "../../../../../Assets/Images/no reviews.png";
import { Scrollbars } from "react-custom-scrollbars";

//@component
//Ratingsandreviews
export default class Ratingsandreviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewDetail: [],
      showUserId: null,
      initialReview: true,
      avgRating:null,
      totRating:null,
      showDiv:false,
      viewMore:0,
      topRated:false
    };
  }
  //function for reviewSuccessRated
  reviewSuccessRated = (jsonResult) => {
    var result = jsonResult.data._embedded.results;
    result.sort((a, b) =>
      a.rating > b.rating ? -1 : b.rating > a.rating ? 1 : 0
    );
    this.setState({
      reviewDetail: result,
    });
  };
   //function for reviewSuccess
   reviewSuccess = (jsonResult) => {
    var result = jsonResult.data._embedded.results;
    // console.log("result: ",result)
    this.setState({
      reviewDetail: result,
    });
    //console.log("first load ",this.state.reviewDetail)
  };
  //function to process success rating api 
  ratingSuccess = (jsonResult) => {
    this.setState({
      avgRating: jsonResult.data.avgRating,
      totRating:jsonResult.data.totalRatings,
      
    },()=>{
      if (this.state.totRating>5){
        this.setState({
         showDiv:true
          
        })
      }
    });
  }; 
  //function for error call
  errorCallback = () => {};
  //function for component mount
  componentDidMount = () => {
    let page,size
    page = 0
    size = 5
    this.setState({ viewMore: 0 });

   this.getRatingParking(); 
   this.getReviews(page,size);
  };
   //api call to get reviews
   getRatingParking=() =>{
    httpRequest.get(
      "ratingParking",
      {},
      this.ratingSuccess,
      this.errorCallback,
      {
        parkingId: this.props.parkingId,
       
      }
    );
  }
  //api call to get reviews
  getReviews=(page,size) =>{
    httpRequest.get(
      "detailReview",
      {},
      this.reviewSuccess,
      this.errorCallback,
      {
        parkingId: this.props.parkingId,
        page:page,
        size:size
      }
    );
  }
  //apicall to loadmore reviews 
  moreReviews=(page,size,topRated) =>{
    this.setState({ viewMore: page+1 });

    topRated?( httpRequest.get(
      "detailReviewTopRated",
      {},
      this.reviewSuccessRated,
      this.errorCallback,
      {
        parkingId: this.props.parkingId,
        size:size,
        page:page+1,
      }
    )):(

    httpRequest.get(
      "detailReview",
      {},
      this.reviewSuccessMore,
      this.errorCallback,
      {
        parkingId: this.props.parkingId,
        page:page+1,
        size:size
      }
    ))
  }
  //success on loadmore reviews call
   reviewSuccessMore = (jsonResult) => {
    var result = jsonResult.data._embedded.results
    if (jsonResult.data.page.totalPages===jsonResult.data.page.number+1){
      this.setState({showDiv:false})
    }
    result.forEach(data=>{
      this.state.reviewDetail.push(data)
    })
   

    // this.setState(prevState => ({
    //   reviewDetail: [...prevState.reviewDetail, result]
    // }))
    // console.log("load more ",this.state.reviewDetail)
  };
  //function for dateFormat 
  dateFormat = (str) => {
    const newdate = new Date(str);
    const date = newdate.toString().split(" ");
    const one = new Date(str);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const time = one.toLocaleString("en-US", options);
    const timeString = [date[2], date[1], date[3], time].join(" ");
    // ;
    return timeString;
  };
  //function for readFullReviews
  readFullReviews = (id) => {
    this.setState({ showUserId: id });
  };
  //function for readLessReviews
  readLessReviews = () => {
    this.setState({ showUserId: null });
  };
  //function for changeReviews
  changeReviews = (e) => {
    this.setState({ initialReview: false,showDiv:true,viewMore:0 });
    if (e.target.value === "Top Rated") {
      this.setState({topRated:true})
      httpRequest.get(
        "detailReviewTopRated",
        {},
        this.reviewSuccessRated,
        this.errorCallback,
        {
          parkingId: this.props.parkingId,
          size:5,
          page:0,
        }
      );
    
    }
    if (e.target.value === "Most Recent") {
      
     
      
      this.getReviews(0,5)
    }
  };
  render() {
    return (
      <Grid container style={{ marginTop: "-50px" }}>
        <Grid item xs={12}>
          <div className="Ratingsandreviews">
            <div className="Ratingsandreviews__wrapper_box">
              <Card raised={true} className="details_card">
                <div className="Ratingsandreviews__wrapper_box_heading">
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Ratings and Reviews
                    </Typography>
                  </div>
                  <div className="pso-filter" style={{ color: "#102E35" }}>
                    Sort By
                    <select
                      // onChange={(e) => this.handleClick(e.target.value)}
                      className="selectBoxA"
                      style={{
                        width: "100px",
                        marginRight: "1em",
                        paddingBottom: "5px",
                        outline: "transparent",
                        paddingTop: "5px",
                        borderColor: "#eee",
                        backgroundColor: "#E6E6EF",
                        height: "35px",
                        borderRadius: "15px",
                      }}
                      onChange={this.changeReviews}
                    >
                      
                      <option value="Most Recent">Most Recent</option>
                      <option value="Top Rated">Top Rated</option>
                    </select>
                  </div>
                </div>
                <div></div>
                {/* <CardHeader
                  title="Ratings and Reviews"
                  titleTypographyProps={{ variant: 'h6' }}

                /> */}

                <p className="Ratingsandreviews__wrapper_box_final-ratings">
                  Overview
                  <span
                    style={{
                      color: "#ffb539",
                      fontSize: "17px",
                      fontWeight: "bold",
                      padding: "0px 5px 0px 10px",
                    }}
                  >
                    {this.state.avgRating}
                  </span>
                  <span style={{ paddingRight: "10px" }}>Average Rating</span>|
                  <span
                    style={{
                      color: "#ffb539",
                      fontSize: "17px",
                      fontWeight: "bold",
                      padding: "0px 5px 0px 10px",
                    }}
                  >
                    {this.state.totRating}
                  </span>
                  <span >Total Ratings</span>
                </p>
                <Scrollbars style={{ height: 420 }}>
                  
                  {this.state.reviewDetail.length ? (
                    this.state.reviewDetail.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="Ratingsandreviews__wrapper_box_review"
                        >
                          <div className="Ratingsandreviews__wrapper_box_review_heading">
                            <p className="Ratingsandreviews__wrapper_box_review_heading_name">
                              <b>
                                {item.user.firstName + " " + item.user.lastName}
                              </b>
                            </p>
                            <p className="Ratingsandreviews__wrapper_box_review_heading_ratings">
                              {/* {console.log(item.rating)} */}
                              {/* <Rating
                                readOnly={true}
                                name="half-rating"
                                defaultValue={item.rating}
                                precision={0.5}
                                onChange={(event, newValue) => {
                                  alert(event, newValue);
                                  this.setState({ newValue });
                                }}
                                onChangeActive={(event, newHover) => {
                                  alert(event, newHover);
                                  this.setState({ newHover });
                                }}
                              /> */}
                              <BeautyStars
                                size="1.1rem"
                                className="reviewStar"
                                activeColor="#F49D3E"
                                value={item.rating}
                                onChange={(value) => this.setState({ value })}
                                maxStars={5}
                                inactiveColor="grey"
                              />
                            </p>
                          </div>
                          <p className="Ratingsandreviews__wrapper_box_review_date">
                            {this.dateFormat(item.lastUpdatedDate)}
                          </p>
                          <p className="Ratingsandreviews__wrapper_box_review_content">
                            {item.review !== undefined &&
                            item.review !== null &&
                            item.review.length > 50 ? (
                              <>
                                {this.state.showUserId === item.user.userId ? (
                                  <Button onClick={this.readLessReviews}>
                                    View Less
                                  </Button>
                                ) : (
                                  <>
                                    {item.review.slice(0, 40)}...
                                    <Button
                                      onClick={() =>
                                        this.readFullReviews(item.user.userId)
                                      }
                                    >
                                      View More
                                    </Button>
                                  </>
                                )}
                              </>
                            ) : (
                              item.review
                            )}

                            {/* This is one of the best parking in the world. */}
                          </p>

                          {this.state.showUserId === item.user.userId && (
                            <p className="wrapReview">{item.review}</p>
                          )}
                          
                       
                        </div>
                        
                      );
                    
                   
                    }
                    )
                    
                    
                   
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <img src={noReviews} alt="noReviews" />
                      <br />
                      <p style={{ paddingTop: "20px" }}>
                        No Reviews or ratings found for this parking space
                      </p>
                      <p style={{ paddingBottom: "30px" }}>
                        Ratings and review comments will be displayed here when
                        parking users add them
                      </p>
                    </div>
                  )}
                    
                    {this.state.showDiv && (
                      <Button 
                                    style={{marginLeft:"1em",color:'#03A9F3',marginBottom:'1em'}}
                                     onClick={()=>this.moreReviews(this.state.viewMore,5,this.state.topRated)}
                                   >
                                     See More Reviews
                                   </Button>
                     )}
               
                     </Scrollbars>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}
