// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCookie } from "../../../../Utils/cookies";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../../../redux/modules/app/actions";
import listviewUseStyles from "./style";
import Loader from "../../../../Components/Preloader/index";
import httpRequest from "../../../../Utils/httpRequest";
import Pagination from "@material-ui/lab/Pagination";
import Filtericon from "./../../../../Assets/Images/funnel-hz.png";
import Clickedfilter from "./../../../../Assets/Images/Path 1482.png";
import "../../../SolutionAdmin/ParkingManagement/AddParkingModule/OperationalTimings/style.scss";

var classData = "";

//@component
//ONPListView
function ONPListView(props) {
  const classes = listviewUseStyles();

  const [clientList, setClientList] = useState([]);
  const [viewList, setViewList] = useState([]);
  const [parkingSpaceList, setParkingSpaceList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [responseDataNotFound, setResponseDataNotFound] = useState("");
  const [postsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("datetime");
  const [pageNumber, setPagenumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKey, setSearchKey] = useState(null);
  const [internetFlag, setInternetFlag] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterFlag, setFilterFlag] = useState(false);
  const [onLoad, setOnLoad] = useState(true);

  const [dropDownFlag, setDropDownFlag] = useState(false);
  const [pageNumberSelect, setPageNumberSelect] = useState(0);
  const [totalPagesSelect, setTotalPagesSelect] = useState(0);
  const [selectValue, setSelectValue] = useState("");

  // function to set initial table value
  const onpSelectHandler = (event) => {
    setSelectValue(event.target.value);
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    setFilterFlag(false);
    setSearchKey("");
    setPagenumber(0);
    setTableDataNotFound("");
    httpRequest.get(
      "onpList",
      {
        "Content-Type": "application/json",
      },
      successCallbackSelectFilter,
      errorCallbackSelectFilter,
      {
        size: postsPerPage,
        pageNumber: pageNumber,
        onpStatus: filterValue,
        parkingId: event.target.value,
      }
    );
  };
  // success callback get list
  const successCallbackSelectFilter = (jsonResponse) => {
    setIsLoading(false);
    setDropDownFlag(true);
    setFilterFlag(false);
    if (!jsonResponse) {
      setTableDataNotFound("No Overnight Parking Requests Found");
      setViewList([]);
      setInternetFlag(true);
    }
    if (!jsonResponse.data._embedded) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Overnight Parking Requests Found");
      setViewList([]);
    }
    const list = jsonResponse.data._embedded.results;
    if (
      getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
      getCookie("role") === "FACILITY_ADMIN" ||
      getCookie("role") === "MULTITENANT_ADMIN"
    ) {
      setClientList(list);
    }
    if (onLoad === true) {
      const te = jsonResponse.data.page.totalElements;
      httpRequest.get(
        "onpList",
        {
          "Content-Type": "application/json",
        },
        successCallbackListAll,
        errorCallbackList,
        {
          size: te,
          pageNumber: pageNumber,
          onpStatus: filterValue,
          parkingId: selectValue,
        }
      );
    }

    if (jsonResponse.data?.page?.totalPages < 1) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found!..Please Refine Filter Above")
        : setTableDataNotFound("No Overnight Parking Requests Found");
      setTotalPages(0);
    } else {
      setTableDataNotFound("");
      setPageNumberSelect(jsonResponse.data.page.number);
      setViewList(jsonResponse.data._embedded.results);
      setTotalPagesSelect(jsonResponse.data.page.totalPages);
      setInternetFlag(false);
    }
  };
  // error callback get list
  const errorCallbackSelectFilter = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };
  // when we click on page number while dropdown select is being used
  const handleChangeSelect = (event, value) => {
    let currpage;
    currpage = value - 1;
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    setFilterFlag(false);
    setDropDownFlag(true);
    setSearchKey("");
    setPageNumberSelect(0);
    setTableDataNotFound("");
    httpRequest.get(
      "onpList",
      {
        "Content-Type": "application/json",
      },
      successCallbackSelectFilter,
      errorCallbackSelectFilter,
      {
        size: postsPerPage,
        pageNumber: currpage,
        onpStatus: filterValue,
        parkingId: selectValue,
      }
    );
  };
  // function to set initial table value
  const getONPList = (page) => {
    setFilterList([]);
    setIsLoading(true);
    setShowDiv(false);
    if (filterValue !== "") {
      setFilterFlag(true);
      setDropDownFlag(false);
    }
    if (filterValue === "") {
      setFilterFlag(false);
      setDropDownFlag(false);
    }
    setSearchKey("");
    setPagenumber(0);
    setTableDataNotFound("");

    httpRequest.get(
      "onpList",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: postsPerPage,
        pageNumber: page,
        onpStatus: filterValue,
        parkingId: selectValue,
      }
    );
  };
  // success callback get list
  const successCallbackList = (jsonResponse) => {
    if (!jsonResponse) {
      setTableDataNotFound("No Overnight Parking Requests Found");
      setViewList([]);
      setInternetFlag(true);
    }
    if (!jsonResponse.data._embedded) {
      setInternetFlag(true);
      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Overnight Parking Requests Found");
      setViewList([]);
    }
    setIsLoading(false);
    const list = jsonResponse.data._embedded.results;
    if (getCookie("role") === "SECURITY_PERSONNEL") {
      setClientList(list);
    }
    if (onLoad === true) {
      const te = jsonResponse.data.page.totalElements;
      httpRequest.get(
        "onpList",
        {
          "Content-Type": "application/json",
        },
        successCallbackListAll,
        errorCallbackList,
        {
          size: te,
          pageNumber: pageNumber,
          onpStatus: filterValue,
          parkingId: selectValue,
        }
      );
    }
    if (jsonResponse.data?.page?.totalPages < 1) {
      setInternetFlag(true);

      filterValue.length
        ? setTableDataNotFound("No Results Found, Please Refine Filter Above")
        : setTableDataNotFound("No Overnight Parking Requests Found");
      setTotalPages(0);
      setViewList([]);
    } else {
      setTableDataNotFound("");
      setPagenumber(jsonResponse.data.page.number);
      setViewList(jsonResponse.data._embedded.results);

      setTotalPages(jsonResponse.data.page.totalPages);
      setInternetFlag(false);
    }
  };
  //get all data for client search and filter
  const successCallbackListAll = (jsonResponse) => {
    const list = jsonResponse.data._embedded.results;
    setClientList(list);
    setOnLoad(false);
  };
  // error callback get list
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };
  // callback to set page number and size for pagination
  const pageHandler = (number) => {
    let pageSize = postsPerPage || 10;
    let pageNumber = number || 0;
    setPagenumber(number);
    httpRequest.get(
      "onpList",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        size: pageSize,
        pageNumber: pageNumber,
        onpStatus: filterValue,
        parkingId: selectValue,
      }
    );
  };
  // function to handle pagination change
  const handleChange = (event, value) => {
    let currpage;
    currpage = value - 1;
    pageHandler(currpage);
  };
  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateSidebar(3));
    props.dispatch(updateBubble(4));
    if (getCookie("role") === "SECURITY_PERSONNEL") {
      props.dispatch(updateBreadcrumbs("onpviewSecurity"));
    }
    if (
      getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
      getCookie("role") === "FACILITY_ADMIN" ||
      getCookie("role") === "MULTITENANT_ADMIN"
    ) {
      props.dispatch(updateBreadcrumbs("onplistview"));
    }
    getSelectListHandler();

    let back_page = props.history.location.state?.currentPage;
    back_page
      ? getONPList(props.history.location.state?.currentPage)
      : getONPList(0);
  }, []);
  //data for row header
  const headCells = [
    { id: "sno", label: "S.no", width: "7%" },
    { id: "email id", label: "Email Id", width: "20%" },
    { id: "vehicle number", label: "Vehicle Number", width: "13%" },
    { id: "pname", label: "Parking Space Name", width: "18%" },
    { id: "sdate", label: "Start Date", width: "10%" },
    { id: "edate", label: "End Date", width: "10%" },
    { id: "status", label: "Status", width: "14%" },
    { id: "actions", label: "Actions", width: "8%" },
  ];
  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.id === "sno" ? "center" : "left"}
              width={headCell.width}
              className={classes.thData}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //function for filter
  const filterOptionSetter = () => {
    let toggleShowDiv = !showDiv;
    setShowDiv(toggleShowDiv);
  };
  // Status filter handler function
  const statusFilterHandler = (status) => {
    setFilterValue(status);
    setFilterFlag(false);
  };
  // function for click away
  const handleClickAway = () => {
    setShowDiv(false);
  };
  //redirect to details page click handler
  const itemClicked = (data) => {
    props.history.push({
      pathname: "/portal/onpRequestDetails",
      state: {
        rowData: data,
        currentPage: pageNumber,
        search: filterFlag,
      },
    });
  };
  // function for clearing search
  const clearSearch = () => {
    classData = "";
    setSearchKey(null);
    setFilterList([]);
    setFilterFlag(false);
    getONPList(0);
  };
  //function to handle search functionality
  const handleSearchKeyChange = (e) => {
    var inputBox = document.getElementById(e.target.id);
    var invalidChars = [
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ".",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());
    if (e.target.value.length <= 30) {
      if (invalidChars.includes(e.target.value)) {
        e.preventDefault();
        setSearchKey("");
        classData = "";
      } else {
        setFilterList([]);
        setDropDownFlag(false);
        classData = e.target.value;
        if (e.target.value) {
          setSearchKey(e.target.value);

          let searchList = clientList.filter((item) => {
            if (item) {
              if (item.parking.name) {
                return (
                  item?.parking?.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  item?.startDate.includes(e.target.value) ||
                  item?.endDate.includes(e.target.value) ||
                  item?.vehicle?.vehicleNumber.includes(
                    e.target.value.toUpperCase()
                  )
                );
              }
            }
          });
          setFilterFlag(true);
          if (searchList.length < 10) {
            setFilterList(searchList);
            setPagenumber(0);
          } else {
            let page_search = searchList.slice(0, 10);
            setFilterList(page_search);
            setPagenumber(0);
          }
          if (filterList.length === 0) {
            setTableDataNotFound(
              "No Results Found, Please Refine Search or Check Filter Above"
            );
            setPagenumber(0);
          }
        }
        if (!e.target.value) {
          setSearchKey(null);
          setFilterList([]);
          setFilterFlag(false);
          getONPList(0);
        }
      }
    }
  };
  //api call when component loads first time on page
  const getSelectListHandler = () => {
    if (getCookie("role") !== "SECURITY_PERSONNEL") {
      httpRequest.get(
        "devCoupledParkingList",
        {
          "Content-Type": "application/json",
        },
        successCallbackSelectList,
        errorCallbackSelectList,
        {}
      );
    }
  };
  // success callback for component page load api
  const successCallbackSelectList = (jsonResponse) => {
    setIsLoading(false);
    setParkingSpaceList(jsonResponse.data);
  };
  // error callback for component page load api
  const errorCallbackSelectList = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      // any error message
    }
    if (error) {
      setResponseDataNotFound("");
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          direction="column"
          spacing="1"
          className={classes.container}
        >
          <Grid item className={classes.topRowGridParent}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <Grid
                    item
                    container
                    justify="space-between"
                    className={classes.searchDiv}
                  >
                    <Grid item>
                      <input
                        style={{ width: "80%" }}
                        id="searchbox"
                        className={classes.searchInput}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search account" }}
                        value={searchKey}
                        onChange={(e) => handleSearchKeyChange(e)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={classes.searchIconButton}
                        aria-label="search-onp-module"
                      >
                        {classData === "" && <SearchIcon />}
                        <p
                          style={{ marginRight: "20px" }}
                          className={
                            classData === ""
                              ? classes.editCross
                              : classes.input__cross
                          }
                          onClick={clearSearch}
                        >
                          <ClearIcon />
                        </p>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {getCookie("role") === "SECURITY_PERSONNEL" && (
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    style={{ visibility: "hidden" }}
                  ></Grid>
                )}
                {getCookie("role") !== "SECURITY_PERSONNEL" && (
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <select
                      onChange={onpSelectHandler}
                      className={classes.selectListCard}
                    >
                      {props.location?.state?.onpRequest ? (
                        <option value="">
                          {props.location?.state?.parkingName}
                        </option>
                      ) : getCookie("role") ===
                          "CLOSED_GROUP_PARKING_PROVIDER" ||
                        getCookie("role") === "FACILITY_ADMIN" ||
                        getCookie("role") === "MULTITENANT_ADMIN" ? (
                        <option value="">All</option>
                      ) : (
                        undefined
                      )}
                      {parkingSpaceList.map((list, index) => {
                        return (
                          <option key={index} value={list.parkingId}>
                            {list.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                )}
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <ButtonGroup
                    color="secondary"
                    aria-label="outlined secondary button group"
                  >
                    <Button className={classes.filter_button}>
                      <img
                        src={
                          // Filtericon
                          filterValue.length ? Clickedfilter : Filtericon
                        }
                        alt="Filtericon"
                        onClick={() => filterOptionSetter()}
                        className={classes.filter_image}
                      />
                      <Grid
                        item
                        container
                        direction="column"
                        className={classes.filter_box}
                        style={{
                          display: showDiv ? "block" : "none",
                        }}
                      >
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("")}
                                checked={filterValue === ""}
                              />
                            </div>
                            <label className={classes.filterLabel}>All</label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("APPROVED")}
                                checked={filterValue === "APPROVED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Approved
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("COMPLETED")
                                }
                                checked={filterValue === "COMPLETED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Completed
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("PENDING")}
                                checked={filterValue === "PENDING"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Pending
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("CANCELLED")
                                }
                                checked={filterValue === "CANCELLED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Cancelled
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() => statusFilterHandler("REJECTED")}
                                checked={filterValue === "REJECTED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Rejected
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_APPROVED")
                                }
                                checked={filterValue === "EXTENSION_APPROVED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Approved
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_REJECTED")
                                }
                                checked={filterValue === "EXTENSION_REJECTED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Rejected
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_PENDING")
                                }
                                checked={filterValue === "EXTENSION_PENDING"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Pending
                            </label>
                          </li>
                        </Grid>
                        <Grid item className={classes.liGrid}>
                          <li>
                            <div className="OperationalTimings__wrapper_form_checkside">
                              <Checkbox
                                color="default"
                                type="checkbox"
                                id="all"
                                data-target="filter"
                                onChange={() =>
                                  statusFilterHandler("EXTENSION_CANCELLED")
                                }
                                checked={filterValue === "EXTENSION_CANCELLED"}
                              />
                            </div>
                            <label className={classes.filterLabel}>
                              Extension Cancelled
                            </label>
                          </li>
                        </Grid>
                        <hr className={classes.hrStyling} />
                        <button
                          type="button"
                          onClick={() => getONPList(0)}
                          className={classes.applyButtonFilter}
                        >
                          Apply
                        </button>
                      </Grid>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Grid>
          {responseDataNotFound.length === 0 ? (
            <>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer className={classes.paper} component={Paper}>
                  <Table className={classes.table} aria-label="View table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {(searchKey ? filterList : viewList).length !== 0 &&
                      internetFlag === false ? (
                        (searchKey ? filterList : viewList).map(
                          (row, index) => {
                            let no = index;
                            function formatNo(no) {
                              return no < 10
                                ? pageNumber + "" + no
                                : (pageNumber + 1) * no;
                            }
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className={classes.tableCell}
                                  align="center"
                                >
                                  {formatNo(no + 1)}
                                </TableCell>
                                {/* <TableCell className={classes.tableCell} align="left" >{row?.user?.firstName + " " + row?.user?.lastName}</TableCell> */}
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.user?.corpEmailId ? (
                                    row?.user?.corpEmailId
                                  ) : (
                                    <span syle={{ align: "center" }}>--</span>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.vehicle?.vehicleNumber ? (
                                    row?.vehicle?.vehicleNumber
                                  ) : (
                                    <span className={classes.nullSpan}>--</span>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.parking?.name}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.startDate ? row?.startDate : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  align="left"
                                >
                                  {row?.endDate ? row?.endDate : "--"}
                                </TableCell>
                                <TableCell
                                  className={classes.statusRow}
                                  align="left"
                                  style={{
                                    color:
                                      row?.status?.name === "PENDING"
                                        ? "orange"
                                        : row?.status?.name ===
                                          "EXTENSION_PENDING"
                                        ? "orange"
                                        : row?.status?.name ===
                                          "EXTENSION_APPROVED"
                                        ? "#67b561"
                                        : row?.status?.name === "APPROVED"
                                        ? "#67b561"
                                        : row?.status?.name === "CANCELLED"
                                        ? "#F30626"
                                        : row?.status?.name === "REJECTED"
                                        ? "#F30626"
                                        : row?.status?.name ===
                                          "EXTENSION_REJECTED"
                                        ? "#F30626"
                                        : row?.status?.name ===
                                          "EXTENSION_CANCELLED"
                                        ? "#F30626"
                                        : row?.status?.name === "COMPLETED"
                                        ? "#67b561"
                                        : undefined,
                                  }}
                                >
                                  {row?.status?.displayName}
                                </TableCell>
                                <TableCell
                                  className={classes.viewTableCell}
                                  onClick={() => {
                                    itemClicked(row);
                                  }}
                                >
                                  <span className={classes.viewSpan}>View</span>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      ) : (
                        <TableRow className={classes.tableRowNoImage}>
                          <TableCell>
                            <Grid
                              container
                              direction="column"
                              className={classes.gridContainerNoImage}
                              alignItems="center"
                              style={{
                                width: viewList.length === 0 ? "80vw" : "",
                              }}
                            >
                              <Grid
                                item
                                className={classes.typographyGridNoImage}
                              >
                                <Typography variant="body1">
                                  {tableDataNotFound}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                className={classes.paginationOuterGrid}
              >
                {dropDownFlag === true && filterFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPagesSelect}
                          page={pageNumberSelect + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChangeSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {filterFlag === false && dropDownFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPages}
                          page={pageNumber + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {filterFlag === true && dropDownFlag === false && (
                  <Grid
                    item
                    style={{ display: internetFlag ? "none" : undefined }}
                    className={classes.paginationGrid}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >
                      <Grid item>
                        <Pagination
                          count={totalPages}
                          page={pageNumber + 1}
                          className={classes.root}
                          size="small"
                          onChange={handleChangeSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Grid item className={classes.notFoundGrid}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">
                    {responseDataNotFound}
                    {tableDataNotFound}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ClickAwayListener>
    </>
  );
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// }

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(ONPListView));
