// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component, Suspense, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import Login from "./../Pages/Login/LoginDashboard";
import DataPrivacy from "../Pages/DataPrivacy/DataPrivacy";
import ForgotPassword from "./../Pages/Login/ForgotPassword/index";
import SsoLogin from './../Pages/Login/LoginDashboard/SsoLogin';
import ParkingManagement from "./../Pages/SolutionAdmin/ParkingManagement";
import { connect } from "react-redux";
import { updateAuth } from "../redux/modules/app/actions";
import { withRouter } from "react-router-dom";

//@component
//Routes
class Routes extends Component {
  //function for component mount
  componentDidMount() {
    // if (getCookie("auth")) {
    //   this.props.updateAuth(getCookie("auth"));
    // } else {
    //   this.props.history.push("/");
    // }
    // this.interval =setInterval(()=>{
    //   var url = new URL(window.location.href);
    //   var c = url.searchParams.get("access_token")
    //   console.log("object",url.search)
    // },10000)
 
  }
  render() {
    return (
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
             
              return <Redirect to="/login"></Redirect>;
            }}
          />
          <Route exact path="/sso" component={SsoLogin} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dataprivacynotice" component={DataPrivacy} />
          <Route path="/forgot_password" component={ForgotPassword} />

          {this.props.auth && (
            <Fragment>
              <Route exact path="/portal" component={ParkingManagement} />
              <Route exact path="/portal/*" component={ParkingManagement} />
            </Fragment>
          )}
        </Switch>
      </Suspense>
    );
  }
}
//function for redux connect
export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth }
  )(Routes)
);
