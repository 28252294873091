// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './style.scss';
//Global array Declarations
var storeEditData = [];
const daysArray = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];
//function for applyAll
const applyALl = (props, data) => {
  let result = {};
  let keys = Object.keys(props.data.timings);
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = JSON.parse(JSON.stringify(data));
  }
  props.updateChildrenState(
    'step2',
    'timings',
    JSON.parse(JSON.stringify(result)),
  );
};

//@component
//Operational Timings
const OperationalTimings = (props) => {
  const [checkAll, setCheckAll] = useState(true);
  const [applyAllCheck, setApplyAllCheck] = useState(false);
  const [applyAll, setApplyAll] = useState(false);
  // const [isFirefox, setIsFirefox] = useState(false);

  //function for component mount
  useEffect(() => {
    checkedAllMethod();
    setApplyAllCheck(!applyAllCheck);
    // // detecting browser type code below
    // let navigatorType = navigator.userAgent;
    // if(!navigatorType.includes("Chrome")){
    //   setIsFirefox(true);
    // }else{
    //   setIsFirefox(false);
    // }
  }, []);
  //function for component mount
  useEffect(() => {
    if (storeEditData.timings) {
      storeEditData.timings.MONDAY.isChecked &&
        storeEditData.timings.TUESDAY.isChecked &&
        storeEditData.timings.WEDNESDAY.isChecked &&
        storeEditData.timings.THURSDAY.isChecked &&
        storeEditData.timings.FRIDAY.isChecked &&
        storeEditData.timings.SATURDAY.isChecked &&
        storeEditData.timings.SUNDAY.isChecked
        ? setApplyAll(true)
        : setApplyAll(false);
    }
  }, [applyAllCheck]);
  //function for checkedAllMethod
  const checkedAllMethod = () => {
    let obj = { ...storeEditData.timings.applyForAll };
    setCheckAll(!checkAll);
    obj.isChecked = checkAll ? true : false;
    applyALl(props, { ...obj });
  };
  //variable reference
  storeEditData = props.data;

  return (
    <div className="OperationalTimings">
      <div className="OperationalTimings__wrapper">
        <div className="OperationalTimings__wrapper_form parent">
          <div className="OperationalTimings__wrapper_form_checkside">
            <div className="OperationalTimings__wrapper_form_checkside_checkbox">
              <Checkbox
                checked={
                  applyAll
                    ? true
                    : storeEditData.timings.applyForAll.isChecked
                }
                onChange={checkedAllMethod}
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color',
                }}
              />
            </div>
            <div className="OperationalTimings__wrapper_form_checkside_text">
              <span style={{ 'font-style': 'italic' }}>
                Apply to All
              </span>
            </div>
          </div>
          <div className="OperationalTimings__wrapper_form_timeside">
            <div className="OperationalTimings__wrapper_form_timeside_opening">
              <input
                type="time"
                className="OperationalTimings__wrapper_form_timeside_opening_input"
                defaultValue="00:00:00"
                value={
                  storeEditData.timings.applyForAll.time.openingTime.substring(0, storeEditData.timings.applyForAll.time.openingTime?.length - 3)

                }
                step="900"
                onChange={(evt) => {
                  let obj = { ...storeEditData.timings };
                  for (var days in obj) {
                    obj[days].time.openingTime = evt.target.value + ':00';
                  }

                  props.updateChildrenState('step2', 'timings', obj);
                }}
              />
            </div>
            <span>.</span>
            <div className="OperationalTimings__wrapper_form_timeside_closing">
              <input
                type="time"
                className="OperationalTimings__wrapper_form_timeside_closing_input"
                defaultValue="00:00:00"
                value={
                  storeEditData.timings.applyForAll.time.closingTime.substring(0, storeEditData.timings.applyForAll.time.closingTime?.length - 3)

                }
                step="900"
                onChange={(evt) => {
                  let obj = { ...storeEditData.timings };
                  for (var days in obj) {
                    obj[days].time.closingTime = evt.target.value + ':00';
                  }
                  props.updateChildrenState('step2', 'timings', obj);
                }}
              />
            </div>
          </div>
        </div>
        {daysArray.map((day, index) => {
          return (
            <div
              className="OperationalTimings__wrapper_form"
              key={index}
            >
              <div className="OperationalTimings__wrapper_form_checkside">
                <div className="OperationalTimings__wrapper_form_checkside_checkbox">
                  <Checkbox
                    checked={storeEditData.timings[day].isChecked}
                    color="default"
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                    onChange={() => {
                      setApplyAllCheck(!applyAllCheck);
                      let obj = { ...storeEditData.timings };
                      obj[day].isChecked = !storeEditData.timings[day]
                        .isChecked;
                      props.updateChildrenState(
                        'step2',
                        'timings',
                        obj,
                      );
                    }}
                  />
                </div>
                <div className="OperationalTimings__wrapper_form_checkside_text">
                  <span style={{ 'font-weight': 'bold' }}>{day}</span>
                </div>
              </div>
              <div className="OperationalTimings__wrapper_form_timeside">
                <div className="OperationalTimings__wrapper_form_timeside_opening">
                  <input
                    type="time"
                    className="OperationalTimings__wrapper_form_timeside_opening_input"
                    defaultValue="00:00:00"
                    value={
                      storeEditData.timings[day].time.openingTime.substring(0, storeEditData.timings[day].time.openingTime?.length - 3)

                    }
                    step="900"
                    onChange={(evt) => {
                      let obj = { ...storeEditData.timings };
                      obj[day].time.openingTime = evt.target.value + ':00';
                      props.updateChildrenState(
                        'step2',
                        'timings',
                        obj,
                      );
                    }}
                  />
                </div>
                <span>.</span>
                <div className="OperationalTimings__wrapper_form_timeside_closing">
                  <input
                    type="time"
                    className="OperationalTimings__wrapper_form_timeside_closing_input"
                    defaultValue="22:00:00"
                    value={
                      storeEditData.timings[day].time.closingTime.substring(0, storeEditData.timings[day].time.closingTime?.length - 3)


                    }
                    step="900"
                    onChange={(evt) => {
                      let obj = { ...storeEditData.timings };

                      obj[day].time.closingTime = evt.target.value + ':00';
                      props.updateChildrenState(
                        'step2',
                        'timings',
                        obj,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OperationalTimings;
