// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from 'react';
import { Card } from '@material-ui/core';
import './../MainForm.scss';
import ServicesNearBy from './../ServicesNearBy';
import ParkingFacilities from './../ParkingFacilities';
import error from '../../../../../Assets/Images/error.png';

//@component
//AddParkingStepThree
function AddParkingStepThree(props) {
  props.updateRootState(2, 3);
  return (
    <div className="AddParkingStepThree">
      <Card>
        <div className="AddParkingStepThree__wrapper">
          <div className="AddParkingStepThree__wrapper__heading">
            <p className="AddParkingStepThree__wrapper__heading__title">
              Parking Facilities & Services Near by
              {props.search !== '' ? (
                <span className="notedit">
                  <img src={error} alt="error" />
                  This Information change will be subjected to
                  approval
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="AddParkingStepThree__wrapper__container">
            <div className="AddParkingStepThree__wrapper__container__leftside">
              <ParkingFacilities
                data={props.data}
                updateRootState={props.updateRootState}
                updateChildrenState={props.updateChildrenState}
              />
            </div>
            <div className="AddParkingStepThree__wrapper__container__rightside">
              <ServicesNearBy
                data={props.data}
                updateRootState={props.updateRootState}
                updateChildrenState={props.updateChildrenState}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default AddParkingStepThree;
