// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
/* eslint-disable no-useless-escape */
import React, { useState, useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { updateBreadcrumbs, updateToasterConfig } from "../../../redux/modules/app/actions";
import "./style.scss";
import httpRequest from '../../../Utils/httpRequest';
import { getCookie } from "../../../Utils/cookies";
import Loader from '../../../Components/Preloader/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';


//@component
//Create Security User
function CreateUser(props) {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ name,setName ] = useState("");
  const [ nameError,setNameError ] = useState("");
  const [ emailId,setEmailId ] = useState("");
  const [ emailError,setEmailError ] = useState("");
  const [ dialogOpen,setDialogOpen ] = useState(false);
  const [ parkingSpace,setParkingSpace ] = useState("");
  const [ parkingSpaceError,setParkingSpaceError ] = useState("");
  const [ parkingSpaceList,setParkingSpaceList ] = useState([]); 

  // function for create security api
  const createSecurityAPI = (name,email,parkingSpace) => {
    setIsLoading(true);
    var format = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(format.test(name)){
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(updateToasterConfig({
        show: true,
        message: 'Error! Please remove special characters in designation and proceed.',
        color: 'red'
      }))     
    }else{
      httpRequest.post(
        'createSecurity',
        {
          "designation":name,
          "corpEmailId":email,
          "role":{ 
              "name":"SECURITY_PERSONNEL"
          },
          "parking":[{
            "parkingId": parkingSpace
          }]
        },
        {
          'Content-Type': 'application/json' ,     
        },
        successCallbackCreate,
        errorCallbackCreate,
        {},
      )
    }    
  }
  // function for create security success callback
  const successCallbackCreate = jsonResponse => {
    if(jsonResponse){
      const modifiedName = name.replace(/[^a-zA-Z ]/g, "");
      setIsLoading(false);
      setDialogOpen(false);
      clearHandler();
      props.history.push("/portal/securitylistview")
      props.dispatch(updateToasterConfig({
        show: true,
        message: `User ${modifiedName} having ${emailId} created successfully`,
        color:'green'
      }))
    }
  }
  // function for create security error callback
  const errorCallbackCreate = error => {
    if(error){
      setIsLoading(false);
      setDialogOpen(false);
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))
    }
  }
  //function to clear fields after creating account
  const clearHandler = () => {
    setName("");
    setEmailId("");
  };
  //variable to handle mediaquery and theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //function for dialog confirm button  
  const submitDetailsConfirm = () => {
    setDialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setDialogOpen(false);
  };
  //function for pre-details submit api call
  const submitDetails = () => {
    createSecurityAPI(name,emailId,parkingSpace);
  };
  //functions for name handler
  const nameHandler = event => {
    var inputBox = document.getElementById(event.target.id); 
    var invalidChars = [
      "-","_","+","~","`","!","@","#","$","%","^","&","*","(",")",
      "<",">","?","/","{","}","[","]","|",",",".",":",";","'","",",","\\","\"","="
    ];  
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }    
    });
    inputBox.addEventListener('paste', e => e.preventDefault());
  
    if(event.target.value.length <= 100) {
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      }
      else {
        setNameError("");
        setName(event.target.value);
      }      
    }
    if(event.target.value.length > 100){
      setNameError("Maximum 100 characters allowed");
    }
    if(event.target.value.length < 5){
      if (invalidChars.includes(event.target.value)) {
        event.preventDefault();
        setNameError("");
        setName("");
      }else {
        setNameError("");
        setName(event.target.value);
      }
    }
    if(!event.target.value){
      setNameError("");
    }
  };
  //function for generic email regex validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase());
  }
  //functions for email handler
  const emailHandler = (event) => {
    if (event.target.value.length > 100) {
      setEmailError("Max.100 charecters");
    }
    if(event.target.value.length <= 100) {
      if(validateEmail(event.target.value)) {
        setEmailError("");
        setEmailId(event.target.value);
      }
      if(!validateEmail(event.target.value)){
        setEmailId(event.target.value);
        setEmailError("Enter valid email");
      }
      if(event.target.value.length < 5) {
        setEmailError("Minimum 5 characters allowed")
        setEmailId(event.target.value);
      }
      if(!event.target.value) {
        setEmailError("");
      }      
    }
  };
  // after selecting parking space to store value
  const selectHandler = event => {
    if(event.target.value) {
      setParkingSpace(event.target.value);
      setParkingSpaceError("");
    }
    if(!event.target.value) {
      setParkingSpaceError("Please select one role");
    }
  }
  //api call when component loads first time on page
  const getSelectListHandler = ()=>{
   ( getCookie('role')==='MULTITENANT_ADMIN') ?(
    httpRequest.get(
      'reportParkingListMt',
      {
        'Content-Type': 'application/json'
      },
      successCallbackSelectList,
      errorCallbackSelectList,
      {
  
      },
      ))
   :(
    httpRequest.get(
    'reportParkingListMt',
    {
      'Content-Type': 'application/json'
    },
    successCallbackSelectList,
    errorCallbackSelectList,
    {

    },
    )
   )
}
// success callback for component page load api
const successCallbackSelectList = jsonResponse => {
    setParkingSpaceList(jsonResponse.data._embedded.results)
}
// error callback for component page load api
const errorCallbackSelectList = error => {
    setIsLoading(false)
    if (error.message !== "Internal Server Error, Please try again later") {
    // any error message
    }
  }
  // function for form cancel button
  const formCancelHandler = () => {
    props.history.push("/portal/securitylistview");
  }
  //function call on component render/re-render
  useEffect(() => {    
    props.dispatch(updateBreadcrumbs("securitycreate"));
    getSelectListHandler();
  },[])
  
  return (
    <>
      {isLoading && <Loader />}
      <div className="security-root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Create User</h2>
              </div>
              <form className="form-field" style={{padding:0}}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Designation*</label><br/>
                    <input
                                            
                      className='text-field-item'
                      id='destinationId'
                      type="text"
                      name="accountFName"
                      placeholder="Enter the input field"
                      value={name}
                      onChange={nameHandler}
                    />
                    <p>
                      <span className="error_style">
                        {nameError}
                      </span>
                    </p>
                    <label>Enter Email*</label><br/>
                    <input
                      
                      className='text-field-item'
                      style={{
                        width: "49.5%",
                        paddingLeft: "15px"
                      }}
                      type="email"
                      value={emailId}
                      autoComplete="off"
                      name="contactEmail"
                      placeholder="Enter the input field"
                      onChange={emailHandler}
                      
                    />
                    <p>
                      <span className="error_style">
                        {emailError}
                      </span>
                    </p>
                    <label>Role*</label><br/>
                    {/* <FormControl style={{marginTop: '10px'}}> */}
                      <select 
                        disabled
                        //variant="standard"
                        defaultValue="Select Parking Space"
                        value="SECURITY_PERSONNEL"
                        className="selectInputSecurity"
                      >
                        <option value="SECURITY_PERSONNEL">SECURITY_PERSONNEL</option>                        
                      </select>
                    {/* </FormControl> */}
                    <p></p>
                    <label style={{marginTop:'1%'}}>Assign Parking Space Name*</label><br/>
                    {/* <FormControl style={{marginTop: '10px'}}> */}
                      <select
                        //variant="standard"
                        defaultValue="Select Parking Space"
                        onChange={selectHandler}
                        className="selectInputSecurity"
                        
                      >
                        <option value="">Enter the input field</option>
                        {parkingSpaceList?.map((list,index)=>{
                          return (
                            <option key={index} value={list.parkingId}>{list.name}</option> 
                          )                       
                        })}
                      </select>
                    {/* </FormControl> */}
                </div>
              </div>
              <div className="button-section">
                <button 
                   type="button"                  
                   className="form-button-submit" 
                   onClick={submitDetailsConfirm}
                   disabled={
                    name.length===0 ||
                    nameError.length>0 ||
                    emailId.length===0 ||
                    emailError.length>0 ||
                    parkingSpace.length===0 ||
                    parkingSpaceError.length>0
                   }
                   style={{
                     backgroundColor:
                        name.length===0 ||
                        nameError.length>0 ||
                        emailId.length===0 ||
                        emailError.length>0 ||
                        parkingSpace.length===0 ||
                        parkingSpaceError.length>0 ?
                        "#707070" :
                        undefined
                   }}
                >
                  Create
                </button>
                <button type="button" className="form-button-cancel" onClick={formCancelHandler}>
                  Cancel
                </button>
                
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          PaperProps={{
            style: {
              padding: "1% 2%",
            },
          }}
          onClose={handleClose}
          aria-labelledby="create-security-admin-dialog"
        >
            <DialogTitle
              className="dialog-title"
              id="create admin dialog-header"
              style={{paddingBottom:"0px",paddingTop:"2px",paddingLeft:"2px",paddingRight:"2px"}}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <span className="dialog-create-user-text">Create User</span>
                </Grid>
                {/* <Grid item>
                  <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                </Grid> */}
              </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent style={{paddingLeft:"0px",paddingRight:"0px"}} className="dialog-content">
              <p className="dialog-content-text">Do you want to create this User?</p>
            </DialogContent>
            <DialogActions className="dialog-actions">
              <button type="button" onClick={submitDetails} className="confirm-button" >
                Confirm
              </button>
              <button type="button" onClick={handleClose} className="cancel-button" >
                Cancel
              </button>
            </DialogActions>
      </Dialog>
    </>
  )
}
// //redux dispatch method
// const mapDispatchToProps = (dispatch) => ({
//   updateBreadcrumbs,
//   updateToasterConfig,
//   dispatch,
// });
//method to connect react with redux
export default withRouter(
  connect(() => {
    return {};
  })(CreateUser)
);