// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component, Fragment } from "react";
import ParkingSpaceListView from "./../ParkingSpace/ParkingSpaceListView";
import ParkingSpaceMapView from "./../ParkingSpace/ParkingSpaceMapView";
import ParkingSpace from "./../ParkingSpace/ParkingSpace";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import "./../ParkingSpace/ParkingSpace/style.scss";
import "./style.scss";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import httpRequest from "../../../../Utils/httpRequest";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
// import listviewUseStyles from "./style";
import { connect } from "react-redux";
import {
  updateBreadcrumbs,
  updateFilterData,
  updateAppliedFilterData,
  updateSidebar,
  updateBubble,
} from "../../../../redux/modules/app/actions";
import cardviewIcon from "../../../../Assets/Images/card view deactive.png";
import listviewIcon from "../../../../Assets/Images/list view deactive.png";
import mapviewIcon from "../../../../Assets/Images/map view deactive.png";
import cardviewIconActive from "../../../../Assets/Images/card view active.png";
import listviewIconActive from "../../../../Assets/Images/list view active.png";
import mapviewIconActive from "../../../../Assets/Images/map view active.png";
import live from "../../../../Assets/Images/live.png";
import outside from "../../../../Assets/Images/outside.png";
import offline from "../../../../Assets/Images/offline.png";
import Filtericon from "./../../../../Assets/Images/funnel-hz.png";
import Clickedfilter from "./../../../../Assets/Images/Path 1482.png";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "./../../../../Components/Preloader";
import { getCookie } from "../../../../Utils/cookies";
import NoParking from "./../ParkingSpace/NoParking";
import Pagination from "@material-ui/lab/Pagination";

// Global variable declaration
var classData = "";

//@component
//Parking Overview
class ParkingOverview extends Component {
  //React state
  state = {
    card: true,
    list: false,
    map: false,
    parkingList: [],
    selectedParkingId: null,
    searchKey: "",
    showDiv: false,
    filter: [],
    searchPs: null,
    isLoading: false,
    filterActive: false,
    postsPerPage: 9,
    page: {},
    pageNumber: 0,
    currentPage: 1,
    admin: false,
    tenant: false,
    path: window.location.pathname,
  };
  // function for component mount
  componentDidMount() {
    this.props.updateSidebar(1);
    this.props.updateBubble(0);
    this.setState({
      isLoading: !this.state.isLoading,
      path: window.location.pathname,
    });
    this.getParkingList(this.state.pageNumber, this.state.postsPerPage);
    this.props.updateBreadcrumbs("parkingManagement");
    if (
      this.props.location.search !== undefined &&
      this.props.location.search !== ""
    ) {
      this.changeHandler("three");
    }
  }
  //function for showSelectedNavCards
  // classes = listviewUseStyles();
  showSelectedNavCards = (e) => {
    this.setState({
      postsPerPage: e.target.value,
      isLoading: !this.state.isLoading,
    });
    this.getParkingList(0, e.target.value);
  };
  //function for getParkingList
  getParkingList = (pageNumber, pageSize) => {
    // console.log("sdasdasdasdasd",this.props.route.key)

    if (
      getCookie("role") === "MULTITENANT_ADMIN" &&
      this.state.path === "/portal/parkingmanagment"
    ) {
      this.setState(
        {
          admin: true,
          tenant: false,
        },
        () => {
          httpRequest.get(
            "parkingList",
            {},
            this.parkingListSuccess,
            this.errorCallback,
            {
              userId: getCookie("userId"),
              page: pageNumber,
              size: pageSize,
              admin: this.state.admin,
              tenant: this.state.tenant,
            }
          );
        }
      );
    } else if (
      getCookie("role") === "MULTITENANT_ADMIN" &&
      this.state.path === "/portal/tenantparkingmanagment"
    ) {
      this.setState(
        {
          admin: false,
          tenant: true,
        },
        () => {
          httpRequest.get(
            "parkingList",
            {},
            this.parkingListSuccess,
            this.errorCallback,
            {
              userId: getCookie("userId"),
              page: pageNumber,
              size: pageSize,
              admin: this.state.admin,
              tenant: this.state.tenant,
            }
          );
        }
      );
    } else {
      this.setState(
        {
          admin: false,
          tenant: false,
        },
        () => {
          httpRequest.get(
            "parkingList",
            {},
            this.parkingListSuccess,
            this.errorCallback,
            {
              userId: getCookie("userId"),
              page: pageNumber,
              size: pageSize,
              admin: this.state.admin,
              tenant: this.state.tenant,
            }
          );
        }
      );
    }
  };
  //function for parkingListSuccess
  parkingListSuccess = (jsonResult) => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
    this.setState({
      parkingList: jsonResult.data._embedded.results,
      page: jsonResult.data.page,
    });
    this.props.updateFilterData(jsonResult.data._embedded.results || []);
  };
  //function for error call
  errorCallback = () => {};
  //Server pagination functions
  paginate = (e, pageNum) => {
    this.setState(
      {
        pageNumber: pageNum - 1,
        currentPage: pageNum,
        isLoading: !this.state.isLoading,
      },
      () => {
        this.getParkingList(this.state.pageNumber, this.state.postsPerPage);
      }
    );
  };
  //function for filter
  filter = () => {
    this.setState({
      showDiv: !this.state.showDiv,
    });
  };
  //function for applyFilter
  applyFilter = (status) => {
    let statusData = [...this.state.filter];
    let index = statusData.indexOf(status);
    index > -1 ? statusData.splice(index, 1) : statusData.push(status);
    this.setState({
      filter: [...statusData],
    });
  };
  //function for submitFilter
  submitFilter = () => {
    this.setState({
      filterActive: true,
    });
    const { updateAppliedFilterData, filterData } = this.props;
    const { filter } = this.state;
    if (!filter.length) {
      updateAppliedFilterData([...filterData]);
      this.setState({
        parkingList: [...filterData],
      });
    } else {
      let result = [];
      for (let i = 0; i < filter.length; i++) {
        let data = filterData.filter((item) => {
          return item.status.name.toUpperCase() === filter[i].toUpperCase();
        });
        result = [...result, ...data];
      }
      this.props.updateAppliedFilterData([...result]);
      this.setState({
        parkingList: [...result],
      });
    }
    this.setState({
      showDiv: !this.state.showDiv,
    });
  };
  //function for changeHandler
  changeHandler = (id) => {
    if (id === "one") {
      this.setState({
        card: true,
        list: false,
        map: false,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }

    if (id === "two") {
      this.setState({
        card: false,
        map: false,
        list: true,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }

    if (id === "three") {
      this.setState({
        card: false,
        list: false,
        map: true,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }
  };
  //function for  handleChange
  handleChange = (evt) => {
    this.setState({
      searchKey: evt.target.value,
    });
  };
  //function for handleSearchKeyChange
  handleSearchKeyChange = (e) => {
    var inputBox = document.getElementById(e.target.id);
    var invalidChars = [
      "-",
      "_",
      "+",
      "~",
      "`",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "<",
      ">",
      "?",
      "/",
      "{",
      "}",
      "[",
      "]",
      "|",
      ",",
      ".",
      ":",
      ";",
      "'",
      "",
      ",",
      "\\",
      '"',
      "=",
    ];
    inputBox.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
    inputBox.addEventListener("paste", (e) => e.preventDefault());
    if (e.target.value.length <= 30) {
      if (invalidChars.includes(e.target.value)) {
        const { filterData } = this.props;
        e.preventDefault();
        this.setState({
          searchPs: "",
          parkingList: [...filterData],
        });
        classData = "";
      } else {
        classData = e.target.value;
        this.setState({ searchPs: e.target.value });
        const { filterData } = this.props;
        if (!e.target.value) {
          this.setState({
            parkingList: [...filterData],
          });
        } else {
          let result = this.state.parkingList.filter((item) => {
            return item.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          });
          this.setState({
            parkingList: [...result],
          });
        }
      }
    }
  };
  //function for clearSerach
  clearSerach = () => {
    const { filterData } = this.props;
    classData = "";
    this.setState({ searchPs: "" });
    this.setState({
      parkingList: [...filterData],
    });
  };
  render() {
    let { card, list, map, filter } = this.state;
    this.state.parkingList.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    //function for print
    const print = () => {
      if (card === true) {
        return (
          <ParkingSpace
            page={this.state.page}
            cardData={this.state.parkingList}
            filterStatus={this.state.filter}
            parentCallback={this.handleCallback}
          />
        );
      }
      if (list === true) {
        return (
          <ParkingSpaceListView
            page={this.state.page}
            cardData={this.state.parkingList}
            filterStatus={this.state.filter}
            parentCallback={this.handleCallback}
          />
        );
      }
      if (map === true) {
        return <ParkingSpaceMapView cardData={this.state.parkingList} />;
      }
    };
    return (
      <Fragment>
        <div className="ParkingOverview-navbar">
          <div className="ParkingOverview-navbar__wrapper">
            <div className="ParkingOverview-navbar__wrapper_nav">
              <div
                className="ParkingOverview-navbar__wrapper_nav_left"
                style={{ visibility: "hidden" }}
              >
                <div className="ParkingOverview-navbar__wrapper_nav_left_searchicon sIconC">
                  {classData === "" && <SearchIcon />}
                </div>
                <div className="ParkingOverview-navbar__wrapper_nav_left_search">
                  <input
                    id="searchbox"
                    placeholder="Search Parking Space"
                    value={this.state.searchPs}
                    onChange={this.handleSearchKeyChange.bind(this)}
                  />
                  <p
                    className={classData === "" ? "editcross" : "input__cross"}
                    onClick={this.clearSerach}
                  >
                    <ClearIcon />
                  </p>
                </div>
              </div>
              {!this.state.map && (
                <div className="dropdownNumber" style={{ display: "none" }}>
                  <label className="rowsLabel">rows per page</label>
                  <select
                    name="selectNavcards"
                    id="selectNavcards"
                    className="selectNavcards"
                    onChange={this.showSelectedNavCards}
                  >
                    {this.state.card && (
                      <>
                        <option value={9}>9</option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 9
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 9}
                          value={18}
                        >
                          18
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 18
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 18}
                          value={27}
                        >
                          27
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 27
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 27}
                          value={36}
                        >
                          36
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 36
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 36}
                          value={45}
                        >
                          45
                        </option>
                      </>
                    )}
                    {this.state.list && (
                      <>
                        <option value={10}>10</option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 10
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 10}
                          value={20}
                        >
                          20
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 20
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 20}
                          value={30}
                        >
                          30
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 30
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 30}
                          value={40}
                        >
                          40
                        </option>
                        <option
                          style={{
                            backgroundColor:
                              this.state.page.totalElements < 40
                                ? "#D3D3D3"
                                : undefined,
                          }}
                          disabled={this.state.page.totalElements < 40}
                          value={50}
                        >
                          50
                        </option>
                      </>
                    )}
                  </select>
                </div>
              )}

              <div className="ParkingOverview-navbar__wrapper_nav_right">
                {(getCookie("role") === "MULTITENANT_ADMIN" ||
                  getCookie("role") === "CLOSED_GROUP_PARKING_PROVIDER" ||
                  getCookie("role") === "FACILITY_ADMIN" ||
                  getCookie("role") === "RESIDENTIAL_GROUP_PARKING_PROVIDER" ||
                  getCookie("role") === "RESIDENTIAL_FACILITY_ADMIN") &&
                  this.state.path === "/portal/parkingmanagment" && (
                    <Link
                      to={"/portal/addparkingspace"}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className="parkingmodule-navbar__wrapper_nav_addparking"
                      >
                        List A Parking Space &nbsp; +
                      </Button>
                    </Link>
                  )}
                <ButtonGroup
                  className="ParkingOverview-navbar__wrapper_button"
                  color="secondary"
                  aria-label="outlined secondary button group"
                >
                  <Button className="filter-icon">
                    <img
                      src={
                        this.state.filter.length ? Clickedfilter : Filtericon
                      }
                      alt="Filtericon"
                      onClick={() => {
                        this.filter();
                      }}
                    />
                    <div
                      className="filter-box"
                      style={{
                        display: this.state.showDiv ? "block" : "none",
                      }}
                    >
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() => this.applyFilter("DEACTIVATED")}
                          checked={filter.indexOf("DEACTIVATED") > -1}
                        />
                        <label>Deactive</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() => this.applyFilter("REQUEST_REJECTED")}
                          checked={filter.indexOf("REQUEST_REJECTED") > -1}
                        />
                        <label>Request Rejected</label>
                      </li>
                      {getCookie("role") === "MULTITENANT_ADMIN" &&
                        this.state.path === "/portal/parkingmanagment" && (
                          <li>
                            <Checkbox
                              color="default"
                              type="checkbox"
                              id="all"
                              data-target="filter"
                              onChange={() => this.applyFilter("Draft")}
                              checked={filter.indexOf("Draft") > -1}
                            />
                            <label>Draft</label>
                          </li>
                        )}
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() => this.applyFilter("Approved")}
                          checked={filter.indexOf("Approved") > -1}
                        />
                        <label>Approved</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() => this.applyFilter("REQUEST_SUBMITTED")}
                          checked={filter.indexOf("REQUEST_SUBMITTED") > -1}
                        />
                        <label>Request Submitted</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() => this.applyFilter("Active")}
                          checked={filter.indexOf("Active") > -1}
                        />
                        <label>Active</label>
                      </li>
                      <hr />
                      <Button
                        onClick={() => {
                          this.submitFilter();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </Button>
                  <Button
                    id="one"
                    className="ParkingOverview-navbar__wrapper_button_active"
                    value={this.state.card}
                    onClick={() => this.changeHandler("one")}
                  >
                    <img
                      alt="viewIcon"
                      src={this.state.card ? cardviewIconActive : cardviewIcon}
                    />
                  </Button>
                  <Button
                    id="two"
                    value={this.state.list}
                    onClick={() => this.changeHandler("two")}
                  >
                    <img
                      alt="listViewIcon"
                      src={this.state.list ? listviewIconActive : listviewIcon}
                    />
                  </Button>
                  <Button
                    id="three"
                    value={this.state.map}
                    onClick={() => this.changeHandler("three")}
                  >
                    <img
                      alt="mapViewIcon"
                      src={this.state.map ? mapviewIconActive : mapviewIcon}
                    />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Loader />}
        {!this.state.parkingList.length && !this.state.isLoading ? (
          this.state.filterActive ? (
            <h2
              style={{
                textAlign: "center",
                paddingBottom: "180px",
                paddingTop: "100px",
              }}
            >
              No Parkings found
            </h2>
          ) : (
            <NoParking />
          )
        ) : (
          print()
        )}
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="parkingoverview_legend"
          style={{ marginBottom: "70px", width: "80vw" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80vw",
            }}
          >
            <Grid item xs={2} sm={2} md={2} lg={2} className="legend_grid">
              <img alt="live" src={live} />
              <p className="legend">Live and Open</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className="legend_grid">
              <img alt="outside" src={outside} />
              <p className="legend">Outside Operating Hours</p>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className="legend_grid">
              <img alt="offline" src={offline} />
              <p className="legend">Offline/Deactivated</p>
            </Grid>

            <div
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "90px",
                paddingLeft: "15px",
                boxShadow: "0px 3px 6px #00000029 !important",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                count={this.state.page.totalPages}
                page={this.state.currentPage}
                size="small"
                className="root"
                onChange={this.paginate}
                style={{ margin: "0" }}
              />
            </div>
          </div>

          {/* <Grid
            container
            direction="row"
            justify="flex-end"
            style={{ marginBottom: "6%", marginTop: "1.5%" }}
          >
            <Grid item style={{ marginRight: "2%" }}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "90px",
                  paddingLeft: "15px",
                  boxShadow: "0px 3px 6px #00000029 !important",
                }}
              >
                <Grid item>
                  <Pagination
                    count={this.state.page.totalPages}
                    page={this.state.currentPage}
                    className="root"
                    size="small"
                    onChange={this.paginate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Fragment>
    );
  }
}
//function for redux connect
export default connect(
  (state) => ({
    auth: state.app.auth,
    filterData: state.app.filterData,
    appliedFilterData: state.app.appliedFilterData,
  }),
  {
    updateBreadcrumbs,
    updateFilterData,
    updateAppliedFilterData,
    updateSidebar,
    updateBubble,
  }
)(ParkingOverview);
