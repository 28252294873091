// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.

import { makeStyles } from "@material-ui/core/styles";

//@component
//reportUseStyles
const OvernightReportUseStyles = makeStyles(() => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#e46a76 !important",
      color: "#ffffff",
      fontWeight: "900",
    },
    "& .MuiPaginationItem-root": {
      fontFamily: "'Poppins' !important",
      fontSize: "14px",
    },
  },
  paper: {
    boxShadow: "0px 3px 6px #00000029 !important",
  },
  thData: {
    height: "70px",
    fontSize: "12px",
    wordWrap: "break-word",
    fontFamily: "'Poppins' !important",
    fontWeight: "bolder",
    lineHeight: "1.6",
    letterSpacing: "0.5px",
    backgroundColor: "rgba(226, 220, 242, 0.38)",
  },
  tableCell: {
    fontSize: "12px",
    wordBreak: "break-word",
    fontFamily: "'Poppins' !important",
    fontWeight: "500",
    lineHeight: "1.6",
  },
  filter_TopGrid: {
    marginTop: "0.5%",
  },
  applyButtonFilter: {
    width: "60%",
    color: "#fff",
    background: "#e46767",
    borderRadius: "20px",
    padding: "5px 10px",
    fontSize: "10px",
    marginTop: "10px",
    "&:hover": {
      width: "60%",
      color: "#fff",
      background: "#e46767",
      borderRadius: "20px",
      padding: "5px 10px",
      textDecoration: "none",
      fontSize: "0.7rem",
      marginTop: "10px",
    },
    ".MuiButton-outlinedSecondary:hover": {
      border: "none",
    },
  },
  hrStyling: {
    marginTop: "10px",
  },
  liGrid: {
    height: "25px",
  },
  filter_option_label: {
    fontSize: "10px",
    fontWeight: "regular !important",
  },
  filterBox: {
    paddingTop: "10px",
    right: "0.0001%",
    width: "180px",
    listStyle: "none",
    backgroundColor: "#fff",
    position: "absolute",
    top: "130%",
    zIndex: 1,
    color: "#000",
    fontSize: "10px",
    paddingBottom: "20%",
    borderRadius: "15px",
    boxShadow: "0px 3px 6px #00000029 !important",
    ".MuiButton-outlinedSecondary": {
      border: "none",
    },
    "& li": {
      display: "flex",
      marginRight: "10px",
      marginLeft: "10px",
      height: "1%",
    },
    "& label": {
      // paddingTop: "12px"
    },
  },
  filter_image: {
    width: "65%",
  },

  filter_button: {
    position: "relative",
    border: "none",
    borderRadius: "90px",
    outline: "none",
    padding: "5px",
    marginLeft: "20px",
    "&:hover": {
      border: "none",
      outline: "none",
    },
    "& hr": {
      marginLeft: "23.8px",
      marginRight: "23.8px",
    },
  },
  dialog: {
    zIndex: 1301,
  },
  downloadReportButtonGrid: {
    marginTop: "1%",
  },
  warningIcon: {
    verticalAlign: "middle",
  },
  paginationOuterGrid: {
    padding: "2% 0%",
    marginBottom: "4.5%",
  },
  paginationGrid: {
    marginRight: "2%",
  },
  pageTextGrid: {
    marginTop: "2.5%",
    marginRight: "20px",
  },
  toDatePicker: {
    width: "195px",
    marginLeft: "5px",
  },
  fromDatePicker: {
    width: "195px",
    marginLeft: "5px",
  },
  outerRowGrid: {
    display: "flex",
    alignContent: "center",
    width: "98%",
    margin: "1%",
    height: "40px",
  },
  reportExportImage: {
    width: "100%",
    height: "100%",
  },
  selectInput: {
    marginTop: "8%",
    border: 0,
    width: "100%",
  },
  tableGrid: {
    marginTop: "1%",
    width: "96%",
    height: "30%",
    marginLeft: "2%",
    display: "flex",
    flexWrap: "nowrap",
  },
  aboveRowsGrid: {
    height: "40px",
  },
  downloadButton: {
    width: "65%",
    cursor: "Pointer",
    height: "36px",
    padding: "8% 17%",
    background: "white",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    marginLeft: "100%",
    borderRadius: "90px",
  },
  container: {
    display: "flex",
    flexWrap: "nowrap",
  },

  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    width: "100%",
    maxHeight: "64vh",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bolder",
  },
  dateTimeCard: {
    backgroundColor: "rgb(255, 255, 255)",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: 10,
    height: 47,
    width: "80%",
  },
  dateLabel: {
    fontFamily: "'Poppins' !important",
    fontSize: "11px",
    marginRight: "1px",
  },
  selectListCard: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    width: "99%",
    height: "36px",
    textAlign: "left",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    border: "none",
    borderRadius: "90px",
    background: "#ffffff",
    paddingLeft: "4%",
    marginTop: 0,
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "'Poppins' !important",
    borderRight: "10px solid #ffffff",
  },
  datePicker: {
    display: "flex",
    width: "100%",
    height: "36px",
    textAlign: "left",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    border: "none",
    borderRadius: "90px",
    background: "#ffffff",
    paddingLeft: "1.0%",
    marginTop: 0,
    fontSize: "11px",

    fontFamily: "'Poppins' !important",

    alignItems: "center",
  },
  datePickerto: {
    width: "100%",
    border: "none",
    height: "36px",
    display: "flex",
    background: "#ffffff",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    marginTop: "0",
    textAlign: "left",
    alignItems: "center",
    paddingLeft: "1.0%",
    borderRadius: "90px",
    fontSize: "11px",

    fontFamily: "'Poppins' !important",
  },

  verticalIconGrid: {
    marginTop: "1%",
  },
  filter_group_header_grid: {
    height: "25px",
    marginBottom: "10px",
  },
  filter_group_header: {
    fontSize: "0.7rem",
    fontWeight: "600",
    fontFamily: "'Poppins' !important",
    marginRight: "10%",
  },
  generateReportGrid: {
    marginLeft: "0.5%",
  },
  generateReportButton: {
    cursor: "pointer",
    borderRadius: "90px",
    border: "0",
    backgroundColor: "#03A9F3",
    color: "#fff",
    height: "38px",
    width: "80%",
    fontFamily: "'Poppins' !important",
    fontSize: "12px",
    fontWeight: 600,
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    letterSpacing: "0.8px",
    marginLeft: "15%",
  },
  paginationStyle: {
    backgroundColor: "white",
    width: "100%",
    padding: "10px",
    borderRadius: "90px",
    paddingLeft: "15px",
    boxShadow: "0px 3px 6px #00000029 !important",
    fontSize: "10px",
    //eslint-disable-next-line
    fontFamily: "'Poppins' !important",
    height: "3rem",
    alignContent: "center",
  },
  tableCellNoSelectedData: {
    fontFamily: "'Poppins' !important",
    fontWeight: "500",
    fontSize: "10px",
  },
  tableCellNoResponseData: {
    fontFamily: "'Poppins' !important",
    fontWeight: "500",
    fontSize: "10px",
  },
  noDataTableBody: {
    //marginBottom: "50px"
  },
  noList: {
    width: "190%",
    fontSize: "12px",
    marginTop: "140%",
    fontFamily: "'Poppins'",
    marginLeft: "400%",
    wordBreak: "break-word",
    textAlign: "center",
    fontWeight: "500",
  },
  noResponseData: {
    fontFamily: "Poppins",
    fontSize: "11px",
    marginTop: "110%",
    marginLeft: "300%",
    width: "370%",
  },
}));

export default OvernightReportUseStyles;
