import React from "react";
import "./style.scss";
const Alert = () => {
  const red = (
    <svg
      width="1.45vw"
      height="1.32vw"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9515 17.4998H2.04818C1.49941 17.4998 1.01091 17.2144 0.74145 16.7363C0.472 16.2583 0.48077 15.6926 0.76493 15.2231L8.71659 2.08559C8.99072 1.63269 9.47044 1.3623 9.99984 1.3623C10.5292 1.3623 11.009 1.63269 11.2831 2.08559L19.2347 15.2231C19.5189 15.6926 19.5277 16.2583 19.2582 16.7363C18.9888 17.2144 18.5003 17.4998 17.9515 17.4998Z"
        fill="#FAE0E3"
      />
      <path
        d="M9.99994 1.8623C9.83047 1.8623 9.40786 1.90929 9.14444 2.3445L1.19278 15.482C0.91973 15.9332 1.09087 16.3378 1.17713 16.4908C1.26339 16.6439 1.52094 16.9998 2.04828 16.9998H17.9516C18.4789 16.9998 18.7365 16.6439 18.8227 16.4908C18.909 16.3378 19.0801 15.9332 18.8071 15.482L10.8554 2.3445C10.592 1.90929 10.1694 1.8623 9.99994 1.8623ZM9.99994 0.862305C10.6609 0.862305 11.3218 1.18377 11.7109 1.8267L19.6626 14.9642C20.4694 16.2971 19.5096 17.9998 17.9516 17.9998H2.04828C0.490239 17.9998 -0.46948 16.2971 0.337279 14.9642L8.28894 1.8267C8.67808 1.18377 9.33901 0.862305 9.99994 0.862305Z"
        fill="#E04A58"
      />
      <path
        d="M9.625 5.81818L9.52557 13.1364H8.37216L8.27273 5.81818H9.625ZM8.94886 16.0795C8.7036 16.0795 8.49313 15.9917 8.31747 15.8161C8.14181 15.6404 8.05398 15.4299 8.05398 15.1847C8.05398 14.9394 8.14181 14.7289 8.31747 14.5533C8.49313 14.3776 8.7036 14.2898 8.94886 14.2898C9.19413 14.2898 9.40459 14.3776 9.58026 14.5533C9.75592 14.7289 9.84375 14.9394 9.84375 15.1847C9.84375 15.3471 9.80232 15.4962 9.71946 15.6321C9.63991 15.768 9.5322 15.8774 9.39631 15.9602C9.26373 16.0398 9.11458 16.0795 8.94886 16.0795Z"
        fill="#E04A58"
      />
    </svg>
  );

  return (
    <div className="alert-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="status"></div>
        {red}
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "0.65vw" }}
      >
        <p style={{ fontSize: "1.17vw" }}>Koramangala device down </p>
      </div>
    </div>
  );
};

export default Alert;
