// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {  withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import { Container } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import tick from "../../Assets/Dashboard_icons/Legends_icons/live and open status.png";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../redux/modules/app/actions";
import listviewUseStylesBooking from "./style";
import httpRequest from "../../Utils/httpRequest";
import Loader from "../../Components/Preloader";

//material ui overides
const DialogActions = withStyles(() => ({
  root: {
    padding: "8px 0px !important",
    justifyContent: "flex-start",
  },
}))(MuiDialogActions);

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

//@component
//OvernightParkingDetails
function Checkin(props) {
  const classes = listviewUseStylesBooking();

  const [network, setNetwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButtonStatus, setDialogButtonStatus] = useState("");
  const [adminRemarks, setAdminRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [statusLogs, setStatusLogs] = useState([{}]);
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [address, setAddress] = useState("");
  const [dialog, setdialog] = useState(false);
  const [checkoutFlag, setcheckout] = useState(false);
  //api for data filter list as per view complaint Id
  const requestDetails = (id) => {
    setIsLoading(true);
    httpRequest.get(
      "bookingDetails",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallbackList,
      {
        bookingId: id,
      }
    );
  };

  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    setFilterList(jsonResponse.data);
    // setStatus(jsonResponse.data.status.name);
    setStatus(jsonResponse?.data?.bookingStatus?.name);
    httpRequest.get(
      "parkingdetail",
      {
        "Content-Type": "application/json",
      },
      successCallbackStatusLog,
      errorCallbackList,
      {
        parkingId: props.location.state.rowData.parking?.parkingId,
      }
    );
    setIsLoading(false);
    setDisabled(false);
    setNetwork(true);
  };

  //error callback status update
  const errorCallbackList = (error) => {
    if (error) {
      setIsLoading(false);
      setOpen(false);
      //   props.dispatch(
      //     updateToasterConfig({
      //       show: true,
      //       message: "please try again from the list page",
      //       color: "#FF0000",
      //     })
      //   );
      setAdminRemarks("");
    }
  };
  // handling success response for status logs
  const successCallbackStatusLog = (jsonResponse) => {
    setAddress(jsonResponse.data?.address);
    // setStatusLogs(jsonResponse.data.address);
  };

  //success callback for status update api handler
  const successCallbackStatus = (jsonResponse) => {
    if (jsonResponse) {
      setOpen(false);
      setIsLoading(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: "Status Updated Succesfully",
          color: "#67b561",
        })
      );
      setFilterList([]);
      requestDetails(id);
      setDialogButtonStatus("");
      setAdminRemarks("");
    }
  };
  //error callback status update
  const errorCallbackStatus = (error) => {
    if (error) {
      setIsLoading(false);
      setOpen(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "#FF0000",
        })
      );
      setAdminRemarks("");
      setDialogButtonStatus("");
    }
  };
  //status update handler
  const statusChange = (status) => {
    if (status === "approve") {
      setDialogButtonStatus("APPROVED");
      setDialogTitle("Approve Request");
    } else if (status === "reject") {
      setDialogButtonStatus("REJECTED");
      setDialogTitle("Reject Request");
    } else if (status === "approveExt") {
      setDialogButtonStatus("EXTENSION_APPROVED");
      setDialogTitle("Check-In Page");
    } else if (status === "rejectExt") {
      setDialogButtonStatus("EXTENSION_REJECTED");
      setDialogTitle("Cancel Booking Request");
    } else {
      setDialogButtonStatus("COMPLETED");
      setDialogTitle("Complete Request");
    }
    setOpen(true);
  };
  const successCallbackcheckin = () => {
    setIsLoading(false);
    requestDetails(props.location.state.rowData.bookingId);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "check-in successfull",
        color: "#67b561",
      })
    );
  };

  const errorCallbackcheckin = (err) => {
    setIsLoading(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: err.error_description || err.message || err.error,
        color: "#FF0000",
      })
    );
  };
  const updateStatus = () => {
    setIsLoading(true);
    setOpen(false);
    httpRequest.post(
      "Checkin",
      {
        vehicleNo: filterList?.vehicle?.vehicleNumber,
        parkingId: props.location.state.rowData.parking?.parkingId,
        slotNo: adminRemarks,
        bookingId: props.location.state.rowData.bookingId,
      },

      {
        "Content-Type": "application/json",
      },
      successCallbackcheckin,
      errorCallbackcheckin,
      {}
    );
  };
  // function for back button which is in 'hidden' state
  const backHandler = () => {
    props.history.push({
      pathname: "/portal/viewbookings",
      state: {
        // currentPage: props.location.state.currentPage,
        pressed: true,
        // search: props.location.state.search,
      },
    });
  };
  // function for dialog popup close icon
  const dialogCloseIcon = () => {
    setOpen(false);
    setAdminRemarks("");
    setDialogButtonStatus("");
  };

  //adding component to breadcrumb and displaying filtered data as per view complaint id
  useEffect(() => {
    console.log("first", props.location.state.rowData);
    props.dispatch(updateBreadcrumbs("checkin"));
    requestDetails(props.location.state.rowData.bookingId);
    setId(props.location.state.rowData.bookingId);
    //setFilterList(props.location.state.rowData);
  }, []);

  const successCancel = (json) => {
    console.log(json);
    setIsLoading(false);
    requestDetails(props.location.state.rowData.bookingId);
  };
  const errorCancel = (err) => {
    setIsLoading(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: err.error_description || err.message || err.error,
        color: "#FF0000",
      })
    );
  };

  const successCheckout = () => {
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "check-out successfull",
        color: "#67b561",
      })
    );
    requestDetails(props.location.state.rowData.bookingId);
  };

  const errorCheckout = (err) => {
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: err.error_description || err.message || err.error,
        color: "#FF0000",
      })
    );
  };
  const checkout = () => {
    httpRequest.patch(
      "Checkout",
      {
        bookingId: props.location.state.rowData.bookingId,
      },
      {
        "Content-Type": "application/json",
      },
      successCheckout,
      errorCheckout,
      {}
    );
  };
  const cancelBooking = () => {
    setdialog(true);
    //statusChange("rejectExt");
  };
  const handleClose = () => {
    setdialog(false);
  };

  const handleCheckout = () => {
    setcheckout(false);
    checkout();
  };
  const handleCancel = () => {
    setdialog(false);
    setIsLoading(true);

    httpRequest.patch(
      "cancelBooking",
      {},
      {
        "Content-Type": "application/json",
      },
      successCancel,
      errorCancel,
      {
        bookingId: filterList?.bookingId,
      }
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column" className={classes.container}>
        <Card className={classes.OvernightDetailCard}>
          <Grid className={classes.headerText}>
            <label className={classes.label}>Booking Details</label>
          </Grid>
          <Container xs={12} sm={12} md={12} lg={12}>
            <>
              <Grid
                container
                direction="row"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginBottom: "2%",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} sm={9} md={9} lg={9} style={{}}>
                  <div
                    style={{
                      marginLeft: "2.5%",
                      width: "45%",
                      height: "3vw",
                      color: "#0063B2",
                      background: "#ebebeb",
                      textAlign: "center",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "1.0268vw",
                      }}
                    >
                      Booking ID - {filterList?.bookingId}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Grid container style={{ alignItems: "center" }}>
                    <p
                      className={classes.headingRight}
                      style={{ fontSize: "1vw" }}
                    >
                      Status:{" "}
                    </p>
                    <p
                      className={classes.headingRight}
                      style={{
                        color: filterList?.bookingStatus?.color,
                        fontSize: "1vw",
                      }}
                    >
                      {/* {filterList?.bookingStatus?.displayName} */}
                      {status}
                    </p>
                    {(filterList?.bookingStatus?.displayName === "Confirmed" ||
                      filterList?.bookingStatus?.displayName === "Parked") && (
                      <img
                        alt="tick"
                        src={tick}
                        style={{ height: "1vw", width: "1vw" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} style={{}}>
                <div
                  style={{
                    marginLeft: "2.5%",
                    color: "#0063B2",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Parking Space Address
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      width: "60%",
                      fontWeight: "bold",
                      wordBreak: "break-word",
                    }}
                  >
                    {filterList?.parking?.name}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginBottom: "32px",
                      width: "60%",
                      wordBreak: "break-word",
                    }}
                  >
                    {address}
                  </p>
                </div>
              </Grid>

              <Grid
                item
                container
                direction="row"
                style={{ marginBottom: "6%" }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.detailsGrid}
                  xs={12}
                  sm={9}
                  md={9}
                  lg={9}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>Booked By </p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {filterList?.bookedBy
                        ? filterList?.bookedBy?.displayName
                        : " --"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>Booked on</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {isLoading
                        ? "--"
                        : new Date(filterList?.bookedDate).toDateString()}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>Email Id</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {filterList.emailId ? filterList?.emailId : "--"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>From</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {filterList?.startTime
                        ? moment(filterList?.startTime).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "--"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>To</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item>
                    <p className={classes.value}>
                      {filterList?.startTime
                        ? moment(filterList?.endTime).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "--"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>Vehicle Type</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {filterList.vehicle?.vehicleType?.name
                        ? filterList?.vehicle?.vehicleType?.name
                        : "--"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    className={classes.heading}
                  >
                    <p className={classes.subHeaderColumn}>Vehicle Number</p>
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </p>
                  </Grid>
                  <Grid item className={classes.valueAlign}>
                    <p className={classes.value}>
                      {filterList?.vehicle?.vehicleNumber
                        ? filterList?.vehicle?.vehicleNumber
                        : "--"}
                    </p>
                  </Grid>

                  {status === "CANCELLED" && (
                    <>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>Cancelled By</p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.cancelledBy?.displayName
                            ? filterList?.cancelledBy.displayName
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>
                          Cancelled Date and Time
                        </p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.cancelledDate
                            ? moment(filterList?.cancelledDate).format(
                                "MMM D, YYYY h:mm A"
                              )
                            : "--"}
                        </p>
                      </Grid>
                    </>
                  )}
                  {status === "PARKED" && (
                    <>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>
                          Check-In Date and Time
                        </p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.checkInCheckOutDto?.checkInTime
                            ? moment(
                                filterList?.checkInCheckOutDto?.checkInTime
                              ).format("MMM D, YYYY h:mm A")
                            : "--"}
                        </p>
                      </Grid>
                    </>
                  )}
                  {status === "COMPLETED" && (
                    <>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>Check-Out By</p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.checkoutBy
                            ? filterList?.checkoutBy
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>
                          Check-In Date and Time
                        </p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.checkInCheckOutDto?.checkInTime
                            ? moment(
                                filterList?.checkInCheckOutDto?.checkInTime
                              ).format("MMM D, YYYY h:mm A")
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.heading}
                      >
                        <p className={classes.subHeaderColumn}>
                          Check-Out Date and Time
                        </p>
                        <p
                          style={{
                            marginLeft: "auto",
                            fontSize: "12px",
                            fontWeight: "bolder",
                          }}
                        >
                          :
                        </p>
                      </Grid>
                      <Grid item className={classes.valueAlign}>
                        <p className={classes.value}>
                          {filterList?.checkInCheckOutDto?.checkInTime
                            ? moment(
                                filterList?.checkInCheckOutDto?.checkOutTime
                              ).format("MMM D, YYYY h:mm A")
                            : "--"}
                        </p>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ marginTop: "-9%" }}
                >
                  <>
                    {
                      <Grid item className={classes.gridValues}>
                        <button
                          type="button"
                          disabled={
                            status === "CANCELLED" ||
                            status === "PARKED" ||
                            status === "COMPLETED"
                          }
                          className={
                            status === "CANCELLED" ||
                            status === "PARKED" ||
                            status === "CANCELLED" ||
                            status === "COMPLETED"
                              ? classes.statebtnreject
                              : classes.statebtnapprove
                          }
                          // style={{
                          //   backgroundColor:
                          //     disabled ||
                          //     status === "REJECTED" ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "#A2A2A2"
                          //       : "#67B561",
                          //   cursor:
                          //     disabled ||
                          //     status === "REJECTED" ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "default"
                          //       : "pointer",
                          // }}
                          // style={{
                          //   backgroundColor: "rgb(3, 169, 243)",
                          //   color: "white",
                          // }}
                          onClick={() => statusChange("approveExt")}
                        >
                          Check-In
                        </button>
                      </Grid>
                    }

                    {status !== "PARKED" && status !== "COMPLETED" ? (
                      <Grid item className={classes.gridValues}>
                        <button
                          type="button"
                          disabled={
                            status === "CANCELLED" || status === "PARKED"
                          }
                          // id={"approve"}
                          // disabled={
                          //   disabled ||
                          //   status === "APPROVED" ||
                          //   status === "COMPLETED" ||
                          //   status === "CANCELLED" ||
                          //   status === "REJECTED" ||
                          //   status === "EXTENSION_REJECTED" ||
                          //   status === "EXTENSION_APPROVED" ||
                          //   status === "EXTENSION_CANCELLED"
                          // }
                          // className={classes.statebtnreject}
                          className={
                            status === "CANCELLED" ||
                            status === "PARKED" ||
                            status === "COMPLETED"
                              ? classes.statebtnreject
                              : classes.statebtnapprove
                          }
                          // style={{
                          //   backgroundColor:
                          //     disabled ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "REJECTED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "#A2A2A2"
                          //       : "#E46A76",
                          //   cursor:
                          //     disabled ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "REJECTED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "default"
                          //       : "pointer",
                          // }}

                          onClick={cancelBooking}
                        >
                          Cancel Booking
                        </button>
                      </Grid>
                    ) : (
                      <Grid item className={classes.gridValues}>
                        <button
                          type="button"
                          // disabled={
                          //   status === "CANCELLED" || status === "PARKED"
                          // }
                          // id={"approve"}
                          // disabled={
                          //   disabled ||
                          //   status === "APPROVED" ||
                          //   status === "COMPLETED" ||
                          //   status === "CANCELLED" ||
                          //   status === "REJECTED" ||
                          //   status === "EXTENSION_REJECTED" ||
                          //   status === "EXTENSION_APPROVED" ||
                          //   status === "EXTENSION_CANCELLED"
                          // }
                          // className={classes.statebtnreject}
                          className={
                            // status === "CANCELLED" || status === "PARKED"
                            //   ? classes.statebtnreject
                            //   : classes.statebtnapprove
                            status === "COMPLETED"
                              ? classes.statebtnreject
                              : classes.statebtnapprove
                          }
                          // style={{
                          //   backgroundColor:
                          //     disabled ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "REJECTED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "#A2A2A2"
                          //       : "#E46A76",
                          //   cursor:
                          //     disabled ||
                          //     status === "APPROVED" ||
                          //     status === "COMPLETED" ||
                          //     status === "CANCELLED" ||
                          //     status === "REJECTED" ||
                          //     status === "EXTENSION_REJECTED" ||
                          //     status === "EXTENSION_APPROVED" ||
                          //     status === "EXTENSION_CANCELLED"
                          //       ? "default"
                          //       : "pointer",
                          // }}
                          disabled={status === "COMPLETED"}
                          onClick={() => {
                            setcheckout(true);
                          }}
                        >
                          Check-Out
                        </button>
                      </Grid>
                    )}
                  </>

                  <Grid item className={classes.gridValues}>
                    <button
                      type="button"
                      //   disabled={
                      //     disabled ||
                      //     status === "PENDING" ||
                      //     status === "REJECTED" ||
                      //     status === "CANCELLED" ||
                      //     status === "COMPLETED"
                      //   }
                      className={classes.statebtnapprove}
                      onClick={() =>
                        props.history.push({
                          pathname: "/portal/viewbookings",
                          state: {
                            // currentPage: props.location.state.currentPage,
                            pressed: true,
                            // search: props.location.state.search,
                          },
                        })
                      }
                      //   style={{
                      //     backgroundColor:
                      //       disabled ||
                      //       status === "PENDING" ||
                      //       status === "REJECTED" ||
                      //       status === "CANCELLED" ||
                      //       status === "COMPLETED"
                      //         ? "#A2A2A2"
                      //         : "#67b561",
                      //     cursor:
                      //       disabled ||
                      //       status === "PENDING" ||
                      //       status === "REJECTED" ||
                      //       status === "CANCELLED" ||
                      //       status === "COMPLETED"
                      //         ? "default"
                      //         : "pointer",
                      //   }}
                    >
                      View Bookings
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Container>
        </Card>
        <Grid item className={classes.backButtonGrid}>
          {/* <button
            type="button"
            className={classes.backButton}
            onClick={backHandler}
          >
            Back
          </button> */}
        </Grid>
      </Grid>

      <Dialog
        //fullScreen={fullScreen}
        open={dialog}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={handleClose}
        aria-labelledby="create-security-admin-dialog"
      >
        {/* {loading && <Loader />} */}
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">
                Booking Cancellation
              </span>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginBottom: "10px",
            marginTop: "15px",
          }}
          className="dialog-content"
        >
          <p className="dialog-content-text">
            Are you sure you want to cancel the booking?
          </p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button
            type="button"
            id="confirmSubmit"
            //onClick={handleBookpopup}
            data-testid="confirm-button"
            className="confirm-button"
            onClick={handleCancel}
          >
            Confirm
          </button>
          <button
            type="button"
            id="cancelPopup"
            onClick={handleClose}
            data-testid="cancel-popup"
            className="cancel-button"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      {/* /////////////////////checkout///////////////////////////// */}

      <Dialog
        //fullScreen={fullScreen}
        open={checkoutFlag}
        PaperProps={{
          style: {
            padding: "1% 2%",
          },
        }}
        onClose={() => {
          setcheckout(false);
        }}
        aria-labelledby="create-security-admin-dialog"
      >
        {/* {loading && <Loader />} */}
        <DialogTitle
          className="dialog-title"
          id="create admin dialog-header"
          style={{
            paddingBottom: "0px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <span className="dialog-create-user-text">check-out</span>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginBottom: "10px",
            marginTop: "20px",
          }}
          className="dialog-content"
        >
          <p className="dialog-content-text">Do you want to check-out?</p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <button
            type="button"
            id="confirmSubmit"
            //onClick={handleBookpopup}
            data-testid="confirm-button"
            className="confirm-button"
            onClick={handleCheckout}
          >
            Confirm
          </button>
          <button
            type="button"
            id="cancelPopup"
            onClick={() => {
              setcheckout(false);
            }}
            data-testid="cancel-popup"
            className="cancel-button"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        //BackdropProps={{ style: { backgroundColor: '#DEDEDE' } }}
        PaperProps={{
          style: { padding: "2.5% 3.25%", width: "25%", marginLeft: "25%" },
        }}
        style={{ zIndex: 0 }}
      >
        {isLoading && <Loader />}

        <MuiDialogTitle style={{ padding: 0 }}>
          <Grid item container direction="row" justify="space-between">
            <Grid item className={classes.dialogTitle}>
              {/* {dialogTitle} */}
              Check-In
            </Grid>
            <Grid item style={{ visibility: "hidden" }}>
              <CloseIcon className={classes.dialogCloseIcon} />
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Grid item>
            <Divider />
            <Grid item className={classes.dialogHeading}>
              Slot Number
            </Grid>
            <Grid container direction="row">
              <Grid item className={classes.textareaGrid}>
                <input
                  className={classes.dialogTextArea}
                  value={adminRemarks}
                  onChange={(e) => setAdminRemarks(e.target.value)}
                ></input>
              </Grid>
            </Grid>
          </Grid>
          {/* {adminRemarks.length > 200 && (
            <p className={classes.textareaError}>
              Enter atleast 2 and maximum 200 characters
            </p>
          )} */}
          {/* {adminRemarks.length === 1 && (
            <p className={classes.textareaError}>
              Enter atleast 2 and maximum 200 characters
            </p>
          )} */}
        </DialogContent>
        <DialogActions>
          <Grid item>
            <Grid
              container
              direction="row"
              //justify="flex-start"
              className="dialogActionGrid"
            >
              <Grid item>
                <button
                  type="submit"
                  className={classes.dialogSubmit}
                  style={{
                    backgroundColor:
                      adminRemarks.length < 1 || adminRemarks.length > 200
                        ? "#A2A2A2"
                        : undefined,
                  }}
                  onClick={updateStatus}
                  disabled={adminRemarks === ""}
                >
                  Confirm
                </button>
              </Grid>
              <Grid item>
                <button
                  type="submit"
                  onClick={dialogCloseIcon}
                  className={classes.dialogCancel}
                >
                  Close
                </button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(connect(null, mapDispatchToProps)(Checkin));
