// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import { makeStyles } from '@material-ui/core/styles'

const editUseStyles = makeStyles(() => ({


  contactBlock: {
    marginLeft: '10%',
    marginTop: '4%'
  },

  label: {
    fontWeight: '700',
    fontSize: '14px',
    color: '#102E35',


  },
  textField: {
    border: '1px solid #102E35',
    borderRadius: '4px',
    marginTop: '1%',
    marginBottom: '1%',
    paddingLeft: '10px',
    height: '57px',
    width: '95%',
    fontSize: '14px',
    fontFamily: '\'Poppins\' !important',
    '&:disabled': {

      background: "#eee !important",

    },

  },
  textFieldSelect: {
    border: '1px solid #102E35',
    borderRadius: '4px',
    marginTop: '1%',
    paddingLeft: '10px',
    height: '57px',
    width: '97%',
    fontSize: '14px',
    fontFamily: '\'Poppins\' !important'

  },
  textFieldDisabled: {
    border: '1px solid #102E35',
    borderRadius: '4px',
    marginTop: '1%',
    paddingLeft: '10px',
    height: '55px',
    width: '95%',
    fontSize: '14px',
    backgroundColor: '#eee !important',
    fontFamily: '\'Poppins\' !important'

  },
  headerText: {
    padding: '1rem',
    fontSize: '16px',
    paddingLeft: '5%',
    backgroundColor: '#F4F2FA',
    fontWeight: '700',
    marginBottom: '2%'
  },
  inputArea: {
    padding: 0
  },

  filter_option_label: {
    fontSize: "10px",
    fontWeight: 'regular !important',
  },

  inputActionArea: {
    margin: '4% 8%',
    marginBottom: '10%'
  },

  validationBlock: {
    height: '20px',
    color: 'red',
    marginBottom: '2%'
  },
  updateButton: {
    fontFamily: '\'Poppins\' !important',
    margin: '12px',
    borderRadius: '90px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    minWidth: '64px',
    boxSizing: 'border-box',
    lineHeight: '1.75',
    letterSpacing: '1px',
    padding: '9px 27px',
    textDecoration: 'none',
    verticalAlign: 'middle',
    backgroundColor: '#03A9F3',

  },

  cancelButton: {
    fontFamily: '\'Poppins\' !important',
    margin: '12px',
    borderRadius: '90px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#707070 !important',
    minWidth: '64px',
    boxSizing: 'border-box',
    lineHeight: '1.75',
    letterSpacing: '1px',
    padding: '9px 27px',
    textDecoration: 'none',
    verticalAlign: 'middle'
  },
  dialog: {
    zIndex: 0
  },
  dialogActionGrid: {
    marginTop: "0.5em",
    paddingBottom: '0',
  },
  dialogSubHeader: {
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '0.5px'
  },

  dialogSubTitle: {
    fontFamily: '\'Poppins\' !important',
    fontSize: '12px',
    letterSpacing: '1px',
    fontWeight: 'bold',
  },
  dialogCancel: {
    fontFamily: '\'Poppins\' !important',
    borderRadius: '90px',
    cursor: 'pointer',
    border: "none",
    fontSize: "10px",
    fontWeight: "900",
    color: "#fff",
    backgroundColor: "#707070",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    minWidth: "64px",
    boxSizing: "border-box",
    lineHeight: "1.75",
    letterSpacing: "1px",
    padding: "9px 27px",
    textDecoration: "none",
    verticalAlign: "middle",
    marginRight: "20px",
  },
  dialogCancelGrid: {
    textAlignLast: "right"
  },
  dialogHeader: {
    fontFamily: '\'Poppins\' !important',
    fontSize: "14px",
    letterSpacing: "0.5px",
    fontWeight: 900,

  },
  dialogConfirm: {
    fontFamily: '\'Poppins\' !important',
    borderRadius: '90px',
    cursor: 'pointer',
    border: "none",
    fontSize: "10px",
    fontWeight: "900",
    color: "#fff",
    backgroundColor: "#03A9F3",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    minWidth: "64px",
    boxSizing: "border-box",
    lineHeight: "1.75",
    letterSpacing: "1px",
    padding: "9px 27px",
    textDecoration: "none",
    verticalAlign: "middle"
  },

}));

export default editUseStyles;