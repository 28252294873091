// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateBreadcrumbs,
  updateToasterConfig,
  updateSidebar,
  updateBubble,
} from "../../../redux/modules/app/actions";
import { Button } from "@material-ui/core";
import editUseStyles from "./style";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import httpRequest from "../../../Utils/httpRequest";
import Typography from "@material-ui/core/Typography";
import EditIcon from "../../../Assets/icons/editIcon.png";
import deleteIcon from "../../../Assets/icons/deleteIcon.png";
import Divider from "@material-ui/core/Divider";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import Loader from "../../../Components/Preloader/index";

//@component
//View
function AlertNotifications(props) {
  const classes = editUseStyles();
  const [totalPages, setTotalPages] = useState(0);
  const [alertsList, setAlertsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [tableDataNotFound, setTableDataNotFound] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [parkingName, setParkingName] = useState("");

  const DialogActions = withStyles(() => ({
    root: {
      padding: "8px 0px !important",
    },
  }))(MuiDialogActions);

  const alertNotificationHeaderCell = [
    { id: "sno", label: "S.no", width: "8%" },
    { id: "parkingSpaceDetails", label: "Parking Space Details", width: "40%" },
    { id: "contactNumber", label: "Contact Number", width: "17%" },
    { id: "emailId", label: "Email Id", width: "25%" },
    { id: "actions", label: "Actions", width: "10%" },
  ];

  useEffect(() => {
    setIsLoading(true);
    props.dispatch(updateBreadcrumbs("alertNotifications"));
    props.dispatch(updateSidebar(4));
    props.dispatch(updateBubble(7));
    getAlertNotifications(pageNumber);
  }, []);

  const getAlertNotifications = (page) => {
    //setIsLoading(true)
    httpRequest.get(
      "getAlertNotificationDetails",
      {},
      alertListSuccess,
      errorCallback,
      {
        page: page,
        size: 10,
      }
    );
  };

  const alertListSuccess = (jsonResponse) => {
    setAlertsList(jsonResponse.data._embedded.results);
    setTableDataNotFound("");
    setPageNumber(jsonResponse.data.page.number);
    setTotalPages(jsonResponse.data.page.totalPages);
    setIsLoading(false);
  };

  const errorCallback = (error) => {
    // console.log("dsadasdasdasd",error)
    setTableDataNotFound("No Data Found");
    setAlertsList([]);
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error || "No Data Found",
          color: "red",
        })
      );
    }
  };

  // when pagination button is clicked here page number is set and api callback is triggered
  const handleChange = (event, value) => {
    let currpage;
    currpage = value - 1;
    setPageNumber(currpage);
    pageHandler(currpage);
  };

  // callback api call when pagination button is clicked
  const pageHandler = (number) => {
    console.log("page number", number);
    getAlertNotifications(number);
  };
  const deleteHandler = (id, parkingSpaceDetails) => {
    const parkingSpaceName = parkingSpaceDetails.substring(
      0,
      parkingSpaceDetails.indexOf("(")
    );
    setParkingName(parkingSpaceName);
    setDeleteId(id);
    setDialogOpen(true);
  };

  const submitDetails = () => {
    setIsLoading(true);
    setDialogOpen(false);
    deleteAlertNotification();
  };

  const deleteAlertNotification = () => {
    httpRequest.deleteData(
      "deleteAlertNotification",
      {
        errorNotUUID: deleteId,
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackSave,
      errorCallbackSave,
      {}
    );
  };

  const successCallbackSave = () => {
    getAlertNotifications(pageNumber);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: "Alert Notification Contacts Deleted succesfully.",
        color: "green",
      })
    );

    setDialogOpen(false);
    setIsLoading(true);
    setDeleteId("");
  };

  const errorCallbackSave = (error) => {
    setDialogOpen(false);
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: error.error === "Not Found" ? "API Not Found" : error.message,
        color: "red",
      })
    );
    setDialogOpen(false);
    setIsLoading(false);
    setDeleteId("");
  };
  return (
    <>
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={() => {
          props.history.push({
            pathname: "/portal/addAlerttNotifications",
          });
        }}
      >
        Add Notification Contacts+
      </Button>

      {isLoading && <Loader />}
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.tableGrid}
      >
        <TableContainer className={classes.paper} component={Paper}>
          <Table className={classes.table} aria-label="alert notifications">
            <TableHead>
              <TableRow>
                {alertNotificationHeaderCell.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.id === "sno" ? "center" : "left"}
                    width={headCell.width}
                    className={classes.thData}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {alertsList && (
              <TableBody>
                {alertsList.length !== 0 ? (
                  alertsList.map((row, index) => {
                    let no = index;
                    function formatNo(no) {
                      return no < 10
                        ? pageNumber + "" + no
                        : (pageNumber + 1) * no;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell align="center" className={classes.tableCell}>
                          {formatNo(no + 1)}
                        </TableCell>

                        <TableCell align="left" className={classes.tableCell}>
                          {
                            <>
                              <Grid>
                                <span>
                                  {row?.parkingSpaceDetails
                                    ? row?.parkingSpaceDetails
                                    : "--"}
                                </span>
                              </Grid>
                              <br />
                            </>
                          }
                        </TableCell>

                        <TableCell align="left" className={classes.tableCell}>
                          <span>
                            {row.contactNumber
                              ? row.contactNumber.map((item) => {
                                  return (
                                    <>
                                      <Grid>{item}</Grid>
                                      <br />
                                    </>
                                  );
                                })
                              : "--"}
                          </span>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <span>
                            {row.emailId
                              ? row.emailId.map((item) => {
                                  return (
                                    <>
                                      <Grid>{item}</Grid>
                                      <br />
                                    </>
                                  );
                                })
                              : "--"}
                          </span>
                        </TableCell>
                        <TableCell className={classes.viewTableCell}>
                          <img
                            alt="edit_icon"
                            src={EditIcon}
                            className={classes.edit_icon}
                            onClick={() => {
                              props.history.push({
                                pathname: "/portal/editAlerttNotifications",
                                state: {
                                  contactNumbers: row?.contactNumber,
                                  emails: row?.emailId,
                                  parkingSpaceDetails: row?.parkingSpaceDetails,
                                  parkingId: row?.parkingId,
                                  errorNotUUID: row?.errorNotUUID,
                                },
                              });
                            }}
                          />

                          <img
                            alt="delete_icon"
                            id={`del${index}`}
                            data-testid={`fm-del-${index}`}
                            onClick={() =>
                              deleteHandler(
                                row?.errorNotUUID,
                                row?.parkingSpaceDetails
                              )
                            }
                            src={deleteIcon}
                            className={classes.delete_icon}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow className={classes.tableRowNoImage}>
                    <TableCell
                      className={classes.tableCell}
                      style={{ paddingBottom: "100px", paddingTop: "50px" }}
                    >
                      <Grid
                        container
                        direction="column"
                        className={classes.gridContainerNoImage}
                        alignItems="center"
                        style={{ width: "80vw" }}
                      >
                        <Grid item className={classes.typographyGridNoImage}>
                          <Typography data-testId="loading" variant="body1">
                            {tableDataNotFound}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        className={classes.paginationOuterGrid}
      >
        <Grid item className={classes.paginationGrid}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            className={classes.paginationStyle}
          >
            <Grid item>
              <Pagination
                count={totalPages}
                page={pageNumber + 1}
                className={classes.root}
                size="small"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        PaperProps={{
          style: {
            padding: "1.5% 3%",
          },
        }}
        className={classes.dialog}
      >
        <MuiDialogTitle
          style={{
            paddingBottom: "8px",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <span className={classes.dialogHeader}>
                Delete Alert Notification Contacts
              </span>
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <Divider />
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Grid item>
            <Grid container direction="column" justify="center">
              <Grid item>
                <Typography className={classes.dialogSubTitle}>
                  Are you sure You want to delete the Alert Contact Details?
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                xs={12}
                style={{ marginTop: "1%" }}
              >
                <Grid item xs={6} className={classes.dialogSubTitle}>
                  Parking Space Name:
                </Grid>

                <Grid item xs={6} className={classes.dialogSubHeader}>
                  {parkingName}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              className={classes.dialogActionGrid}
            >
              <Grid item className={classes.dialogConfirmGrid}>
                <button
                  type="button"
                  data-testid="confirm-button"
                  className={classes.dialogConfirm}
                  onClick={submitDetails}
                >
                  Delete
                </button>
              </Grid>
              <Grid item className={classes.dialogCancelGrid}>
                <button
                  type="button"
                  onClick={() => setDialogOpen(false)}
                  className={classes.dialogCancel}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  updateBubble,
  updateSidebar,
  dispatch,
});

export default withRouter(
  connect(null, mapDispatchToProps)(AlertNotifications)
);
