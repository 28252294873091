// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Card, CardContent } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import StoreContext from "../../../context/dashboard-context";
import httpRequest from "../../../Utils/httpRequest";
import verified from "../../../Assets/Dashboard_icons/Verified_tag/Group 4958@2x.png";
import live from "../../../Assets/Dashboard_icons/Legends_icons/live and open status.png";
import outside from "../../../Assets/Dashboard_icons/Legends_icons/outside operating hrs status.png";
import offline from "../../../Assets/Images/offline.png";
import red from "../../../Assets/Dashboard_icons/Legends_icons/alert-error-filled.png";
import EYAvaiibility from "../../../Assets/Dashboard_icons/EY/Availability.png";
import EYBooking from "../../../Assets/Dashboard_icons/EY/Booking.png";
import NoShow from "../../../Assets/Dashboard_icons/EY/No-Shows.png";
import relFail from "../../../Assets/Dashboard_icons/EY/Release Failure.png";
import "./ViewContainer.scss";

//@component
//Card View
const EYDashboardOverview = () => {
  const [parkingData, setparkingData] = useState([]);
  const [prevDate, setprevDate] = useState(null);

  const useStyles = makeStyles({
    root: {
      padding: 0,
    },
  });
  //upon succesfully getting dashboard data
  const updateSuccess = (jsonResult) => {
    let parkData = jsonResult.data;
    //  parkData.sort((a) => (a.isDeviceCoupled ? -1 : 1));
    setparkingData(parkData);
    //  updateAnpr();
  };
  const errorCallback = (error) => {
    if (error) {
      // setdashboardError("Could not fetch data... try again!")
    }
  };

  //function for checkOpen
  const checkOpen = () => {
    return live;
  };

  //upon component load or unload
  useEffect(() => {
    httpRequest.get(
      "parkingListDashboardAsc",
      {},
      updateSuccess,
      errorCallback,
      {}
    );
    let date = new Date();
    let prevdate = date.setDate(date.getDate() - 1);
    setprevDate(prevdate);
    // updateme();
    const updatemeInterval = setInterval(() => {
      httpRequest.get(
        "parkingListDashboardAsc",
        {},
        updateSuccess,
        errorCallback,
        {}
      );
    }, 30000);

    return () => {
      clearInterval(updatemeInterval);
    };
  }, []);
  const classes = useStyles();
  return (
    <StoreContext.Consumer>
      {(context) => (
        <ClickAwayListener onClickAway={context.handleClickAway}>
          <Grid
            container
            className="parent"
            onClick={context.clickBlank}
            style={{ textAlign: "-webkit-center" }}
          >
            {parkingData.map((item, index) => (
              <Grid key={index} item xs={12}>
                <Card
                  className="cust-card-ey"
                  onDoubleClick={() => {
                    context.cardClicked(item.parkingId);
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    xs={12}
                    style={{ padding: "1vw 0vw 1vw 1.5vw" }}
                  >
                    <Grid
                      item
                      container
                      xs={10}
                      style={{ alignItems: "center" }}
                    >
                      <label
                        style={{
                          fontSize: "1vw",
                          fontWeight: "bolder",
                          color: "#102E35",
                        }}
                      >
                        {item.name.length > 50
                          ? `${item.name.slice(0, 50)}... `
                          : item.name}
                      </label>
                      <span className="liveTick">
                        {item.status.name === "VERIFIED" ? (
                          <img
                            src={checkOpen(index)}
                            style={{ height: "65%" }}
                            alt="image2"
                          />
                        ) : item.status.name === "REQUEST_SUBMITTED" ? (
                          <img src={outside} alt="image2" />
                        ) : item.status.name === "APPROVED" ? (
                          <img src={checkOpen(index)} alt="image2" />
                        ) : item.status.name === "REQUEST_REJECTED" ? (
                          <img src={offline} alt="image2" />
                        ) : (
                          <img src={offline} alt="image2" />
                        )}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: "right", paddingRight: "1vw" }}
                    >
                      {item.isVerified ? (
                        <img
                          className="verified-image"
                          src={verified}
                          alt="image4"
                        />
                      ) : (
                        <br />
                      )}
                    </Grid>
                  </Grid>
                  <CardContent
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      xs={12}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gridTemplateRows: "auto",
                        textAlign: "-moz-center",
                      }}
                    >
                      <Grid
                        item
                        className="top-card"
                        style={{ paddingLeft: "1vw" }}
                      >
                        <Card raised={true} className="card-size-ey">
                          <Link
                            to={{
                              pathname:
                                "/portal/parking-details/" + item?.parkingId,
                              state: {
                                parkingId: item?.parkingId,
                                parkingName: item?.name,

                                slotCard: true,
                              },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{ visibility: "hidden", height: "1.4vw" }}
                            >
                              <img src={red} alt="warning" />
                            </Grid>
                            <Grid
                              className="cardSizeRes"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 1.2fr",
                                gridTemplateRows: "auto",
                              }}
                            >
                              <img
                                className="imgCardDevEy"
                                src={EYAvaiibility}
                                alt="icon"
                              />
                              <div className="mt-3">
                                <p className="sourceLabel">Live Availibility</p>
                                <span className="sourceValue">4 Wheeler</span>
                              </div>
                              <div className="pzCount">
                                <span>
                                  {item?.vehicleTypeInfo.find(
                                    (x) => x.vehicleType.uid == "1"
                                  )
                                    ? item?.vehicleTypeInfo[0]?.slotUtilization
                                        ?.vacantSlots
                                    : "-"}
                                </span>
                              </div>
                            </Grid>
                          </Link>
                          <Grid
                            item
                            xs={12}
                            style={{
                              height: "1.9vw",
                              display: "grid",
                              justifyItems: "end",
                              paddingRight: "1vw",
                            }}
                          >
                            <Link
                              to={{
                                pathname:
                                  "/portal/parking-details/" + item?.parkingId,
                                state: {
                                  parkingId: item?.parkingId,
                                  parkingName: item?.name,

                                  bookingCard: true,
                                  parked: true,
                                },
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "0.8vw",
                                  color: "rgb(3, 135, 200)",
                                  letterSpacing: "0.3px",
                                }}
                              >
                                {" "}
                                {`View parked Vehicles >`}
                              </p>
                            </Link>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        className="top-card"
                        style={{ paddingLeft: "1vw" }}
                      >
                        <Card raised={true} className="card-size-ey">
                          <Link
                            to={{
                              pathname:
                                "/portal/parking-details/" + item?.parkingId,
                              state: {
                                parkingId: item?.parkingId,
                                parkingName: item?.name,
                                bookingCard: true,
                                parked: false,
                              },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{ visibility: "hidden", height: "1.8vw" }}
                            >
                              <img src={red} alt="warning" />
                            </Grid>
                            <Grid
                              className="cardSizeRes"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 2fr 1.2fr",
                                gridTemplateRows: "auto",
                              }}
                            >
                              <img
                                className="imgCardDevEy"
                                src={EYBooking}
                                alt="icon"
                              />
                              <div className="mt-3">
                                <p className="sourceLabel">Today's</p>
                                <span className="pzValue">Bookings</span>
                              </div>
                              <div className="bookingCount">
                                <span>{item?.bookingCount}</span>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ visibility: "hidden", height: "1.9vw" }}
                            >
                              <img src={red} alt="warning" />
                            </Grid>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        className="top-card"
                        style={{ paddingLeft: "1vw" }}
                      >
                        <Card raised={true} className="card-size-ey">
                          <Link to={{
                        pathname: "/portal/Noshows",
                        state: {
                          parkingId: item?.parkingId,
                          parkingName: item?.name,

                          noShowReportClick:true
                        }
                      }}
                        style={{ textDecoration: 'none' }}
                      >
                          <Grid
                            item
                            xs={12}
                            style={{ visibility: "hidden", height: "1.8vw" }}
                          >
                            <img src={red} alt="warning" />
                          </Grid>
                          <Grid
                            className="cardSizeRes"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr 1.2fr",
                              gridTemplateRows: "auto",
                            }}
                          >
                            <img
                              className="imgCardDevEy"
                              src={NoShow}
                              alt="icon"
                            />
                            <div className="mt-3">
                              <p className="sourceLabel">Today's</p>
                              <span
                                className="sourceValue"
                                style={{ color: "#e46a76" }}
                              >
                                No-Shows
                              </span>
                            </div>
                            <div className="pzCount">
                              <span>{item?.noShowCount}</span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ visibility: "hidden", height: "1.4vw" }}
                          >
                            <img src={red} alt="warning" />
                          </Grid>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        className="top-card"
                        style={{ paddingLeft: "1vw", paddingRight: "1vw" }}
                      >
                        <Card raised={true} className="card-size-ey">
                          <Link to={{
                        pathname: "/portal/Releasefailure",
                        state: {
                          parkingId: item?.parkingId,
                          parkingName: item?.name,

                          releaseFailureCard:true
                        }
                      }}
                        style={{ textDecoration: 'none' }}
                      >
                          <Grid
                            item
                            xs={12}
                            style={{ visibility: "hidden", height: "1.8vw" }}
                          >
                            <img src={red} alt="warning" />
                          </Grid>
                          <Grid
                            className="cardSizeRes"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr 1.2fr",
                              gridTemplateRows: "auto",
                            }}
                          >
                            <img
                              className="imgCardDevEy"
                              src={relFail}
                              alt="icon"
                            />
                            <div className="mt-3">
                              <p className="sourceLabel">Today's</p>
                              <span
                                className="sourceValue"
                                style={{
                                  color: "rgb(244, 157, 62)",
                                  fontSize: "0.9vw",
                                  textAlign: "initial",
                                }}
                              >
                                Release Failures
                              </span>
                            </div>
                            <div className="pzCount">
                              <span>{item?.releaseFailuresCount}</span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ visibility: "hidden", height: "1.4vw" }}
                          >
                            <img src={red} alt="warning" />
                          </Grid>
                          </Link>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ClickAwayListener>
      )}
    </StoreContext.Consumer>
  );
};

//function for redux connect
export default EYDashboardOverview;
