// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import FileViewer from "../../../Components/pdf/FileViewer";
import TermsConditions from "../../../Assets/pdf/PZ Microapp - Terms of Use.pdf";

//@component
//View
function TermsAndConditions(props) {
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("Terms and Conditions"));
  }, []);

  return (
    <>
      <FileViewer pdfFile={TermsConditions} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(
  connect(null, mapDispatchToProps)(TermsAndConditions)
);
