// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useEffect, useState } from "react";

//import StoreReducer from '../../context/DashboardReducer';

import "./style.scss";
import "../../Assets/Scss/style.scss";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import {
  updateBreadcrumbs,
  updateBubble,
  updateSidebar,
} from "../../redux/modules/app/actions";

import Chart from "../../Components/Charts/utilization/Chart";
import Newcard from "../../Components/Cards/Newcard";

import "react-multi-carousel/lib/styles.css";
import StoreReducer from "../../context/DashboardReducer";
import { Link } from "react-router-dom";
import Input from "../../Components/Input/Input";
import "./style.scss";
import Violation from "./Violation";
import Alert from "./Alert";

import httpRequest from "../../Utils/httpRequest";
import useIsMobile from "../../Utils/useIsMobile";
//@component
//Dashboard
const DashboardNew = (props) => {
  const [deviceCoupleList, setdeviceCoupled] = useState([]);
  const [overview, setOverview] = useState([]);
  const [display, setDisplay] = useState({});
  const [access, displayAccess] = useState({});
  const [anpr, setanpr] = useState(0);
  const [nonanpr, setnonanpr] = useState(0);
  const isMobile = useIsMobile();
  const getDeviceCoupledList = () => {
    httpRequest.get(
      "devCoupledParkingList",
      {},
      (json) => {
        setdeviceCoupled(json.data);
      },
      () => {},
      {
        // userId: getCookie("userId"),
      }
    );
  };

  const getOverview = () => {
    httpRequest.get(
      "parkingListDashboardAsc",
      {},
      (json) => {
        setOverview(json.data);
      },
      (e) => {
        console.log(e);
      },
      {}
    );
  };

  useEffect(() => {
    console.log(access);
    console.log(display);
  });
  //called when component is mounted
  useEffect(() => {
    props.updateBreadcrumbs("home");
    props.updateBubble(null);
    props.updateSidebar(0);
    getDeviceCoupledList();
    getOverview();
  }, []);

  useEffect(() => {
    setanpr(access?.anprAccessCount2w + access?.anprAccessCount4w);
    setnonanpr(access?.nonAnprAccessCount2w + access?.nonAnprAccessCount2w);
  }, [access]);

  const item = [];
  const data = {
    datasets: [
      {
        label: "Access control",
        data: [anpr, nonanpr],
        backgroundColor: ["#BB83CA", "#D49D33"],
        borderColor: ["#BB83CA", "#D49D33"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <StoreReducer>
      <div
        className="availability-container"
        style={{
          width: "80.67vw",
          background: "linear-gradient(90deg, #005691 0%, #0D384E 100%)",
          margin: "2.342vw",
          // display: "grid",
          borderRadius: "0.65vw",
        }}
      >
        <div
          style={{
            height: "3.98vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 1.46vw",
          }}
        >
          <p
            style={{
              color: "#F8F7FD",
              fontSize: "1.171vw",
              fontWeight: 400,
              lineHeight: "1.22vw",
              letterSpacing: "0em",
            }}
          >
            Parking Availability
          </p>
          <a
            style={{
              color: "#F8F7FD",
              fontSize: "1.028vw",
              fontWeight: 400,
              lineHeight: "1.22vw",
              letterSpacing: "0em",
              cursor: "pointer",
            }}
          >
            {" "}
            {
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/portal/parkingmanagment"
              >
                {" "}
                {`View all >`}{" "}
              </Link>
            }
          </a>
        </div>
        <div style={{ width: "76.64vw", marginLeft: "1.756vw" }}>
          <Newcard />
        </div>
        <div style={{ height: "2.342vw" }}></div>
      </div>
      <div
        className="components"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1.0833fr 1.203fr",
          gridGap: "2.342vw",

          margin: "0 2.342vw 2.342vw 2.342vw",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            className: "accesscontrol-container",
            borderRadius: "0.65vw",

            zIndex: "5",
            padding: "1.75vw",
          }}
        >
          <div
            style={{
              marginBottom: "1.9vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b
              style={{
                color: "black",
                textAlign: "left",
                fontSize: "1.17vw",
              }}
            >
              {" "}
              Access control
            </b>
          </div>

          <Input
            deviceList={deviceCoupleList}
            setdeviceList={setdeviceCoupled}
            overview={overview}
            setDisplay={displayAccess}
          />

          <div
            style={{
              width: "100%",
              marginBottom: "1.8vw",
              marginTop: "1.83vw",
            }}
            className="doughnut-container"
          >
            <Doughnut data={data} />
          </div>

          <div style={{}}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "4fr 2fr",

                position: "relative",
                zIndex: "10",
                marginBottom: "1vw",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 3fr",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "1vw",
                    width: "1vw",
                    backgroundColor: "#BB83CA",
                    marginRight: "0.43vw",
                  }}
                ></div>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.09vw",
                    // position: "absolute",
                    // right: "0",
                  }}
                >
                  Vehicles getting automated access
                </p>
              </div>

              <p
                style={{
                  fontSize: "1.02vw",
                  color: "#6D6D6D",
                  position: "absolute",
                  right: "0",
                  cursor: "pointer",
                }}
              >{`View all >`}</p>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "4fr 2fr",

                position: "relative",
                zIndex: "10",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 3fr",
                  placeContent: "center",
                }}
              >
                <div
                  style={{
                    height: "1vw",
                    width: "1vw",
                    backgroundColor: "#D49D33",
                    marginRight: "0.43vw",
                  }}
                ></div>

                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.09vw",
                  }}
                >
                  Unregistered users entering the parking space
                </p>
              </div>

              <p
                style={{
                  fontSize: "1.02vw",
                  color: "#6D6D6D",
                  position: "absolute",
                  right: "0",
                  cursor: "pointer",
                }}
              >{`View all >`}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            // background: "linear-gradient(90deg, #005691 0%, #0D384E 100%)",
            backgroundColor: "white",

            borderRadius: "0.65vw",
            padding: "1.75vw",
          }}
        >
          <div
            style={{
              marginBottom: "1.9vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b
              style={{
                color: "black",
                textAlign: "left",
                fontSize: "1.17vw",
              }}
            >
              {" "}
              Violations
            </b>
          </div>

          <Input
            deviceList={deviceCoupleList}
            setdeviceList={setdeviceCoupled}
            overview={overview}
            setDisplay={setDisplay}
          />
          <Violation
            value={display?.overnightParkingRequestPendingCount}
            text="Overnight Request Pending"
            color="#D49D33"
          />
          <Violation
            value={display?.overnightViolationCount}
            text="Overnight Parking Violation"
            color="#008DCE"
          />
          <Violation text="Overnight Violation" color="#359942" />
          <Violation text="Users Emergency Tickets" color="#E04A58" />
        </div>

        <div
          style={{
            //background: "linear-gradient(90deg, #005691 0%, #0D384E 100%)",
            backgroundColor: "white",
            borderRadius: "0.65vw",
            padding: "1.75vw",
          }}
        >
          <div
            style={{
              marginBottom: "1.9vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b
              style={{
                color: "black",
                textAlign: "left",
                fontSize: "1.17vw",
              }}
            >
              {" "}
              Alerts
            </b>
            <p
              style={{
                fontSize: "1.02vw",
                color: "#6D6D6D",
                cursor: "pointer",
              }}
            >{`View all >`}</p>
          </div>

          <Alert />
        </div>
      </div>
      <div
        style={{
          height: "30.18vw",
          width: "80.67vw",
          // background:
          //   "linear-gradient(90deg, rgb(0, 86, 145) 0%, rgb(13, 56, 78) 100%)",
          margin: "0 2.342vw 2.342vw 2.342vw",
          textAlign: "center",
          borderRadius: "0.65vw",
        }}
        className="graph-container"
      >
        {/* <b style={{ color: "white" }}>Utilization Charts</b> */}
        <Chart />
      </div>
    </StoreReducer>
  );
};
//function for redux connect
export default connect(null, {
  updateBreadcrumbs,
  updateBubble,
  updateSidebar,
})(DashboardNew);
