// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles } from "@material-ui/core/styles";

//@component
//reportUseStyles
const listviewUseStylesBooking = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#e46a76 !important",
      color: "#ffffff",
      fontWeight: "900",
    },
    "& .MuiPaginationItem-root": {
      fontFamily: "'Poppins' !important",
      fontSize: "14px",
    },
  },
  labelStyle: {
    paddingTop: "12px",
  },
  liStyle: {
    display: "flex",
  },
  applyButtonFilter: {
    cursor: "pointer",
    fontFamily: "'Poppins' !important",
    fontSize: "11px",
    fontWeight: "bolder",
    borderRadius: "90px",
    border: "none",
    width: "60%",
    color: "#fff",
    background: "#e46a76",
    padding: "5px 10px",
    marginTop: "4px",
    "&:hover": {
      cursor: "pointer",
      fontFamily: "'Poppins' !important",
      fontSize: "11px",
      fontWeight: "bolder",
      borderRadius: "90px",
      border: "none",
      width: "60%",
      color: "#fff",
      background: "#e46a76",
      padding: "5px 10px",
      textDecoration: "none",
      marginTop: "4px",
    },
    ".MuiButton-outlinedSecondary:hover": {
      border: "none",
    },
  },
  hrStyling: {
    marginTop: "20px",
  },
  liGrid: {
    height: "25px",
  },
  filterLabel: {
    fontFamily: "'Poppins' !important",
    fontSize: "10px",
    color: "#21034F",
  },
  filter_box: {
    right: "0.0001%",
    width: "180px",
    listStyle: "none",
    backgroundColor: "#fff",
    position: "absolute",
    top: "98%",
    zIndex: 1,
    color: "#000",
    fontSize: "10px",
    paddingBottom: "20%",
    borderRadius: "15px",
    boxShadow: "0px 3px 6px #00000029 !important",
    ".MuiButton-outlinedSecondary": {
      border: "none",
    },
    "& li": {
      display: "flex",
      marginRight: "10px",
      marginLeft: "10px",
    },
    "& label": {
      paddingTop: "12px",
    },
  },
  filter_image: {
    width: "65%",
  },
  filter_button: {
    position: "relative",
    border: "none",
    "&:hover": {
      border: "none",
    },
    "& hr": {
      marginLeft: "23.8px",
      marginRight: "23.8px",
    },
  },
  dialog: {
    zIndex: 0,
  },
  topRowGrid: {
    // marginTop: "1px"
  },

  boldText: {
    textWeight: "bold",
  },
  closeIcon: {
    cursor: "pointer",
  },
  notFoundGrid: {
    marginTop: "5%",
  },
  viewTableCell: {
    cursor: "pointer",
    textAlign: "left",
  },
  viewSpan: {
    color: "#03A9F3",
    fontFamily: "'Poppins' !important",
    fontSize: "12px",
    wordBreak: "break-word",
    fontWeight: "500",
    padding: "1%",
  },
  statusRow: {
    fontFamily: "'Poppins' !important",
    fontSize: "12px",
    wordBreak: "break-word",
    fontWeight: "500",
    padding: "1%",
  },
  tableRowNoImage: {
    height: "60vh",
  },
  gridContainerNoImage: {
    position: "absolute",
    paddingBottom: "1%",
    marginBottom: "10px",
  },
  typographyGridNoImage: {
    // marginRight:"20%"
  },
  paginationGrid: {
    marginRight: "3%",
  },
  paginationOuterGrid: {
    marginBottom: "6%",
    padding: "2% 0% 1% 0%",
    paddingRight: "0%",
  },
  pageTextGridSelect: {
    marginTop: "3.8%",
    //marginRight:'1%'
  },
  pageFilterGrid: {
    marginTop: "10%",
  },
  pageTextGrid: {
    marginTop: "3%",
    //marginRight:'1%'
  },
  tableCell: {
    fontFamily: "'Poppins' !important",
    fontSize: "12px",
    color: "#102E35",
    wordBreak: "break-word",
    fontWeight: "500",
    padding: "1%",
  },
  editCross: {
    visibility: "hidden",
  },
  input_cross: {
    // marginLeft:"1em"
  },
  topRowGridParent: {
    height: "32px",
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  selectNavcards: {
    width: "30%",
    height: "80%",
    textAlign: "center",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    marginRight: "5%",
    border: "none",
    borderRadius: "6px",
    background: "#ffffff",
    padding: "0 1px",
    paddingRight: "2%",
    marginTop: "2%",
  },
  aboveRowsGrid: {
    height: "40px",
  },
  aboveTableGrid: {},
  input: {
    width: "100%",
    borderRadius: "20px",
    border: "none",
    outline: "none",
    padding: "10px",
    backgroundColor: "lightgrey",
  },
  rowsLabel: {
    fontSize: "0.7rem",
    marginRight: "3%",
    fontWeight: "bold",
  },
  dropdownNumber: {
    border: "none",
    color: "#a2a2a2",
    outline: "none",
    marginTop: "5%",
  },
  "&.MuiPaper-rounded": {
    borderRadius: "50%",
  },
  selectListCard: {
    fontFamily: "'Poppins' !important",
    fontSize: "small",
    width: "76%",
    height: "42px",
    textAlign: "left",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    border: "none",
    borderRadius: "90px",
    background: "#fff",
    color: "#21034F",
    padding: "4px 10px",
    marginTop: 0,
    borderRight: "10px solid #ffffff",
    "&:focus": {
      outline: "none",
    },
  },

  selectDiv: {
    padding: "4px 4px",
    display: "flex",
    alignItems: "center",
    width: "75%",
    backgroundColor: "#fff",
    borderRadius: "50px",
    variant: "elevation",

    // boxShadow:'0px 3px 6px #00000029 !important'
  },
  searchInput: {
    fontFamily: "'Poppins' !important",
    fontSize: "12px",
    color: "#21034F",
    letterSpacing: "0.5px",
    borderRadius: "90px",
    width: "100%",
    border: "none",
    outline: "none",
    padding: "10px",
    backgroundColor: "lightgrey",
  },
  searchDiv: {
    padding: "1px 1px",
    width: "70%",
    marginLeft: "1.7%",
    backgroundColor: "lightgrey",
    color: "#21034F",
    borderRadius: "90px",
    variant: "elevation",
  },
  searchIconButton: {
    width: "20px",
    fontSize: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    paddingRight: "0px",
    color: "#a1a1a1",
    marginLeft: "5px",
  },
  tableGrid: {
    marginTop: "1%",
    width: "96%",
    maxHeight: "30%",
    marginLeft: "1%",
    display: "flex",
    flexWrap: "nowrap",
  },

  table: {
    // minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "8px",
  },
  thData: {
    fontFamily: "'Poppins' !important",
    fontWeight: "bolder",
    fontSize: "12px",
    color: "#21034F !important",
    padding: "1%",
    backgroundColor: "rgba(226, 220, 242, 0.38)",
    height: "70px",
    wordWrap: "break-word",
    lineHeight: "1.6",
  },
  paper: {
    boxShadow: "0px 3px 6px #00000029 !important",
  },
  paginationStyle: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "90px",
    paddingLeft: "15px",
    boxShadow: "0px 3px 6px #00000029 !important",
  },
  createuserButton: {
    fontFamily: "'Poppins' !important",
    cursor: "pointer",
    borderRadius: "90px",
    border: "none",
    padding: "9px 14px !important",
    width: "100%",
    height: "100%",
    fontSize: "12px",
    fontWeight: 700,
    backgroundColor: "#03A9F3",
    color: "#fff",
    boxShadow: "0px 3px 6px #00000029 !important",
    float: "right",
  },
  viewMore: {
    cursor: "pointer",
    fontStyle: "italic",
    fontSize: "0.65rem",
    marginLeft: "0.5em",
  },
  parkingSpaceGrid: {
    marginTop: "0.25em",
  },
  resolvedButtonGrid: {
    width: "10%",
  },
  resolveButtonGrid: {
    marginTop: "1rem",
  },
  cancelButtonGrid: {
    marginTop: "1rem",
  },
  textareaError: {
    fontSize: "12px",
    color: "red",
  },
  textareaGrid: {
    width: "100%",
  },
  backButton: {
    fontFamily: "'Poppins' !important",
    cursor: "pointer",
    borderRadius: "90px",
    border: "none",
    padding: "9px 27px !important",
    width: "13%",
    fontSize: "12px",
    fontWeight: 900,
    backgroundColor: "#A2A2A2",
    color: "#fff",
    boxShadow: "0px 3px 6px #00000029 !important",
  },
  backButtonGrid: {
    marginTop: "1.3%",
    marginLeft: "1.8%",
    marginBottom: "9.5%",
  },
  subHeaderColumn: {
    fontFamily: "'Poppins' !important",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "1.6",
    marginRight: "15px",
    wordBreak: "break-word",
    textAlign: "justify",
    color: "#102E35",
  },
  headerGrid: {
    marginTop: "0.8%",
    marginLeft: "2.5%",
  },
  headerText: {
    fontFamily: "'Poppins' !important",
    fontWeight: "700",
    fontSize: "18px",
    color: "#21034F",
    lineHeight: "1.6",
  },
  commentParagraph: {
    marginTop: "0.3em",
    wordWrap: "break-word",
    fontSize: "0.85rem",
  },
  descriptionParagraph: {
    marginTop: "0.15em",
    wordWrap: "break-word",
    fontSize: "0.85rem",
  },
  invalidButtonGrid: {
    width: "10%",
    marginLeft: "2em",
  },
  complaintParagraph: {
    fontWeight: "600",
    fontSize: "1rem",
  },
  complaintGrid: {
    width: "70%",
    marginLeft: "2em",
  },
  rowValueSpace: {
    marginLeft: "148px",
  },
  rowSpace: {
    marginTop: "1em",
  },
  statusColumnGrid: {
    right: "50px",
    position: "absolute",
  },
  selectNavCard: {
    height: "4em",
    width: "5em",
    border: 0,
    borderRadius: "22px",
  },
  tableGrid: {
    marginTop: "2em",
    marginBottom: "2em",
    width: "96%",
    height: "50%",
    marginLeft: "1.5em",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bolder",
    // width: 17%;
  },
  detailsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr 3fr)",
    alignItems: "start",
    alignContent: "center",
    paddingLeft: "2%",
    lineHeight:'2'
  },
  OvernightDetailCard: {
    zIndex: 0,
    boxShadow: "0px 3px 6px #00000029 !important",
    marginTop: "24px",
    width: "96%",
    height: "100%",
    marginLeft: "24px",
    // paddingLeft: "2.0em",
    // paddingTop: "2.5em",
    // paddingBottom: "2.5em",
    // paddingRight: "2.5em",
  },
  dialogHeading: {
    fontFamily: "'Poppins' !important",
    fontWeight: 900,
    fontSize: "12px",
    color: "#102E35",
    lineHeight: "1.6",
    marginBottom: "5px",
    marginTop: "3%",
    
  },
  heading : {
    alignItems: 'center'
  },
  valueAlign : {
    alignSelf: 'center'
  },
  statusColors: {
    fontFamily: "'Poppins' !important",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1.6",
    marginBottom: "0.5em",
    color: "green",
  },
  headingRight: {
    fontFamily: "'Poppins' !important",
    fontWeight: "600",
    color: "#21034F",
    fontSize: "14px",
    padding: "1px",
    lineHeight: "1.6",
    textAlign: "start",
  },
  value: {
    fontFamily: "'Poppins' !important",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "1.6",
    color: "#21034F",
    paddingLeft: "10px",
  },
  valueRemarks: {
    fontFamily: "'Poppins' !important",
    fontWeight: "400",
    fontSize: "12px",
    color: "#102E35 !important",
    lineHeight: "1.6",
    paddingLeft: "5px",
    marginRight: "3em",
    wordBreak: "break-word",
    textAlign: "justify",
    marginBottom: "15px",
  },
  statusLog: {
    fontFamily: "'Poppins' !important",
    fontWeight: "400",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1.6",
    wordBreak: "break-word",
    justifyContent: "space-between",
  },
  valueRight: {
    fontFamily: "'Poppins' !important",
    fontWeight: "bolder",
    fontSize: "16px",
    lineHeight: "1.6",
    marginBottom: "5%",
    wordBreak: "break-word",
    textAlign: "start",
  },
  statusChangeButton: {
    ...theme.typography.view,
    borderRadius: 50,
    height: "2.5em",
    width: "10em",
    fontSize: "0.71rem",
    border: "none",
    fontWeight: "600",
  },
  dialogButton: {
    ...theme.typography.view,
    borderRadius: 50,
    color: "#fff",
    height: "2.5em",
    width: "7em",
    fontSize: "0.71rem",
    border: "none",
    fontWeight: "600",
    marginBottom: "2em",
  },

  superAdminComment: {
    fontWeight: "600",
    fontSize: "0.60rem",
  },
  superAdminCommentMargin: {
    marginTop: "3.5em",
    marginBottom: "2em",
  },
  labelMargin: {
    marginTop: "1.5em",
  },
  createdDateGrid: {
    marginLeft: "2em",
    marginTop: "1.5em",
  },
  dialogTextArea: {
    width: "100%",
    height: "3em",
    borderRadius: "5px",
    marginTop: "0.2%",
    color: "#102E35",
    fontFamily: "'Poppins' !important",
    padding: "2%",
    fontSize: "12px",
  },
  dialogTitle: {
    fontFamily: "'Poppins' !important",
    fontSize: "14px",
    fontWeight: 900,
    color: "#21034F",
  },
  dialogCloseIcon: {
    cursor: "pointer",
    fontSize: "14px",
    color: "#21034F",
  },
  statusText: {
    fontWeight: "600",
    fontSize: "0.95rem",
    marginLeft: "2em",
    color: "#F49D3E",
  },
  statusLabel: {
    fontWeight: "600",
    fontSize: "0.60rem",
    marginLeft: "2em",
  },
  complaintDescription: {
    fontSize: "0.65rem",
    maxWidth: "12em",
    height: "4em",
    overflowX: "auto",
    overflowY: "auto",
  },
  gridComplaintDescription: {
    marginLeft: "2em",
    width: "50%",
    marginTop: "2em",
  },
  statebtnapprove: {
    cursor: "pointer",
    border: "none !important",
    fontFamily: "'Poppins' !important",
    borderRadius: "90px !important",
    margin: "auto !important",
    padding: "5px 15px 5px 15px !important",
    backgroundColor: "rgb(3, 169, 243)",
    color: "#ffffff !important",
    width: "175px",
    height: "35px",
    boxShadow: "4px 4px 6px #00000024",
    marginBottom: "30px",
    disabled: {
      backgroundColor: "#ccc",
    },
  },

  statebtnreject: {
    // cursor: "pointer",
    fontFamily: "'Poppins' !important",
    borderRadius: "90px !important",

    margin: "auto !important",
    padding: "5px 15px 5px 15px !important",
    backgroundColor: "lightgray",
    color: "white",
    border: "0",
    width: "175px",
    height: "35px",
    marginBottom: "30px",
  },
  gridValues: {
    marginBottom: "1em",
  },
  dialogActionGrid: {
    marginTop: "0.5em",
  },

  // dialogCancel: {
  //   cursor: "pointer",
  //   fontSize: "12px",
  //   fontWeight: 900,
  //   fontFamily: "'Poppins' !important",
  //   backgroundColor: "#707070 !important",
  //   color: "#fff !important",
  //   borderRadius: "90px !important",
  //   border: "none !important",
  //   width: "90px",
  //   height: "32px",
  //   marginBottom: "3%",
  // },

  headerText: {
    padding: "1rem",
    fontSize: "14px",
    paddingLeft: "3%",
    backgroundColor: "#F4F2FA",
    fontWeight: "700",
    marginBottom: "1%",
  },

  dialogCancel: {
    fontFamily: "'Poppins' !important",
    borderRadius: "90px !important",
    border: "1px solid rgb(3, 169, 243) !important",
    margin: "auto !important",
    padding: "5px 15px 5px 15px !important",
    backgroundColor: "white",
    color: "rgb(3, 169, 243) !important",
    width: "90px",
    height: "35px",
    marginBottom: "30px",
  },

  dialogSubmit: {
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: 900,
    fontFamily: "'Poppins' !important",
    backgroundColor: "#03A9F3",

    color: "#fff !important",
    borderRadius: "90px !important",
    border: "none !important",
    width: "90px",
    height: "32px",
    marginBottom: "3%",
    marginRight: "20px",
  },
}));

export default listviewUseStylesBooking;
