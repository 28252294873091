// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React from "react";

export default React.createContext({
  //context for User Details and Dashboard KPI's
  username: "",
  userOrg: "",
  userLoggedIn: false,
  lastLogin: null,
  cardData: {},
  //context for parking overview
  vehicleType: [
    { name: "Car", value: 1 },
    { name: "Bike", value: 2 },
    { name: "Fleet", value: 3 },
  ],
  parkingData: [],
  deviceCoupleList: [],
  listType: true,
  cardType: false,
  mapType: false,
  isLoading: false,
  filterOption: 1,
  dropDownId: null,
  handleClick: () => {},
  handleCard: () => {},
  handleList: () => {},
  handleMap: () => {},
  handleClickAway: () => {},
  clickBlank: () => {},
  cardClicked: () => {},
  showEditPage: () => {},
  deleteParking: () => {},
  clickButton: () => {},
  getSlotInfo: () => {},
  deleteClickCancel: () => {},
  deleteClick: () => {},
  //context constants
  TempArray: [],
  weekdays: [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ],
  today: new Date().getDay(),
  curTime:
    new Date().getHours() +
    ":" +
    new Date().getMinutes() +
    ":" +
    new Date().getSeconds(),
  list: [],
  iconChange: [],
});
